import React from "react";
import moment from "moment";
import { connect } from "react-redux";
import { Header } from "semantic-ui-react";
import { enums, strings } from "../../resources";
import { referralActions } from "../../../Referrals/store/actions/referral.actions";
import { programmeActions } from "../../../Programmes/store/actions/programme.actions";
import { baselineActions } from "../../../Baselines/store/actions/baseline.actions";
import { assessmentSGActions } from "../../../AssessmentSG/store/actions/assessmentSG.actions";
import { customerActions } from "../../../Customers/store/actions/customer.actions";
import { userActions } from "../../../Users/store/actions/user.actions";
import { taskActions } from "../../../Tasks/store/actions/task.actions";
import { convertToIso, checkFormat } from "../../../App/helpers/dateHelpers";
import { questionsList } from "../../../AssessmentSG/resources/Enums";
import {
  functions as twgValidationFunctions,
  constraints as twgConstraints
} from "pulsion-twg-validation";
import Ocr from "./Ocr";
import { RequestFeedback } from "../../../App/components/";

class OcrContainer extends React.Component {
  state = {
    unknownRequestStatus: false,
    searchCriteria: {
      size: 10000,
      from: 0,
      query: "",
      fields: ["*"],
      clear: false,
      sort: {},
      activeOnly: false
    },
    hasRelatedRecord: true,
    confirmDuplicateModalOpen: false,
    miscRequestStatus: false,
    miscRequestMade: false,
    miscError: "",
    miscResult: "",
    miscErrorDetails: [],
    taskResult: ""
  };

  submitData = async (data, pNumber, type) => {
    let parsedDates = this.parseDates(data);
    if (parsedDates.length === 0) {
      this.setState({
        miscRequestMade: false,
        miscErrorDetails: []
      });

      const sCrit = this.state.searchCriteria;
      let referralId, programmeId;
      data.pNumber = pNumber;
      sCrit.fields = ["pNumber.normalizer"];
      sCrit.query = pNumber;
      await this.props.getProgrammes(
        this.props.headers,
        sCrit.query,
        10000,
        0,
        sCrit.clear,
        sCrit.fields,
        sCrit.sort,
        sCrit.activeOnly
      );

      programmeId = this.props.programmeListData[0]
        ? this.props.programmeListData[0]._id
        : undefined;

      this.chooseEndpoint(type, data, sCrit, programmeId, referralId);
    } else {
      let errorDetails = [];
      parsedDates.forEach((date, index) => {
        errorDetails.push("invalid date format: " + enums.dateStrings[date[0]]);
      });
      this.setState({
        miscRequestMade: true,
        miscErrorDetails: errorDetails,
        miscError: "Date Error"
      });
    }
  };

  parseDates = data => {
    let invalidDates = [];
    Object.entries(data).forEach(entries => {
      if (enums.dateTypes.includes(entries[0])) {
        if (checkFormat(entries[1])) {
          let convertedDate = convertToIso(entries[1]);
          data[entries[0]] = convertedDate;
        } else {
          invalidDates.push(entries);
        }
      }
    });

    return invalidDates;
  };

  chooseEndpoint = async (type, data, sCrit, programmeId, referralId) => {
    switch (type) {
      case enums.formTypes.SPS_EligList:
        this.handleIdentification(data, sCrit);
        break;
      case enums.formTypes.THR_RefForm:
        this.handleThreshold(data);
        break;
      case enums.formTypes.REF_Form:
        this.handleReferralForm(data, sCrit, referralId);
        break;
      case enums.formTypes.EQA_OppForm:
        this.handleEqualOpps(data, sCrit, referralId);
        break;
      case enums.formTypes.SUP_ServiceRef:
        this.handleSupportService(data, sCrit, referralId);
        break;
      case enums.formTypes.PER_ReleasePlan:
        this.handlePersonalRelease(data, programmeId);
        break;
      case enums.formTypes.RSK_Assess:
        this.handleRiskAssessment(data, sCrit, referralId);
        break;
      case enums.formTypes.BAS_MonTool:
        this.handleBaselineAssessment(data, sCrit, programmeId);
        break;
      case enums.formTypes.SGA_Tool:
        this.handleSgaAssessment(data, sCrit, programmeId);
        break;
      default:
        break;
    }
  };

  mergeObjects = (oldData, data) => {
    let mergedObject = JSON.parse(JSON.stringify(data));
    questionsList.forEach(stage => {
      oldData[stage.name].forEach((oldStage, index) => {
        if (
          mergedObject[stage.name][index] === null ||
          mergedObject[stage.name][index] === undefined
        ) {
          mergedObject[stage.name][index] = oldStage;
        }
      });
    });
    return mergedObject;
  };

  handleIdentification = async (data, sCrit) => {
    data.area = strings.areas.NRS;
    data.owner = this.props.organisationOfLoggedInUser;
    await this.props.getCustomerByPNumber(this.props.headers, data.pNumber);
    if (Object.keys(this.props.customerData).length > 0) {
      this.setState({ data: data });
      this.toggleConfirmDuplicateModalVisibility();
    } else {
      data.returningCustomer = strings.fieldValues.no;
      data.sharedWith = [];
      this.props.createReferral(data, this.props.headers);
    }
  };

  handleIdentificationDuplicate = async () => {
    const sCrit = this.state.searchCriteria;
    sCrit.fields = ["pNumber.normalizer"];
    sCrit.query = this.state.data.pNumber;
    let localData = this.state.data;
    let results = twgValidationFunctions.validateData(
      localData,
      twgConstraints.referrals.referralIdentification
    );
    if (results) {
      this.setState({
        miscRequestMade: true,
        miscErrorDetails: Object.values(results),
        miscError: strings.userMessage.validationError
      });
    } else {
      await this.props.getUsers(
        this.props.headers,
        "",
        10000,
        0,
        sCrit.clear,
        [],
        sCrit.sort,
        true
      );
      await this.props.getReferrals(
        this.props.headers,
        sCrit.query,
        10000,
        0,
        sCrit.clear,
        sCrit.fields,
        sCrit.sort,
        sCrit.activeOnly
      );
      let numberOfJournies = this.props.referralListData.length;
      localData.returningCustomer = strings.fieldValues.yes;
      localData.journeyCount = numberOfJournies + 1;
      let taskDetails = this.createTaskDetails(localData);
      this.props.createTask(taskDetails, this.props.headers);
      this.setState({ taskResult: strings.userMessage.duplicateRequest });
    }
  };

  handleThreshold = data => {
    data.area = strings.areas.NRNE;
    data.owner = this.props.organisationOfLoggedInUser;
    data.sharedWith = [];
    this.props.createReferral(data, this.props.headers);
  };

  handleEqualOpps = async (data, sCrit, referralId) => {
    data.formType = "EqualOpportunities";
    await this.props.getReferrals(
      this.props.headers,
      sCrit.query,
      10000,
      0,
      sCrit.clear,
      sCrit.fields,
      sCrit.sort,
      sCrit.activeOnly
    );
    referralId = this.props.referralListData[0]
      ? this.props.referralListData[0]._id
      : undefined;
    if (!referralId) {
      this.setState({ hasRelatedRecord: false });
    } else {
      this.setState({ hasRelatedRecord: true });
      data = { ...this.props.referralListData[0]._source, ...data };
      await this.props.updateReferral(referralId, data, this.props.headers, {});
    }
  };

  handleReferralForm = async (data, sCrit, referralId) => {
    data.formType = "ReferralOcr";
    await this.props.getReferrals(
      this.props.headers,
      sCrit.query,
      10000,
      0,
      sCrit.clear,
      sCrit.fields,
      sCrit.sort,
      sCrit.activeOnly
    );
    referralId = this.props.referralListData[0]
      ? this.props.referralListData[0]._id
      : undefined;
    if (!referralId) {
      this.setState({ hasRelatedRecord: false });
    } else {
      this.setState({ hasRelatedRecord: true });
      data = { ...this.props.referralListData[0]._source, ...data };
      await this.props.updateReferral(referralId, data, this.props.headers, {});
    }
  };

  handleSupportService = async (data, sCrit, referralId) => {
    data.formType = "SupportService";
    await this.props.getReferrals(
      this.props.headers,
      sCrit.query,
      10000,
      0,
      sCrit.clear,
      sCrit.fields,
      sCrit.sort,
      sCrit.activeOnly
    );
    referralId = this.props.referralListData[0]
      ? this.props.referralListData[0]._id
      : undefined;
    if (!referralId) {
      this.setState({ hasRelatedRecord: false });
    } else {
      this.setState({ hasRelatedRecord: true });
      data = { ...this.props.referralListData[0]._source, ...data };
      await this.props.updateReferral(referralId, data, this.props.headers, {});
    }
  };

  handlePersonalRelease = async (data, programmeId) => {
    if (!programmeId) {
      this.setState({ hasRelatedRecord: false });
    } else {
      this.setState({ hasRelatedRecord: true });
      data.formType = "PersonalReleasePlan";
      data = { ...this.props.programmeListData[0]._source, ...data };
      await this.props.updateProgramme(
        programmeId,
        data,
        this.props.headers,
        {}
      );
    }
  };

  handleRiskAssessment = async (data, sCrit, referralId) => {
    data.formType = "RiskAssessment";
    await this.props.getReferrals(
      this.props.headers,
      sCrit.query,
      10000,
      0,
      sCrit.clear,
      sCrit.fields,
      sCrit.sort,
      sCrit.activeOnly
    );
    referralId = this.props.referralListData[0]
      ? this.props.referralListData[0]._id
      : undefined;
    if (!referralId) {
      this.setState({ hasRelatedRecord: false });
    } else {
      this.setState({ hasRelatedRecord: true });
      data = { ...this.props.referralListData[0]._source, ...data };
      await this.props.updateReferral(referralId, data, this.props.headers, {});
    }
  };

  handleBaselineAssessment = async (data, sCrit, programmeId) => {
    if (!programmeId) {
      this.setState({ hasRelatedRecord: false });
    } else {
      this.setState({ hasRelatedRecord: true });
      data.type = "BA";
      if (this.props.programmeListData[0]) {
        data.owner = this.props.programmeListData[0]._source.owner;
        data.programmeId = programmeId;
        data.programmeCrmId = this.props.programmeListData[0]._source.crmId;
        data.sNumber = this.props.programmeListData[0]._source.scroNumber;
        data.area = this.props.programmeListData[0]._source.area;
        await this.props.getBaselinesByProgramme(
          this.props.headers,
          sCrit.query,
          10000,
          0,
          sCrit.clear,
          sCrit.fields,
          sCrit.sort,
          sCrit.activeOnly,
          programmeId,
          data.type
        );
      }

      if (this.props.baselineId) {
        await this.props.getBaselineById(
          this.props.baselineId,
          this.props.headers
        );
        data = { ...this.props.selectedBaselineData, ...data };
        await this.props.updateBaseline(
          this.props.baselineId,
          data,
          this.props.headers,
          {}
        );
      } else {
        await this.props.createBaseline(data, this.props.headers);
      }
    }
  };

  handleSgaAssessment = async (data, sCrit, programmeId) => {
    if (!programmeId) {
      this.setState({ hasRelatedRecord: false });
    } else {
      this.setState({ hasRelatedRecord: true });
      data.type = "SGA";
      if (this.props.programmeListData[0]) {
        data.owner = this.props.programmeListData[0]._source.owner;
        data.programmeId = programmeId;
        data.programmeCrmId = this.props.programmeListData[0]._source.crmId;
        data.sNumber = this.props.programmeListData[0]._source.sNumber;
        data.area = this.props.programmeListData[0]._source.area;
        data.specialist = this.props.programmeListData[0]._source.mentor
          ? this.props.programmeListData[0]._source.mentor
          : this.props.programmeListData[0]._source.communityMentor;
        await this.props.getAssessmentSGByProgramme(
          this.props.headers,
          sCrit.query,
          10000,
          0,
          sCrit.clear,
          sCrit.fields,
          sCrit.sort,
          sCrit.activeOnly,
          programmeId,
          data.type
        );
      }
      if (this.props.assessmentId) {
        await this.props.getAssessmentSGById(
          this.props.assessmentId,
          this.props.headers
        );
        data = this.mergeObjects(this.props.selectedAssessmentSGData, data);
        await this.props.updateAssessmentSG(
          this.props.assessmentId,
          data,
          this.props.headers,
          {}
        );
      } else {
        await this.props.createAssessmentSG(data, this.props.headers);
      }
    }
  };

  toggleConfirmDuplicateModalVisibility = () => {
    this.setState({
      confirmDuplicateModalOpen: !this.state.confirmDuplicateModalOpen
    });
  };

  createTaskDetails = data => {
    let assignedSpecialistIds = [];
    this.props.userListData.forEach(user => {
      if (
        user._source.roleId === strings.fieldValues.teamLead &&
        (user._source.area === data.area ||
          user._source.area === strings.areas.both)
      ) {
        assignedSpecialistIds.push(user._id);
      }
    });
    let returnData = {};
    returnData.assignedSpecialistIds = assignedSpecialistIds;
    returnData.type = "duplicateWarning";
    returnData.customerForename = data.firstName_initial;
    returnData.customerSurname = data.surname_initial;
    returnData.prisonNumber = data.pNumber;
    returnData.dueDate = moment().add(2, "w").toISOString();
    returnData.details = data;
    returnData.gcrId = this.props.customerData[0].gcrId;
    return returnData;
  };

  render() {
    return (
      <>
        <Header className="ocrHeader" as="h1">
          {strings.scanner.headerList}
        </Header>
        <RequestFeedback
          requestStatus={this.props.referralRequestStatus}
          requestMade={this.props.referralRequestMade}
          unknownRequestStatus={this.state.unknownRequestStatus}
          successMessage={this.props.referralResult}
          failureMessage={this.props.referralError}
          processingFeedbackMessage={strings.scanner.feedback.processing}
          unknownFeedbackMessage={strings.scanner.feedback.RequestUnknown}
          statusFeedbackMessage={strings.scanner.feedback.status}
          successFeedbackMessage={strings.scanner.feedback.success}
          errorDetails={this.props.referralErrorDetails}
        />
        <RequestFeedback
          requestStatus={this.props.programmeRequestStatus}
          requestMade={this.props.programmeRequestMade}
          unknownRequestStatus={this.state.unknownRequestStatus}
          successMessage={this.props.programmeResult}
          failureMessage={this.props.programmeError}
          processingFeedbackMessage={strings.scanner.feedback.processing}
          unknownFeedbackMessage={strings.scanner.feedback.RequestUnknown}
          statusFeedbackMessage={strings.scanner.feedback.status}
          successFeedbackMessage={strings.scanner.feedback.success}
          errorDetails={this.props.programmeErrorDetails}
        />
        <RequestFeedback
          requestStatus={this.props.baselineRequestStatus}
          requestMade={this.props.baselineRequestMade}
          unknownRequestStatus={this.state.unknownRequestStatus}
          successMessage={this.props.baselineResult}
          failureMessage={this.props.baselineError}
          processingFeedbackMessage={strings.scanner.feedback.processing}
          unknownFeedbackMessage={strings.scanner.feedback.RequestUnknown}
          statusFeedbackMessage={strings.scanner.feedback.status}
          successFeedbackMessage={strings.scanner.feedback.success}
          errorDetails={this.props.baselineErrorDetails}
        />
        <RequestFeedback
          requestStatus={this.props.assessmentSGRequestStatus}
          requestMade={this.props.assessmentSGRequestMade}
          unknownRequestStatus={this.state.unknownRequestStatus}
          successMessage={this.props.assessmentResult}
          failureMessage={this.props.assessmentError}
          processingFeedbackMessage={strings.scanner.feedback.processing}
          unknownFeedbackMessage={strings.scanner.feedback.RequestUnknown}
          statusFeedbackMessage={strings.scanner.feedback.status}
          successFeedbackMessage={strings.scanner.feedback.success}
          errorDetails={this.props.assessmentErrorDetails}
        />
        <RequestFeedback
          requestStatus={this.props.taskRequestStatus}
          requestMade={this.props.taskRequestMade}
          unknownRequestStatus={this.state.unknownRequestStatus}
          successMessage={this.state.taskResult}
          failureMessage={this.props.taskError}
          processingFeedbackMessage={strings.scanner.feedback.processing}
          unknownFeedbackMessage={strings.scanner.feedback.RequestUnknown}
          statusFeedbackMessage={strings.scanner.feedback.status}
          successFeedbackMessage={strings.scanner.feedback.success}
          errorDetails={this.props.taskErrorDetails}
        />
        <RequestFeedback
          requestStatus={this.state.miscRequestStatus}
          requestMade={this.state.miscRequestMade}
          unknownRequestStatus={this.state.unknownRequestStatus}
          successMessage={this.state.miscResult}
          failureMessage={this.state.miscError}
          processingFeedbackMessage={strings.scanner.feedback.processing}
          unknownFeedbackMessage={strings.scanner.feedback.RequestUnknown}
          statusFeedbackMessage={strings.scanner.feedback.status}
          successFeedbackMessage={strings.scanner.feedback.success}
          errorDetails={this.state.miscErrorDetails}
        />
        <Ocr
          submitData={this.submitData}
          hasRelatedRecord={this.state.hasRelatedRecord}
          confirmDuplicateModalOpen={this.state.confirmDuplicateModalOpen}
          toggleConfirmDuplicateModalVisibility={
            this.toggleConfirmDuplicateModalVisibility
          }
          handleModalConfirm={this.handleIdentificationDuplicate}
        />
      </>
    );
  }
}
const mapStateToProps = state => {
  const {
    referrals,
    programmes,
    baselines,
    assessmentSG,
    customers,
    auth,
    users,
    tasks
  } = state;
  const { userListData } = users;
  const {
    referralListData,
    loadingPage: referralLoadingPage,
    referralRequestStatus,
    referralRequestMade,
    result: referralResult,
    error: referralError,
    errorDetails: referralErrorDetails
  } = referrals;
  const {
    programmeListData,
    loadingPage: programmeLoadingPage,
    programmeRequestStatus,
    programmeRequestMade,
    result: programmeResult,
    error: programmeError,
    errorDetails: programmeErrorDetails
  } = programmes;
  const {
    baselineId,
    loadingPage: baselineLoadingPage,
    selectedBaselineData,
    baselineRequestStatus,
    baselineRequestMade,
    result: baselineResult,
    error: baselineError,
    errorDetails: baselineErrorDetails
  } = baselines;
  const {
    assessmentId,
    loadingPage: assessmentLoadingPage,
    selectedAssessmentSGData,
    assessmentSGRequestStatus,
    assessmentSGRequestMade,
    result: assessmentResult,
    error: assessmentError,
    totalResults: assessmentTotalResults,
    errorDetails: assessmentErrorDetails
  } = assessmentSG;
  const {
    loadingPage: taskLoadingPage,
    taskRequestStatus,
    taskRequestMade,
    result: taskResult,
    error: taskError,
    totalResults: taskTotalResults,
    errorDetails: taskErrorDetails
  } = tasks;
  const { customerData } = customers;
  const { organisationOfLoggedInUser } = auth;
  return {
    userListData,
    referralListData,
    referralLoadingPage,
    referralRequestStatus,
    referralRequestMade,
    referralResult,
    referralError,
    referralErrorDetails,
    programmeListData,
    programmeRequestStatus,
    programmeRequestMade,
    programmeLoadingPage,
    programmeResult,
    programmeError,
    programmeErrorDetails,
    baselineId,
    baselineLoadingPage,
    selectedBaselineData,
    baselineRequestStatus,
    baselineRequestMade,
    baselineResult,
    baselineError,
    baselineErrorDetails,
    assessmentId,
    assessmentLoadingPage,
    selectedAssessmentSGData,
    assessmentSGRequestStatus,
    assessmentSGRequestMade,
    assessmentResult,
    assessmentError,
    assessmentTotalResults,
    assessmentErrorDetails,
    customerData,
    organisationOfLoggedInUser,
    taskLoadingPage,
    taskRequestStatus,
    taskRequestMade,
    taskResult,
    taskError,
    taskTotalResults,
    taskErrorDetails
  };
};

const mapDispatchToProps = dispatch => {
  return {
    createReferral: (data, headers) => {
      dispatch(referralActions.createReferral(data, headers));
    },
    updateReferral: (id, data, headers, initialReferralData) => {
      dispatch(
        referralActions.updateReferral(id, data, headers, initialReferralData)
      );
    },
    getReferrals: async (
      headers,
      queryString,
      size,
      from,
      clear,
      fields,
      sort,
      activeOnly
    ) => {
      await dispatch(
        referralActions.getReferrals(
          headers,
          queryString,
          size,
          from,
          clear,
          fields,
          sort,
          activeOnly
        )
      );
    },
    updateProgramme: (id, data, headers, initialProgrammeData) => {
      dispatch(
        programmeActions.updateProgramme(
          id,
          data,
          headers,
          initialProgrammeData
        )
      );
    },
    getProgrammes: async (
      headers,
      queryString,
      size,
      from,
      clear,
      fields,
      sort,
      activeOnly
    ) => {
      await dispatch(
        programmeActions.getProgrammes(
          headers,
          queryString,
          size,
          from,
          clear,
          fields,
          sort,
          activeOnly
        )
      );
    },
    getBaselinesByProgramme: async (
      headers,
      data,
      size,
      from,
      clear,
      fields,
      sort,
      activeOnly,
      programmeId,
      type
    ) => {
      await dispatch(
        baselineActions.getBaselinesByProgramme(
          headers,
          data,
          size,
          from,
          clear,
          fields,
          sort,
          activeOnly,
          programmeId,
          type
        )
      );
    },
    createBaseline: async (data, headers) => {
      await dispatch(baselineActions.createBaseline(data, headers));
    },
    updateBaseline: (id, data, headers, initialBaselineData) => {
      dispatch(
        baselineActions.updateBaseline(id, data, headers, initialBaselineData)
      );
    },
    getBaselineById: async (id, headers) => {
      await dispatch(baselineActions.getBaselineById(id, headers));
    },
    getAssessmentSGByProgramme: async (
      headers,
      data,
      size,
      from,
      clear,
      fields,
      sort,
      activeOnly,
      programmeId,
      type
    ) => {
      await dispatch(
        assessmentSGActions.getAssessmentSGByProgramme(
          headers,
          data,
          size,
          from,
          clear,
          fields,
          sort,
          activeOnly,
          programmeId,
          type
        )
      );
    },
    createAssessmentSG: async (data, headers) => {
      await dispatch(assessmentSGActions.createAssessmentSG(data, headers));
    },
    updateAssessmentSG: (id, data, headers, initialAssessmentSGData) => {
      dispatch(
        assessmentSGActions.updateAssessmentSG(
          id,
          data,
          headers,
          initialAssessmentSGData
        )
      );
    },
    getAssessmentSGById: async (id, headers) => {
      await dispatch(assessmentSGActions.getAssessmentSGById(id, headers));
    },
    getCustomerByPNumber: async (headers, pNumber) => {
      await dispatch(customerActions.getCustomerByPNumber(headers, pNumber));
    },
    createTask: (details, headers) => {
      dispatch(taskActions.createTask(details, headers));
    },
    getUsers: async (
      headers,
      queryString,
      size,
      from,
      clear,
      fields,
      sort,
      activeOnly
    ) => {
      await dispatch(
        userActions.getUsers(
          headers,
          queryString,
          size,
          from,
          clear,
          fields,
          sort,
          activeOnly
        )
      );
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OcrContainer);
