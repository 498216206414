import React from "react";
import PropTypes from "prop-types";
import { Button, Form, Input } from "semantic-ui-react";
import { strings, costTypeOptions } from "../resources";
import {
  ValidatedFormDropdown,
  ValidatedDateInput,
  ValidatedFormInput
} from "./validation";
import { ComponentHeader, BasicModal, TextInputModal } from ".";
import { withRouter } from "react-router-dom";
import { permissions } from "../../App/resources/AppPermissions";
import {
  dropdownHelper,
  convertFromIso,
  allowCurrencyInput,
  getListOfMentors
} from "../helpers";

class ModifyCost extends React.Component {
  returnToList = () => {
    this.props.history.push("/costs");
  };

  returnToParent = () => {
    this.props.history.push(
      "/programmes/view/" + localStorage.getItem("programmeId")
    );
  };

  renderModifyButtons = () => {
    const { selectedCostData, handleModeSwitch, isDisabled } = this.props;

    const modalTextInput = (
      <Input
        placeholder={strings.form.placeholder.stateReason}
        fluid
        onChange={this.props.handleModalInputChange}
      ></Input>
    );

    return (
      <div className="containerButtons">
        {!this.props.deleteSuccessful &&
          (!isDisabled ? (
            <Button.Group>
              <Button
                className="editButton"
                primary
                onClick={() => {
                  handleModeSwitch("edit");
                }}
                disabled={!selectedCostData.active}
              >
                {strings.button.editLabel}
              </Button>
            </Button.Group>
          ) : (
            <span className="errorText">
              {strings.form.errorText.notShared}
            </span>
          ))}
        {this.props.deletePermissionStatus !== strings.states.approved &&
          !this.props.deleteSuccessful &&
          !isDisabled && (
            <Button.Group>
              <Button
                className="deleteButton"
                secondary
                inverted
                onClick={this.props.toggleEnableDisableCostModalVisibility}
                type="button"
                disabled={this.props.selectedCostData.requestId}
              >
                {strings.button.requestDeleteLabel}
              </Button>
              <TextInputModal
                confirmMessage={strings.modal.requestDeletePermissionMessage}
                handleModalConfirm={this.props.handleModalConfirm}
                modalClassName="requestDeletePermissionModal"
                modalOpen={this.props.enableDisableCostModalOpen}
                showOkButton={true}
                textInput={modalTextInput}
                toggleModal={this.props.toggleEnableDisableCostModalVisibility}
                okLabel={strings.button.submitLabel}
                deletionReason={this.props.deletionReason}
              />
            </Button.Group>
          )}
        {this.props.deletePermissionStatus === strings.states.approved &&
          !this.props.deleteSuccessful && (
            <Button.Group>
              <Button
                className="deleteButton"
                secondary
                onClick={this.props.toggleEnableDisableCostModalVisibility}
                type="button"
              >
                {strings.button.deleteLabel}
              </Button>
              <BasicModal
                confirmMessage={strings.modal.deleteCostMessage}
                handleModalConfirm={this.props.handleModalConfirm}
                modalClassName="deleteModal"
                modalOpen={this.props.enableDisableCostModalOpen}
                showOkButton={false}
                showYesButton={true}
                showNoButton={true}
                toggleModal={this.props.toggleEnableDisableCostModalVisibility}
                noLabel={strings.button.noLabel}
                yesLabel={strings.button.yesLabel}
                okLabel={strings.button.okLabel}
              />
            </Button.Group>
          )}
      </div>
    );
  };
  renderCancelSaveButtons = () => {
    const {
      selectedCostData,
      handleModeSwitch,
      handleModalConfirm,
      toggleCancelChangesModalVisibility,
      toggleConfirmSaveModalVisibility,
      initialCostData,
      validateForm,
      confirmSaveModalOpen,
      mode,
      cancelChangesModalOpen,
      isDisabled
    } = this.props;

    return (
      <div className="containerButtons">
        <Button.Group>
          <Button
            className="saveButton"
            primary
            inverted
            onClick={validateForm}
            disabled={
              JSON.stringify(selectedCostData) ===
                JSON.stringify(initialCostData) || isDisabled
            }
          >
            {strings.button.saveLabel}
          </Button>
          <BasicModal
            confirmMessage={strings.modal.saveChangesMessage}
            handleModalConfirm={handleModalConfirm}
            modalClassName="confirmSaveModal"
            modalOpen={confirmSaveModalOpen}
            showNoButton={true}
            showOkButton={false}
            showYesButton={true}
            toggleModal={toggleConfirmSaveModalVisibility}
            noLabel={strings.button.noLabel}
            yesLabel={strings.button.yesLabel}
            okLabel={strings.button.okLabel}
          />
        </Button.Group>
        <Button.Group>
          <Button
            className="cancelButton"
            primary
            onClick={toggleCancelChangesModalVisibility}
            type="button"
            disabled={isDisabled}
          >
            {strings.button.cancelLabel}
          </Button>
          <BasicModal
            confirmMessage={strings.modal.cancelChangesMessage}
            handleModalConfirm={
              mode === "create" ? handleModalConfirm : handleModeSwitch
            }
            modalClassName="cancelChangesModal"
            modalOpen={cancelChangesModalOpen}
            showOkButton={false}
            showYesButton={true}
            showNoButton={true}
            toggleModal={toggleCancelChangesModalVisibility}
            noLabel={strings.button.noLabel}
            yesLabel={strings.button.yesLabel}
            okLabel={strings.button.okLabel}
          />
        </Button.Group>
      </div>
    );
  };
  renderForm = () => {
    const {
      selectedCostData,
      handleChange,
      mode,
      formInvalid,
      validationResults,
      getOrganisations,
      area,
      userList
    } = this.props;

    return (
      <>
        {mode !== "create" && (
          <div className="infoBlock">
            <div className="infoHeader">
              {strings.form.header.administration}
            </div>
            <div className="infoWrapper">
              <Form.Group>
                <ValidatedFormInput
                  formInvalid={formInvalid}
                  inputData={selectedCostData.crmId}
                  inputLabel={strings.form.label.crmId}
                  inputName="crmId"
                  inputPlaceholder={strings.form.placeholder.crmId}
                  formFieldWidth={8}
                  handleChange={handleChange}
                  validationResult={validationResults.crmId}
                  disabled={mode === "edit"}
                  mode={mode}
                />
                <ValidatedFormDropdown
                  formInvalid={formInvalid}
                  inputData={selectedCostData.owner}
                  viewData={
                    dropdownHelper.getNameFromValues(
                      selectedCostData.owner,
                      getOrganisations()
                    ) || ""
                  }
                  dropdownLabel={strings.form.label.owner}
                  dropdownName="owner"
                  dropdownDisabled={mode === "edit"}
                  dropdownOptions={getOrganisations() || []}
                  dropdownPlaceholder={strings.form.placeholder.owner}
                  formFieldWidth={8}
                  handleChange={handleChange}
                  validationResult={validationResults.owner}
                  mode={mode}
                />
              </Form.Group>
            </div>
          </div>
        )}
        <div className="infoBlock">
          <div className="infoHeader">{strings.form.header.costDetails}</div>
          <div className="infoWrapper">
            <Form.Group>
              <ValidatedDateInput
                inputId="date"
                formInvalid={formInvalid}
                inputLabel={strings.form.label.date}
                inputName="date"
                inputPlaceholder={strings.form.placeholder.date}
                inputDateFormat="DD/MM/YYYY"
                inputData={
                  selectedCostData.date
                    ? convertFromIso(selectedCostData.date)
                    : ""
                }
                iconPosition="left"
                formFieldWidth={8}
                handleChange={handleChange}
                validationResult={validationResults.date}
                mode={mode}
                required={true}
              />
              <ValidatedFormDropdown
                formInvalid={this.props.formInvalid}
                inputData={this.props.selectedCostData.issuedBy}
                viewData={
                  dropdownHelper.getNameFromValues(
                    this.props.selectedCostData.issuedBy,
                    getListOfMentors(
                      area,
                      userList || [],
                      selectedCostData.issuedByOrg
                    )
                  ) || ""
                }
                dropdownLabel={strings.form.label.issuedBy}
                dropdownName="issuedBy"
                dropdownOptions={
                  getListOfMentors(
                    area,
                    userList || [],
                    selectedCostData.issuedByOrg
                  ) || []
                }
                dropdownPlaceholder={strings.form.placeholder.issuedBy}
                formFieldWidth={8}
                handleChange={this.props.handleChange}
                validationResult={this.props.validationResults.issuedBy}
                dropdownFluid={true}
                required={true}
                mode={mode}
              />
            </Form.Group>
            <Form.Group>
              <ValidatedFormInput
                formInvalid={this.props.formInvalid}
                inputData={selectedCostData.amount}
                inputLabel={strings.form.label.amount}
                inputName="amount"
                onKeyPress={allowCurrencyInput}
                inputPlaceholder={strings.form.placeholder.amount}
                formFieldWidth={8}
                handleChange={this.props.handleChange}
                validationResult={this.props.validationResults.amount}
                required={true}
                iconPosition="left"
                icon="gbp"
                mode={mode}
              />
              <ValidatedFormDropdown
                formInvalid={this.props.formInvalid}
                inputData={this.props.selectedCostData.issuedByOrg}
                viewData={
                  dropdownHelper.getNameFromValues(
                    this.props.selectedCostData.issuedByOrg,
                    getOrganisations()
                  ) || ""
                }
                dropdownLabel={strings.form.label.issuedByOrg}
                dropdownName="issuedByOrg"
                dropdownOptions={getOrganisations() || []}
                dropdownPlaceholder={strings.form.placeholder.issuedByOrg}
                formFieldWidth={8}
                handleChange={this.props.handleChange}
                validationResult={this.props.validationResults.issuedByOrg}
                dropdownFluid={true}
                required={true}
                mode={mode}
              />
            </Form.Group>
            <Form.Group>
              <ValidatedFormDropdown
                formInvalid={formInvalid}
                inputData={selectedCostData.type}
                viewData={selectedCostData.type}
                dropdownLabel={strings.form.label.type}
                dropdownName="type"
                dropdownOptions={costTypeOptions || []}
                dropdownPlaceholder={strings.form.placeholder.type}
                formFieldWidth={8}
                handleChange={handleChange}
                validationResult={validationResults.type}
                required={true}
                mode={mode}
              />
              {selectedCostData.type === strings.fieldValues.other && (
                <ValidatedFormInput
                  formInvalid={formInvalid}
                  inputData={selectedCostData.otherType}
                  inputLabel={strings.form.label.otherType}
                  inputName="otherType"
                  inputPlaceholder={strings.form.placeholder.otherType}
                  formFieldWidth={8}
                  handleChange={handleChange}
                  validationResult={validationResults.otherType}
                />
              )}
            </Form.Group>
          </div>
        </div>
      </>
    );
  };

  render = () => {
    const { mode, formInvalid, pageTitle, requestStatus } = this.props;

    let parentPage = localStorage.getItem("programmeName")
      ? localStorage.getItem("programmeName")
      : undefined;

    return (
      <div className="container">
        <ComponentHeader
          parentPage={parentPage}
          returnToParent={this.returnToParent}
          returnToList={this.returnToList}
          pageTitle={pageTitle}
          mode={mode}
          requestStatus={requestStatus}
          modifyButtons={this.renderModifyButtons()}
          cancelSaveButtons={this.renderCancelSaveButtons()}
          headerList={strings.header.costList}
          permissionsUpdate={permissions.costs.update}
          permissionsCreate={permissions.costs.create}
          formInvalid={formInvalid}
          validationResults={this.props.validationResults}
          sticky
        />

        <Form id="costForm" className="stickyForm">
          <fieldset disabled={mode === "view"}>{this.renderForm()}</fieldset>
        </Form>
      </div>
    );
  };
}

ModifyCost.propTypes = {
  mode: PropTypes.string.isRequired,
  selectedCostData: PropTypes.object.isRequired,
  pageTitle: PropTypes.string.isRequired,
  confirmSaveModalOpen: PropTypes.bool.isRequired,
  cancelChangesModalOpen: PropTypes.bool.isRequired,
  toggleConfirmSaveModalVisibility: PropTypes.func.isRequired,
  toggleCancelChangesModalVisibility: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleModalConfirm: PropTypes.func.isRequired,
  formInvalid: PropTypes.bool.isRequired,
  validationResults: PropTypes.object,
  validateForm: PropTypes.func.isRequired,
  requestStatus: PropTypes.bool,
  initialCostData: PropTypes.object.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  handleModalInputChange: PropTypes.func
};

export default withRouter(ModifyCost);
