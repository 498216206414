import React from "react";
import PropTypes from "prop-types";
import { Button, Form, Input } from "semantic-ui-react";
import { strings } from "../resources";
import { ValidatedFormInput, ValidatedFormDropdown } from "./validation";
import { ComponentHeader, BasicModal, TextInputModal } from ".";
import "./css/BaselineComponents.scss";
import { withRouter } from "react-router-dom";
import { permissions } from "../../App/resources/AppPermissions";
import { allowNumericalInput, dropdownHelper } from "../helpers";
import ValidatedBaselineAssessment from "./validation/ValidatedBaselineAssessment";

class ModifyBaseline extends React.Component {
  returnToParent = () => {
    this.props.history.push(
      "/programmes/view/" + localStorage.getItem("programmeId")
    );
  };

  renderModifyButtons = () => {
    const { isDisabled } = this.props;

    const modalTextInput = (
      <Input
        placeholder={strings.form.placeholder.stateReason}
        fluid
        onChange={this.props.handleModalInputChange}
      ></Input>
    );

    return (
      <div className="containerButtons">
        {!this.props.deleteSuccessful &&
          (!isDisabled ? (
            <Button.Group>
              <Button
                className="editButton"
                primary
                onClick={() => {
                  this.props.handleModeSwitch("edit");
                }}
                disabled={!this.props.selectedBaselineData.active}
              >
                {strings.button.editLabel}
              </Button>
            </Button.Group>
          ) : (
            <span className="errorText">
              {strings.form.errorText.notShared}
            </span>
          ))}
        {this.props.deletePermissionStatus !== strings.states.approved &&
          !this.props.deleteSuccessful &&
          !isDisabled && (
            <Button.Group>
              <Button
                className="deleteButton"
                secondary
                inverted
                onClick={this.props.toggleEnableDisableBaselineModalVisibility}
                type="button"
                disabled={this.props.selectedBaselineData.requestId}
              >
                {strings.button.requestDeleteLabel}
              </Button>
              <TextInputModal
                confirmMessage={strings.modal.requestDeletePermissionMessage}
                handleModalConfirm={this.props.handleModalConfirm}
                modalClassName="requestDeletePermissionModal"
                modalOpen={this.props.enableDisableBaselineModalOpen}
                showOkButton={true}
                textInput={modalTextInput}
                toggleModal={
                  this.props.toggleEnableDisableBaselineModalVisibility
                }
                okLabel={strings.button.submitLabel}
                deletionReason={this.props.deletionReason}
              />
            </Button.Group>
          )}
        {this.props.deletePermissionStatus === strings.states.approved &&
          !this.props.deleteSuccessful &&
          !isDisabled && (
            <Button.Group>
              <Button
                className="deleteButton"
                secondary
                onClick={this.props.toggleEnableDisableBaselineModalVisibility}
                type="button"
              >
                {strings.button.deleteLabel}
              </Button>
              <BasicModal
                confirmMessage={strings.modal.deleteBaselineMessage}
                handleModalConfirm={this.props.handleModalConfirm}
                modalClassName="deleteModal"
                modalOpen={this.props.enableDisableBaselineModalOpen}
                showOkButton={false}
                showYesButton={true}
                showNoButton={true}
                toggleModal={
                  this.props.toggleEnableDisableBaselineModalVisibility
                }
                noLabel={strings.button.noLabel}
                yesLabel={strings.button.yesLabel}
                okLabel={strings.button.okLabel}
              />
            </Button.Group>
          )}
      </div>
    );
  };
  renderCancelSaveButtons = () => {
    const {
      selectedBaselineData,
      handleModeSwitch,
      handleModalConfirm,
      toggleCancelChangesModalVisibility,
      toggleConfirmSaveModalVisibility,
      initialBaselineData,
      validateForm,
      confirmSaveModalOpen,
      mode,
      cancelChangesModalOpen,
      isDisabled
    } = this.props;

    return (
      <div className="containerButtons">
        <Button.Group>
          <Button
            className="saveButton"
            primary
            inverted
            onClick={validateForm}
            disabled={
              JSON.stringify(selectedBaselineData) ===
                JSON.stringify(initialBaselineData) || isDisabled
            }
          >
            {strings.button.saveLabel}
          </Button>
          <BasicModal
            confirmMessage={strings.modal.saveChangesMessage}
            handleModalConfirm={handleModalConfirm}
            modalClassName="confirmSaveModal"
            modalOpen={confirmSaveModalOpen}
            showNoButton={true}
            showOkButton={false}
            showYesButton={true}
            toggleModal={toggleConfirmSaveModalVisibility}
            noLabel={strings.button.noLabel}
            yesLabel={strings.button.yesLabel}
            okLabel={strings.button.okLabel}
          />
        </Button.Group>
        <Button.Group>
          <Button
            className="cancelButton"
            primary
            onClick={toggleCancelChangesModalVisibility}
            type="button"
            disabled={isDisabled}
          >
            {strings.button.cancelLabel}
          </Button>
          <BasicModal
            confirmMessage={strings.modal.cancelChangesMessage}
            handleModalConfirm={
              mode === "create" ? handleModalConfirm : handleModeSwitch
            }
            modalClassName="cancelChangesModal"
            modalOpen={cancelChangesModalOpen}
            showOkButton={false}
            showYesButton={true}
            showNoButton={true}
            toggleModal={toggleCancelChangesModalVisibility}
            noLabel={strings.button.noLabel}
            yesLabel={strings.button.yesLabel}
            okLabel={strings.button.okLabel}
          />
        </Button.Group>
      </div>
    );
  };
  renderForm = () => {
    const {
      selectedBaselineData,
      handleChange,
      mode,
      formInvalid,
      validationResults,
      requestStatus,
      isDisabled,
      getOrganisations
    } = this.props;

    return (
      <>
        <div className="infoBlock">
          <div className="infoHeader">{strings.form.header.baselineInfo}</div>
          <div className="infoWrapper">
            <Form.Group>
              {mode !== "create" && (
                <ValidatedFormInput
                  formInvalid={formInvalid}
                  inputData={selectedBaselineData.crmId}
                  inputLabel={strings.form.label.crmId}
                  inputName="crmId"
                  inputPlaceholder={strings.form.placeholder.crmId}
                  formFieldWidth={8}
                  handleChange={handleChange}
                  validationResult={validationResults.crmId}
                  mode={mode}
                  disabled={mode === "edit"}
                />
              )}
              <ValidatedFormDropdown
                formInvalid={formInvalid}
                inputData={selectedBaselineData.owner}
                viewData={
                  dropdownHelper.getNameFromValues(
                    selectedBaselineData.owner,
                    getOrganisations()
                  ) || ""
                }
                dropdownLabel={strings.form.label.owner}
                dropdownName="owner"
                dropdownDisabled={true}
                dropdownOptions={getOrganisations() || []}
                dropdownPlaceholder={strings.form.placeholder.owner}
                formFieldWidth={8}
                handleChange={handleChange}
                validationResult={validationResults.owner}
                dropdownSearch={true}
                mode={mode}
              />
            </Form.Group>
            <Form.Group>
              <ValidatedFormInput
                formInvalid={formInvalid}
                inputData={selectedBaselineData.customerName}
                inputLabel={strings.form.label.customerName}
                inputName="customerName"
                inputPlaceholder={strings.form.placeholder.customerName}
                formFieldWidth={8}
                handleChange={handleChange}
                validationResult={validationResults.customerName}
                mode={mode}
                required={false}
              />
              <ValidatedFormInput
                formInvalid={formInvalid}
                inputData={selectedBaselineData.sNumber}
                inputLabel={strings.form.label.sNumber}
                inputName="sNumber"
                inputPlaceholder={strings.form.placeholder.sNumber}
                formFieldWidth={8}
                handleChange={handleChange}
                validationResult={validationResults.sNumber}
                required={false}
                mode={mode}
              />
            </Form.Group>
          </div>
        </div>
        <div className="infoBlock">
          <div className="infoHeader">
            {strings.form.header.baselineAssessment}
          </div>
          <div className="infoWrapper">
            {(requestStatus || mode === "create") && (
              <ValidatedBaselineAssessment
                selectedBaselineData={selectedBaselineData}
                formInvalid={formInvalid}
                validationResults={validationResults}
                handleChange={handleChange}
                allowNumericalInput={allowNumericalInput}
                savePage={this.props.savePage}
                getAssessmentData={this.props.getAssessmentData}
                updateSelectedBaseline={this.props.updateSelectedBaseline}
                isDisabled={isDisabled}
                mode={mode}
                hasCancelled={this.props.hasCancelled}
                resetCancelledState={this.props.resetCancelledState}
              />
            )}
          </div>
        </div>
      </>
    );
  };

  render = () => {
    const { mode, formInvalid, pageTitle, requestStatus } = this.props;

    let parentPage = localStorage.getItem("programmeName")
      ? localStorage.getItem("programmeName")
      : undefined;

    return (
      <div className="container">
        <ComponentHeader
          parentPage={parentPage}
          returnToParent={this.returnToParent}
          pageTitle={pageTitle}
          mode={mode}
          requestStatus={requestStatus}
          modifyButtons={this.renderModifyButtons()}
          cancelSaveButtons={this.renderCancelSaveButtons()}
          permissionsUpdate={permissions.baselines.update}
          permissionsCreate={permissions.baselines.create}
          formInvalid={formInvalid}
          validationResults={this.props.validationResults}
          sticky
        />

        <Form id="baselineForm" className="stickyForm">
          <fieldset disabled={mode === "view"}>{this.renderForm()}</fieldset>
        </Form>
      </div>
    );
  };
}

ModifyBaseline.propTypes = {
  mode: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  selectedBaselineData: PropTypes.object.isRequired,
  pageTitle: PropTypes.string.isRequired,
  confirmSaveModalOpen: PropTypes.bool.isRequired,
  cancelChangesModalOpen: PropTypes.bool.isRequired,
  toggleConfirmSaveModalVisibility: PropTypes.func.isRequired,
  toggleCancelChangesModalVisibility: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleModalConfirm: PropTypes.func.isRequired,
  formInvalid: PropTypes.bool.isRequired,
  validationResults: PropTypes.object,
  validateForm: PropTypes.func.isRequired,
  requestStatus: PropTypes.bool,
  initialBaselineData: PropTypes.object.isRequired,
  deletePermissionStatus: PropTypes.string,
  handleModalInputChange: PropTypes.func,
  deletionReason: PropTypes.object,
  isDisabled: PropTypes.bool.isRequired
};

export default withRouter(ModifyBaseline);
