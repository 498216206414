import React from "react";
import ModifyEmployment from "../../components/ModifyEmployment";
import { Loader } from "semantic-ui-react";
import { connect } from "react-redux";
import moment from "moment";
import { strings } from "../../resources";
import { employmentActions } from "../../store/actions/employment.actions";
import { organisationActions } from "../../../Organisations/store/actions/organisation.actions";
import { programmeActions } from "../../../Programmes/store/actions/programme.actions";
import PropTypes from "prop-types";
import {
  convertToIso,
  allowNumericalInput,
  getOrganisations,
  canUserModifyRecord
} from "../../helpers";
import uuidv4 from "uuid/v4";
import {
  functions as twgValidationFunctions,
  constraints as twgConstraints
} from "pulsion-twg-validation";

class EmploymentCreateContainer extends React.Component {
  state = {
    confirmSaveModalOpen: false,
    cancelChangesModalOpen: false,
    enableDisableEmploymentModalOpen: false,
    formInvalid: false,
    validationResults: {},
    searchCriteria: {
      size: 10000,
      from: 0,
      query: "",
      fields: ["*"],
      clear: false,
      sort: {},
      activeOnly: false
    },
    isPrePopulated: false,
    shouldSaveFiles: false,
    hasFileChanges: false,
    hasPageLoaded: false
  };

  componentDidMount = async () => {
    this.props.updateSelectedEmployment("employmentId", uuidv4());
    this.props.getProgrammeById(localStorage.getItem("programmeId"));
    await this.handleFetchData();
    let form = document.getElementById("employmentForm");
    if (form) {
      form.setAttribute("novalidate", true);
      //set the form to have the novalidate attribute to suppress the default html validation tooltips
    }
    await this.props.updateSelectedEmployment(
      "programmeId",
      localStorage.getItem("programmeId")
    );
    this.setState({ hasPageLoaded: true });
  };

  componentDidUpdate = () => {
    if (
      Object.keys(this.props.selectedProgrammeData).length > 0 &&
      !this.state.isPrePopulated
    ) {
      if (this.props.selectedProgrammeData.owner) {
        this.props.updateSelectedEmployment(
          "owner",
          this.props.selectedProgrammeData.owner
        );
      }
      if (this.props.selectedProgrammeData.area) {
        this.props.updateSelectedEmployment(
          "area",
          this.props.selectedProgrammeData.area
        );
      }
      if (this.props.selectedProgrammeData.firstName) {
        this.props.updateSelectedEmployment(
          "firstName",
          this.props.selectedProgrammeData.firstName
        );
      }
      if (this.props.selectedProgrammeData.surname) {
        this.props.updateSelectedEmployment(
          "surname",
          this.props.selectedProgrammeData.surname
        );
      }
      if (this.props.selectedProgrammeData.pNumber) {
        this.props.updateSelectedEmployment(
          "pNumber",
          this.props.selectedProgrammeData.pNumber
        );
      }
      if (this.props.selectedProgrammeData.scroNumber) {
        this.props.updateSelectedEmployment(
          "scroNumber",
          this.props.selectedProgrammeData.scroNumber
        );
      }
      this.setState({ isPrePopulated: true });
    }
  };

  componentWillUnmount() {
    this.props.clearSelectedEmployment();
  }

  handleFetchData = async page => {
    const sCrit = this.state.searchCriteria;
    await this.props.getOrganisations(
      this.props.headers,
      sCrit.query,
      sCrit.size,
      page * sCrit.size,
      sCrit.clear,
      sCrit.fields,
      sCrit.sort,
      sCrit.activeOnly
    );
  };

  validateForm = async () => {
    let details = JSON.parse(JSON.stringify(this.props.selectedEmploymentData));
    for (let property in details) {
      if (!details[property]) {
        delete details[property];
      }
    }
    let results = twgValidationFunctions.validateData(
      details,
      twgConstraints.employments.modifyEmployment
    );

    if (results) {
      this.setState({
        validationResults: results,
        formInvalid: true
      });
    } else {
      this.setState({ formInvalid: false });
      this.toggleConfirmSaveModalVisibility();
    }
  };

  handleSubmit = async () => {
    this.setState({ shouldSaveFiles: true });
    await this.props.createEmployment(
      this.props.selectedEmploymentData,
      this.props.headers
    );
    this.setState({ shouldSaveFiles: false });
    this.props.history.push("/employments");
  };

  handleCancel = () => {
    this.setState({ formInvalid: false });
    this.props.clearEmploymentRequest();
    this.props.history.goBack();
  };

  handleChange = async (event, data) => {
    if (data.name === "employmentStartDate") {
      await this.props.updateSelectedEmployment(
        "week13SustainedDate",
        convertToIso(
          moment(data.value, "DD-MM-YYYY")
            .add(13, "w")
            .local()
            .format("DD/MM/YYYY")
        )
      );
      await this.props.updateSelectedEmployment(
        "week26SustainedDate",
        convertToIso(
          moment(data.value, "DD-MM-YYYY")
            .add(26, "w")
            .local()
            .format("DD/MM/YYYY")
        )
      );
    }
    if (
      typeof data.value === "string" &&
      data.value.match(/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/)
    ) {
      await this.props.updateSelectedEmployment(
        data.name,
        convertToIso(data.value)
      );
    } else {
      await this.props.updateSelectedEmployment(data.name, data.value);
    }
  };

  handleModalConfirm = modalClassName => {
    switch (modalClassName) {
      case "confirmSaveModal":
        this.handleSubmit();
        break;
      case "cancelChangesModal":
        this.handleCancel();
        break;
      default:
        break;
    }
  };

  filesHaveChanged = () => {
    this.setState({ hasFileChanges: true });
  };

  getOrganisations = () => {
    return getOrganisations(this.props.organisationListData);
  };

  toggleConfirmSaveModalVisibility = () => {
    this.setState({
      confirmSaveModalOpen: !this.state.confirmSaveModalOpen
    });
  };

  toggleCancelChangesModalVisibility = () => {
    this.setState({
      cancelChangesModalOpen: !this.state.cancelChangesModalOpen
    });
  };

  toggleEnableDisableEmploymentModalVisibility = () => {
    this.setState({
      enableDisableEmploymentModalOpen: !this.state
        .enableDisableEmploymentModalOpen
    });
  };

  isDisabled = () => {
    return !canUserModifyRecord(
      this.props.selectedProgrammeData,
      this.props.organisationOfLoggedInUser
    );
  };

  render = () => {
    const loading =
      this.props.loadingPage ||
      this.props.programmeLoadingPage ||
      this.props.orgsLoadingPage;

    return (
      <div>
        <div style={{ display: loading ? "" : "none" }}>
          <Loader active>{strings.header.loading}</Loader>
        </div>
        <div style={{ display: loading ? "none" : "" }}>
          <ModifyEmployment
            mode="create"
            pageTitle={strings.header.createEmployment}
            selectedEmploymentData={this.props.selectedEmploymentData}
            confirmSaveModalOpen={this.state.confirmSaveModalOpen}
            cancelChangesModalOpen={this.state.cancelChangesModalOpen}
            enableDisableEmploymentModalOpen={
              this.state.enableDisableEmploymentModalOpen
            }
            toggleConfirmSaveModalVisibility={
              this.toggleConfirmSaveModalVisibility
            }
            toggleCancelChangesModalVisibility={
              this.toggleCancelChangesModalVisibility
            }
            toggleEnableDisableEmploymentModalVisibility={
              this.toggleEnableDisableEmploymentModalVisibility
            }
            handleChange={this.handleChange}
            handleModalConfirm={this.handleModalConfirm}
            formInvalid={this.state.formInvalid}
            validationResults={this.state.validationResults}
            validateForm={this.validateForm}
            handleAddActivity={this.handleAddActivity}
            initialEmploymentData={{}}
            type={this.props.match.params.employmentType}
            allowNumericalInput={allowNumericalInput}
            getOrganisations={this.getOrganisations}
            shouldSaveFiles={this.state.shouldSaveFiles}
            filesHaveChanged={this.filesHaveChanged}
            hasPageLoaded={this.state.hasPageLoaded}
            hasFileChanges={this.state.hasFileChanges}
            headers={this.props.headers}
            isDisabled={this.isDisabled()}
          />
        </div>
      </div>
    );
  };
}

EmploymentCreateContainer.propTypes = {
  headers: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  loadingPage: PropTypes.bool.isRequired,
  selectedEmploymentData: PropTypes.object.isRequired,
  updateSelectedEmployment: PropTypes.func.isRequired,
  clearSelectedEmployment: PropTypes.func.isRequired,
  createEmployment: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const { employments, auth, organisations, programmes } = state;
  const { loadingPage, selectedEmploymentData } = employments;
  const { headers, organisationOfLoggedInUser } = auth;
  const { organisationListData, loadingPage: orgsLoadingPage } = organisations;
  const {
    selectedProgrammeData,
    loadingPage: programmeLoadingPage
  } = programmes;

  return {
    loadingPage,
    selectedEmploymentData,
    headers,
    organisationListData,
    selectedProgrammeData,
    organisationOfLoggedInUser,
    programmeLoadingPage,
    orgsLoadingPage
  };
};

const mapDispatchToProps = dispatch => {
  return {
    createEmployment: async (data, headers) => {
      await dispatch(employmentActions.createEmployment(data, headers));
    },
    updateSelectedEmployment: (key, value) => {
      dispatch(employmentActions.updateSelectedEmployment(key, value));
    },
    clearSelectedEmployment: () => {
      dispatch(employmentActions.clearSelectedEmployment());
    },
    clearEmploymentRequest: () => {
      dispatch(employmentActions.clearEmploymentRequest());
    },
    getOrganisations: (
      headers,
      queryString,
      size,
      from,
      clear,
      fields,
      sort,
      activeOnly
    ) => {
      dispatch(
        organisationActions.getOrganisations(
          headers,
          queryString,
          size,
          from,
          clear,
          fields,
          sort,
          activeOnly
        )
      );
    },
    getProgrammeById: (id, headers) => {
      dispatch(programmeActions.getProgrammeById(id, headers));
    },
    clearSelectedProgramme: () => {
      dispatch(programmeActions.clearSelectedProgramme());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmploymentCreateContainer);
