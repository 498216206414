import { API } from "../../services";
import { costActionTypes } from "./cost.actionTypes";

const api = new API();

const createCost = (details, headers) => {
  const request = details => {
    return { type: costActionTypes.CREATE_COST_REQUEST, details };
  };
  const success = response => {
    return { type: costActionTypes.CREATE_COST_SUCCESS, response };
  };
  const failure = error => {
    return { type: costActionTypes.CREATE_COST_FAILURE, error };
  };

  api.setHeaders({ ...headers, systemHeader_subsysref: "createCost" });

  return dispatch => {
    dispatch(request(details));
    const body = {
      ...details
    };
    return new Promise((resolve, reject) => {
      api.createCost(body).then(
        response => {
          dispatch(success(response));
          resolve(response);
        },
        error => {
          dispatch(failure(error));
          reject(error);
        }
      );
    });
  };
};

const clearCostRequest = () => {
  const request = () => {
    return { type: costActionTypes.CLEAR_CREATE_COST_REQUEST };
  };

  const success = () => {
    return { type: costActionTypes.CLEAR_CREATE_COST_SUCCESS };
  };

  return dispatch => {
    dispatch(request());
    dispatch(success());
  };
};

const updateCost = (
  id,
  details,
  headers,
  initialDetails,
  showNotification = true
) => {
  const request = details => {
    return { type: costActionTypes.UPDATE_COST_REQUEST, details };
  };
  const success = response => {
    return {
      type: costActionTypes.UPDATE_COST_SUCCESS,
      response,
      showNotification
    };
  };
  const failure = error => {
    return {
      type: costActionTypes.UPDATE_COST_FAILURE,
      error,
      initialDetails
    };
  };

  api.setHeaders({ ...headers, systemHeader_subsysref: "updateCost" });

  return dispatch => {
    dispatch(request(details));
    const body = {
      ...details
    };
    api.updateCost(id, body).then(
      response => {
        dispatch(success(response));
      },
      error => {
        dispatch(failure(error, initialDetails));
      }
    );
  };
};

const deleteCost = (id, headers) => {
  const request = () => {
    return { type: costActionTypes.DELETE_COST_REQUEST };
  };
  const success = response => {
    return { type: costActionTypes.DELETE_COST_SUCCESS, response };
  };
  const failure = error => {
    return { type: costActionTypes.DELETE_COST_FAILURE, error };
  };

  api.setHeaders({ ...headers, systemHeader_subsysref: "deleteCost" });

  return dispatch => {
    dispatch(request());
    return new Promise((resolve, reject) => {
      if (id !== "") {
        api.deleteCost(id).then(
          data => {
            dispatch(success(data));
            resolve(data);
          },
          error => {
            dispatch(failure(error));
            reject(error);
          }
        );
      }
    });
  };
};

const getCosts = (
  headers,
  queryString,
  size,
  from,
  clear,
  fields = ["*"],
  sort = {},
  activeOnly,
  programmeId
) => {
  const request = () => {
    return { type: costActionTypes.GET_COSTS_REQUEST };
  };
  const success = response => {
    return { type: costActionTypes.GET_COSTS_SUCCESS, response };
  };
  const failure = error => {
    return { type: costActionTypes.GET_COSTS_FAILURE, error };
  };

  api.setHeaders({ ...headers, systemHeader_subsysref: "getCost" });

  return dispatch => {
    dispatch(request());
    if (clear) {
      // If supposed to clear, replicate blank result
      dispatch(success({ hits: { hits: [] } }));
    } else {
      api
        .getCosts(
          queryString,
          size,
          from,
          fields,
          sort,
          activeOnly,
          programmeId
        )
        .then(
          response => {
            dispatch(success(response));
          },
          error => dispatch(failure(error))
        );
    }
  };
};

const getCostsByProgramme = (
  headers,
  queryString,
  size,
  from,
  clear,
  fields = ["*"],
  sort = {},
  activeOnly,
  programmeId
) => {
  const request = () => {
    return { type: costActionTypes.GET_COSTS_BY_PROGRAMME_REQUEST };
  };
  const success = response => {
    return {
      type: costActionTypes.GET_COSTS_BY_PROGRAMME_SUCCESS,
      response
    };
  };
  const failure = error => {
    return {
      type: costActionTypes.GET_COSTS_BY_PROGRAMME_FAILURE,
      error
    };
  };

  api.setHeaders({
    ...headers,
    systemHeader_subsysref: "getCostByProgramme"
  });

  return dispatch => {
    dispatch(request());
    if (clear) {
      // If supposed to clear, replicate blank result
      dispatch(success({ hits: { hits: [] } }));
    } else {
      api
        .getCostByProgramme(
          queryString,
          size,
          from,
          fields,
          sort,
          activeOnly,
          programmeId
        )
        .then(
          response => {
            dispatch(success(response));
          },
          error => dispatch(failure(error))
        );
    }
  };
};

const updateSearchCriteria = (
  headers,
  queryString,
  size,
  from,
  clear,
  fields,
  sort,
  activeOnly,
  programmeId
) => {
  const request = () => {
    return { type: costActionTypes.UPDATE_SEARCH_CRITERIA_REQUEST };
  };
  const success = response => {
    return {
      type: costActionTypes.UPDATE_SEARCH_CRITERIA_SUCCESS,
      response
    };
  };
  const newCriteria = {
    query: queryString,
    size: size,
    from: from,
    clear,
    fields: fields,
    sort: sort,
    activeOnly: activeOnly,
    programmeId: programmeId
  };
  return dispatch => {
    dispatch(request());
    dispatch(success(newCriteria));
    dispatch(
      getCosts(
        headers,
        queryString,
        size,
        from,
        clear,
        fields,
        sort,
        activeOnly,
        programmeId
      )
    );
  };
};

const getCostById = (id, headers) => {
  const request = () => {
    return { type: costActionTypes.GET_COST_BY_ID_REQUEST };
  };
  const success = response => {
    return {
      type: costActionTypes.GET_COST_BY_ID_SUCCESS,
      response
    };
  };
  const failure = error => {
    return { type: costActionTypes.GET_COST_BY_ID_FAILURE, error };
  };

  api.setHeaders({ ...headers, systemHeader_subsysref: "getCostById" });

  return dispatch => {
    dispatch(request());
    return new Promise((resolve, reject) => {
      api.getCostById(id).then(
        response => {
          dispatch(success(response.data));
          resolve(response.data);
        },
        error => {
          dispatch(failure(error));
          reject(error);
        }
      );
    });
  };
};

const updateSelectedCost = (key, value) => {
  const request = (key, value) => {
    return {
      type: costActionTypes.UPDATE_SELECTED_COST_REQUEST,
      key,
      value
    };
  };
  const success = (key, value) => {
    return {
      type: costActionTypes.UPDATE_SELECTED_COST_SUCCESS,
      key,
      value
    };
  };
  const failure = () => {
    return { type: costActionTypes.UPDATE_SELECTED_COST_FAILURE };
  };

  return dispatch => {
    dispatch(request(key, value));
    if (key) {
      dispatch(success(key, value));
    } else {
      dispatch(failure());
    }
  };
};

const clearSelectedCost = () => {
  const request = () => {
    return { type: costActionTypes.CLEAR_SELECTED_COST_REQUEST };
  };
  const success = () => {
    return { type: costActionTypes.CLEAR_SELECTED_COST_SUCCESS };
  };

  return dispatch => {
    dispatch(request());
    dispatch(success());
  };
};

const cancelSelectedCostUpdate = initialCostData => {
  const request = () => {
    return {
      type: costActionTypes.CANCEL_SELECTED_COST_UPDATE_REQUEST
    };
  };
  const success = data => {
    return {
      type: costActionTypes.CANCEL_SELECTED_COST_UPDATE_SUCCESS,
      data
    };
  };

  return dispatch => {
    dispatch(request());
    dispatch(success(initialCostData));
  };
};

export const costActions = {
  createCost,
  updateCost,
  deleteCost,
  getCostById,
  getCosts,
  getCostsByProgramme,
  updateSearchCriteria,
  updateSelectedCost,
  clearSelectedCost,
  clearCostRequest,
  cancelSelectedCostUpdate
};
