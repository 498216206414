import React from "react";
import PropTypes from "prop-types";
import { Form } from "semantic-ui-react";
import { strings } from "../../resources";
import { ValidatedFormTextArea } from "../validation";
import "../css/ReferralComponents.scss";

class NotesFields extends React.Component {
  render = () => {
    return (
      <div className="infoBlock">
        <div className="infoHeader">{strings.form.header.notesHeader}</div>
        <div className="infoWrapper">
          <Form.Group>
            <ValidatedFormTextArea
              formInvalid={this.props.formInvalid}
              inputData={this.props.selectedReferralData.notes}
              inputLabel={strings.form.label.notes}
              inputName="notes"
              inputPlaceholder={strings.form.placeholder.notes}
              formFieldWidth={8}
              handleChange={this.props.handleChange}
              required={false}
              mode={this.props.mode}
            />
          </Form.Group>
        </div>
      </div>
    );
  };
}

NotesFields.propTypes = {
  formInvalid: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  mode: PropTypes.string.isRequired,
  selectedReferralData: PropTypes.object.isRequired,
  validationResults: PropTypes.object
};

export default NotesFields;
