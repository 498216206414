import { enums } from "../resources/Enums";

const adminRoles = [enums.role.PULSION, enums.role.ICT];

// All users should be able to view My Work page
const globalPermissions = [
  ...adminRoles,
  enums.role.MENTOR,
  enums.role.TEAMLEAD,
  enums.role.MANAGER,
  enums.role.MANAGERPARTNER,
  enums.role.SSP,
  enums.role.HEAD,
  enums.role.ASSURANCE,
  enums.role.SUPPORT
];

export const permissions = {
  users: [...adminRoles],
  global: [...globalPermissions],
  customers: {
    create: [],
    read: [...globalPermissions],
    update: [
      ...adminRoles,
      enums.role.SUPPORT,
      enums.role.MENTOR,
      enums.role.TEAMLEAD,
      enums.role.MANAGER,
      enums.role.MANAGERPARTNER
    ],
    delete: [...adminRoles, enums.role.MANAGER, enums.role.HEAD]
  },
  referrals: {
    create: [
      ...adminRoles,
      enums.role.MENTOR,
      enums.role.TEAMLEAD,
      enums.role.MANAGER,
      enums.role.MANAGERPARTNER
    ],
    read: [...globalPermissions],
    update: [
      ...adminRoles,
      enums.role.SUPPORT,
      enums.role.MENTOR,
      enums.role.TEAMLEAD,
      enums.role.MANAGER,
      enums.role.MANAGERPARTNER
    ],
    delete: [...adminRoles, enums.role.MANAGER, enums.role.HEAD],
    updateOwner: [
      ...adminRoles,
      enums.role.TEAMLEAD,
      enums.role.MENTOR,
      enums.role.MANAGER
    ],
    updateProjectLeadOnly: [...adminRoles, enums.role.MANAGER],
    updateLeadAndSpecialist: [
      ...adminRoles,
      enums.role.MANAGER,
      enums.role.MENTOR
    ],
    shareRecord: [enums.role.TEAMLEAD, enums.role.MENTOR]
  },
  programmes: {
    create: [
      ...adminRoles,
      enums.role.MENTOR,
      enums.role.TEAMLEAD,
      enums.role.MANAGER,
      enums.role.MANAGERPARTNER
    ],
    read: [...globalPermissions],
    update: [
      ...adminRoles,
      enums.role.SUPPORT,
      enums.role.MENTOR,
      enums.role.TEAMLEAD,
      enums.role.MANAGER,
      enums.role.MANAGERPARTNER
    ],
    updateProjectLeadOnly: [...adminRoles, enums.role.MANAGER],
    updateLeadAndSpecialist: [
      ...adminRoles,
      enums.role.MANAGER,
      enums.role.MENTOR
    ],
    delete: [...adminRoles, enums.role.MANAGER, enums.role.HEAD],
    updateOwner: [...adminRoles, enums.role.TEAMLEAD, enums.role.MANAGER],
    shareRecord: [enums.role.TEAMLEAD, enums.role.MENTOR]
  },
  engagements: {
    create: [
      ...adminRoles,
      enums.role.MENTOR,
      enums.role.TEAMLEAD,
      enums.role.MANAGER,
      enums.role.MANAGERPARTNER
    ],
    read: [...globalPermissions],
    update: [
      ...adminRoles,
      enums.role.SUPPORT,
      enums.role.MENTOR,
      enums.role.TEAMLEAD,
      enums.role.MANAGER,
      enums.role.MANAGERPARTNER
    ],
    updateProjectLeadOnly: [...adminRoles, enums.role.MANAGER],
    updateLeadAndSpecialist: [
      ...adminRoles,
      enums.role.MANAGER,
      enums.role.MENTOR
    ],
    delete: [...adminRoles, enums.role.MANAGER, enums.role.HEAD],
    updateOwner: [
      ...adminRoles,
      enums.role.TEAMLEAD,
      enums.role.MENTOR,
      enums.role.MANAGER
    ]
  },
  assessmentSG: {
    create: [
      ...adminRoles,
      enums.role.MENTOR,
      enums.role.TEAMLEAD,
      enums.role.MANAGER,
      enums.role.MANAGERPARTNER
    ],
    read: [...globalPermissions],
    update: [
      ...adminRoles,
      enums.role.SUPPORT,
      enums.role.MENTOR,
      enums.role.TEAMLEAD,
      enums.role.MANAGER,
      enums.role.MANAGERPARTNER
    ],
    updateProjectLeadOnly: [...adminRoles, enums.role.MANAGER],
    updateLeadAndSpecialist: [
      ...adminRoles,
      enums.role.MANAGER,
      enums.role.MENTOR
    ],
    delete: [...adminRoles, enums.role.MANAGER, enums.role.HEAD],
    updateOwner: [
      ...adminRoles,
      enums.role.TEAMLEAD,
      enums.role.MENTOR,
      enums.role.MANAGER
    ]
  },
  activities: {
    create: [
      ...adminRoles,
      enums.role.MENTOR,
      enums.role.TEAMLEAD,
      enums.role.MANAGER,
      enums.role.MANAGERPARTNER
    ],
    read: [...globalPermissions],
    update: [
      ...adminRoles,
      enums.role.SUPPORT,
      enums.role.MENTOR,
      enums.role.TEAMLEAD,
      enums.role.MANAGER,
      enums.role.MANAGERPARTNER
    ],
    updateProjectLeadOnly: [...adminRoles, enums.role.MANAGER],
    updateLeadAndSpecialist: [
      ...adminRoles,
      enums.role.MANAGER,
      enums.role.MENTOR
    ],
    delete: [...adminRoles, enums.role.MANAGER, enums.role.HEAD],
    updateOwner: [
      ...adminRoles,
      enums.role.TEAMLEAD,
      enums.role.MENTOR,
      enums.role.MANAGER
    ]
  },
  employments: {
    create: [
      ...adminRoles,
      enums.role.MENTOR,
      enums.role.TEAMLEAD,
      enums.role.MANAGER,
      enums.role.MANAGERPARTNER
    ],
    read: [...globalPermissions],
    update: [
      ...adminRoles,
      enums.role.SUPPORT,
      enums.role.MENTOR,
      enums.role.TEAMLEAD,
      enums.role.MANAGER,
      enums.role.MANAGERPARTNER
    ],
    updateProjectLeadOnly: [...adminRoles, enums.role.MANAGER],
    updateLeadAndSpecialist: [
      ...adminRoles,
      enums.role.MANAGER,
      enums.role.MENTOR
    ],
    delete: [...adminRoles, enums.role.MANAGER, enums.role.HEAD],
    updateOwner: [
      ...adminRoles,
      enums.role.TEAMLEAD,
      enums.role.MENTOR,
      enums.role.MANAGER
    ]
  },
  costs: {
    create: [enums.role.MENTOR, enums.role.TEAMLEAD],
    read: [...globalPermissions],
    update: [enums.role.MENTOR, enums.role.TEAMLEAD],
    delete: [enums.role.MENTOR, enums.role.TEAMLEAD, ...adminRoles]
  },
  feedback: {
    create: [
      enums.role.MENTOR,
      enums.role.TEAMLEAD,
      enums.role.SUPPORT,
      enums.role.SSP,
      enums.role.MANAGER,
      enums.role.MANAGERPARTNER,
      ...adminRoles
    ],
    read: [
      enums.role.MENTOR,
      enums.role.SSP,
      enums.role.SUPPORT,
      enums.role.HEAD,
      enums.role.ASSURANCE,
      ...adminRoles
    ],
    update: [
      enums.role.MENTOR,
      enums.role.SSP,
      enums.role.SUPPORT,
      enums.role.HEAD,
      enums.role.PULSION,
      ...adminRoles
    ],
    delete: [...adminRoles]
  },
  organisations: {
    create: [
      ...adminRoles,
      enums.role.SUPPORT,
      enums.role.MENTOR,
      enums.role.TEAMLEAD,
      enums.role.MANAGER,
      enums.role.MANAGERPARTNER,
      enums.role.HEAD
    ],
    read: [...globalPermissions],
    update: [
      ...adminRoles,
      enums.role.SUPPORT,
      enums.role.MENTOR,
      enums.role.TEAMLEAD,
      enums.role.MANAGER,
      enums.role.MANAGERPARTNER,
      enums.role.HEAD
    ],
    delete: [...adminRoles, enums.role.MANAGER, enums.role.HEAD]
  },
  baselines: {
    create: [
      ...adminRoles,
      enums.role.MENTOR,
      enums.role.TEAMLEAD,
      enums.role.MANAGER,
      enums.role.MANAGERPARTNER
    ],
    read: [...globalPermissions],
    update: [
      ...adminRoles,
      enums.role.SUPPORT,
      enums.role.MENTOR,
      enums.role.TEAMLEAD,
      enums.role.MANAGER,
      enums.role.MANAGERPARTNER
    ],
    updateLeadAndSpecialist: [
      ...adminRoles,
      enums.role.MANAGER,
      enums.role.MENTOR
    ],
    delete: [...adminRoles, enums.role.MANAGER, enums.role.HEAD],
    updateOwner: [
      ...adminRoles,
      enums.role.TEAMLEAD,
      enums.role.MENTOR,
      enums.role.MANAGER
    ]
  },
  reports: {
    read: [
      ...adminRoles,
      enums.role.HEAD,
      enums.role.TEAMLEAD,
      enums.role.MANAGER,
      enums.role.MENTOR
    ],
    create: [
      ...adminRoles,
      enums.role.HEAD,
      enums.role.TEAMLEAD,
      enums.role.MANAGER,
      enums.role.MENTOR
    ],
    update: [
      ...adminRoles,
      enums.role.HEAD,
      enums.role.TEAMLEAD,
      enums.role.MANAGER,
      enums.role.MENTOR
    ]
  },
  tasks: {
    create: [
      ...adminRoles,
      enums.role.MENTOR,
      enums.role.TEAMLEAD,
      enums.role.MANAGER,
      enums.role.MANAGERPARTNER,
      enums.role.HEAD
    ],
    read: [...globalPermissions],
    update: [
      ...adminRoles,
      enums.role.SUPPORT,
      enums.role.MENTOR,
      enums.role.TEAMLEAD,
      enums.role.MANAGER,
      enums.role.MANAGERPARTNER,
      enums.role.HEAD
    ],
    delete: [...globalPermissions]
  }
};
