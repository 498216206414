import React from "react";
import PropTypes from "prop-types";
import { Icon, Menu, Dropdown, Image } from "semantic-ui-react";
import ShowIfAuthorised from "../../ShowIfAuthorised";
import "./css/topMenuBar.scss";
import image from "../../../assets/images/logo_tiny.png";
import { permissions } from "../../../resources";

const TopMenuBar = props =>
  props.isLoggedIn && (
    <div className="layoutHeaderSection">
      <Menu inverted pointing secondary className="topMenuBar">
        <ShowIfAuthorised allowedRoles={permissions.global}>
          <Menu.Item
            className={`menuCollapser ${
              props.sidebarOpened ? "open" : "closed"
            }`}
            onClick={props.toggleSidebar}
          >
            <Icon name="sidebar" />
          </Menu.Item>
        </ShowIfAuthorised>

        <Menu.Item position="right" className="profile">
          <Dropdown
            icon="angle down"
            className="menuDropdown"
            trigger={
              <span>
                <Image src={image} avatar />
                <span className="usersName">{props.name}</span>
              </span>
            }
          >
            <Dropdown.Menu direction="left">
              <Dropdown.Item
                text="Profile"
                icon="address card"
                disabled
                className="menuDropdownItem"
              />
              <Dropdown.Item
                text="Settings"
                icon="wrench"
                disabled
                className="menuDropdownItem"
              />
              <Dropdown.Item
                text="Logout"
                icon="log out"
                onClick={props.logout}
                className="menuDropdownItem"
              />
            </Dropdown.Menu>
          </Dropdown>
        </Menu.Item>
      </Menu>
    </div>
  );

TopMenuBar.propTypes = {
  isMobile: PropTypes.bool,
  toggleSidebar: PropTypes.func.isRequired,
  isLoggedIn: PropTypes.bool,
  logout: PropTypes.func.isRequired,
  roleId: PropTypes.string,
  name: PropTypes.string.isRequired,
  sidebarOpened: PropTypes.bool
};

export default TopMenuBar;
