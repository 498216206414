import React from "react";
import PropTypes from "prop-types";
import { Form } from "semantic-ui-react";
import {
  strings,
  referralSourceOptionsNrs,
  referralSourceOptionsNrne,
  outcomeOfIdentificationOptions
} from "../../resources";
import { dropdownHelper, convertFromIso } from "../../helpers/index";
import { ShowIfSameArea } from "../../../App/components/";
import {
  ValidatedFormInput,
  ValidatedFormDropdown,
  ValidatedDateInput,
  ValidatedFormTextArea
} from "../validation";
import "../css/ReferralComponents.scss";

class AdministrationFields extends React.Component {
  render = () => {
    return (
      <div className="infoBlock">
        <div className="infoHeader">{strings.form.header.referralInfo}</div>
        <div className="infoWrapper">
          <Form.Group>
            <ValidatedFormInput
              formInvalid={this.props.formInvalid}
              inputData={this.props.selectedReferralData.crmId}
              inputLabel={strings.form.label.crmId}
              inputName="crmId"
              inputPlaceholder={strings.form.placeholder.crmId}
              formFieldWidth={8}
              handleChange={this.props.handleChange}
              validationResult={this.props.validationResults.crmId}
              disabled={this.props.mode === "edit"}
              mode={this.props.mode}
            />
            <ValidatedFormDropdown
              formInvalid={this.props.formInvalid}
              inputData={
                this.props.selectedReferralData.owner
                  ? this.props.selectedReferralData.owner
                  : this.props.getInitialOwner()
              }
              viewData={
                dropdownHelper.getNameFromValues(
                  this.props.selectedReferralData.owner,
                  this.props.owner
                ) || ""
              }
              dropdownLabel={strings.form.label.owner}
              dropdownName="owner"
              dropdownDisabled={
                this.props.modifyOwnerRoles.indexOf(this.props.roleId) === -1 &&
                this.props.mode === "edit"
              }
              dropdownOptions={this.props.owner || []}
              dropdownPlaceholder={strings.form.placeholder.owner}
              formFieldWidth={8}
              handleChange={this.props.handleChange}
              validationResult={this.props.validationResults.owner}
              unclearable
              mode={this.props.mode}
            />
          </Form.Group>
          <Form.Group>
            <ShowIfSameArea
              area={this.props.area}
              allowedArea={strings.areas.NRS}
            >
              <ValidatedFormDropdown
                formInvalid={this.props.formInvalid}
                inputData={this.props.selectedReferralData.referralSourceNrs}
                viewData={this.props.selectedReferralData.referralSourceNrs}
                dropdownLabel={strings.form.label.referralSourceNrs}
                dropdownName="referralSourceNrs"
                dropdownOptions={referralSourceOptionsNrs}
                dropdownPlaceholder={strings.form.placeholder.referralSourceNrs}
                formFieldWidth={8}
                handleChange={this.props.handleChange}
                validationResult={
                  this.props.validationResults.referralSourceNrs
                }
                mode={this.props.mode}
              />
            </ShowIfSameArea>
            <ShowIfSameArea
              area={this.props.area}
              allowedArea={strings.areas.NRNE}
            >
              <ValidatedFormDropdown
                formInvalid={this.props.formInvalid}
                inputData={this.props.selectedReferralData.referralSourceNrne}
                viewData={this.props.selectedReferralData.referralSourceNrne}
                dropdownLabel={strings.form.label.referralSourceNrne}
                dropdownName="referralSourceNrne"
                dropdownOptions={referralSourceOptionsNrne}
                dropdownPlaceholder={
                  strings.form.placeholder.referralSourceNrne
                }
                formFieldWidth={8}
                handleChange={this.props.handleChange}
                validationResult={
                  this.props.validationResults.referralSourceNrne
                }
                mode={this.props.mode}
              />
              <ValidatedDateInput
                inputId="dateOfReferral"
                formInvalid={this.props.formInvalid}
                inputLabel={strings.form.label.dateOfReferral}
                inputName="dateOfReferral"
                inputPlaceholder={strings.form.placeholder.dateOfReferral}
                inputDateFormat="DD/MM/YYYY"
                inputData={
                  this.props.selectedReferralData.dateOfReferral
                    ? convertFromIso(
                        this.props.selectedReferralData.dateOfReferral
                      )
                    : ""
                }
                iconPosition="left"
                required
                handleChange={this.props.handleChange}
                validationResult={this.props.validationResults.dateOfReferral}
                formFieldWidth={8}
                mode={this.props.mode}
              />
            </ShowIfSameArea>
          </Form.Group>
          <ShowIfSameArea
            area={this.props.area}
            allowedArea={strings.areas.NRNE}
          >
            <Form.Group>
              <ValidatedFormTextArea
                formInvalid={this.props.formInvalid}
                inputData={this.props.selectedReferralData.communityRisk}
                inputLabel={strings.form.label.communityRisk}
                inputName="communityRisk"
                inputPlaceholder={strings.form.placeholder.communityRisk}
                formFieldWidth={8}
                handleChange={this.props.handleChange}
                validationResult={this.props.validationResults.communityRisk}
                required={false}
                mode={this.props.mode}
              />
              <ValidatedFormTextArea
                formInvalid={this.props.formInvalid}
                inputData={this.props.selectedReferralData.needsAnalysis}
                inputLabel={strings.form.label.needsAnalysis}
                inputName="needsAnalysis"
                inputPlaceholder={strings.form.placeholder.needsAnalysis}
                formFieldWidth={8}
                handleChange={this.props.handleChange}
                validationResult={this.props.validationResults.needsAnalysis}
                required={false}
                mode={this.props.mode}
              />
            </Form.Group>
            <Form.Group>
              <ValidatedFormInput
                formInvalid={this.props.formInvalid}
                inputData={this.props.selectedReferralData.linkWorker}
                inputLabel={strings.form.label.linkWorker}
                inputName="linkWorker"
                inputPlaceholder={strings.form.placeholder.linkWorker}
                formFieldWidth={8}
                handleChange={this.props.handleChange}
                validationResult={this.props.validationResults.linkWorker}
                required={false}
                mode={this.props.mode}
              />
              <ValidatedFormInput
                formInvalid={this.props.formInvalid}
                inputData={this.props.selectedReferralData.prisonHistory}
                inputLabel={strings.form.label.prisonHistory}
                inputName="prisonHistory"
                inputPlaceholder={strings.form.placeholder.prisonHistory}
                formFieldWidth={8}
                handleChange={this.props.handleChange}
                validationResult={this.props.validationResults.prisonHistory}
                required={false}
                mode={this.props.mode}
              />
            </Form.Group>
          </ShowIfSameArea>

          <ShowIfSameArea
            area={this.props.area}
            allowedArea={strings.areas.NRS}
          >
            <Form.Group>
              <ValidatedDateInput
                inputId="dateOfReferral"
                formInvalid={this.props.formInvalid}
                inputLabel={strings.form.label.dateOfReferral}
                inputName="dateOfReferral"
                inputPlaceholder={strings.form.placeholder.dateOfReferral}
                inputDateFormat="DD/MM/YYYY"
                inputData={
                  this.props.selectedReferralData.dateOfReferral
                    ? convertFromIso(
                        this.props.selectedReferralData.dateOfReferral
                      )
                    : ""
                }
                iconPosition="left"
                required
                handleChange={this.props.handleChange}
                validationResult={this.props.validationResults.dateOfReferral}
                formFieldWidth={8}
                mode={this.props.mode}
              />
              <ValidatedFormDropdown
                formInvalid={this.props.formInvalid}
                inputData={
                  this.props.selectedReferralData.outcomeOfIdentification
                }
                viewData={
                  this.props.selectedReferralData.outcomeOfIdentification
                }
                dropdownLabel={strings.form.label.outcomeOfIdentification}
                dropdownName="outcomeOfIdentification"
                dropdownOptions={outcomeOfIdentificationOptions}
                dropdownPlaceholder={
                  strings.form.placeholder.outcomeOfIdentification
                }
                dropdownDisabled={true}
                formFieldWidth={8}
                handleChange={this.props.handleChange}
                validationResult={
                  this.props.validationResults.outcomeOfIdentification
                }
                mode={this.props.mode}
              />
            </Form.Group>
          </ShowIfSameArea>
        </div>
      </div>
    );
  };
}

AdministrationFields.propTypes = {
  formInvalid: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  mode: PropTypes.string.isRequired,
  owner: PropTypes.array.isRequired,
  selectedReferralData: PropTypes.object.isRequired,
  validationResults: PropTypes.object
};

export default AdministrationFields;
