import LocalizedStrings from "react-localization";

export const strings = new LocalizedStrings({
  en: {
    header: {
      login: "Login",
      taskList: "Tasks",
      createTask: "New Task",
      editTask: "Edit Task",
      viewTask: "Task Details",
      loading: "Loading Information",
      showCleared: "Show Cleared Tasks",
      hideCleared: "Hide Cleared Tasks"
    },
    form: {
      header: {
        taskInfo: "Task Information",
        tasksLink: "Tasks",
        clientsLink: "Clients",
        supportLink: "Support",
        settingsLink: "Settings"
      },
      label: {
        taskName: "Task Name",
        firstName: "First Name",
        surname: "Last Name",
        description: "Description",
        assignedSpecialistIds: "Assigned Specialists",
        cleared: "Cleared",
        clearReason: "Clear Reason",
        crmId: "CRM ID",
        dueDate: "Due Date",
        pNumber: "Prison Number",
        clearTask: "Clear Task",
        deleteReason: "Delete Request Reason",
        rejectionReason: "Rejection Reason"
      },
      placeholder: {
        taskName: "",
        firstName: "",
        surname: "",
        description: "",
        assignedSpecialistIds: "",
        cleared: "",
        clearReason: "",
        crmId: "",
        dueDate: "",
        pNumber: "",
        stateReason: "State your reason...",
        approveReject: "-",
        deleteReason: "No Reason Provided",
        rejectionReason: ""
      },
      feedback: {
        processing: "Processing request...",
        taskRequestSuccess: "Task created successfully!",
        taskRequestRefreshPrompt:
          "Use the refresh function to see an updated tasks list.",
        taskRequestFailure: "Task creation failed.",
        taskRequestUnknown: "Something went wrong with the task request.",
        referralRequestUnknown:
          "Something went wrong with the referral request.",
        status: "Status",
        success: "Success",
        failure: "Failure"
      }
    },
    modal: {
      createTaskMessage: "Save task?",
      modifyTaskMessage: "Are you sure you want to save these changes?",
      deleteTaskMessage:
        "Are you sure you want to delete this task? (This action cannot be undone)",
      saveChangesMessage: "Are you sure you want to save these changes?",
      cancelChangesMessage: "Cancel changes?",
      setActiveMessage: "Set this task's status to active?",
      setInactiveMessage: "Set this task's status to inactive?",
      clearTaskReasonMessage: "Please submit a reason for deleting this task: ",
      actionTaskMessage: "Would you like to approve or reject this request?"
    },
    button: {
      loginLabel: "Login",
      editLabel: "Edit",
      deleteLabel: "Delete",
      cancelLabel: "Cancel",
      deactivateLabel: "Deactivate",
      activateLabel: "Activate",
      saveLabel: "Save",
      yesLabel: "Yes",
      noLabel: "No",
      okLabel: "Ok",
      addTaskLabel: "New Task",
      approve: "Approve",
      reject: "Reject",
      actionLabel: "Action Task",
      confirm: "Confirm"
    },
    tooltip: {
      editTask: "Edit Task",
      deleteTask: "Delete Task"
    },
    table: {
      header: {
        firstName: "First Name",
        surname: "Last Name",
        createdDate: "Created",
        updatedDate: "Updated",
        active: "Active",
        dueDate: "Due Date",
        crmId: "CRM ID",
        taskType: "Task Type"
      },
      text: {
        noResults: "No results found."
      },
      title: "Tasks Table"
    },
    fieldValues: {
      yes: "Yes",
      no: "No",
      Employer: "Employer",
      approved: "Approved",
      rejected: "Rejected"
    },
    amendmentTypes: {
      deleteAmendment: "deleteAmendment",
      changeAmendment: "changeAmendment",
      duplicateWarning: "duplicateWarning",
      mentorAssigned: "mentorAssigned"
    },
    tasks: {
      header: "My Work",
      labels: {
        due: "Due",
        stillToComplete: "Still to Complete"
      },
      na: "N/A"
    },
    areas: {
      NRS: "NRS",
      NRNE: "NRNE",
      both: "both"
    }
  }
});
