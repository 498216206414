import LocalizedStrings from "react-localization";

export const strings = new LocalizedStrings({
  en: {
    header: {
      login: "Login",
      userList: "Users",
      createUser: "New User",
      editUser: "Edit User",
      viewUser: "User Details",
      loading: "Loading Information"
    },
    form: {
      header: {
        userInfo: "User Information",
        contactInfo: "Contact Information",
        usersLink: "Users",
        clientsLink: "Clients",
        supportLink: "Support",
        settingsLink: "Settings"
      },
      label: {
        userName: "Username",
        role: "User Role",
        email: "Email",
        firstName: "First Name",
        surname: "Last Name",
        password: "Password",
        organisation: "Organisation",
        area: "Geography",
        caseloadMax: "Maximum Caseload",
        prison: "Prison",
        localAuthorityArea: "Local Authority Area"
      },
      placeholder: {
        stateReason: "State your reason...",
        username: "",
        password: "",
        role: "- select -",
        organisation: "- select -",
        userName: "",
        userType: "",
        firstName: "",
        surname: "",
        email: "",
        contactEmailAddress: "",
        contactNumber: "",
        searchBar: "Search",
        area: "- select -",
        caseloadMax: "",
        prison: "- select -",
        localAuthorityArea: ""
      },
      feedback: {
        processing: "Processing request...",
        userRequestSuccess: "User created successfully!",
        userRequestRefreshPrompt:
          "Use the refresh function to see an updated users list.",
        userRequestFailure: "User creation failed.",
        userRequestUnknown: "Something went wrong with the user request.",
        status: "Status",
        success: "Success",
        failure: "Failure"
      }
    },
    modal: {
      createUserMessage: "Save user?",
      modifyUserMessage: "Are you sure you want to save these changes?",
      deleteUserMessage:
        "Are you sure you want to delete this user? (This action cannot be undone)",
      saveChangesMessage: "Are you sure you want to save these changes?",
      cancelChangesMessage: "Cancel changes?",
      setActiveMessage: "Set this user's status to active?",
      setInactiveMessage: "Set this user's status to inactive?",
      requestDeletePermissionMessage: "Please submit a reason for deletion:"
    },
    button: {
      loginLabel: "Login",
      editLabel: "Edit",
      deleteLabel: "Delete",
      cancelLabel: "Cancel",
      deactivateLabel: "Deactivate",
      activateLabel: "Activate",
      saveLabel: "Save",
      yesLabel: "Yes",
      noLabel: "No",
      okLabel: "Ok",
      addUserLabel: "New User",
      requestDeleteLabel: "Request Delete Permission",
      submitLabel: "Submit"
    },
    tooltip: {
      editUser: "Edit User",
      deleteUser: "Delete User",
      toggleStatus: "Click to change status",
      userTypeWarning: "User Type cannot be changed after creation.",
      passwordFormatHeader: "Password Format",
      passwordFormatContent:
        "At least 1 Uppercase, 1 Lowercase and 8 characters in length."
    },
    table: {
      header: {
        userName: "Username",
        userType: "User Type",
        firstName: "First Name",
        surname: "Last Name",
        email: "Email",
        organisation: "Organisation",
        createdDate: "Created",
        updatedDate: "Updated",
        active: "Active",
        actions: "Actions",
        ViewIcon: "View Details",
        role: "Role"
      },
      text: {
        noResults: "No results found."
      }
    },
    fieldValues: {
      mentorId: "mentor"
    },
    states: {
      approved: "Approved",
      rejected: "Rejected"
    },
    areas: {
      NRS: "NRS",
      NRNE: "NRNE",
      both: "both"
    }
  }
});
