import React from "react";
import PropTypes from "prop-types";
import { Form } from "semantic-ui-react";
import { strings, yesNoOptions } from "../../resources";
import { ShowIfSameArea } from "../../../App/components/";
import { ValidatedFormDropdown, ValidatedFormTextArea } from "../validation";
import "../css/ReferralComponents.scss";

class NeedsFields extends React.Component {
  render = () => {
    return (
      <div className="infoBlock">
        <div className="infoHeader">{strings.form.header.needsHeader}</div>
        <div className="infoWrapper">
          <Form.Group>
            <ValidatedFormTextArea
              formInvalid={this.props.formInvalid}
              inputData={this.props.selectedReferralData.prisonNeeds}
              inputLabel={strings.form.label.prisonNeeds}
              inputName="prisonNeeds"
              inputPlaceholder={strings.form.placeholder.prisonNeeds}
              formFieldWidth={8}
              handleChange={this.props.handleChange}
              required={false}
              mode={this.props.mode}
            />
            <ValidatedFormTextArea
              formInvalid={this.props.formInvalid}
              inputData={this.props.selectedReferralData.communityNeeds}
              inputLabel={strings.form.label.communityNeeds}
              inputName="communityNeeds"
              inputPlaceholder={strings.form.placeholder.communityNeeds}
              formFieldWidth={8}
              handleChange={this.props.handleChange}
              required={false}
              mode={this.props.mode}
            />
          </Form.Group>
          <Form.Group>
            <ValidatedFormTextArea
              formInvalid={this.props.formInvalid}
              inputData={this.props.selectedReferralData.additionalNeedsInfo}
              inputLabel={strings.form.label.additionalNeedsInfo}
              inputName="additionalNeedsInfo"
              inputPlaceholder={strings.form.placeholder.additionalNeedsInfo}
              formFieldWidth={8}
              handleChange={this.props.handleChange}
              validationResult={
                this.props.validationResults.additionalNeedsInfo
              }
              required={false}
              mode={this.props.mode}
            />
            <ShowIfSameArea
              area={this.props.area}
              allowedArea={strings.areas.NRS}
            >
              <ValidatedFormDropdown
                formInvalid={this.props.formInvalid}
                inputData={this.props.selectedReferralData.threeWayMeeting}
                viewData={this.props.selectedReferralData.threeWayMeeting}
                dropdownLabel={strings.form.label.threeWayMeeting}
                dropdownName="threeWayMeeting"
                dropdownOptions={yesNoOptions}
                dropdownPlaceholder={strings.form.placeholder.threeWayMeeting}
                formFieldWidth={8}
                handleChange={this.props.handleChange}
                validationResult={this.props.validationResults.threeWayMeeting}
                required={false}
                mode={this.props.mode}
              />
            </ShowIfSameArea>
          </Form.Group>
        </div>
      </div>
    );
  };
}

NeedsFields.propTypes = {
  formInvalid: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  mode: PropTypes.string.isRequired,
  selectedReferralData: PropTypes.object.isRequired,
  validationResults: PropTypes.object
};

export default NeedsFields;
