import { isEmpty } from "./objectHelpers";

const getListOfCommunityMentors = (
  area,
  userListData,
  organisation,
  liberationLocalArea,
  refStats,
  assignedMentor
) => {
  let communityMentorCaseLoads = refStats;
  //only add the ones of the correct role type to the list and are in the correct area.
  //If organisation is not set, return all, else return only ones of matching org and
  //local liberation authority area
  let data = [];
  if (userListData) {
    userListData.forEach(result => {
      if (result["_source"]) {
        if (
          (hasAreaAndRole(result["_source"], area) ||
            hasTeamLeadRole(result["_source"])) &&
          !organisation
        ) {
          data.push(result["_source"]);
        } else if (
          (hasAreaAndRole(result["_source"], area) &&
            hasValue(result["_source"], "organisationId", organisation) &&
            hasLocalAuthorityArea(result["_source"], liberationLocalArea)) ||
          hasTeamLeadRole(result["_source"])
        ) {
          data.push(result["_source"]);
        }
      }
    });
  }

  if (!isEmpty(assignedMentor)) {
    data.push(assignedMentor);
  }

  let dropdownFields = [];
  data.forEach((object, key) => {
    //format the data in an appropriate format for a dropdown list
    dropdownFields.push({
      key: key,
      text: object.firstName + " " + object.surname,
      value: object.userId,
      roleid: object.roleId,
      caseloadmax: object.caseloadMax,
      organisation: object.organisationId,
      localauthorityarea: object.localAuthorityArea,
      caseload: 0
    });
  });
  //add caseloads to the mentors
  if (
    dropdownFields &&
    dropdownFields.length >= 0 &&
    communityMentorCaseLoads &&
    communityMentorCaseLoads.length >= 0
  ) {
    dropdownFields.forEach(user => {
      communityMentorCaseLoads.forEach(communityMentor => {
        if (user.value === communityMentor.key) {
          user.caseload = communityMentor.doc_count;
        }
      });
    });
  }
  return dropdownFields;
};

const hasValue = (obj, key, value) => {
  return obj.hasOwnProperty(key) && obj[key] === value;
};

const includesValue = (obj, key, arr) => {
  return obj.hasOwnProperty(key) && arr.includes(obj[key]);
};

const hasAreaAndRole = (source, area) => {
  return (
    hasValue(source, "area", area) &&
    includesValue(source, "roleId", ["mentor", "teamLead"])
  );
};

const hasTeamLeadRole = source => {
  return hasValue(source, "roleId", "teamLead");
};

const hasLocalAuthorityArea = (source, liberationLocalArea) => {
  return (
    source.hasOwnProperty("localAuthorityArea") &&
    source["localAuthorityArea"].includes(liberationLocalArea)
  );
};

export default getListOfCommunityMentors;
