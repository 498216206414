import { permissions } from "./AppPermissions";
import { strings } from "./Strings";

const generateMenuBar = props => {
  const menubarPart1 = [
    {
      menuItemUrl: "mywork",
      menuItemName: strings.menuPages.mywork,
      menuItemRole: permissions.global,
      iconName: "suitcase"
    },
    {
      menuItemUrl: "customers",
      menuItemName: strings.menuPages.customers,
      menuItemRole: permissions.customers.read,
      iconName: "user"
    },
    {
      menuItemUrl: "referrals",
      menuItemName: strings.menuPages.referrals,
      menuItemRole: permissions.referrals.read,
      iconName: "share"
    }
  ];

  let programmes = {
    menuItemUrl: "programmes",
    menuItemName: strings.menuPages.programmes,
    menuItemRole: permissions.programmes.read,
    iconName: "list ul"
  };
  if (localStorage.getItem("programmeId")) {
    programmes.subItems = programmeSubMenuItems(props);
  }

  const menubarPart2 = [
    {
      menuItemUrl: "organisations",
      menuItemName: strings.menuPages.organisations,
      menuItemRole: permissions.organisations.read,
      iconName: "map marker alternate"
    },
    {
      menuItemUrl: "ocr",
      menuItemName: strings.menuPages.ocr,
      menuItemRole: permissions.global,
      iconName: "upload"
    },
    {
      menuItemUrl: "reports",
      menuItemName: strings.menuPages.reports,
      menuItemRole: permissions.reports.read,
      iconName: "chart bar"
    },
    {
      menuItemUrl: "users",
      menuItemName: strings.menuPages.users,
      menuItemRole: permissions.users,
      iconName: "lock"
    }
  ];

  return [...menubarPart1, programmes, ...menubarPart2];
};

const programmeSubMenuItems = props => {
  let items = [
    {
      menuItemUrl: "engagements",
      menuItemName: strings.menuPages.engagements,
      menuItemRole: permissions.engagements.read,
      iconName: "handshake"
    },
    {
      menuItemUrl: "activities",
      menuItemName: strings.menuPages.activities,
      menuItemRole: permissions.activities.read,
      iconName: "object group"
    },
    {
      menuItemUrl: "outcomes",
      menuItemName: strings.menuPages.outcomes,
      menuItemRole: permissions.programmes.read,
      iconName: "eye"
    },
    {
      menuItemUrl: "baselines",
      menuItemName: strings.menuPages.baseline,
      menuItemRole: permissions.baselines.read,
      iconName: "clipboard outline"
    },
    {
      menuItemUrl: "employments",
      menuItemName: strings.menuPages.employments,
      menuItemRole: permissions.employments.read,
      iconName: "address card"
    },
    {
      menuItemUrl: "costs",
      menuItemName: strings.menuPages.costs,
      menuItemRole: permissions.costs.read,
      iconName: "gbp"
    }
  ];

  if (props.area !== strings.areas.NRNE) {
    items.splice(3, 0, {
      menuItemUrl: "assessmentSG",
      menuItemName: strings.menuPages.assessmentSG,
      menuItemRole: permissions.assessmentSG.read,
      iconName: "clipboard list"
    });
  }

  return items;
};

const generateFlattenedMenu = props => {
  let flattenedMenu = [];
  const menubar = generateMenuBar(props);
  menubar.forEach(items => {
    flattenedMenu.push({
      itemUrl: items.menuItemName,
      activeItem: items.menuItemName,
      activeSubItem: ""
    });
    if (items.subItems)
      items.subItems.forEach(subItem => {
        flattenedMenu.push({
          itemUrl: subItem.menuItemUrl,
          activeItem: items.menuItemName,
          activeSubItem: subItem.menuItemUrl
        });
      });
  });

  return flattenedMenu;
};

export { generateMenuBar, generateFlattenedMenu };
