import { strings } from "../resources";
import { dropdownHelper, convertFromIso, arrayHelpers } from "./index";

const removeBothAttributes = object => {
  let data = {};

  if (object && typeof object === "object") {
    Object.entries(object).forEach(([key, value]) => {
      if (value && value !== strings.text.both) {
        data[key] = value;
      }
    });
  }

  return data;
};

const handleData = inputObject => {
  inputObject = { ...inputObject };
  if (inputObject.isMultiple) {
    if (!inputObject.data) {
      return [];
    } else if (inputObject.mode === "view") {
      if (inputObject.data && typeof inputObject.data === "string") {
        return inputObject.data.split(",");
      } else {
        return [];
      }
    } else if (Array.isArray(inputObject.data)) {
      return inputObject.data.toString();
    } else if (typeof inputObject.data === "string") {
      return inputObject.data.split(",");
    }
  } else {
    if (inputObject.isUUID && inputObject.dropdownOptions) {
      return dropdownHelper.getNameFromValues(
        !Array.isArray(inputObject.data) && inputObject.data
          ? inputObject.data.split(",")
          : inputObject.data,
        inputObject.dropdownOptions
      );
    } else if (inputObject.dropdownOptions) {
      if (inputObject.isUUID) {
        return dropdownHelper.getNameFromValues(
          inputObject.data,
          inputObject.dropdownOptions
        );
      } else {
        return inputObject.data;
      }
    } else if (!inputObject.dropdownOptions) {
      if (inputObject.data) {
        return convertFromIso(inputObject.data);
      } else {
        return "";
      }
    } else {
      return inputObject.data;
    }
  }
};

const displayViewData = inputObject => {
  if (inputObject.isMultiple && inputObject.isUUID) {
    let data = inputObject.data ? inputObject.data.split(",") : [];
    let returnArray = [];

    data.forEach(value =>
      returnArray.push(
        dropdownHelper.getNameFromValues(value, inputObject.dropdownOptions)
      )
    );
    return returnArray;
  } else {
    return dropdownHelper.getNameFromValues(
      inputObject.data,
      inputObject.dropdownOptions
    );
  }
};

const generateConversion = (data = "", reportOptions = [], typeOptions) => {
  let reports = [];

  if (data && reportOptions.length > 0) {
    const reportObjects = reportOptions.filter(
      report => report.conversion === data
    );

    reportObjects.forEach(report => reports.push(report.value));
  } else if (data && typeOptions.length > 0) {
    const selectedReport = typeOptions.find(type => type.value === data);
    if (selectedReport && selectedReport.ids) {
      return selectedReport.ids;
    } else {
      return "";
    }
  }

  return reports.toString();
};

const filterReports = (query, reportOptions, isConversion) => {
  let results = reportOptions;

  results = results.filter(
    report =>
      report.conversion !== strings.text.etsReportType &&
      report.conversion !== strings.text.stpReportType
  );

  if (Object.keys(query).length === 0) {
    return results;
  }

  Object.keys(query).forEach(key => {
    if (!query[key]) {
      return;
    }

    switch (key) {
      case "reportViewType":
        results = results.filter(
          option => option.reporttype === query.reportViewType
        );
        break;
      case "type":
        if (!isConversion) {
          results = results.filter(option => option.report === query.type);
        }
        break;
      case "area":
        if (query.area === strings.areas.nrs) {
          results = results.filter(
            option => option.area !== strings.areas.nrne
          );
        } else if (query.area === strings.areas.nrne) {
          results = results.filter(option => option.area !== strings.areas.nrs);
        }
        break;
      default:
        break;
    }
  });

  return results;
};

const findReport = (options, value) => {
  return options.find(option => option.value === value);
};

const prepareForUpload = data => {
  let newData = [];

  newData = arrayHelpers.mapSearchResultsToRows(data);
  newData = arrayHelpers.filterUnique(newData, "referralId");

  return newData;
};

export default {
  removeBothAttributes,
  handleData,
  generateConversion,
  filterReports,
  findReport,
  prepareForUpload,
  displayViewData
};
