import { strings } from "../resources";

export const reportTypeOptions = [
  { key: 0, value: "template", text: "Standard Reports" },
  { key: 1, value: "result", text: "Saved Reports" }
];

export const reportSubTypeOptions = [
  { key: 0, text: "Key Performance Indicators", value: "KPI" },
  { key: 1, text: "Performance Measures", value: "performanceMeasure" },
  {
    key: 2,
    text: "Start to Post Liberation Engagement Conversion",
    value: "stp",
    ids: "6ae418eb-69f4-4c4b-900b-2f5f5835eec5"
  },
  {
    key: 3,
    text: "Eligible to Start Conversion",
    value: "ets",
    ids: "4ab23081-086c-4c28-a0ab-ed31adad60e5"
  }
];

const partners = [
  {
    key: 1,
    text: "The Wise Group",
    value: "d3eeddfa-2b1a-4e77-9380-b196da8bbea2"
  },
  { key: 2, text: "SACRO", value: "262e4844-8cfc-4b7b-a46f-4660bc4850af" },
  { key: 3, text: "APEX", value: "5fdf549e-29b5-4509-8a6f-a8090f4e153b" },
  {
    key: 4,
    text: "Turning Point Scotland",
    value: "6f26c1d8-7b32-4a81-a466-96c4d6e256fc"
  },
  { key: 5, text: "Circle", value: "fbe20d23-3305-4725-abc1-8a35eb50578b" },
  {
    key: 6,
    text: "YSCA",
    value: "37c28b44-a315-4523-929e-262354ba5dc9"
  }
];

export const partnershipOptions = [
  { key: 0, text: "Yes", value: "Yes" },
  { key: 1, text: "No", value: "No" }
];

export const partnerOptions = partners;

export const ageCategoryOptions = [
  { key: 1, text: "Male Prisoner 16-25", value: "Male Prisoner 16-25" },
  { key: 2, text: "Male Prisoner 26+", value: "Male Prisoner 26+" },
  { key: 3, text: "Both", value: strings.text.both }
];

export const areaOptions = [
  { key: 0, text: "NRS", value: "NRS" },
  { key: 1, text: "NRNE", value: "NRNE" },
  { key: 2, text: "Both", value: strings.text.both }
];

export const uploadSizeOptions = [
  { key: 0, text: "20", value: "20" },
  { key: 1, text: "40", value: "40" },
  { key: 2, text: "60", value: "60" },
  { key: 3, text: "80", value: "80" },
  { key: 4, text: "100", value: "100" },
  { key: 5, text: "140", value: "140" },
  { key: 6, text: "180", value: "180" },
  { key: 7, text: "220", value: "220" },
  { key: 8, text: "260", value: "260" },
  { key: 9, text: "300", value: "300" }
];
