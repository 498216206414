import React from "react";
import ModifyCustomer from "../../components/ModifyCustomer";
import { Loader, Divider } from "semantic-ui-react";
import { connect } from "react-redux";
import {
  strings,
  referralToOptions,
  referralFromOptions
} from "../../resources";
import { customerActions } from "../../store/actions/customer.actions";
import { amendmentActions } from "../../../Amendments/store/actions/amendment.actions";
import PropTypes from "prop-types";
import { RequestFeedback } from "../../components";
import {
  functions as twgValidationFunctions,
  constraints as twgConstraints
} from "pulsion-twg-validation";
import { convertToIso, allowNumericalInput } from "../../helpers/";

class CustomerEditContainer extends React.Component {
  state = {
    mode: "view",
    confirmSaveModalOpen: false,
    cancelChangesModalOpen: false,
    enableDisableCustomerModalOpen: false,
    initialCustomerData: {},
    formInvalid: false,
    validationResults: {},
    unknownRequestStatus: false,
    referralToOptions: undefined,
    referralFromOptions: undefined,
    hasFileChanges: false,
    shouldSaveFiles: false,
    deletionReason: undefined,
    fetchedPermissionStatus: false,
    permissionStatus: ""
  };

  componentDidMount = async () => {
    await this.props.getCustomerById(
      this.props.match.params.id,
      this.props.headers
    );

    //set the form to have the novalidate attribute to suppress the default html validation tooltips

    if (
      this.state.mode === "view" &&
      this.props.selectedCustomerData.requestId
    ) {
      await this.props.getDeletePermissionStatus(
        this.props.selectedCustomerData.requestId
      );
      if (this.props.permissionStatus === strings.states.rejected) {
        this.props.updateSelectedCustomer("requestId", null);
        this.props.updateCustomer(
          this.props.selectedCustomerData.gcrId,
          this.props.selectedCustomerData,
          this.props.headers,
          this.state.initialCustomerData,
          false
        );
      }
      this.setState(
        { permissionStatus: this.props.permissionStatus },
        async () => {
          if (this.state.permissionStatus === strings.states.rejected) {
            await this.props.updateSelectedCustomer("requestId", null);
            this.props.updateCustomer(
              this.props.selectedCustomerData.gcrId,
              this.props.selectedCustomerData,
              this.props.headers,
              this.state.initialCustomerData,
              false
            );
          }
        }
      );
    }
    this.setState({
      fetchedPermissionStatus: true,
      initialCustomerData: this.props.selectedCustomerData
    });
    let form = document.getElementById("engagementForm");
    if (form) {
      form.setAttribute("novalidate", true);
      //set the form to have the novalidate attribute to suppress the default html validation tooltips
    }
  };

  validateForm = async () => {
    let details = JSON.parse(JSON.stringify(this.props.selectedCustomerData));
    for (let property in details) {
      if (!details[property]) {
        delete details[property];
      }
    }

    let results = twgValidationFunctions.validateData(
      details,
      twgConstraints.customers.modifyCustomerDirectly
    );
    if (results) {
      this.setState({
        validationResults: results,
        formInvalid: true
      });
    } else {
      this.setState({ formInvalid: false });
      this.toggleConfirmSaveModalVisibility();
    }
  };

  handleSubmit = async () => {
    this.setState({ shouldSaveFiles: true });
    await this.props.updateCustomer(
      this.props.match.params.id,
      this.props.selectedCustomerData,
      this.props.headers,
      this.state.initialCustomerData
    );
    this.handleModeSwitch();
    this.setState({ shouldSaveFiles: false });
  };

  handleCancel = () => {
    this.setState({ formInvalid: false });
    this.props.history.goBack();
  };

  handleRequestDeletePermission = async () => {
    await this.props.requestDeletePermission(
      this.props.selectedCustomerData.gcrId,
      this.state.deletionReason.value,
      this.props.headers,
      this.props.selectedCustomerData
    );
    this.props.updateSelectedCustomer("requestId", this.props.requestId);
    this.props.updateCustomer(
      this.props.selectedCustomerData.gcrId,
      this.props.selectedCustomerData,
      this.props.headers,
      this.state.initialCustomerData
    );
    this.setState({
      initialCustomerData: this.props.selectedCustomerData
    });
  };

  handleModalInputChange = (e, value) => {
    this.setState({ deletionReason: value });
  };

  componentWillUnmount() {
    this.props.clearCustomerRequest();
    this.props.clearSelectedCustomer();
  }

  handleChange = async (event, data) => {
    if (data.type === "checkbox") {
      await this.props.updateSelectedCustomer(data.name, data.checked);
    } else if (
      typeof data.value === "string" &&
      data.value.match(/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/)
    ) {
      await this.props.updateSelectedCustomer(
        data.name,
        convertToIso(data.value)
      );
    } else {
      await this.props.updateSelectedCustomer(data.name, data.value);
    }
    if (data.name === "referralFrom" || data.name === "referralTo") {
      this.filterReferralOptions(data.value);
    }
  };

  handleDelete = async () => {
    await this.props.deleteCustomer(
      this.props.selectedCustomerData.gcrId,
      this.props.headers
    );
    if (this.props.deleteSuccessful) {
      this.props.setDeletePermissionStatusActioned(
        this.props.selectedCustomerData.requestId
      );
    }
  };

  filterReferralToOptions = selectedOption => {
    let newOptions = referralToOptions.filter(
      option => !option.value.includes(selectedOption)
    );
    this.setState({ referralToOptions: newOptions });
  };

  filterReferralFromOptions = selectedOption => {
    let newOptions = referralFromOptions.filter(
      option => !option.value.includes(selectedOption)
    );
    this.setState({ referralFromOptions: newOptions });
  };

  filesHaveChanged = () => {
    this.setState({ hasFileChanges: true });
  };

  handleModalConfirm = modalClassName => {
    switch (modalClassName) {
      case "confirmSaveModal":
        this.handleSubmit();
        break;
      case "cancelChangesModal":
        this.handleCancel();
        break;
      case "activationModal":
        this.handleEnableDisable();
        break;
      case "requestDeletePermissionModal":
        this.handleRequestDeletePermission();
        break;
      case "deleteModal":
        this.handleDelete();
        break;
      default:
        break;
    }
  };

  toggleConfirmSaveModalVisibility = () => {
    this.setState({
      confirmSaveModalOpen: !this.state.confirmSaveModalOpen
    });
  };

  toggleCancelChangesModalVisibility = () => {
    this.setState({
      cancelChangesModalOpen: !this.state.cancelChangesModalOpen
    });
  };

  toggleEnableDisableCustomerModalVisibility = () => {
    this.setState({
      enableDisableCustomerModalOpen: !this.state.enableDisableCustomerModalOpen
    });
  };

  handleModeSwitch = async () => {
    if (this.state.mode === "edit") {
      if (
        JSON.stringify(this.state.initialCustomerData) !==
        JSON.stringify(this.props.selectedCustomerData)
      ) {
        await this.props.cancelSelectedCustomerUpdate(
          this.state.initialCustomerData
        );
      }
      this.setState({ mode: "view", formInvalid: false });
    } else {
      this.setState({
        mode: "edit",
        initialCustomerData: JSON.parse(
          JSON.stringify(this.props.selectedCustomerData)
        )
      });
    }
  };

  render = () => {
    const loading =
      this.props.loadingPage || !this.state.fetchedPermissionStatus;

    return (
      <div>
        <div
          style={{
            display: loading ? "" : "none"
          }}
        >
          <Loader active>{strings.header.loading}</Loader>
        </div>
        <div
          style={{
            display: loading ? "none" : ""
          }}
        >
          {this.props.showNotification && (
            <RequestFeedback
              requestStatus={this.props.customerRequestStatus}
              requestMade={this.props.customerRequestMade}
              unknownRequestStatus={this.state.unknownRequestStatus}
              successMessage={this.props.result}
              failureMessage={this.props.error}
              processingFeedbackMessage={strings.form.feedback.processing}
              unknownFeedbackMessage={
                strings.form.feedback.customerRequestUnknown
              }
              statusFeedbackMessage={strings.form.feedback.status}
              successFeedbackMessage={strings.form.feedback.success}
              errorDetails={this.props.errorDetails}
            />
          )}
          <Divider hidden />
          <ModifyCustomer
            requestStatus={this.props.customerRequestStatus}
            mode={this.state.mode}
            pageTitle={
              this.state.mode === "edit"
                ? strings.header.editCustomer
                : strings.header.viewCustomer
            }
            toggleCancelChangesModalVisibility={
              this.toggleCancelChangesModalVisibility
            }
            toggleConfirmSaveModalVisibility={
              this.toggleConfirmSaveModalVisibility
            }
            toggleEnableDisableCustomerModalVisibility={
              this.toggleEnableDisableCustomerModalVisibility
            }
            selectedCustomerData={this.props.selectedCustomerData}
            handleChange={this.handleChange}
            handleModeSwitch={this.handleModeSwitch}
            handleModalConfirm={this.handleModalConfirm}
            cancelChangesModalOpen={this.state.cancelChangesModalOpen}
            confirmSaveModalOpen={this.state.confirmSaveModalOpen}
            enableDisableCustomerModalOpen={
              this.state.enableDisableCustomerModalOpen
            }
            initialCustomerData={this.state.initialCustomerData}
            formInvalid={this.state.formInvalid}
            allowNumericalInput={allowNumericalInput}
            validateForm={this.validateForm}
            validationResults={this.state.validationResults}
            referralToOptions={this.state.referralToOptions}
            referralFromOptions={this.state.referralFromOptions}
            headers={this.props.headers}
            shouldSaveFiles={this.state.shouldSaveFiles}
            filesHaveChanged={this.filesHaveChanged}
            hasFileChanges={this.state.hasFileChanges}
            loadingPage={this.props.loadingPage}
            deletePermissionStatus={this.state.permissionStatus}
            handleModalInputChange={this.handleModalInputChange}
            deletionReason={this.state.deletionReason}
            deleteSuccessful={this.props.deleteSuccessful}
          />
        </div>
      </div>
    );
  };
}

CustomerEditContainer.propTypes = {
  match: PropTypes.object.isRequired,
  headers: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  loadingPage: PropTypes.bool.isRequired,
  selectedCustomerData: PropTypes.object.isRequired,
  updateSelectedCustomer: PropTypes.func.isRequired,
  updateCustomer: PropTypes.func.isRequired,
  getCustomerById: PropTypes.func.isRequired,
  errorDetails: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

const mapStateToProps = state => {
  const { customers, amendments } = state;
  const {
    loadingPage,
    selectedCustomerData,
    customerRequestStatus,
    customerRequestMade,
    result,
    error,
    errorDetails,
    deleteSuccessful,
    showNotification
  } = customers;
  const { requestId, permissionStatus } = amendments;
  return {
    loadingPage,
    selectedCustomerData,
    customerRequestStatus,
    customerRequestMade,
    result,
    error,
    errorDetails,
    requestId,
    permissionStatus,
    deleteSuccessful,
    showNotification
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateCustomer: (
      id,
      data,
      headers,
      initialCustomerData,
      showNotification = true
    ) => {
      dispatch(
        customerActions.updateCustomer(
          id,
          data,
          headers,
          initialCustomerData,
          showNotification
        )
      );
    },
    getCustomerById: async (id, headers) => {
      await dispatch(customerActions.getCustomerById(id, headers));
    },
    updateSelectedCustomer: (key, value) => {
      dispatch(customerActions.updateSelectedCustomer(key, value));
    },
    cancelSelectedCustomerUpdate: data => {
      dispatch(customerActions.cancelSelectedCustomerUpdate(data));
    },
    clearCustomerRequest: () => {
      dispatch(customerActions.clearCustomerRequest());
    },
    clearSelectedCustomer: () => {
      dispatch(customerActions.clearSelectedCustomer());
    },
    requestDeletePermission: async (id, reason, headers, body) => {
      await dispatch(
        amendmentActions.requestDeletePermission(id, reason, headers, body)
      );
    },
    getDeletePermissionStatus: async (id, headers) => {
      await dispatch(amendmentActions.getDeletePermissionStatus(id, headers));
    },
    deleteCustomer: async (id, headers) => {
      await dispatch(customerActions.deleteCustomer(id, headers));
    },
    setDeletePermissionStatusActioned: (id, headers) => {
      dispatch(amendmentActions.setDeletePermissionStatusActioned(id, headers));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerEditContainer);
