import React from "react";
import ModifyTask from "../../components/ModifyTask";
import { Loader, Divider } from "semantic-ui-react";
import { connect } from "react-redux";
import { strings } from "../../resources/Strings";
import { taskActions } from "../../store/actions/task.actions";
import { amendmentActions } from "../../../Amendments/store/actions/amendment.actions";
import { referralActions } from "../../../Referrals/store/actions/referral.actions";

import PropTypes from "prop-types";
import { RequestFeedback } from "../../components";
import {
  functions as twgValidationFunctions,
  constraints as twgConstraints
} from "pulsion-twg-validation";
import { convertToIso } from "../../helpers/index";
class TaskEditContainer extends React.Component {
  state = {
    mode: "view",
    confirmSaveModalOpen: false,
    cancelChangesModalOpen: false,
    enableDisableTaskModalOpen: false,
    enableDisableTaskApprovalModalOpen: false,
    deleteModalOpen: false,
    initialTaskData: {},
    formInvalid: false,
    validationResults: {},
    unknownRequestStatus: false,
    clearTaskReason: "",
    approvalSelection: "",
    rejectionReason: ""
  };

  componentDidMount = async () => {
    await this.props.getTaskById(
      this.props.match.params.id,
      this.props.headers
    );
    if (
      this.props.selectedTaskData.linkedRecordId &&
      this.props.selectedTaskData.linkedRecordId !== strings.tasks.na
    ) {
      await this.props.getDeletePermissionStatus(
        this.props.selectedTaskData.linkedRecordId
      );
    }

    let form = document.getElementById("taskForm");
    form.setAttribute("novalidate", true);
  };

  componentWillUnmount() {
    this.props.clearTaskRequest();
  }

  validateForm = async () => {
    let details = JSON.parse(JSON.stringify(this.props.selectedTaskData));
    for (let property in details) {
      if (!details[property]) {
        delete details[property];
      }
    }
    let results = twgValidationFunctions.validateData(
      details,
      twgConstraints.activities.modifyTask
    );
    if (results) {
      this.setState({
        validationResults: results,
        formInvalid: true
      });
    } else {
      this.setState({ formInvalid: false });
      this.toggleConfirmSaveModalVisibility();
    }
  };

  handleSubmit = async () => {
    await this.props.updateTask(
      this.props.match.params.id,
      this.props.selectedTaskData,
      this.props.headers,
      this.state.initialTaskData
    );
    this.setState({
      initialTaskData: this.props.selectedTaskData
    });
    this.handleModeSwitch();
  };

  handleCancel = () => {
    this.props.history.goBack();
    this.setState({ formInvalid: false });
  };

  handleChange = async (event, data) => {
    if (
      typeof data.value === "string" &&
      data.value.match(/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/)
    ) {
      await this.props.updateSelectedTask(data.name, convertToIso(data.value));
    } else {
      await this.props.updateSelectedTask(data.name, data.value);
    }
  };

  handleDelete = async () => {
    await this.props.deleteTask(
      this.props.selectedTaskData.taskId,
      this.state.clearTaskReason !== ""
        ? this.state.clearTaskReason
        : undefined,
      this.props.headers
    );

    await this.props.getTaskById(
      this.props.match.params.id,
      this.props.headers
    );
  };

  handleChangeModal = (event, data) => {
    if (data.name === "clearReason") {
      this.setState({ clearTaskReason: data.value });
    }
    if (data.name === "rejectionReason")
      this.setState({ rejectionReason: data.value });
  };

  approveDuplicateRequest = () => {
    if (this.state.approvalSelection === strings.fieldValues.approved) {
      if (this.props.selectedTaskData.details.area === strings.areas.NRS) {
        this.props.createReferral(
          this.props.selectedTaskData.details,
          this.props.headers
        );
      } else if (
        this.props.selectedTaskData.details.area === strings.areas.NRNE
      ) {
        this.props.updateReferral(
          this.props.selectedTaskData.details.referralId,
          this.props.selectedTaskData.details,
          this.props.headers,
          {},
          false
        );
      }
      this.setState({ clearTaskReason: "Duplicate Approved" }, () =>
        this.handleDelete()
      );
    } else {
      this.setState({ clearTaskReason: "Duplicate Rejected" }, () =>
        this.handleDelete()
      );
    }
  };

  assignmentRequest = async () => {
    if (this.state.approvalSelection === strings.fieldValues.rejected) {
      this.props.updateReferral(
        this.props.selectedTaskData.linkedRecordId,
        { communityMentor: "reject" },
        this.props.headers,
        {},
        true
      );
      await this.props.updateSelectedTask(
        "rejectionReason",
        this.state.rejectionReason
      );
      await this.props.updateSelectedTask("cleared", true);
      await this.props.updateSelectedTask("clearReason", "Assignment Rejected");
    } else {
      await this.props.updateSelectedTask("cleared", true);
      await this.props.updateSelectedTask("clearReason", "Assignment Accepted");
    }
    await this.props.updateTask(
      this.props.match.params.id,
      this.props.selectedTaskData,
      this.props.headers,
      this.state.initialTaskData
    );
  };

  handleApprovalModal = e => {
    this.props.updateDeletePermissionStatus(
      this.props.selectedTaskData.linkedRecordId,
      this.state.approvalSelection,
      this.state.headers
    );
    this.handleDelete();
  };

  handleApprovalDropdown = (e, value) => {
    this.setState({
      approvalSelection: value.value
    });
  };

  resetDataOnModalClose = () => {
    this.setState({
      approvalSelection: "",
      clearTaskReason: "",
      rejectionReason: ""
    });
  };

  handleModalConfirm = modalClassName => {
    switch (modalClassName) {
      case "confirmSaveModal":
        this.handleSubmit();
        break;
      case "cancelChangesModal":
        this.handleCancel();
        break;
      case "activationModal":
        this.handleEnableDisable();
        break;
      case "deleteTaskModal":
        this.handleDelete();
        break;
      case "approveRequestModal":
        this.handleApprovalModal();
        break;
      case "approveDuplicateModal":
        this.approveDuplicateRequest();
        break;
      case "assignmentModal":
        this.assignmentRequest();
        break;
      default:
        break;
    }
  };

  toggleConfirmSaveModalVisibility = () => {
    this.setState({
      confirmSaveModalOpen: !this.state.confirmSaveModalOpen
    });
  };

  toggleCancelChangesModalVisibility = () => {
    this.setState({
      cancelChangesModalOpen: !this.state.cancelChangesModalOpen
    });
  };

  toggleEnableDisableTaskModalVisibility = () => {
    this.setState({
      enableDisableTaskModalOpen: !this.state.enableDisableTaskModalOpen
    });
  };

  toggleEnableDisableTaskApprovalModalVisibility = () => {
    this.setState({
      enableDisableTaskApprovalModalOpen: !this.state
        .enableDisableTaskApprovalModalOpen
    });
  };

  handleModeSwitch = async () => {
    if (this.state.mode === "edit") {
      if (
        JSON.stringify(this.state.initialTaskData) !==
        JSON.stringify(this.props.selectedTaskData)
      ) {
        await this.props.cancelSelectedTaskUpdate(this.state.initialTaskData);
      }
      this.setState({ mode: "view", formInvalid: false });
    } else {
      this.setState({
        mode: "edit",
        initialTaskData:
          JSON.parse(JSON.stringify(this.props.selectedTaskData)) ||
          this.props.selectedTaskData
      });
    }
  };

  render = () => {
    const loading = this.props.loadingPage;

    return (
      <div>
        <div style={{ display: loading ? "" : "none" }}>
          <Loader active>{strings.header.loading}</Loader>
        </div>
        <div style={{ display: loading ? "none" : "" }}>
          <RequestFeedback
            requestStatus={this.props.taskRequestStatus}
            requestMade={this.props.taskRequestMade}
            unknownRequestStatus={this.state.unknownRequestStatus}
            successMessage={this.props.result}
            failureMessage={this.props.error}
            processingFeedbackMessage={strings.form.feedback.processing}
            unknownFeedbackMessage={strings.form.feedback.taskRequestUnknown}
            statusFeedbackMessage={strings.form.feedback.status}
            successFeedbackMessage={strings.form.feedback.success}
            errorDetails={this.props.errorDetails}
          />
          <RequestFeedback
            className="smallerFeedbackMargin"
            requestStatus={this.props.referralRequestStatus}
            requestMade={this.props.referralRequestMade}
            unknownRequestStatus={this.state.unknownRequestStatus}
            successMessage={this.props.referralResult}
            failureMessage={this.props.referralError}
            processingFeedbackMessage={strings.form.feedback.processing}
            unknownFeedbackMessage={
              strings.form.feedback.referralRequestUnknown
            }
            statusFeedbackMessage={strings.form.feedback.status}
            successFeedbackMessage={strings.form.feedback.success}
            errorDetails={this.props.referralErrorDetails}
          />

          <Divider hidden />

          <ModifyTask
            requestStatus={this.props.taskRequestStatus}
            mode={this.state.mode}
            pageTitle={
              this.state.mode === "edit"
                ? strings.header.editTask
                : strings.header.viewTask
            }
            toggleCancelChangesModalVisibility={
              this.toggleCancelChangesModalVisibility
            }
            toggleConfirmSaveModalVisibility={
              this.toggleConfirmSaveModalVisibility
            }
            toggleEnableDisableTaskModalVisibility={
              this.toggleEnableDisableTaskModalVisibility
            }
            toggleEnableDisableTaskApprovalModalVisibility={
              this.toggleEnableDisableTaskApprovalModalVisibility
            }
            selectedTaskData={this.props.selectedTaskData}
            handleChange={this.handleChange}
            handleModeSwitch={this.handleModeSwitch}
            handleModalConfirm={this.handleModalConfirm}
            handleApprovalModal={this.handleApprovalModal}
            deleteModalOpen={this.state.deleteModalOpen}
            cancelChangesModalOpen={this.state.cancelChangesModalOpen}
            confirmSaveModalOpen={this.state.confirmSaveModalOpen}
            enableDisableTaskModalOpen={this.state.enableDisableTaskModalOpen}
            enableDisableTaskApprovalModalOpen={
              this.state.enableDisableTaskApprovalModalOpen
            }
            initialTaskData={this.state.initialTaskData}
            formInvalid={this.state.formInvalid}
            validateForm={this.validateForm}
            validationResults={this.state.validationResults}
            cancelSelectedTaskUpdate={this.props.cancelSelectedTaskUpdate}
            handleChangeModal={this.handleChangeModal}
            clearTaskReason={this.state.clearTaskReason}
            handleApprovalDropdown={this.handleApprovalDropdown}
            approvalSelection={this.state.approvalSelection}
            deleteReason={this.props.deleteReason}
            rejectionReason={this.state.rejectionReason}
            resetDataOnModalClose={this.resetDataOnModalClose}
          />
        </div>
      </div>
    );
  };
}

TaskEditContainer.propTypes = {
  match: PropTypes.object.isRequired,
  headers: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  loadingPage: PropTypes.bool.isRequired,
  selectedTaskData: PropTypes.object.isRequired,
  updateSelectedTask: PropTypes.func.isRequired,
  updateTask: PropTypes.func.isRequired,
  getTaskById: PropTypes.func.isRequired,
  errorDetails: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  deleteTask: PropTypes.func.isRequired,
  updateDeletePermissionStatus: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const { tasks, amendments, referrals } = state;
  const {
    loadingPage,
    selectedTaskData,
    taskRequestStatus,
    taskRequestMade,
    result,
    error,
    errorDetails
  } = tasks;
  const { deleteReason } = amendments;
  const {
    referralRequestStatus,
    referralRequestMade,
    result: referralResult,
    error: referralError,
    errorDetails: referralErrorDetails
  } = referrals;

  return {
    loadingPage,
    selectedTaskData,
    taskRequestStatus,
    taskRequestMade,
    result,
    error,
    errorDetails,
    deleteReason,
    referralRequestStatus,
    referralRequestMade,
    referralResult,
    referralError,
    referralErrorDetails
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateTask: (id, data, headers, initialTaskData) => {
      dispatch(taskActions.updateTask(id, data, headers, initialTaskData));
    },
    getTaskById: async (id, headers) => {
      await dispatch(taskActions.getTaskById(id, headers));
    },
    updateSelectedTask: (key, value) => {
      dispatch(taskActions.updateSelectedTask(key, value));
    },
    cancelSelectedTaskUpdate: data => {
      dispatch(taskActions.cancelSelectedTaskUpdate(data));
    },
    clearTaskRequest: () => {
      dispatch(taskActions.clearTaskRequest());
    },
    deleteTask: async (id, clearReason, headers) => {
      await dispatch(taskActions.deleteTask(id, clearReason, headers));
    },
    updateDeletePermissionStatus: async (id, approvalStatus, headers) => {
      await dispatch(
        amendmentActions.updateDeletePermissionStatus(
          id,
          approvalStatus,
          headers
        )
      );
    },
    getDeletePermissionStatus: async (id, headers) => {
      await dispatch(amendmentActions.getDeletePermissionStatus(id, headers));
    },
    createReferral: (data, headers) => {
      dispatch(referralActions.createReferral(data, headers));
    },
    updateReferral: (
      id,
      data,
      headers,
      initialReferralData,
      showNotification = true
    ) => {
      dispatch(
        referralActions.updateReferral(
          id,
          data,
          headers,
          initialReferralData,
          showNotification
        )
      );
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskEditContainer);
