import LocalizedStrings from "react-localization";

export const strings = new LocalizedStrings({
  en: {
    fieldValues: { other: "Other" },
    areas: { NRS: "NRS", both: "both", NRNE: "NRNE" },
    header: {
      login: "Login",
      costList: "Costs",
      createCost: "New Cost",
      editCost: "Edit Cost",
      viewCost: "Cost Details",
      loading: "Loading Information"
    },
    form: {
      errorText: {
        notShared:
          "Your organisation does not currently have access to this record."
      },
      header: {
        administration: "Administration",
        costDetails: "Cost Details"
      },
      label: {
        crmId: "CRM ID",
        owner: "Owner",
        date: "Date",
        type: "Type",
        amount: "Amount",
        issuedByOrg: "Issued By Organisation",
        issuedBy: "Issued By",
        otherType: "Other Type"
      },
      placeholder: {
        stateReason: "State your reason...",
        crmId: "",
        owner: "- select -",
        date: "- select -",
        type: "- select -",
        amount: "",
        issuedByOrg: "- select -",
        issuedBy: "- select -",
        otherType: ""
      },
      feedback: {
        processing: "Processing request...",
        costRequestSuccess: "Cost created successfully!",
        costRequestRefreshPrompt:
          "Use the refresh function to see an updated cost list.",
        costRequestFailure: "Cost creation failed.",
        costRequestUnknown: "Something went wrong with the cost request.",
        status: "Status",
        success: "Success",
        failure: "Failure"
      },
      text: {
        yes: "Yes",
        no: "No"
      }
    },
    modal: {
      createCostMessage: "Save cost?",
      modifyCostMessage: "Are you sure you want to save these changes?",
      deleteCostMessage:
        "Are you sure you want to delete this cost? (This action cannot be undone)",
      saveChangesMessage: "Are you sure you want to save these changes?",
      cancelChangesMessage: "Cancel changes?",
      setActiveMessage: "Set this cost's status to active?",
      setInactiveMessage: "Set this cost's status to inactive?",
      requestDeletePermissionMessage: "Please submit a reason for deletion:"
    },
    button: {
      loginLabel: "Login",
      editLabel: "Edit",
      deleteLabel: "Delete",
      cancelLabel: "Cancel",
      deactivateLabel: "Deactivate",
      activateLabel: "Activate",
      saveLabel: "Save",
      yesLabel: "Yes",
      noLabel: "No",
      okLabel: "Ok",
      nextLabel: "Next",
      addCostLabel: "New Cost",
      requestDeleteLabel: "Request Delete Permission",
      submitLabel: "Submit"
    },
    tooltip: {},
    table: {
      header: {
        crmId: "CRM ID",
        date: "Date",
        type: "Type",
        amount: "Amount",
        issuedByOrg: "Issued by Organisation",
        issuedBy: "Issued By",
        firstName: "First Name",
        surname: "Last Name",
        pNumber: "Prison Number",
        scroNumber: "S Number"
      },
      text: {
        yes: "Yes",
        no: "No",
        noResults: "No results found."
      }
    },
    states: {
      approved: "Approved",
      rejected: "Rejected"
    }
  }
});
