import React from "react";
import PropTypes from "prop-types";
import { Button, Form, Input } from "semantic-ui-react";
import {
  strings,
  methodOptions,
  typeOptions,
  referralMeetingOutcomeOptions,
  isSuccessfulOptions,
  hasActivitiesOptions,
  locationOptions,
  referralEngagementType,
  signupEngagementType,
  signupWarmEngagementType,
  yesNoOptions
} from "../resources";
import {
  ValidatedFormDropdown,
  ValidatedDateInput,
  ValidatedFormTextArea,
  ValidatedFormInput
} from "./validation";
import { ComponentHeader, BasicModal, TextInputModal } from ".";
import { withRouter } from "react-router-dom";
import { permissions } from "../../App/resources/AppPermissions";
import { dropdownHelper, convertFromIso } from "../helpers";
import FileManager from "../../File/components/FileManager";

class ModifyEngagement extends React.Component {
  returnToParent = () => {
    if (localStorage.getItem("programmeId")) {
      this.props.history.push(
        "/programmes/view/" + localStorage.getItem("programmeId")
      );
    } else if (localStorage.getItem("referralId")) {
      this.props.history.push(
        "/referrals/view/" + localStorage.getItem("referralId")
      );
    }
  };

  renderEditButton = () => {
    const {
      selectedEngagementData,
      handleModeSwitch,
      handleAddActivity,
      isDisabled
    } = this.props;

    const modalTextInput = (
      <Input
        placeholder={strings.form.placeholder.stateReason}
        fluid
        onChange={this.props.handleModalInputChange}
      ></Input>
    );

    return (
      <div className="containerButtons">
        {!this.props.deleteSuccessful &&
          (!isDisabled ? (
            <>
              <Button.Group>
                <Button
                  className="editButton"
                  primary
                  onClick={() => {
                    handleModeSwitch("edit");
                  }}
                  disabled={!selectedEngagementData.active}
                >
                  {strings.button.editLabel}
                </Button>
              </Button.Group>
              <Button.Group>
                <Button
                  className="addButton"
                  primary
                  inverted
                  onClick={handleAddActivity}
                  type="button"
                  disabled={!selectedEngagementData.active}
                >
                  {strings.button.addActivity}
                </Button>
              </Button.Group>
            </>
          ) : (
            <span className="errorText">
              {strings.form.errorText.notShared}
            </span>
          ))}
        {this.props.deletePermissionStatus !== strings.states.approved &&
          !this.props.deleteSuccessful &&
          !isDisabled && (
            <Button.Group>
              <Button
                className="deleteButton"
                secondary
                inverted
                onClick={
                  this.props.toggleEnableDisableEngagementModalVisibility
                }
                type="button"
                disabled={this.props.selectedEngagementData.requestId}
              >
                {strings.button.requestDeleteLabel}
              </Button>
              <TextInputModal
                confirmMessage={strings.modal.requestDeletePermissionMessage}
                handleModalConfirm={this.props.handleModalConfirm}
                modalClassName="requestDeletePermissionModal"
                modalOpen={this.props.enableDisableEngagementModalOpen}
                showOkButton={true}
                textInput={modalTextInput}
                toggleModal={
                  this.props.toggleEnableDisableEngagementModalVisibility
                }
                okLabel={strings.button.submitLabel}
                deletionReason={this.props.deletionReason}
              />
            </Button.Group>
          )}
        {this.props.deletePermissionStatus === strings.states.approved &&
          !this.props.deleteSuccessful &&
          !isDisabled && (
            <Button.Group>
              <Button
                className="deleteButton"
                secondary
                onClick={
                  this.props.toggleEnableDisableEngagementModalVisibility
                }
                type="button"
              >
                {strings.button.deleteLabel}
              </Button>
              <BasicModal
                confirmMessage={strings.modal.deleteEngagementMessage}
                handleModalConfirm={this.props.handleModalConfirm}
                modalClassName="deleteModal"
                modalOpen={this.props.enableDisableEngagementModalOpen}
                showOkButton={false}
                showYesButton={true}
                showNoButton={true}
                toggleModal={
                  this.props.toggleEnableDisableEngagementModalVisibility
                }
                noLabel={strings.button.noLabel}
                yesLabel={strings.button.yesLabel}
                okLabel={strings.button.okLabel}
              />
            </Button.Group>
          )}
      </div>
    );
  };
  renderCancelSaveButton = () => {
    const {
      selectedEngagementData,
      handleModeSwitch,
      handleModalConfirm,
      toggleCancelChangesModalVisibility,
      toggleConfirmSaveModalVisibility,
      initialEngagementData,
      validateForm,
      confirmSaveModalOpen,
      mode,
      cancelChangesModalOpen,
      handleAddActivity,
      isDisabled
    } = this.props;

    const activitiesCarriedOut =
      selectedEngagementData &&
      selectedEngagementData.hasActivitiesValue === strings.form.text.yes &&
      initialEngagementData.hasActivitiesValue !==
        selectedEngagementData.hasActivitiesValue;

    const cancelNextButtons = (
      <div className="containerButtons">
        <Button.Group>
          <Button
            className="nextButton"
            primary
            inverted
            onClick={handleAddActivity}
            disabled={isDisabled}
          >
            {strings.button.nextLabel}
          </Button>
          <BasicModal
            confirmMessage={strings.modal.saveChangesMessage}
            handleModalConfirm={handleModalConfirm}
            modalClassName="confirmSaveModal"
            modalOpen={confirmSaveModalOpen}
            showNoButton={true}
            showOkButton={false}
            showYesButton={true}
            toggleModal={toggleConfirmSaveModalVisibility}
            noLabel={strings.button.noLabel}
            yesLabel={strings.button.yesLabel}
            okLabel={strings.button.okLabel}
          />
        </Button.Group>
        <Button.Group>
          <Button
            className="cancelButton"
            primary
            onClick={toggleCancelChangesModalVisibility}
            type="button"
            disabled={isDisabled}
          >
            {strings.button.cancelLabel}
          </Button>
          <BasicModal
            confirmMessage={strings.modal.cancelChangesMessage}
            handleModalConfirm={
              mode === "create" ? handleModalConfirm : handleModeSwitch
            }
            modalClassName="cancelChangesModal"
            modalOpen={cancelChangesModalOpen}
            showOkButton={false}
            showYesButton={true}
            showNoButton={true}
            toggleModal={toggleCancelChangesModalVisibility}
            noLabel={strings.button.noLabel}
            yesLabel={strings.button.yesLabel}
            okLabel={strings.button.okLabel}
          />
        </Button.Group>
      </div>
    );

    const cancelSaveButtons = (
      <div className="containerButtons">
        <Button.Group>
          <Button
            className="saveButton"
            primary
            inverted
            onClick={validateForm}
            disabled={
              (JSON.stringify(selectedEngagementData) ===
                JSON.stringify(initialEngagementData) &&
                !this.props.hasFileChanges) ||
              isDisabled
            }
          >
            {strings.button.saveLabel}
          </Button>
          <BasicModal
            confirmMessage={strings.modal.saveChangesMessage}
            handleModalConfirm={handleModalConfirm}
            modalClassName="confirmSaveModal"
            modalOpen={confirmSaveModalOpen}
            showNoButton={true}
            showOkButton={false}
            showYesButton={true}
            toggleModal={toggleConfirmSaveModalVisibility}
            noLabel={strings.button.noLabel}
            yesLabel={strings.button.yesLabel}
            okLabel={strings.button.okLabel}
          />
        </Button.Group>
        <Button.Group>
          <Button
            className="cancelButton"
            primary
            onClick={toggleCancelChangesModalVisibility}
            type="button"
            disabled={isDisabled}
          >
            {strings.button.cancelLabel}
          </Button>
          <BasicModal
            confirmMessage={strings.modal.cancelChangesMessage}
            handleModalConfirm={
              mode === "create" ? handleModalConfirm : handleModeSwitch
            }
            modalClassName="cancelChangesModal"
            modalOpen={cancelChangesModalOpen}
            showOkButton={false}
            showYesButton={true}
            showNoButton={true}
            toggleModal={toggleCancelChangesModalVisibility}
            noLabel={strings.button.noLabel}
            yesLabel={strings.button.yesLabel}
            okLabel={strings.button.okLabel}
          />
        </Button.Group>
      </div>
    );

    return !activitiesCarriedOut ? cancelSaveButtons : cancelNextButtons;
  };
  renderForm = () => {
    const {
      selectedEngagementData,
      handleChange,
      mode,
      formInvalid,
      validationResults,
      getOrganisations,
      getSignUpOutcomeOptions
    } = this.props;

    const isReferralType =
      selectedEngagementData &&
      selectedEngagementData.type === referralEngagementType;
    const isSignupType =
      selectedEngagementData &&
      (selectedEngagementData.type === signupEngagementType ||
        selectedEngagementData.type === signupWarmEngagementType);

    return (
      <>
        <div className="infoHeader">{strings.form.header.engagementInfo}</div>
        <div className="infoWrapper">
          <Form.Group>
            {mode !== "create" && (
              <ValidatedFormInput
                formInvalid={formInvalid}
                inputData={selectedEngagementData.crmId}
                inputLabel={strings.form.label.crmId}
                inputName="crmId"
                inputPlaceholder={strings.form.placeholder.crmId}
                formFieldWidth={8}
                handleChange={handleChange}
                validationResult={validationResults.crmId}
                mode="view"
                disabled={mode === "edit"}
              />
            )}
            <ValidatedFormDropdown
              formInvalid={formInvalid}
              inputData={selectedEngagementData.owner}
              viewData={
                dropdownHelper.getNameFromValues(
                  selectedEngagementData.owner,
                  getOrganisations()
                ) || ""
              }
              dropdownLabel={strings.form.label.owner}
              dropdownName="owner"
              dropdownOptions={getOrganisations() || []}
              dropdownPlaceholder={strings.form.placeholder.owner}
              formFieldWidth={8}
              handleChange={handleChange}
              validationResult={validationResults.owner}
              mode={mode !== "edit" ? mode : "view"}
            />
          </Form.Group>
          <Form.Group>
            <ValidatedDateInput
              inputId="dateOfEngagement"
              formInvalid={formInvalid}
              inputLabel={strings.form.label.dateOfEngagement}
              inputName="dateOfEngagement"
              inputPlaceholder={strings.form.placeholder.dateOfEngagement}
              inputDateFormat="DD/MM/YYYY"
              inputData={
                selectedEngagementData.dateOfEngagement
                  ? convertFromIso(selectedEngagementData.dateOfEngagement)
                  : ""
              }
              iconPosition="left"
              required
              formFieldWidth={8}
              handleChange={handleChange}
              validationResult={validationResults.dateOfEngagement}
              mode={mode}
            />
            <ValidatedFormDropdown
              formInvalid={formInvalid}
              inputData={selectedEngagementData.location}
              viewData={selectedEngagementData.location}
              dropdownLabel={strings.form.label.location}
              dropdownName="location"
              dropdownOptions={locationOptions}
              dropdownPlaceholder={strings.form.placeholder.location}
              formFieldWidth={8}
              handleChange={handleChange}
              validationResult={validationResults.location}
              mode={mode}
            />
          </Form.Group>

          <Form.Group>
            <ValidatedFormDropdown
              formInvalid={formInvalid}
              inputData={selectedEngagementData.type}
              viewData={selectedEngagementData.type}
              dropdownLabel={strings.form.label.type}
              dropdownName="type"
              dropdownOptions={typeOptions}
              dropdownPlaceholder={strings.form.placeholder.type}
              formFieldWidth={8}
              handleChange={handleChange}
              validationResult={validationResults.type}
              dropdownDisabled={this.props.type ? true : false}
              mode={mode}
            />
            <ValidatedFormDropdown
              formInvalid={formInvalid}
              inputData={selectedEngagementData.method}
              viewData={selectedEngagementData.method}
              dropdownLabel={strings.form.label.method}
              dropdownName="method"
              dropdownOptions={methodOptions}
              dropdownPlaceholder={strings.form.placeholder.method}
              formFieldWidth={8}
              handleChange={handleChange}
              validationResult={validationResults.method}
              mode={mode}
            />
          </Form.Group>

          <Form.Group>
            {isReferralType && (
              <ValidatedFormDropdown
                formInvalid={formInvalid}
                inputData={selectedEngagementData.outcomeOfReferralMeeting}
                viewData={selectedEngagementData.outcomeOfReferralMeeting}
                dropdownLabel={strings.form.label.outcomeOfReferralMeeting}
                dropdownName="outcomeOfReferralMeeting"
                dropdownOptions={referralMeetingOutcomeOptions}
                dropdownPlaceholder={
                  strings.form.placeholder.outcomeOfReferralMeeting
                }
                formFieldWidth={8}
                handleChange={handleChange}
                validationResult={validationResults.outcomeOfReferralMeeting}
                mode={mode}
              />
            )}
            {isSignupType && (
              <ValidatedFormDropdown
                formInvalid={formInvalid}
                inputData={selectedEngagementData.outcomeOfSignupMeeting}
                viewData={selectedEngagementData.outcomeOfSignupMeeting}
                dropdownLabel={strings.form.label.outcomeOfSignupMeeting}
                dropdownName="outcomeOfSignupMeeting"
                dropdownOptions={getSignUpOutcomeOptions}
                dropdownPlaceholder={
                  strings.form.placeholder.outcomeOfSignupMeeting
                }
                formFieldWidth={8}
                handleChange={handleChange}
                validationResult={validationResults.outcomeOfSignupMeeting}
                mode={mode}
              />
            )}
            {isSignupType && (
              <ValidatedFormDropdown
                formInvalid={this.props.formInvalid}
                inputData={this.props.selectedEngagementData.consent}
                viewData={this.props.selectedEngagementData.consent}
                dropdownLabel={strings.form.label.consent}
                dropdownName="consent"
                dropdownOptions={yesNoOptions}
                dropdownPlaceholder={strings.form.placeholder.consent}
                formFieldWidth={8}
                handleChange={this.props.handleChange}
                validationResult={this.props.validationResults.consent}
                mode={mode}
              />
            )}
          </Form.Group>
          <Form.Group>
            <ValidatedFormDropdown
              formInvalid={formInvalid}
              inputData={selectedEngagementData.hasActivitiesValue}
              viewData={selectedEngagementData.hasActivitiesValue}
              dropdownLabel={strings.form.label.hasActivities}
              dropdownName="hasActivitiesValue"
              dropdownOptions={hasActivitiesOptions}
              dropdownPlaceholder={strings.form.placeholder.hasActivities}
              formFieldWidth={8}
              handleChange={handleChange}
              validationResult={validationResults.hasActivitiesValue}
              mode={mode}
            />
            <ValidatedFormDropdown
              formInvalid={formInvalid}
              inputData={selectedEngagementData.isSuccessfulValue}
              viewData={selectedEngagementData.isSuccessfulValue}
              dropdownLabel={strings.form.label.isSuccessfulValue}
              dropdownName="isSuccessfulValue"
              dropdownOptions={isSuccessfulOptions}
              dropdownPlaceholder={strings.form.placeholder.isSuccessfulValue}
              formFieldWidth={8}
              handleChange={handleChange}
              validationResult={validationResults.isSuccessfulValue}
              mode={mode}
            />
          </Form.Group>
          <Form.Group>
            <ValidatedFormTextArea
              formInvalid={formInvalid}
              inputData={selectedEngagementData.engagementNotes}
              inputLabel={strings.form.label.engagementNotes}
              inputName="engagementNotes"
              inputPlaceholder={strings.form.placeholder.engagementNotes}
              formFieldWidth={8}
              handleChange={handleChange}
              required={false}
              validationResult={validationResults.engagementNotes}
              mode={mode}
            />
            {selectedEngagementData.isSuccessfulValue ===
            strings.form.text.no ? (
              <ValidatedFormTextArea
                formInvalid={formInvalid}
                inputData={selectedEngagementData.isNotSuccessfulReason}
                inputLabel={strings.form.label.isNotSuccessfulReason}
                inputName="isNotSuccessfulReason"
                inputPlaceholder={
                  strings.form.placeholder.isNotSuccessfulReason
                }
                formFieldWidth={8}
                handleChange={handleChange}
                validationResult={validationResults.isNotSuccessfulReason}
                mode={mode}
              />
            ) : (
              <div className="fieldPusher" />
            )}
          </Form.Group>
          <Form.Group>
            {(this.props.hasPageLoaded || this.props.mode !== "create") &&
              this.props.selectedEngagementData.engagementId && (
                <FileManager
                  headers={this.props.headers}
                  allowEdit={true}
                  filePath="engagements/"
                  filesLabel={strings.form.fileUpload}
                  disabled={this.props.mode === "view"}
                  entityId={this.props.selectedEngagementData.engagementId}
                  entity="engagement"
                  shouldSaveFiles={this.props.shouldSaveFiles}
                  filesHaveChanged={this.props.filesHaveChanged}
                />
              )}
          </Form.Group>
        </div>
      </>
    );
  };

  render = () => {
    const { mode, pageTitle, requestStatus, formInvalid } = this.props;

    let parentPage;
    if (localStorage.getItem("programmeName")) {
      parentPage = localStorage.getItem("programmeName");
    } else if (localStorage.getItem("referralName")) {
      parentPage = localStorage.getItem("referralName");
    }

    return (
      <div className="container">
        <ComponentHeader
          parentPage={parentPage}
          returnToParent={this.returnToParent}
          pageTitle={pageTitle}
          mode={mode}
          requestStatus={requestStatus}
          modifyButtons={this.renderEditButton()}
          cancelSaveButtons={this.renderCancelSaveButton()}
          permissionsUpdate={permissions.engagements.update}
          permissionsCreate={permissions.engagements.create}
          formInvalid={formInvalid}
          validationResults={this.props.validationResults}
          sticky
        />

        <Form id="engagementForm" className="infoBlock stickyForm">
          <fieldset disabled={mode === "view"}>{this.renderForm()}</fieldset>
        </Form>
      </div>
    );
  };
}

ModifyEngagement.propTypes = {
  mode: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  selectedEngagementData: PropTypes.object.isRequired,
  pageTitle: PropTypes.string.isRequired,
  confirmSaveModalOpen: PropTypes.bool.isRequired,
  cancelChangesModalOpen: PropTypes.bool.isRequired,
  toggleConfirmSaveModalVisibility: PropTypes.func.isRequired,
  toggleCancelChangesModalVisibility: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleModalConfirm: PropTypes.func.isRequired,
  formInvalid: PropTypes.bool.isRequired,
  validationResults: PropTypes.object,
  validateForm: PropTypes.func.isRequired,
  requestStatus: PropTypes.bool,
  initialEngagementData: PropTypes.object.isRequired,
  deletePermissionStatus: PropTypes.string,
  handleModalInputChange: PropTypes.func,
  deletionReason: PropTypes.object,
  isDisabled: PropTypes.bool.isRequired
};

export default withRouter(ModifyEngagement);
