import React from "react";
import ReactTable from "react-table";
import { TableFunctions } from ".";
import { strings } from "../resources";
import PropTypes from "prop-types";
import { permissions } from "../../App/resources";
import { convertFromIso } from "../helpers/index";

class TableComponent extends React.Component {
  getTdProps = (state, rowInfo, column, instance) => {
    if (column) {
      if (column.id !== "delete" && column.id !== "active") {
        return {
          onClick: () => {
            if (rowInfo) {
              localStorage.setItem("referralId", rowInfo.original.referralId);
              localStorage.setItem(
                "referralName",
                `${rowInfo.original.firstName} ${rowInfo.original.surname}`
              );
              this.props.history.push(
                "/referrals/view/" + rowInfo.original.referralId
              );
            }
          }
        };
      }
    }
    return {}; // getTdProps requires a return statement
  };

  mapSearchResultsToRows = () => {
    const { referralListData } = this.props;
    let data = [];
    if (referralListData) {
      referralListData.forEach((result, index) => {
        if (result["_source"]) {
          data.push(result["_source"]);
        }
      });
    }
    return data;
  };

  render = () => {
    let data = this.mapSearchResultsToRows();
    let pages = Math.ceil(this.props.totalResults / this.props.pageSize);
    const cellTruncate = length => {
      return row => {
        let value = row.value ? row.value : "";
        let truncatedValue =
          value.length > length ? (
            <div>{value.substring(0, length)}...</div>
          ) : (
            <div>{value}</div>
          );
        return truncatedValue;
      };
    };

    const columns = [
      {
        Header: strings.table.header.firstName,
        accessor: "firstName",
        headerStyle: { textAlign: "left" },
        Cell: cellTruncate(20)
      },
      {
        Header: strings.table.header.surname,
        accessor: "surname",
        headerStyle: { textAlign: "left" },
        Cell: cellTruncate(20)
      },
      {
        Header: strings.table.header.initials,
        accessor: "initials",
        show:
          this.props.area === strings.areas.NRNE ||
          this.props.area === strings.areas.both,
        headerStyle: { textAlign: "left" },
        Cell: cellTruncate(5),
        width: 70
      },
      {
        Header: strings.table.header.pNumber,
        accessor: "pNumber",
        headerStyle: { textAlign: "left" },
        Cell: cellTruncate(20)
      },
      {
        Header: strings.table.header.scroNumber,
        accessor: "scroNumber",
        show:
          this.props.area === strings.areas.NRS ||
          this.props.area === strings.areas.both,
        headerStyle: { textAlign: "left" },
        Cell: cellTruncate(40),
        width: 100
      },
      {
        Header: strings.table.header.returningCustomer,
        accessor: "returningCustomer",
        headerStyle: { textAlign: "left" },
        Cell: cellTruncate(20)
      },
      {
        id: "prisonSpecialist",
        Header: strings.table.header.prisonSpecialist,
        accessor: ref => {
          if (
            Object.entries(ref).length > 0 &&
            this.props.userList.length > 0
          ) {
            let specialist = this.props.userList.find(
              user => ref.prisonSpecialist === user.value
            )
              ? this.props.userList.find(
                  user => ref.prisonSpecialist === user.value
                ).text
              : "";
            return specialist;
          } else {
            return "";
          }
        },
        show:
          this.props.area === strings.areas.NRS ||
          this.props.area === strings.areas.both,
        headerStyle: { textAlign: "left" },
        Cell: cellTruncate(40)
      },
      {
        id: "dateOfReferral", //we need an id for this because the accessor is not a string
        Header: strings.table.header.dateOfReferral,
        accessor: referral =>
          referral.dateOfReferral
            ? convertFromIso(referral.dateOfReferral)
            : "",
        headerStyle: { textAlign: "left" }
      },
      {
        id: "mentor",
        Header: strings.table.header.mentor,
        accessor: ref => {
          if (
            Object.entries(ref).length > 0 &&
            this.props.userList.length > 0
          ) {
            let specialist = this.props.userList.find(
              user => ref.mentor === user.value
            )
              ? this.props.userList.find(user => ref.mentor === user.value).text
              : "";
            return specialist;
          } else {
            return "";
          }
        },
        show:
          this.props.area === strings.areas.NRNE ||
          this.props.area === strings.areas.both,
        headerStyle: { textAlign: "left" },
        Cell: cellTruncate(40),
        width: 120
      },
      {
        Header: strings.table.header.outcomeOfReferralNrs,
        accessor: "outcomeOfReferralNrs",
        show:
          this.props.area === strings.areas.NRS ||
          this.props.area === strings.areas.both,
        headerStyle: { textAlign: "left" },
        Cell: cellTruncate(40)
      },
      {
        Header: strings.table.header.outcomeOfThreshold,
        accessor: "outcomeOfThreshold",
        show:
          this.props.area === strings.areas.NRNE ||
          this.props.area === strings.areas.both,
        headerStyle: { textAlign: "left" },
        Cell: cellTruncate(40)
      },

      {
        id: "communityMentor",
        Header: strings.table.header.communityMentor,
        accessor: ref => {
          if (
            Object.entries(ref).length > 0 &&
            this.props.userList.length > 0
          ) {
            let specialist = this.props.userList.find(
              user => ref.communityMentor === user.value
            )
              ? this.props.userList.find(
                  user => ref.communityMentor === user.value
                ).text
              : "";
            return specialist;
          } else {
            return "";
          }
        },
        show:
          this.props.area === strings.areas.NRS ||
          this.props.area === strings.areas.both,
        headerStyle: { textAlign: "left" },
        Cell: cellTruncate(40)
      }
    ];

    return (
      <div>
        {!this.props.isReporting && (
          <TableFunctions
            headers={this.props.headers}
            history={this.props.history}
            handleRefresh={this.props.handleRefresh}
            headerList={strings.header.referralList}
            buttonAddLabel={strings.button.addReferralLabel}
            permissionsCreate={permissions.referrals.create}
            headerClass="pageHeader"
            refreshButtonClass="refreshButton"
            addButtonClass="addButton"
            addButtonLink="/referrals/create"
            handleChange={this.props.handleChange}
            searchBarQuery={this.props.searchBarQuery}
            handleSubmit={this.props.handleSubmit}
          />
        )}
        <div>
          <ReactTable
            manual
            data={data}
            pages={pages}
            defaultPageSize={this.props.pageSize}
            showPagination={true}
            getTdProps={this.getTdProps}
            onFetchData={(state, instance) => {
              this.props.handleFetchData(state);
              this.props.updatePageIndex(state.page);
            }}
            columns={columns}
            className="-highlight"
            showPageSizeOptions={false}
            minRows="1"
            noDataText={strings.table.text.noResults}
            previousText="<"
            nextText=">"
            pageText=""
          />
        </div>
      </div>
    );
  };
}

TableComponent.propTypes = {
  referralListData: PropTypes.array.isRequired,
  headers: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  pageSize: PropTypes.number.isRequired,
  handleFetchData: PropTypes.func.isRequired,
  totalResults: PropTypes.number.isRequired,
  updatePageIndex: PropTypes.func.isRequired
};

export default TableComponent;
