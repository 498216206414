import React from "react";
import PropTypes from "prop-types";
import { Route, Switch, Redirect } from "react-router-dom";
import { strings } from "./resources";
import { PrivateRoute } from "./components/Navigation/PrivateRoute";
import { Login, Logout, PasswordReset } from "./containers/Login";
import {
  UserListContainer,
  UserEditContainer,
  UserCreateContainer
} from "../Users/containers";
import {
  CustomerListContainer,
  CustomerEditContainer
} from "../Customers/containers";
import {
  ProgrammeListContainer,
  ProgrammeEditContainer
} from "../Programmes/containers";
import { TaskListContainer, TaskEditContainer } from "../Tasks/containers";
import {
  ReferralListContainer,
  ReferralEditContainer,
  ReferralCreateContainer
} from "../Referrals/containers";
import { ReportEditContainer } from "../Reports/containers";
import {
  EngagementListContainer,
  EngagementEditContainer,
  EngagementCreateContainer
} from "../Engagements/containers";
import {
  AssessmentSGHandler,
  AssessmentSGCreateContainer,
  AssessmentSGEditContainer
} from "../AssessmentSG/containers";
import {
  BaselineHandler,
  BaselineEditContainer,
  BaselineCreateContainer
} from "../Baselines/containers";
import {
  OrganisationListContainer,
  OrganisationEditContainer,
  OrganisationCreateContainer
} from "../Organisations/containers";
import {
  ActivityListContainer,
  ActivityEditContainer,
  ActivityCreateContainer
} from "../Activities/containers";
import { OutcomeListContainer } from "../Outcomes/containers";
import {
  EmploymentListContainer,
  EmploymentCreateContainer,
  EmploymentEditContainer
} from "../Employments/containers";
import {
  CostListContainer,
  CostCreateContainer,
  CostEditContainer
} from "../Costs/containers";
import OcrContainer from "../OCR/components/Ocr/OcrContainer";

import { permissions } from "./resources/AppPermissions";
import NotFoundPage from "./components/Navigation/NotFoundPage/NotFoundPage";

// Some routes will have commented out roles.
// This is simply since no such components exist yet so no point
// giving anyone access.
const Routes = props => {
  return (
    <Switch>
      <Route exact path="/login" render={props => <Login {...props} />} />
      <Route
        exact
        path="/reset-password"
        render={props =>
          props.isLoggedIn ? (
            <Redirect to="/mywork" />
          ) : (
            <PasswordReset {...props} />
          )
        }
      />
      <PrivateRoute
        exact
        path="/users/create"
        roles={permissions.users}
        component={UserCreateContainer}
        {...props.injectedProps}
      />
      <PrivateRoute
        exact
        path="/users/view/:id"
        roles={permissions.users}
        component={UserEditContainer}
        {...props.injectedProps}
      />
      <PrivateRoute
        exact
        path="/users"
        roles={permissions.users}
        component={UserListContainer}
        {...props.injectedProps}
      />
      <PrivateRoute
        exact
        path="/mywork/view/:id"
        roles={permissions.global}
        component={TaskEditContainer}
        {...props.injectedProps}
      />
      <PrivateRoute
        exact
        path="/mywork/edit/:id"
        roles={permissions.global}
        component={TaskEditContainer}
        {...props.injectedProps}
      />
      <PrivateRoute
        exact
        path="/mywork"
        roles={permissions.global}
        component={TaskListContainer}
        {...props.injectedProps}
      />
      <PrivateRoute
        exact
        path="/reports"
        roles={permissions.reports.read}
        component={ReportEditContainer}
        {...props.injectedProps}
      />
      <PrivateRoute
        exact
        path="/referrals/create"
        roles={permissions.referrals.create}
        component={ReferralCreateContainer}
        {...props.injectedProps}
      />
      <PrivateRoute
        exact
        path="/referrals/view/:id"
        roles={permissions.referrals.read}
        component={ReferralEditContainer}
        {...props.injectedProps}
      />
      <PrivateRoute
        exact
        path="/referrals/edit/:id"
        roles={permissions.referrals.update}
        component={ReferralEditContainer}
        {...props.injectedProps}
      />
      <PrivateRoute
        exact
        path="/referrals"
        roles={permissions.referrals.read}
        component={ReferralListContainer}
        {...props.injectedProps}
      />
      <PrivateRoute
        exact
        path="/programmes/view/:id"
        roles={permissions.programmes.read}
        component={ProgrammeEditContainer}
        {...props.injectedProps}
      />
      <PrivateRoute
        exact
        path="/programmes/edit/:id"
        roles={permissions.programmes.update}
        component={ProgrammeEditContainer}
        {...props.injectedProps}
      />
      <PrivateRoute
        exact
        path="/programmes"
        roles={permissions.programmes.read}
        component={ProgrammeListContainer}
        {...props.injectedProps}
      />
      <PrivateRoute
        exact
        path="/engagements/create/:entityId/:entityType?/:engagementType?"
        roles={permissions.engagements.create}
        component={EngagementCreateContainer}
        {...props.injectedProps}
      />
      <PrivateRoute
        exact
        path="/engagements/view/:id?/:referralId?"
        roles={permissions.engagements.read}
        component={EngagementEditContainer}
        {...props.injectedProps}
      />
      <PrivateRoute
        exact
        path="/engagements"
        roles={permissions.engagements.read}
        component={EngagementListContainer}
        {...props.injectedProps}
      />
      <PrivateRoute
        exact
        path="/baselines/create/"
        roles={permissions.baselines.create}
        component={BaselineCreateContainer}
        {...props.injectedProps}
      />
      <PrivateRoute
        exact
        path="/baselines/view/:id"
        roles={permissions.baselines.read}
        component={BaselineEditContainer}
        {...props.injectedProps}
      />
      <PrivateRoute
        exact
        path="/baselines"
        roles={permissions.baselines.read}
        component={BaselineHandler}
        {...props.injectedProps}
      />
      <PrivateRoute
        exact
        path="/activities/create/:engagementId"
        roles={permissions.activities.create}
        component={ActivityCreateContainer}
        {...props.injectedProps}
      />

      <PrivateRoute
        exact
        path="/activities/view/:referralId?/:id?"
        roles={permissions.activities.read}
        component={ActivityEditContainer}
        {...props.injectedProps}
      />
      <PrivateRoute
        exact
        path="/activities"
        roles={permissions.activities.read}
        component={ActivityListContainer}
        {...props.injectedProps}
      />
      <PrivateRoute
        exact
        path="/outcomes"
        roles={permissions.programmes.read}
        component={OutcomeListContainer}
        {...props.injectedProps}
      />
      {props.injectedProps.area !== strings.areas.NRNE && (
        <PrivateRoute
          exact
          path="/assessmentSG/create/"
          roles={permissions.assessmentSG.read}
          component={AssessmentSGCreateContainer}
          {...props.injectedProps}
        />
      )}
      {props.injectedProps.area !== strings.areas.NRNE && (
        <PrivateRoute
          exact
          path="/assessmentSG/view:id"
          roles={permissions.assessmentSG.read}
          component={AssessmentSGEditContainer}
          {...props.injectedProps}
        />
      )}
      {props.injectedProps.area !== strings.areas.NRNE && (
        <PrivateRoute
          exact
          path="/assessmentSG"
          roles={permissions.assessmentSG.read}
          component={AssessmentSGHandler}
          {...props.injectedProps}
        />
      )}
      <PrivateRoute
        exact
        path="/employments/view/:id"
        roles={permissions.employments.edit}
        component={EmploymentEditContainer}
        {...props.injectedProps}
      />
      <PrivateRoute
        exact
        path="/employments/create/"
        roles={permissions.employments.create}
        component={EmploymentCreateContainer}
        {...props.injectedProps}
      />
      <PrivateRoute
        exact
        path="/employments"
        roles={permissions.employments.read}
        component={EmploymentListContainer}
        {...props.injectedProps}
      />
      <PrivateRoute
        exact
        path="/costs/view/:id"
        roles={permissions.costs.edit}
        component={CostEditContainer}
        {...props.injectedProps}
      />
      <PrivateRoute
        exact
        path="/costs/create/"
        roles={permissions.costs.create}
        component={CostCreateContainer}
        {...props.injectedProps}
      />
      <PrivateRoute
        exact
        path="/costs"
        roles={permissions.costs.read}
        component={CostListContainer}
        {...props.injectedProps}
      />

      <PrivateRoute
        exact
        path="/organisations/edit/:id"
        roles={permissions.organisations.update}
        component={OrganisationEditContainer}
        {...props.injectedProps}
      />
      <PrivateRoute
        exact
        path="/organisations/view/:id"
        roles={permissions.organisations.read}
        component={OrganisationEditContainer}
        {...props.injectedProps}
      />
      <PrivateRoute
        exact
        path="/organisations/create"
        roles={permissions.organisations.create}
        component={OrganisationCreateContainer}
        {...props.injectedProps}
      />
      <PrivateRoute
        exact
        path="/organisations"
        roles={permissions.organisations.read}
        component={OrganisationListContainer}
        {...props.injectedProps}
      />

      <PrivateRoute
        exact
        path="/customers/view/:id"
        roles={permissions.customers.read}
        component={CustomerEditContainer}
        {...props.injectedProps}
      />
      <PrivateRoute
        exact
        path="/customers/edit/:id"
        roles={permissions.customers.update}
        component={CustomerEditContainer}
        {...props.injectedProps}
      />
      <PrivateRoute
        exact
        path="/customers"
        roles={permissions.customers.read}
        component={CustomerListContainer}
        {...props.injectedProps}
      />
      <PrivateRoute
        exact
        path="/"
        roles={permissions.global}
        component={TaskListContainer}
        {...props.injectedProps}
      />
      <PrivateRoute
        exact
        path="/ocr"
        roles={permissions.global}
        component={OcrContainer}
        {...props.injectedProps}
      />
      <PrivateRoute
        exact
        path="/logout"
        roles={permissions.global}
        component={Logout}
        {...props.injectedProps}
      />
      <PrivateRoute
        exact
        path="/notFound"
        component={NotFoundPage}
        roles={permissions.global}
        {...props.injectedProps}
      />

      <Redirect to="/notFound" />
    </Switch>
  );
};

Routes.propTypes = {
  injectedProps: PropTypes.object.isRequired
};

export default Routes;
