import { API } from "../../services";
import { userActionTypes } from "./user.actionTypes";

const api = new API();

const createUser = (details, headers) => {
  const request = details => {
    return { type: userActionTypes.CREATE_USER_REQUEST, details };
  };
  const success = response => {
    return { type: userActionTypes.CREATE_USER_SUCCESS, response };
  };
  const failure = error => {
    return { type: userActionTypes.CREATE_USER_FAILURE, error };
  };
  api.setHeaders({ ...headers, systemHeader_subsysref: "createUser" });

  return dispatch => {
    dispatch(request(details));

    api.createUser(details).then(
      response => dispatch(success(response)),
      error => dispatch(failure(error))
    );
  };
};

const clearUserRequest = () => {
  const request = () => {
    return { type: userActionTypes.CLEAR_CREATE_USER_REQUEST };
  };

  const success = () => {
    return { type: userActionTypes.CLEAR_CREATE_USER_SUCCESS };
  };

  return dispatch => {
    dispatch(request());
    dispatch(success());
  };
};

const updateUser = (
  id,
  details,
  headers,
  initialDetails,
  showNotification = true
) => {
  const request = details => {
    return { type: userActionTypes.UPDATE_USER_REQUEST, details };
  };
  const success = response => {
    return {
      type: userActionTypes.UPDATE_USER_SUCCESS,
      response,
      showNotification
    };
  };
  const failure = error => {
    return {
      type: userActionTypes.UPDATE_USER_FAILURE,
      error,
      initialDetails
    };
  };
  api.setHeaders({ ...headers, systemHeader_subsysref: "updateUser" });

  return dispatch => {
    dispatch(request(details));

    api.updateUser(id, details).then(
      response => {
        dispatch(success(response));
      },
      error => dispatch(failure(error, initialDetails))
    );
  };
};

const deleteUser = (id, headers) => {
  const request = () => {
    return { type: userActionTypes.DELETE_USER_REQUEST };
  };
  const success = response => {
    return { type: userActionTypes.DELETE_USER_SUCCESS, response };
  };
  const failure = error => {
    return { type: userActionTypes.DELETE_USER_FAILURE, error };
  };

  api.setHeaders({ ...headers, systemHeader_subsysref: "deleteUser" });

  return dispatch => {
    dispatch(request());
    return new Promise((resolve, reject) => {
      if (id !== "") {
        api.deleteUser(id).then(
          data => {
            dispatch(success(data));
            resolve(data);
          },
          error => {
            dispatch(failure(error));
            reject(error);
          }
        );
      }
    });
  };
};

const getUsers = (
  headers,
  queryString,
  size,
  from,
  clear,
  fields = ["*"],
  sort = {},
  activeOnly
) => {
  const request = () => {
    return { type: userActionTypes.GET_USERS_REQUEST };
  };
  const success = response => {
    return { type: userActionTypes.GET_USERS_SUCCESS, response };
  };
  const failure = error => {
    return { type: userActionTypes.GET_USERS_FAILURE, error };
  };

  api.setHeaders({ ...headers, systemHeader_subsysref: "getUsers" });

  return dispatch => {
    dispatch(request());
    return new Promise((resolve, reject) => {
      if (clear) {
        // If supposed to clear, replicate blank result
        dispatch(success({ hits: { hits: [] } }));
        resolve();
      } else {
        api.getUsers(queryString, size, from, fields, sort, activeOnly).then(
          response => {
            dispatch(success(response));
            resolve(response);
          },
          error => {
            dispatch(failure(error));
            reject(error);
          }
        );
      }
    });
  };
};

const updateSearchCriteria = (
  headers,
  queryString,
  size,
  from,
  clear,
  fields,
  sort,
  activeOnly
) => {
  const request = () => {
    return { type: userActionTypes.UPDATE_SEARCH_CRITERIA_REQUEST };
  };
  const success = response => {
    return { type: userActionTypes.UPDATE_SEARCH_CRITERIA_SUCCESS, response };
  };

  const newCriteria = {
    query: queryString,
    size: size,
    from: from,
    clear,
    fields: fields,
    sort: sort,
    activeOnly: activeOnly
  };
  return dispatch => {
    dispatch(request());
    dispatch(success(newCriteria));
    dispatch(
      getUsers(
        headers,
        queryString,
        size,
        from,
        clear,
        fields,
        sort,
        activeOnly
      )
    );
  };
};

const getUserById = (id, headers) => {
  const request = () => {
    return { type: userActionTypes.GET_USER_BY_ID_REQUEST };
  };
  const success = response => {
    return { type: userActionTypes.GET_USER_BY_ID_SUCCESS, response };
  };
  const failure = error => {
    return { type: userActionTypes.GET_USER_BY_ID_FAILURE, error };
  };

  api.setHeaders({ ...headers, systemHeader_subsysref: "getUserById" });

  return dispatch => {
    dispatch(request());
    return new Promise((resolve, reject) => {
      api.getUserById(id).then(
        response => {
          dispatch(success(response.data));
          resolve(response.data);
        },
        error => {
          dispatch(failure(error));
          reject(error);
        }
      );
    });
  };
};

const getPrisonMentorById = (id, headers) => {
  const request = () => {
    return { type: userActionTypes.GET_PRISONMENTOR_BY_ID_REQUEST };
  };
  const success = response => {
    return { type: userActionTypes.GET_PRISONMENTOR_BY_ID_SUCCESS, response };
  };
  const failure = error => {
    return { type: userActionTypes.GET_PRISONMENTOR_BY_ID_FAILURE, error };
  };

  api.setHeaders({ ...headers, systemHeader_subsysref: "getPrisonMentorById" });

  return dispatch => {
    dispatch(request());
    return new Promise((resolve, reject) => {
      api.getUserById(id).then(
        response => {
          dispatch(success(response.data));
          resolve(response.data);
        },
        error => {
          dispatch(failure(error));
          reject(error);
        }
      );
    });
  };
};

const updateSelectedUser = (key, value) => {
  const request = (key, value) => {
    return {
      type: userActionTypes.UPDATE_SELECTED_USER_REQUEST,
      key,
      value
    };
  };
  const success = (key, value) => {
    return {
      type: userActionTypes.UPDATE_SELECTED_USER_SUCCESS,
      key,
      value
    };
  };
  const failure = () => {
    return { type: userActionTypes.UPDATE_SELECTED_USER_FAILURE };
  };

  return dispatch => {
    dispatch(request(key, value));
    if (key) {
      dispatch(success(key, value));
    } else {
      dispatch(failure());
    }
  };
};

const clearSelectedUser = () => {
  const request = () => {
    return { type: userActionTypes.CLEAR_SELECTED_USER_REQUEST };
  };
  const success = () => {
    return { type: userActionTypes.CLEAR_SELECTED_USER_SUCCESS };
  };

  return dispatch => {
    dispatch(request());
    dispatch(success());
  };
};

const clearPrisonMentor = () => {
  const request = () => {
    return { type: userActionTypes.CLEAR_SELECTED_PRISONMENTOR_REQUEST };
  };
  const success = () => {
    return { type: userActionTypes.CLEAR_SELECTED_PRISONMENTOR_SUCCESS };
  };

  return dispatch => {
    dispatch(request());
    dispatch(success());
  };
};

const cancelSelectedUserUpdate = initialUserData => {
  const request = () => {
    return { type: userActionTypes.CANCEL_SELECTED_USER_UPDATE_REQUEST };
  };
  const success = data => {
    return { type: userActionTypes.CANCEL_SELECTED_USER_UPDATE_SUCCESS, data };
  };

  return dispatch => {
    dispatch(request());
    dispatch(success(initialUserData));
  };
};

export const userActions = {
  createUser,
  updateUser,
  deleteUser,
  getUserById,
  getUsers,
  updateSearchCriteria,
  updateSelectedUser,
  clearSelectedUser,
  clearUserRequest,
  cancelSelectedUserUpdate,
  getPrisonMentorById,
  clearPrisonMentor
};
