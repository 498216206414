import React from "react";
import PropTypes from "prop-types";
import { Button, Form, Popup, Icon } from "semantic-ui-react";
import {
  strings,
  referralToOptions,
  referralFromOptions,
  referralInformationContactOptions,
  referralInformationNeedOptions,
  outcomeOfReferral,
  yesNoOptions
} from "../resources";
import { ComponentHeader, BasicModal } from ".";
import {
  ValidatedFormInput,
  ValidatedFormDropdown,
  ValidatedDateInput,
  ValidatedFormTextArea
} from "./validation";
import { withRouter } from "react-router-dom";
import { permissions } from "../../App/resources/AppPermissions";
import { convertFromIso } from "../helpers";
import FileManager from "../../File/components/FileManager";

class ModifyCustomer extends React.Component {
  returnToList = () => {
    this.props.history.push("/customers");
  };

  renderModifyButtons = () => {
    return (
      <div className="containerButtons">
        {!this.props.deleteSuccessful && (
          <>
            <Button.Group>
              <Button
                className="editButton"
                primary
                onClick={() => {
                  this.props.handleModeSwitch("edit");
                }}
              >
                {strings.button.editLabel}
              </Button>
            </Button.Group>
          </>
        )}
      </div>
    );
  };
  renderCancelSaveButtons = () => {
    return (
      <div className="containerButtons">
        <Button.Group>
          <Button
            className="saveButton"
            primary
            inverted
            onClick={this.props.validateForm}
            disabled={
              JSON.stringify(this.props.selectedCustomerData) ===
                JSON.stringify(this.props.initialCustomerData) &&
              !this.props.hasFileChanges
            }
          >
            {strings.button.saveLabel}
          </Button>
          <BasicModal
            confirmMessage={strings.modal.saveChangesMessage}
            handleModalConfirm={this.props.handleModalConfirm}
            modalClassName="confirmSaveModal"
            modalOpen={this.props.confirmSaveModalOpen}
            showNoButton={true}
            showOkButton={false}
            showYesButton={true}
            toggleModal={this.props.toggleConfirmSaveModalVisibility}
            noLabel={strings.button.noLabel}
            yesLabel={strings.button.yesLabel}
            okLabel={strings.button.okLabel}
          />
        </Button.Group>
        <Button.Group>
          <Button
            className="cancelButton"
            primary
            onClick={this.props.toggleCancelChangesModalVisibility}
            type="button"
          >
            {strings.button.cancelLabel}
          </Button>
          <BasicModal
            confirmMessage={strings.modal.cancelChangesMessage}
            handleModalConfirm={
              this.props.mode === "create"
                ? this.props.handleModalConfirm
                : this.props.handleModeSwitch
            }
            modalClassName="cancelChangesModal"
            modalOpen={this.props.cancelChangesModalOpen}
            showOkButton={false}
            showYesButton={true}
            showNoButton={true}
            toggleModal={this.props.toggleCancelChangesModalVisibility}
            noLabel={strings.button.noLabel}
            yesLabel={strings.button.yesLabel}
            okLabel={strings.button.okLabel}
          />
        </Button.Group>
      </div>
    );
  };
  renderForm = () => {
    return (
      <>
        <div className="infoHeader">{strings.form.header.customerInfo}</div>
        <div className="infoWrapper">
          <Form.Group>
            <ValidatedFormInput
              formInvalid={this.props.formInvalid}
              inputData={this.props.selectedCustomerData.firstName}
              inputLabel={strings.form.label.firstName}
              inputName="firstName"
              inputPlaceholder={strings.form.placeholder.firstName}
              formFieldWidth={8}
              handleChange={this.props.handleChange}
              validationResult={this.props.validationResults.firstName}
              disabled={this.props.mode === "edit"}
              mode={this.props.mode}
              required={false}
            />
            <ValidatedFormInput
              formInvalid={this.props.formInvalid}
              inputData={this.props.selectedCustomerData.surname}
              inputLabel={strings.form.label.surname}
              inputName="surname"
              inputPlaceholder={strings.form.placeholder.surname}
              formFieldWidth={8}
              handleChange={this.props.handleChange}
              validationResult={this.props.validationResults.surname}
              required={false}
              disabled={this.props.mode === "edit"}
              mode={this.props.mode}
            />
          </Form.Group>
          <Form.Group>
            <ValidatedFormInput
              formInvalid={this.props.formInvalid}
              inputData={this.props.selectedCustomerData.libAddress1}
              inputLabel={strings.form.label.libAddress1}
              inputName="libAddress1"
              inputPlaceholder={strings.form.placeholder.libAddress1}
              formFieldWidth={8}
              handleChange={this.props.handleChange}
              validationResult={this.props.validationResults.libAddress1}
              disabled={this.props.mode === "edit"}
              required={false}
              mode={this.props.mode}
            />
            <ValidatedFormInput
              formInvalid={this.props.formInvalid}
              inputData={this.props.selectedCustomerData.libAddress2}
              inputLabel={strings.form.label.libAddress2}
              inputName="libAddress2"
              inputPlaceholder={strings.form.placeholder.libAddress2}
              formFieldWidth={8}
              handleChange={this.props.handleChange}
              validationResult={this.props.validationResults.libAddress2}
              disabled={this.props.mode === "edit"}
              required={false}
              mode={this.props.mode}
            />
          </Form.Group>
          <Form.Group>
            <ValidatedFormInput
              formInvalid={this.props.formInvalid}
              inputData={this.props.selectedCustomerData.libTown}
              inputLabel={strings.form.label.libTown}
              inputName="libTown"
              inputPlaceholder={strings.form.placeholder.libTown}
              formFieldWidth={8}
              handleChange={this.props.handleChange}
              validationResult={this.props.validationResults.libTown}
              disabled={this.props.mode === "edit"}
              required={false}
              mode={this.props.mode}
            />
            <ValidatedFormInput
              formInvalid={this.props.formInvalid}
              inputData={this.props.selectedCustomerData.libPostcode}
              inputLabel={strings.form.label.libPostcode}
              inputName="libPostcode"
              inputPlaceholder={strings.form.placeholder.libPostcode}
              formFieldWidth={8}
              handleChange={this.props.handleChange}
              validationResult={this.props.validationResults.libPostcode}
              disabled={this.props.mode === "edit"}
              required={false}
              mode={this.props.mode}
            />
          </Form.Group>
          <Form.Group>
            <ValidatedFormInput
              formInvalid={this.props.formInvalid}
              inputData={this.props.selectedCustomerData.email}
              inputLabel={strings.form.label.email}
              inputName="email"
              inputPlaceholder={strings.form.placeholder.email}
              formFieldWidth={8}
              handleChange={this.props.handleChange}
              validationResult={this.props.validationResults.email}
              disabled={this.props.mode === "edit"}
              required={false}
              mode={this.props.mode}
            />
          </Form.Group>
          <Form.Group>
            <ValidatedFormInput
              formInvalid={this.props.formInvalid}
              inputData={this.props.selectedCustomerData.phone1}
              inputLabel={strings.form.label.phone1}
              inputName="phone1"
              inputPlaceholder={strings.form.placeholder.phone1}
              formFieldWidth={8}
              onKeyPress={this.props.allowNumericalInput}
              handleChange={this.props.handleChange}
              validationResult={this.props.validationResults.phone1}
              disabled={this.props.mode === "edit"}
              required={false}
              mode={this.props.mode}
            />
            <ValidatedFormInput
              formInvalid={this.props.formInvalid}
              inputData={this.props.selectedCustomerData.phone2}
              inputLabel={strings.form.label.phone2}
              inputName="phone2"
              inputPlaceholder={strings.form.placeholder.phone2}
              formFieldWidth={8}
              onKeyPress={this.props.allowNumericalInput}
              handleChange={this.props.handleChange}
              validationResult={this.props.validationResults.phone2}
              disabled={this.props.mode === "edit"}
              required={false}
              mode={this.props.mode}
            />
          </Form.Group>
          <Form.Group>
            <ValidatedFormInput
              formInvalid={this.props.formInvalid}
              inputData={this.props.selectedCustomerData.nin}
              inputLabel={strings.form.label.nin}
              inputName="nin"
              inputPlaceholder={strings.form.placeholder.nin}
              formFieldWidth={8}
              handleChange={this.props.handleChange}
              validationResult={this.props.validationResults.nin}
              disabled={this.props.mode === "edit"}
              mode={this.props.mode}
              required={false}
              popUp={
                <Popup
                  content={strings.tooltip.ninFormatContent}
                  key="nin"
                  header={strings.tooltip.ninFormatHeader}
                  trigger={<Icon className="info tooltip" name="info circle" />}
                />
              }
            />
            <ValidatedFormInput
              formInvalid={this.props.formInvalid}
              inputData={this.props.selectedCustomerData.latestRiskRating}
              inputLabel={strings.form.label.latestRiskRating}
              inputName="latestRiskRating"
              inputPlaceholder={strings.form.placeholder.latestRiskRating}
              formFieldWidth={8}
              handleChange={this.props.handleChange}
              validationResult={this.props.validationResults.latestRiskRating}
              disabled={this.props.mode === "edit"}
              required={false}
              mode={this.props.mode}
            />
          </Form.Group>
          <Form.Group>
            <ValidatedFormDropdown
              formInvalid={this.props.formInvalid}
              inputData={this.props.selectedCustomerData.consent}
              viewData={this.props.selectedCustomerData.consent}
              dropdownLabel={strings.form.label.consent}
              dropdownName="consent"
              dropdownOptions={yesNoOptions}
              dropdownPlaceholder={strings.form.placeholder.consent}
              formFieldWidth={8}
              handleChange={this.props.handleChange}
              validationResult={this.props.validationResults.consent}
              dropdownDisabled={this.props.mode === "edit"}
              mode={this.props.mode}
              required={false}
            />
          </Form.Group>
          <Form.Group>
            <ValidatedFormDropdown
              formInvalid={this.props.formInvalid}
              inputData={this.props.selectedCustomerData.referralFrom}
              viewData={this.props.selectedCustomerData.referralFrom}
              dropdownLabel={strings.form.label.referralFrom}
              dropdownName="referralFrom"
              dropdownOptions={
                this.props.referralFromOptions
                  ? this.props.referralFromOptions
                  : referralFromOptions
              }
              dropdownPlaceholder={strings.form.placeholder.referralFrom}
              formFieldWidth={8}
              handleChange={this.props.handleChange}
              validationResult={this.props.validationResults.referralFrom}
              required={false}
              dropdownDisabled={this.props.mode === "edit"}
              mode={this.props.mode}
            />
            <ValidatedFormDropdown
              formInvalid={this.props.formInvalid}
              inputData={this.props.selectedCustomerData.referralTo}
              viewData={this.props.selectedCustomerData.referralTo}
              dropdownLabel={strings.form.label.referralTo}
              dropdownName="referralTo"
              dropdownOptions={
                this.props.referralToOptions
                  ? this.props.referralToOptions
                  : referralToOptions
              }
              dropdownPlaceholder={strings.form.placeholder.referralTo}
              formFieldWidth={8}
              handleChange={this.props.handleChange}
              validationResult={this.props.validationResults.referralTo}
              mode={this.props.mode}
              dropdownDisabled={this.props.mode !== "create"}
            />
          </Form.Group>
          <Form.Group>
            <ValidatedDateInput
              inputId="dateOfReferral"
              formInvalid={this.props.formInvalid}
              inputLabel={strings.form.label.dateOfReferral}
              inputName="dateOfReferral"
              inputPlaceholder={strings.form.placeholder.dateOfReferral}
              inputDateFormat="DD/MM/YYYY"
              inputData={
                this.props.selectedCustomerData.dateOfReferral
                  ? convertFromIso(
                      this.props.selectedCustomerData.dateOfReferral
                    )
                  : ""
              }
              iconPosition="left"
              handleChange={this.props.handleChange}
              validationResult={this.props.validationResults.dateOfReferral}
              disabled={this.props.mode === "edit"}
              formFieldWidth={8}
              required={false}
              mode={this.props.mode}
            />
          </Form.Group>
          <Form.Group>
            <ValidatedFormDropdown
              formInvalid={this.props.formInvalid}
              inputData={
                this.props.selectedCustomerData.referralInformationNeed
              }
              viewData={this.props.selectedCustomerData.referralInformationNeed}
              dropdownLabel={strings.form.label.referralInformationNeed}
              dropdownName="referralInformationNeed"
              dropdownOptions={referralInformationNeedOptions}
              dropdownPlaceholder={
                strings.form.placeholder.referralInformationNeed
              }
              formFieldWidth={8}
              handleChange={this.props.handleChange}
              validationResult={
                this.props.validationResults.referralInformationNeed
              }
              required={false}
              mode={this.props.mode}
            />
            <ValidatedFormDropdown
              formInvalid={this.props.formInvalid}
              inputData={
                this.props.selectedCustomerData.referralInformationContact || []
              }
              viewData={
                this.props.selectedCustomerData.referralInformationContact
              }
              dropdownLabel={strings.form.label.referralInformationContact}
              dropdownName="referralInformationContact"
              dropdownOptions={referralInformationContactOptions}
              dropdownPlaceholder={
                strings.form.placeholder.referralInformationContact
              }
              formFieldWidth={8}
              handleChange={this.props.handleChange}
              validationResult={
                this.props.validationResults.referralInformationContact
              }
              dropdownMultiple={true}
              required={false}
              mode={this.props.mode}
            />
          </Form.Group>
          <Form.Group>
            <ValidatedFormTextArea
              formInvalid={this.props.formInvalid}
              inputData={
                this.props.selectedCustomerData.referralInformationNotes
              }
              inputLabel={strings.form.label.referralInformationNotes}
              inputName="referralInformationNotes"
              inputPlaceholder={
                strings.form.placeholder.referralInformationNotes
              }
              formFieldWidth={8}
              handleChange={this.props.handleChange}
              validationResult={
                this.props.validationResults.referralInformationNotes
              }
              required={false}
              mode={this.props.mode}
            />
          </Form.Group>
          <Form.Group>
            <ValidatedFormDropdown
              formInvalid={this.props.formInvalid}
              inputData={this.props.selectedCustomerData.outcomeOfReferral}
              viewData={this.props.selectedCustomerData.outcomeOfReferral}
              dropdownLabel={strings.form.label.outcomeOfReferral}
              dropdownName="outcomeOfReferral"
              dropdownOptions={outcomeOfReferral}
              dropdownPlaceholder={strings.form.placeholder.outcomeOfReferral}
              formFieldWidth={8}
              handleChange={this.props.handleChange}
              validationResult={this.props.validationResults.outcomeOfReferral}
              required={false}
              mode={this.props.mode}
            />
            <ValidatedDateInput
              inputId="dateOfOutcomeOfReferral"
              formInvalid={this.props.formInvalid}
              inputLabel={strings.form.label.dateOfOutcomeOfReferral}
              inputName="dateOfOutcomeOfReferral"
              inputPlaceholder={
                strings.form.placeholder.dateOfOutcomeOfReferral
              }
              inputDateFormat="DD/MM/YYYY"
              inputData={
                this.props.selectedCustomerData.dateOfOutcomeOfReferral
                  ? convertFromIso(
                      this.props.selectedCustomerData.dateOfOutcomeOfReferral
                    )
                  : ""
              }
              iconPosition="left"
              handleChange={this.props.handleChange}
              validationResult={
                this.props.validationResults.dateOfOutcomeOfReferral
              }
              formFieldWidth={8}
              required={false}
              mode={this.props.mode}
            />
          </Form.Group>
          <Form.Group>
            <ValidatedFormTextArea
              formInvalid={this.props.formInvalid}
              inputData={this.props.selectedCustomerData.outcomeOfReferralNotes}
              inputLabel={strings.form.label.outcomeOfReferralNotes}
              inputName="outcomeOfReferralNotes"
              inputPlaceholder={strings.form.placeholder.outcomeOfReferralNotes}
              formFieldWidth={8}
              handleChange={this.props.handleChange}
              validationResult={
                this.props.validationResults.outcomeOfReferralNotes
              }
              required={false}
              mode={this.props.mode}
            />
            {this.props.selectedCustomerData.gcrId && (
              <FileManager
                headers={this.props.headers}
                allowEdit={true}
                filePath="customers/"
                filesLabel={strings.form.fileUpload}
                disabled={this.props.mode === "view"}
                entityId={this.props.selectedCustomerData.gcrId}
                entity="customer"
                shouldSaveFiles={this.props.shouldSaveFiles}
                filesHaveChanged={this.props.filesHaveChanged}
              />
            )}
          </Form.Group>
        </div>
      </>
    );
  };

  render = () => {
    return (
      <div className="container">
        <ComponentHeader
          returnToList={this.returnToList}
          pageTitle={this.props.pageTitle}
          mode={this.props.mode}
          requestStatus={this.props.requestStatus}
          modifyButtons={this.renderModifyButtons()}
          cancelSaveButtons={this.renderCancelSaveButtons()}
          headerList={strings.header.customerList}
          permissionsUpdate={permissions.customers.update}
          permissionsCreate={permissions.customers.create}
          formInvalid={this.props.formInvalid}
          validationResults={this.props.validationResults}
          sticky
        />

        <Form id="customerForm" className="infoBlock stickyForm">
          <fieldset disabled={this.props.mode === "view"}>
            {this.renderForm()}
          </fieldset>
        </Form>
      </div>
    );
  };
}

ModifyCustomer.propTypes = {
  allowNumericalInput: PropTypes.func.isRequired,
  cancelChangesModalOpen: PropTypes.bool.isRequired,
  confirmSaveModalOpen: PropTypes.bool.isRequired,
  enableDisableCustomerModalOpen: PropTypes.bool.isRequired,
  formInvalid: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleModalConfirm: PropTypes.func.isRequired,
  handleModeSwitch: PropTypes.func,
  history: PropTypes.object,
  initialCustomerData: PropTypes.object.isRequired,
  mode: PropTypes.string.isRequired,
  pageTitle: PropTypes.string.isRequired,
  requestStatus: PropTypes.bool,
  selectedCustomerData: PropTypes.object.isRequired,
  toggleCancelChangesModalVisibility: PropTypes.func.isRequired,
  toggleConfirmSaveModalVisibility: PropTypes.func.isRequired,
  toggleEnableDisableCustomerModalVisibility: PropTypes.func.isRequired,
  validateForm: PropTypes.func.isRequired,
  validationResults: PropTypes.object,
  deletePermissionStatus: PropTypes.string,
  handleModalInputChange: PropTypes.func,
  deletionReason: PropTypes.object
};

export default withRouter(ModifyCustomer);
