import LocalizedStrings from "react-localization";

export const strings = new LocalizedStrings({
  en: {
    menuPages: {
      users: "User Directory",
      mywork: "My Work",
      customers: "Customers",
      organisations: "Organisations",
      referrals: "Referrals",
      programmes: "Programmes",
      reports: "Reports",
      engagements: "Engagements",
      activities: "Activities",
      outcomes: "Outcomes",
      employments: "Employments",
      costs: "Costs",
      assessmentSG: "SG Assessment",
      baseline: "Baseline Assessments",
      ocr: "OCR",
      feedback: "Feedback"
    },
    text: {
      to: "To",
      from: "From",
      true: "true",
      none: "None"
    },
    header: {
      login: "Login",
      journey: " - Journey "
    },
    label: {
      signingIn: "Signing in..",
      passwordReset: "Password Reset",
      twg: "The Wise Group",
      startDate: "Start Date",
      endDate: "End Date"
    },
    tooltip: {
      passwordFormatHeader: "Password Format",
      passwordFormatContent:
        "At least 1 Uppercase, 1 Lowercase, 1 Number, and 8 characters in length."
    },
    form: {
      placeholder: {
        username: "Username",
        password: "Password",
        email: "Email Address",
        confirmationCode: "Confirmation Code",
        newPassword: "Please enter your new password...",
        confirmPassword: "Confirm Password",
        enterDate: "enter a date"
      }
    },
    button: {
      loginLabel: "Login",
      submit: "Submit",
      yesLabel: "Yes",
      noLabel: "No",
      okLabel: "Ok"
    },
    footer: {
      pulsion: "Pulsion Technology",
      version: "Version",
      website: "https://www.pulsion.co.uk"
    },
    error: {
      validationMessage:
        "Please review the form following the information below:",
      formField: "Can't set form field data",
      passwordCodeSent:
        "Could not send password reset email. Try again later or contact support.",
      passwordCodeConfirmed:
        "Could not confirm password. Try again later or contact support.",
      passwordValidated: "Missing code or invalid password.",
      passwordNeedsReset:
        "Your password must be reset. Please create a new password ...",
      accountNeedsConfirmed: "This account needs confirmed by an Administrator",
      invalidPassword: "Invalid Password",
      accountNotFound: "Account not found for this email address",
      temporaryPasswordExpired:
        "Temporary password has expired and must be reset by an administrator.",
      accountInactive: "This account has been set to inactive",
      accountDisabled: "This account has been disabled",
      defaultLoginError: "Error: could not log in",
      emailAndPasswordRequired: "Username and Password must both be entered"
    },
    areas: {
      NRS: "NRS",
      NRNE: "NRNE",
      both: "both"
    },
    modal: {
      confirmMessage: "Are you still working on this?",
      yesLabel: "Yes",
      noLabel: "No",
      okLabel: "Ok"
    }
  }
});
