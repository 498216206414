import { API } from "../../services";
import { reportActionTypes } from "./report.actionTypes";
import { strings } from "../../resources";
import { reportHelpers } from "../../helpers";

const api = new API();

const saveReport = (details, headers) => {
  const request = details => {
    return { type: reportActionTypes.SAVE_REPORT_REQUEST, details };
  };
  const success = (response, details) => {
    return { type: reportActionTypes.SAVE_REPORT_SUCCESS, response, details };
  };
  const failure = error => {
    return { type: reportActionTypes.SAVE_REPORT_FAILURE, error };
  };
  api.setHeaders({ ...headers, systemHeader_subsysref: "saveReport" });

  return dispatch => {
    let data = { ...details };

    data.reportType = strings.text.resultReportType;
    if (!data.dateSaved) {
      data.dateSaved = new Date().toISOString();
    }

    dispatch(request(data));
    return new Promise((resolve, reject) => {
      api.saveReport(data).then(
        response => {
          dispatch(success(response, data));
          resolve(response);
        },
        error => {
          dispatch(failure(error));
          reject(error);
        }
      );
    });
  };
};

const uploadReferrals = (details, headers) => {
  const request = () => {
    return { type: reportActionTypes.UPLOAD_REFERRALS_REQUEST, details };
  };
  const success = responses => {
    return { type: reportActionTypes.UPLOAD_REFERRALS_SUCCESS, responses };
  };
  const failure = errors => {
    return { type: reportActionTypes.UPLOAD_REFERRALS_FAILURE, errors };
  };

  api.setHeaders({ ...headers, systemHeader_subsysref: "uploadReferrals" });

  return dispatch => {
    let data = { ...details };

    dispatch(request());
    return new Promise(async (resolve, reject) => {
      let promises = [];
      let referrals = data.body || [];
      let from = data.from + data.pageSize;

      // Get all referrals to be uploaded
      for (
        from;
        from - data.from < data.uploadSize && from < data.result;
        from = from + data.pageSize
      ) {
        const filters = reportHelpers.removeBothAttributes(data.query);
        promises.push(
          api.generateReport({
            id: data.reportTemplateId,
            from,
            size: data.pageSize,
            ...filters
          })
        );
      }

      await Promise.all(promises).then(responses => {
        responses.forEach(resp => {
          const info = reportHelpers.prepareForUpload(resp.data.referrals);
          referrals = [...referrals, ...info];
        });
      });
      promises = [];

      // Upload retrieved referrals in batches
      for (let i = 0; i < referrals.length; i = i + data.pageSize) {
        const uploadData = {
          reportId: data.reportId,
          body: referrals.slice(i, i + data.pageSize),
          result: data.result
        };
        if (uploadData.body.length > 0) {
          promises.push(api.uploadReferrals(uploadData));
        }
      }

      await Promise.all(promises).then(
        responses => {
          dispatch(success(responses));
          resolve(responses);
        },
        errors => {
          dispatch(failure(errors));
          reject(errors);
        }
      );
    });
  };
};

const clearReportRequest = () => {
  const request = () => {
    return { type: reportActionTypes.CLEAR_CREATE_REPORT_REQUEST };
  };

  const success = () => {
    return { type: reportActionTypes.CLEAR_CREATE_REPORT_SUCCESS };
  };

  return dispatch => {
    dispatch(request());
    dispatch(success());
  };
};

const deleteReport = (id, headers) => {
  const request = () => {
    return { type: reportActionTypes.DELETE_REPORT_REQUEST };
  };
  const success = response => {
    return { type: reportActionTypes.DELETE_REPORT_SUCCESS, response };
  };
  const failure = error => {
    return { type: reportActionTypes.DELETE_REPORT_FAILURE, error };
  };

  api.setHeaders({ ...headers, systemHeader_subsysref: "deleteReport" });

  return dispatch => {
    dispatch(request());
    if (id !== "") {
      api.deleteReport(id).then(
        data => {
          dispatch(success(data));
        },
        error => dispatch(failure(error))
      );
    }
  };
};

const getReports = (searchCriteria, headers) => {
  searchCriteria = JSON.parse(JSON.stringify(searchCriteria));
  let searchQuery = searchCriteria.query,
    from = searchCriteria.from,
    sort = searchCriteria.sort,
    size = searchCriteria.size;

  const request = () => {
    return { type: reportActionTypes.GET_REPORTS_REQUEST };
  };
  const success = response => {
    return { type: reportActionTypes.GET_REPORTS_SUCCESS, response };
  };
  const failure = error => {
    return { type: reportActionTypes.GET_REPORTS_FAILURE, error };
  };

  api.setHeaders({ ...headers, systemHeader_subsysref: "getReports" });

  return dispatch => {
    dispatch(request());

    const body = {
      query_string: "",
      from: from,
      sort: sort,
      size: size,
      reportType: searchQuery.reportViewType
    };

    api.getReports(body).then(
      response => {
        dispatch(success(response));
      },
      error => dispatch(failure(error))
    );
  };
};

const searchReportReferrals = (searchCriteria, headers) => {
  searchCriteria = JSON.parse(JSON.stringify(searchCriteria));
  let searchQuery = searchCriteria.query,
    from = searchCriteria.from,
    sort = searchCriteria.sort,
    size = searchCriteria.size;

  const request = () => {
    return { type: reportActionTypes.SEARCH_REPORT_REFERRALS_REQUEST };
  };
  const success = response => {
    return {
      type: reportActionTypes.SEARCH_REPORT_REFERRALS_SUCCESS,
      response
    };
  };
  const failure = error => {
    return { type: reportActionTypes.SEARCH_REPORT_REFERRALS_FAILURE, error };
  };

  api.setHeaders({
    ...headers,
    systemHeader_subsysref: "searchReportReferrals"
  });

  return async dispatch => {
    dispatch(request());

    const body = {
      query_string: "",
      from: from,
      sort: sort,
      size: size,
      reportId: searchQuery.reportId
    };

    await api.searchReportReferrals(body).then(
      response => {
        dispatch(success(response));
      },
      error => dispatch(failure(error))
    );
  };
};

const getReportById = (id, headers, searchCriteria) => {
  const request = () => {
    return { type: reportActionTypes.GET_REPORT_BY_ID_REQUEST };
  };
  const success = response => {
    return { type: reportActionTypes.GET_REPORT_BY_ID_SUCCESS, response };
  };
  const failure = error => {
    return { type: reportActionTypes.GET_REPORT_BY_ID_FAILURE, error };
  };

  api.setHeaders({ ...headers, systemHeader_subsysref: "getReportById" });

  return dispatch => {
    dispatch(request());

    const body = {
      query_string: id,
      fields: ["reportId"],
      from: 0,
      size: 1,
      reportType: strings.text.resultReportType
    };

    api.getReports(body).then(
      async response => {
        let report = response.data.Body.hits.hits[0]._source;

        if (!report.result && !report.hasSavedReferrals) {
          await dispatch(
            generateReport(
              {
                ...report,
                reportType: undefined,
                id: report.reportTemplateId,
                reportTemplateId: undefined,
                reportId: undefined,
                name: undefined,
                dateSaved: undefined,
                description: undefined,
                criteria: {
                  size: searchCriteria.size,
                  from: searchCriteria.from
                }
              },
              headers
            )
          );
        } else if (report.result && report.hasSavedReferrals) {
          const referralsBody = {
            query: {
              reportId: id
            },
            from: searchCriteria.from,
            size: searchCriteria.size
          };
          await dispatch(searchReportReferrals(referralsBody, headers));
        }

        const responseBody = {
          ...report
        };

        dispatch(success(responseBody));
      },
      error => dispatch(failure(error))
    );
  };
};

const generateReport = (body, headers) => {
  const request = () => {
    return { type: reportActionTypes.GENERATE_REPORT_REQUEST };
  };
  const success = response => {
    return { type: reportActionTypes.GENERATE_REPORT_SUCCESS, response };
  };
  const failure = error => {
    return { type: reportActionTypes.GENERATE_REPORT_FAILURE, error };
  };

  api.setHeaders({ ...headers, systemHeader_subsysref: "generateReport" });

  return dispatch => {
    dispatch(request());

    let successResponse = {};
    let failureError = {};
    const size = body.criteria.size;
    const from = body.criteria.from;

    return new Promise(async (resolve, reject) => {
      const data = reportHelpers.removeBothAttributes(body);
      delete data.criteria;
      if (data.id) {
        data.id = data.id.split(",");
      } else {
        return;
      }
      for (let i = 0; i < data.id.length; i++) {
        if (data.partner) {
          data.partner = data.partner.split(",");
          if (data.partner.length === 0) {
            delete data.partner;
          }
        }
        await api.generateReport({ ...data, id: data.id[i], size, from }).then(
          response => {
            successResponse.result = successResponse.result
              ? successResponse.result + response.data.result
              : response.data.result;
            successResponse.fromDate = response.data.fromDate;
            successResponse.toDate = response.data.toDate;
            successResponse.Reason = response.data.Reason;
            successResponse.referrals = response.data.referrals;
            resolve(response.data);
          },
          error => {
            failureError.data = error;
            reject(error);
          }
        );
      }

      if (typeof successResponse.result === "number") {
        dispatch(success(successResponse));
      } else {
        if (failureError.data) {
          dispatch(failure(failureError.data));
        } else {
          dispatch(failure("Something went wrong when generating the report."));
        }
      }
    });
  };
};

const updateSelectedReport = (key, value) => {
  const request = (key, value) => {
    return {
      type: reportActionTypes.UPDATE_SELECTED_REPORT_REQUEST,
      key,
      value
    };
  };
  const success = (key, value) => {
    return {
      type: reportActionTypes.UPDATE_SELECTED_REPORT_SUCCESS,
      key,
      value
    };
  };
  const failure = () => {
    return { type: reportActionTypes.UPDATE_SELECTED_REPORT_FAILURE };
  };

  return dispatch => {
    dispatch(request(key, value));
    if (key) {
      dispatch(success(key, value));
    } else {
      dispatch(failure());
    }
  };
};

const clearSelectedReport = () => {
  const request = () => {
    return { type: reportActionTypes.CLEAR_SELECTED_REPORT_REQUEST };
  };
  const success = () => {
    return { type: reportActionTypes.CLEAR_SELECTED_REPORT_SUCCESS };
  };

  return dispatch => {
    dispatch(request());
    dispatch(success());
  };
};

const cancelSelectedReportUpdate = initialReportData => {
  const request = () => {
    return {
      type: reportActionTypes.CANCEL_SELECTED_REPORT_UPDATE_REQUEST
    };
  };
  const success = data => {
    return {
      type: reportActionTypes.CANCEL_SELECTED_REPORT_UPDATE_SUCCESS,
      data
    };
  };

  return dispatch => {
    dispatch(request());
    dispatch(success(initialReportData));
  };
};

const clearSavedReport = () => {
  const success = () => {
    return { type: reportActionTypes.CLEAR_SAVED_REPORT_SUCCESS };
  };

  return dispatch => {
    dispatch(success());
  };
};

const storeSpecialistOptions = users => {
  const success = () => {
    return { type: reportActionTypes.STORE_SPECIALIST_OPTIONS_SUCCESS, users };
  };

  return dispatch => {
    if (users && users.length > 0) {
      dispatch(success());
    }
  };
};

export const reportActions = {
  saveReport,
  deleteReport,
  generateReport,
  getReports,
  updateSelectedReport,
  clearSelectedReport,
  clearReportRequest,
  cancelSelectedReportUpdate,
  getReportById,
  uploadReferrals,
  searchReportReferrals,
  clearSavedReport,
  storeSpecialistOptions
};
