import React from "react";
import PropTypes from "prop-types";
import { Form } from "semantic-ui-react";
import { strings } from "../../resources";
import FileManager from "../../../File/components/FileManager";
import { ValidatedFormTextArea } from "../validation";
import "../css/ProgrammeComponents.scss";

const NotesFields = props => (
  <div className="infoBlock">
    <div className="infoHeader">{strings.form.header.notesHeader}</div>
    <div className="infoWrapper">
      <Form.Group>
        <ValidatedFormTextArea
          formInvalid={props.formInvalid}
          inputData={props.selectedProgrammeData.notes}
          inputLabel={strings.form.label.notes}
          inputName="notes"
          inputPlaceholder={strings.form.placeholder.notes}
          formFieldWidth={8}
          handleChange={props.handleChange}
          validationResult={props.validationResults.notes}
          required={false}
          mode={props.mode}
        />
        <FileManager
          headers={props.headers}
          allowEdit={true}
          filePath="programmes/"
          filesLabel={strings.form.fileUpload}
          disabled={props.mode === "view"}
          entityId={props.selectedProgrammeData.programmeId}
          entity="programme"
          shouldSaveFiles={props.shouldSaveFiles}
          filesHaveChanged={props.filesHaveChanged}
        />
      </Form.Group>
    </div>
  </div>
);
NotesFields.propTypes = {
  formInvalid: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  mode: PropTypes.string.isRequired,
  selectedProgrammeData: PropTypes.object.isRequired,
  validationResults: PropTypes.object
};

export default NotesFields;
