export const userRoleOptions = [
  { key: 1, text: "Pulsion", value: "pulsion" },
  { key: 2, text: "Mentor", value: "mentor" },
  { key: 3, text: "NR Team Lead", value: "teamLead" },
  { key: 4, text: "NR Manager", value: "manager" },
  { key: 5, text: "NR Manager Partner", value: "managerPartner" },
  { key: 6, text: "NR Specialist Support Partner", value: "ssp" },
  { key: 7, text: "NR Head of Operations", value: "hoo" },
  { key: 8, text: "NR Assurance", value: "assurance" },
  { key: 9, text: "NR ICT", value: "ict" },
  { key: 10, text: "NR Support", value: "support" }
];

export const areaOptions = [
  { key: 1, text: "NRS", value: "NRS" },
  { key: 2, text: "NRNE", value: "NRNE" },
  { key: 3, text: "Both NRNE and NRS", value: "both" }
];

export const areaOptionsMentor = [
  { key: 1, text: "NRS", value: "NRS" },
  { key: 2, text: "NRNE", value: "NRNE" },
]