import React from "react";
import PropTypes from "prop-types";
import { permissions } from "../../App/resources/AppPermissions";
import { EngagementListContainer } from "../../Engagements/containers/";
import { Button, Grid } from "semantic-ui-react";
import { strings } from "../resources/";
import { filterOutUndesiredProps } from "../helpers/index";

import {
  AdministrationFields,
  GeneralFields,
  MentorFields,
  AddictionFields,
  DependantsFields,
  ExitFields,
  HobbiesFields,
  HouseholdStatusFields,
  KeyDatesAndMilestonesFields,
  LiberationFields,
  GeneralHealthFields,
  MentalHealthFields,
  NotesFields,
  PrisonSupportServiceFields,
  EmploymentAndBenefitsFields,
  ChooseForm,
  ChooseEngagement
} from "./";

const FieldList = props => {
  const administrationFields = (
    <AdministrationFields
      formInvalid={props.formInvalid}
      handleChange={props.handleChange}
      mode={props.mode}
      selectedProgrammeData={props.selectedProgrammeData}
      validationResults={props.validationResults}
      modifyOwnerRoles={permissions.programmes.updateOwner}
      projectLeadRole={permissions.programmes.updateProjectLeadOnly}
      organisations={filterOutUndesiredProps(props.getOrganisations())}
      roleId={props.roleId}
    />
  );

  const generalFields = (
    <GeneralFields
      formInvalid={props.formInvalid}
      handleChange={props.handleChange}
      mode={props.mode}
      selectedProgrammeData={props.selectedProgrammeData}
      validationResults={props.validationResults}
      area={props.area}
    />
  );

  const mentorFields = (
    <MentorFields
      formInvalid={props.formInvalid}
      handleChange={props.handleChange}
      mode={props.mode}
      selectedProgrammeData={props.selectedProgrammeData}
      validationResults={props.validationResults}
      getMentors={props.getMentors}
      getMentorsAll={props.getMentorsAll}
      area={props.area}
      roleId={props.roleId}
      specialistDropdown={props.specialistDropdown}
      userId={props.userId}
      organisations={props.getOrganisations()}
    />
  );

  const addictionFields = (
    <AddictionFields
      formInvalid={props.formInvalid}
      handleChange={props.handleChange}
      mode={props.mode}
      selectedProgrammeData={props.selectedProgrammeData}
      validationResults={props.validationResults}
    />
  );

  const dependantsFields = (
    <DependantsFields
      formInvalid={props.formInvalid}
      handleChange={props.handleChange}
      mode={props.mode}
      selectedProgrammeData={props.selectedProgrammeData}
      validationResults={props.validationResults}
    />
  );

  const generalHealthFields = (
    <GeneralHealthFields
      formInvalid={props.formInvalid}
      handleChange={props.handleChange}
      mode={props.mode}
      selectedProgrammeData={props.selectedProgrammeData}
      validationResults={props.validationResults}
    />
  );

  const exitFields = (
    <ExitFields
      formInvalid={props.formInvalid}
      handleChange={props.handleChange}
      mode={props.mode}
      selectedProgrammeData={props.selectedProgrammeData}
      validationResults={props.validationResults}
    />
  );

  const hobbiesFields = (
    <HobbiesFields
      formInvalid={props.formInvalid}
      handleChange={props.handleChange}
      mode={props.mode}
      selectedProgrammeData={props.selectedProgrammeData}
      validationResults={props.validationResults}
    />
  );

  const householdStatusFields = (
    <HouseholdStatusFields
      formInvalid={props.formInvalid}
      handleChange={props.handleChange}
      mode={props.mode}
      selectedProgrammeData={props.selectedProgrammeData}
      validationResults={props.validationResults}
    />
  );

  const keyDatesAndMilestonesFields = (
    <KeyDatesAndMilestonesFields
      area={props.area}
      formInvalid={props.formInvalid}
      handleChange={props.handleChange}
      mode={props.mode}
      selectedProgrammeData={props.selectedProgrammeData}
      validationResults={props.validationResults}
      projectLeadRole={permissions.programmes.updateProjectLeadOnly}
      roleId={props.roleId}
      initialProgrammeData={props.initialProgrammeData}
    />
  );

  const employmentAndBenefitsFields = (
    <EmploymentAndBenefitsFields
      formInvalid={props.formInvalid}
      handleChange={props.handleChange}
      mode={props.mode}
      selectedProgrammeData={props.selectedProgrammeData}
      validationResults={props.validationResults}
    />
  );

  const liberationFields = (
    <LiberationFields
      formInvalid={props.formInvalid}
      handleChange={props.handleChange}
      mode={props.mode}
      selectedProgrammeData={props.selectedProgrammeData}
      validationResults={props.validationResults}
    />
  );

  const mentalHealthFields = (
    <MentalHealthFields
      formInvalid={props.formInvalid}
      handleChange={props.handleChange}
      mode={props.mode}
      selectedProgrammeData={props.selectedProgrammeData}
      validationResults={props.validationResults}
    />
  );

  const notesFields = (
    <NotesFields
      formInvalid={props.formInvalid}
      handleChange={props.handleChange}
      mode={props.mode}
      selectedProgrammeData={props.selectedProgrammeData}
      validationResults={props.validationResults}
      headers={props.headers}
      shouldSaveFiles={props.shouldSaveFiles}
      filesHaveChanged={props.filesHaveChanged}
    />
  );

  const prisonSupportServiceFields = (
    <PrisonSupportServiceFields
      formInvalid={props.formInvalid}
      handleChange={props.handleChange}
      mode={props.mode}
      selectedProgrammeData={props.selectedProgrammeData}
      validationResults={props.validationResults}
    />
  );

  const resetFormSelection = (
    <Button
      className="formButton"
      primary
      inverted
      onClick={props.resetForm}
      disabled={props.mode === "edit"}
    >
      {strings.button.resetLabel}
    </Button>
  );

  const formSelection = (
    <Grid columns={2}>
      <Grid.Column>
        <ChooseForm chooseForm={props.chooseForm} />
      </Grid.Column>
      <Grid.Column>
        <ChooseEngagement
          formInvalid={props.formInvalid}
          handleChange={props.handleChange}
          mode={props.mode}
          selectedProgrammeData={props.selectedProgrammeData}
          validationResults={props.validationResults}
          area={props.area}
        />
      </Grid.Column>
    </Grid>
  );

  let formFields;

  if (props.hasChoseForm) {
    formFields = (
      <>
        {(props.hasChoseForm || props.selectedProgrammeData.engagementType) &&
          resetFormSelection}
        <fieldset disabled={props.mode === "view"}>
          {administrationFields}
          {mentorFields}
          {generalFields}
          {keyDatesAndMilestonesFields}
          {liberationFields}
          {householdStatusFields}
          {dependantsFields}
          {generalHealthFields}
          {mentalHealthFields}
          {addictionFields}
          {employmentAndBenefitsFields}
          {prisonSupportServiceFields}
          {hobbiesFields}
          {notesFields}
          {exitFields}
        </fieldset>
      </>
    );
  } else if (props.selectedProgrammeData.engagementType) {
    formFields = (
      <>
        {(props.hasChoseForm || props.selectedProgrammeData.engagementType) &&
          resetFormSelection}
        <fieldset>
          <div className="embeddedComponent">
            <EngagementListContainer
              headers={props.headers}
              history={props.history}
              engagementType={props.selectedProgrammeData.engagementType}
              programmeId={props.selectedProgrammeData.programmeId}
              listHeader={props.selectedProgrammeData.engagementType}
              programmeCrmId={props.selectedProgrammeData.crmId}
              initialProgrammeData={props.initialProgrammeData}
              hideSearchBar={true}
              programmeNameId={`${props.selectedProgrammeData.firstName} ${props.selectedProgrammeData.surname}`}
            />
          </div>
        </fieldset>
      </>
    );
  } else {
    formFields = formSelection;
  }
  return formFields;
};
FieldList.propTypes = {
  mode: PropTypes.string.isRequired,
  selectedProgrammeData: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  formInvalid: PropTypes.bool.isRequired,
  validationResults: PropTypes.object
};

export default FieldList;
