import React from "react";
import PropTypes from "prop-types";
import { Form } from "semantic-ui-react";
import { strings } from "../../resources";
import { convertFromIso } from "../../helpers/index";

import { ValidatedDateInput, ValidatedFormDropdown } from "../validation";
import "../css/ProgrammeComponents.scss";
import { yesNoOptions, journeyStatusOptions } from "../../resources";

const KeyDatesAndMilestonesFields = props => (
  <div className="infoBlock">
    <div className="infoHeader">
      {strings.form.header.keyDatesAndMilestonesHeader}
    </div>
    <div className="infoWrapper">
      <Form.Group>
        <ValidatedDateInput
          inputId="hdcQualificationDate"
          formInvalid={props.formInvalid}
          inputLabel={strings.form.label.hdcQualificationDate}
          inputName="hdcQualificationDate"
          inputPlaceholder={strings.form.placeholder.hdcQualificationDate}
          inputDateFormat="DD/MM/YYYY"
          inputData={
            props.selectedProgrammeData.hdcQualificationDate
              ? convertFromIso(props.selectedProgrammeData.hdcQualificationDate)
              : ""
          }
          iconPosition="left"
          handleChange={props.handleChange}
          validationResult={props.validationResults.hdcQualificationDate}
          formFieldWidth={8}
          required={false}
          mode={props.mode}
        />
        <ValidatedFormDropdown
          formInvalid={props.formInvalid}
          inputData={props.selectedProgrammeData.journeyStatus}
          viewData={props.selectedProgrammeData.journeyStatus || ""}
          dropdownLabel={strings.form.label.journeyStatus}
          dropdownName="journeyStatus"
          dropdownOptions={journeyStatusOptions}
          dropdownPlaceholder={strings.form.placeholder.journeyStatus}
          formFieldWidth={8}
          handleChange={props.handleChange}
          validationResult={props.validationResults.journeyStatus}
          mode={props.mode}
        />
      </Form.Group>
      <Form.Group>
        <ValidatedDateInput
          inputId="overallEDL"
          formInvalid={props.formInvalid}
          inputLabel={strings.form.label.overallEDL}
          inputName="overallEDL"
          inputPlaceholder={strings.form.placeholder.overallEDL}
          inputDateFormat="DD/MM/YYYY"
          inputData={
            props.selectedProgrammeData.overallEDL
              ? convertFromIso(props.selectedProgrammeData.overallEDL)
              : ""
          }
          iconPosition="left"
          handleChange={props.handleChange}
          validationResult={props.validationResults.overallEDL}
          formFieldWidth={8}
          disabled={
            props.projectLeadRole.indexOf(props.roleId) === -1 &&
            props.initialProgrammeData.overallEDL &&
            props.mode === "edit"
          }
          mode={props.mode}
          required={props.area === strings.areas.NRS}
        />
        <ValidatedDateInput
          inputId="anticipatedEndDate"
          formInvalid={props.formInvalid}
          inputLabel={strings.form.label.anticipatedEndDate}
          inputName="anticipatedEndDate"
          inputPlaceholder={strings.form.placeholder.anticipatedEndDate}
          inputDateFormat="DD/MM/YYYY"
          inputData={
            props.selectedProgrammeData.anticipatedEndDate
              ? convertFromIso(props.selectedProgrammeData.anticipatedEndDate)
              : ""
          }
          iconPosition="left"
          handleChange={props.handleChange}
          validationResult={props.validationResults.anticipatedEndDate}
          formFieldWidth={8}
          mode={props.mode}
        />
      </Form.Group>
      <Form.Group>
        <ValidatedDateInput
          inputId="dateOfReferral"
          formInvalid={props.formInvalid}
          inputLabel={strings.form.label.dateOfReferral}
          inputName="dateOfReferral"
          inputPlaceholder={strings.form.placeholder.dateOfReferral}
          inputDateFormat="DD/MM/YYYY"
          inputData={
            props.selectedProgrammeData.dateOfReferral
              ? convertFromIso(props.selectedProgrammeData.dateOfReferral)
              : ""
          }
          iconPosition="left"
          handleChange={props.handleChange}
          validationResult={props.validationResults.dateOfReferral}
          formFieldWidth={8}
          mode={props.mode}
        />
        <ValidatedFormDropdown
          formInvalid={props.formInvalid}
          inputData={props.selectedProgrammeData.firstCommunityMeetingAttended}
          viewData={props.selectedProgrammeData.firstCommunityMeetingAttended}
          dropdownLabel={strings.form.label.firstCommunityMeetingAttended}
          dropdownName="firstCommunityMeetingAttended"
          dropdownOptions={yesNoOptions}
          dropdownPlaceholder={
            strings.form.placeholder.firstCommunityMeetingAttended
          }
          formFieldWidth={8}
          handleChange={props.handleChange}
          validationResult={
            props.validationResults.firstCommunityMeetingAttended
          }
          required={false}
          mode={props.mode}
        />
      </Form.Group>
      <Form.Group>
        <ValidatedDateInput
          inputId="signupMeetingDate"
          formInvalid={props.formInvalid}
          inputLabel={strings.form.label.signupMeetingDate}
          inputName="signupMeetingDate"
          inputPlaceholder={strings.form.placeholder.signupMeetingDate}
          inputDateFormat="DD/MM/YYYY"
          inputData={
            props.selectedProgrammeData.signupMeetingDate
              ? convertFromIso(props.selectedProgrammeData.signupMeetingDate)
              : ""
          }
          iconPosition="left"
          handleChange={props.handleChange}
          validationResult={props.validationResults.signupMeetingDate}
          formFieldWidth={8}
          mode={props.mode}
        />
        <ValidatedDateInput
          inputId="firstCommunityMeetingDate"
          formInvalid={props.formInvalid}
          inputLabel={strings.form.label.firstCommunityMeetingDate}
          inputName="firstCommunityMeetingDate"
          inputPlaceholder={strings.form.placeholder.firstCommunityMeetingDate}
          inputDateFormat="DD/MM/YYYY"
          inputData={
            props.selectedProgrammeData.firstCommunityMeetingDate
              ? convertFromIso(
                  props.selectedProgrammeData.firstCommunityMeetingDate
                )
              : ""
          }
          iconPosition="left"
          handleChange={props.handleChange}
          validationResult={props.validationResults.firstCommunityMeetingDate}
          formFieldWidth={8}
          required={false}
          mode={props.mode}
        />
      </Form.Group>
      <Form.Group>
        <ValidatedDateInput
          inputId="revisedEDL"
          formInvalid={props.formInvalid}
          inputLabel={strings.form.label.revisedEDL}
          inputName="revisedEDL"
          inputPlaceholder={strings.form.placeholder.revisedEDL}
          inputDateFormat="DD/MM/YYYY"
          inputData={
            props.selectedProgrammeData.revisedEDL
              ? convertFromIso(props.selectedProgrammeData.revisedEDL)
              : ""
          }
          iconPosition="left"
          handleChange={props.handleChange}
          validationResult={props.validationResults.revisedEDL}
          formFieldWidth={8}
          required={false}
          mode={props.mode}
        />
      </Form.Group>
    </div>
  </div>
);
KeyDatesAndMilestonesFields.propTypes = {
  formInvalid: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  mode: PropTypes.string.isRequired,
  selectedProgrammeData: PropTypes.object.isRequired,
  validationResults: PropTypes.object
};

export default KeyDatesAndMilestonesFields;
