import { programmeActionTypes } from "../actions/programme.actionTypes";

const initialState = {
  loadingPage: false,
  selectedProgrammeData: {},
  programmeListData: [{}],
  totalResults: 0,
  programmeToDelete: "",
  result: "",
  error: "",
  errorDetails: {},
  programmeRequestStatus: undefined,
  programmeRequestMade: false,
  deleteSuccessful: false,
  showNotification: true,
  outcomeListData: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case programmeActionTypes.CREATE_PROGRAMME_REQUEST:
      return {
        ...state,
        programmeRequestStatus: undefined,
        programmeRequestMade: true
      };
    case programmeActionTypes.CREATE_PROGRAMME_SUCCESS:
      return {
        ...state,
        result: action.response.data.Reason,
        programmeRequestStatus: true
      };
    case programmeActionTypes.CREATE_PROGRAMME_FAILURE:
      let details = [];
      if (action.error.response.data.Reason) {
        if (typeof action.error.response.data.Reason === "object") {
          let newErrorDetails = action.error.response.data.Reason;
          Object.keys(newErrorDetails).forEach(key => {
            let value = newErrorDetails[key];
            details = [...details, ...value];
          });
        } else {
          details = action.error.response.data.Reason;
        }
      }
      return {
        ...state,
        error: action.error.response.data.Reason,
        errorDetails: details,
        programmeRequestStatus: false
      };

    case programmeActionTypes.CLEAR_CREATE_PROGRAMME_REQUEST:
      return { ...state };
    case programmeActionTypes.CLEAR_CREATE_PROGRAMME_SUCCESS:
      return {
        ...state,
        programmeRequestMade: false,
        programmeRequestStatus: undefined
      };
    case programmeActionTypes.CLEAR_CREATE_PROGRAMME_FAILURE:
      return { ...state };

    case programmeActionTypes.UPDATE_PROGRAMME_REQUEST:
      return {
        ...state,
        loadingPage: true,
        programmeRequestStatus: undefined,
        programmeRequestMade: true
      };
    case programmeActionTypes.UPDATE_PROGRAMME_SUCCESS:
      return {
        ...state,
        result: action.response.data.Reason,
        loadingPage: false,
        programmeRequestStatus: true,
        selectedProgrammeData: action.response.data.Data,
        showNotification: action.showNotification
      };
    case programmeActionTypes.UPDATE_PROGRAMME_FAILURE:
      details = [];
      if (action.error.response.data.Reason) {
        if (typeof action.error.response.data.Reason === "object") {
          let newErrorDetails = action.error.response.data.Reason;
          Object.keys(newErrorDetails).forEach(key => {
            let value = newErrorDetails[key];
            details = [...details, ...value];
          });
        } else {
          details = action.error.response.data.Reason;
        }
      }
      return {
        ...state,
        error: action.error.response.data.Reason,
        loadingPage: false,
        programmeRequestStatus: false,
        selectedProgrammeData: action.initialDetails,
        errorDetails: details
      };

    case programmeActionTypes.DELETE_PROGRAMME_REQUEST:
      return {
        ...state,
        programmeRequestStatus: undefined,
        programmeRequestMade: true,
        loadingPage: true,
        deleteSuccessful: false
      };
    case programmeActionTypes.DELETE_PROGRAMME_SUCCESS:
      return {
        ...state,
        result: action.response.data.Reason,
        programmeRequestStatus: true,
        loadingPage: false,
        deleteSuccessful: true
      };
    case programmeActionTypes.DELETE_PROGRAMME_FAILURE:
      return {
        ...state,
        error: action.error.response.data.Reason,
        programmeRequestStatus: false,
        loadingPage: false,
        deleteSuccessful: false
      };

    case programmeActionTypes.GET_PROGRAMMES_REQUEST:
      return {
        ...state,
        loadingPage: true,
        deleteSuccessful: false
      };
    case programmeActionTypes.GET_PROGRAMMES_SUCCESS:
      let rawHits = [];
      let newTotal = 0;
      if (action.response.data.Data.hits) {
        newTotal = action.response.data.Data.hits.total;
        rawHits = action.response.data.Data.hits.hits.slice();
        rawHits.forEach((result, index) => {
          let newProgramme = Object.assign({}, result["_source"]);
          rawHits[index]["_source"] = newProgramme;
        });
      } else {
        rawHits = [];
      }
      return {
        ...state,
        programmeListData: rawHits,
        totalResults: newTotal,
        loadingPage: false
      };
    case programmeActionTypes.GET_PROGRAMMES_FAILURE:
      return {
        ...state,
        programmeListData: [{}],
        loadingPage: false
      };

    case programmeActionTypes.GET_PROGRAMME_BY_ID_REQUEST:
      return {
        ...state,
        loadingPage: true,
        programmeRequestStatus: undefined
      };
    case programmeActionTypes.GET_PROGRAMME_BY_ID_SUCCESS:
      return {
        ...state,
        result: action.response.Reason,
        selectedProgrammeData: action.response.Body.Item,
        loadingPage: false,
        programmeRequestStatus: true
      };
    case programmeActionTypes.GET_PROGRAMME_BY_ID_FAILURE:
      return {
        ...state,
        error: action.error.response.data.Reason,
        loadingPage: false,
        programmeRequestStatus: false,
        selectedProgrammeData: {},
        programmeRequestMade: true
      };

    case programmeActionTypes.UPDATE_SELECTED_PROGRAMME_REQUEST:
      return { ...state };
    case programmeActionTypes.UPDATE_SELECTED_PROGRAMME_SUCCESS:
      return {
        ...state,
        selectedProgrammeData: {
          ...state.selectedProgrammeData,
          [action.key]: action.value
        }
      };
    case programmeActionTypes.UPDATE_SELECTED_PROGRAMME_FAILURE:
      return { ...state };

    case programmeActionTypes.CLEAR_SELECTED_PROGRAMME_REQUEST:
      return { ...state };
    case programmeActionTypes.CLEAR_SELECTED_PROGRAMME_SUCCESS:
      return { ...state, selectedProgrammeData: {} };
    case programmeActionTypes.CLEAR_SELECTED_PROGRAMME_FAILURE:
      return { ...state };

    case programmeActionTypes.CANCEL_SELECTED_PROGRAMME_UPDATE_REQUEST:
      return { ...state };
    case programmeActionTypes.CANCEL_SELECTED_PROGRAMME_UPDATE_SUCCESS:
      return { ...state, selectedProgrammeData: action.data };
    case programmeActionTypes.CANCEL_SELECTED_PROGRAMME_UPDATE_FAILURE:
      return { ...state };

    case programmeActionTypes.GET_PROGRAMME_OUTCOMES_REQUEST:
      return {
        ...state,
        loadingPage: true,
        programmeRequestStatus: undefined
      };
    case programmeActionTypes.GET_PROGRAMME_OUTCOMES_SUCCESS:
      const outcomesData = action.response.Body;
      let outcomeslist = [];
      if (outcomesData) {
        Object.keys(outcomesData).forEach(key => {
          let currentOutcomeData = outcomesData[key];
          outcomeslist.push({ outcomeName: key, ...currentOutcomeData });
        });
      }
      return {
        ...state,
        result: action.response.Reason,
        outcomeListData: outcomeslist,
        loadingPage: false,
        programmeRequestStatus: true
      };
    case programmeActionTypes.GET_PROGRAMME_OUTCOMES_FAILURE:
      return {
        ...state,
        error: action.error.response.data.Reason,
        loadingPage: false,
        programmeRequestStatus: false,
        outcomeListData: [],
        programmeRequestMade: true
      };

    default:
      return state;
  }
};
