import React from "react";
import PropTypes from "prop-types";
import { Button, Form, Checkbox, Divider, Grid } from "semantic-ui-react";
import {
  strings,
  reportTypeOptions,
  partnershipOptions,
  partnerOptions,
  ageCategoryOptions,
  reportSubTypeOptions,
  areaOptions,
  uploadSizeOptions
} from "../resources";
import { ComponentHeader, BasicModal } from ".";
import "./css/ReportComponents.scss";
import { withRouter } from "react-router-dom";
import { permissions } from "../resources";
import {
  ValidatedFormInput,
  ValidatedFormTextArea,
  ValidatedFormDropdown,
  ValidatedDateInput
} from "../components/validation";
import moment from "moment";
import { reportHelpers, dropdownHelper } from "../helpers";
import { AccordionComponent } from "../components";
import { ReferralListContainer } from "../containers";

const segmentationFields = {
  partnership: "partnership",
  partner: "partner",
  specialist: "specialist",
  ageCategory: "ageCategory",
  fromDate: "fromDate",
  toDate: "toDate",
  area: "area"
};

class ModifyReport extends React.Component {
  renderModifyButtons = () => {
    const {
      handleModeSwitch,
      searchCriteria,
      savedReportId,
      reportReferrals
    } = this.props;
    const isTemplateReport =
      searchCriteria.query.reportViewType === strings.text.templateReportType;

    return (
      <>
        {isTemplateReport &&
          (!savedReportId ? (
            <div className="containerButtons">
              <Button.Group>
                <Button
                  className="editButton"
                  primary
                  onClick={() => {
                    handleModeSwitch("edit");
                  }}
                >
                  {strings.button.saveReportLabel}
                </Button>
              </Button.Group>
            </div>
          ) : (
            reportReferrals &&
            reportReferrals.length > 0 && (
              <span className="referralsText">
                {strings.text.uploadReferrals}
              </span>
            )
          ))}
      </>
    );
  };

  renderCancelSaveButtons = () => {
    const {
      handleModeSwitch,
      selectedReportData,
      handleModalConfirm,
      initialReportData,
      confirmSaveModalOpen,
      toggleConfirmSaveModalVisibility,
      toggleCancelChangesModalVisibility,
      mode,
      cancelChangesModalOpen
    } = this.props;

    return (
      <div className="containerButtons">
        <Button.Group>
          <Button
            className="saveButton"
            primary
            inverted
            onClick={this.props.validateForm}
            disabled={
              JSON.stringify(selectedReportData) ===
              JSON.stringify(initialReportData)
            }
          >
            {strings.button.saveLabel}
          </Button>
          <BasicModal
            confirmMessage={strings.modal.saveChangesMessage}
            handleModalConfirm={handleModalConfirm}
            modalClassName="confirmSaveModal"
            modalOpen={confirmSaveModalOpen}
            showNoButton={true}
            showOkButton={false}
            showYesButton={true}
            toggleModal={toggleConfirmSaveModalVisibility}
            noLabel={strings.button.noLabel}
            yesLabel={strings.button.yesLabel}
            okLabel={strings.button.okLabel}
          />
        </Button.Group>
        <Button.Group>
          <Button
            className="cancelButton"
            primary
            onClick={toggleCancelChangesModalVisibility}
            type="button"
          >
            {strings.button.cancelLabel}
          </Button>
          <BasicModal
            confirmMessage={strings.modal.cancelChangesMessage}
            handleModalConfirm={
              mode === "create" ? handleModalConfirm : handleModeSwitch
            }
            modalClassName="cancelChangesModal"
            modalOpen={cancelChangesModalOpen}
            showOkButton={false}
            showYesButton={true}
            showNoButton={true}
            toggleModal={toggleCancelChangesModalVisibility}
            noLabel={strings.button.noLabel}
            yesLabel={strings.button.yesLabel}
            okLabel={strings.button.okLabel}
          />
        </Button.Group>
      </div>
    );
  };

  renderReportOptions = () => {
    const {
      searchCriteria,
      mode,
      handleReportOptionsChange,
      areaIsBoth
    } = this.props;
    const reportTypeIsSelected = Object.keys(searchCriteria.query).includes(
      "reportViewType"
    );
    const isTemplateReport =
      searchCriteria.query.reportViewType === strings.text.templateReportType;
    const isConversion =
      searchCriteria.query.type === strings.text.etsReportType ||
      searchCriteria.query.type === strings.text.stpReportType;

    const reportOptions = reportHelpers.filterReports(
      searchCriteria.query,
      this.props.reportOptions,
      isConversion
    );

    return (
      <Form className="infoWrapper">
        <>
          <Form.Group>
            <ValidatedFormDropdown
              inputData={searchCriteria.query.reportViewType}
              viewData={dropdownHelper.getNameFromValues(
                searchCriteria.query.reportViewType,
                reportTypeOptions
              )}
              dropdownLabel={strings.label.reportViewType}
              dropdownPlaceholder={strings.placeholder.reportViewType}
              dropdownName="reportViewType"
              dropdownOptions={reportTypeOptions}
              formFieldWidth={8}
              handleChange={handleReportOptionsChange}
              hideMessage
              required={false}
              dropdownDisabled={mode === "edit"}
            />
          </Form.Group>
          <Form.Group>
            {areaIsBoth && (
              <ValidatedFormDropdown
                inputData={searchCriteria.query.area}
                viewData={dropdownHelper.getNameFromValues(
                  searchCriteria.query.area,
                  areaOptions
                )}
                dropdownLabel={strings.label.area}
                dropdownPlaceholder={strings.placeholder.area}
                dropdownName="area"
                dropdownOptions={areaOptions}
                formFieldWidth={8}
                handleChange={handleReportOptionsChange}
                required={false}
                dropdownDisabled={mode === "edit"}
              />
            )}
          </Form.Group>
          <Form.Group>
            <>
              {isTemplateReport && (
                <ValidatedFormDropdown
                  inputData={searchCriteria.query.type}
                  viewData={dropdownHelper.getNameFromValues(
                    searchCriteria.query.type,
                    reportSubTypeOptions
                  )}
                  dropdownLabel={strings.label.type}
                  dropdownPlaceholder={strings.placeholder.type}
                  dropdownName="type"
                  dropdownOptions={reportSubTypeOptions}
                  formFieldWidth={8}
                  handleChange={handleReportOptionsChange}
                  required={false}
                  dropdownDisabled={mode === "edit"}
                />
              )}
              {!isConversion && (
                <ValidatedFormDropdown
                  inputData={searchCriteria.query.reportId}
                  viewData={dropdownHelper.getNameFromValues(
                    searchCriteria.query.reportId,
                    reportOptions
                  )}
                  dropdownLabel={strings.label.reportId}
                  dropdownPlaceholder={strings.placeholder.reportId}
                  dropdownName="reportId"
                  dropdownOptions={reportOptions}
                  formFieldWidth={8}
                  handleChange={handleReportOptionsChange}
                  required={false}
                  dropdownDisabled={!reportTypeIsSelected || mode === "edit"}
                  dropdownSearch
                />
              )}
            </>
          </Form.Group>
          {searchCriteria.query.reportId && (
            <>
              {searchCriteria.query.stage && (
                <Form.Group>
                  <ValidatedFormInput
                    inputData={searchCriteria.query.stage}
                    inputLabel={strings.label.stage}
                    inputPlaceholder={strings.placeholder.stage}
                    inputName="stage"
                    formFieldWidth={8}
                    handleChange={handleReportOptionsChange}
                    required={false}
                    disabled
                  />
                </Form.Group>
              )}
              {searchCriteria.query.measure && (
                <Form.Group>
                  <ValidatedFormInput
                    inputData={searchCriteria.query.measure}
                    inputLabel={strings.label.measure}
                    inputPlaceholder={strings.placeholder.measure}
                    inputName="measure"
                    formFieldWidth={16}
                    handleChange={handleReportOptionsChange}
                    required={false}
                    disabled
                  />
                </Form.Group>
              )}
            </>
          )}
        </>
      </Form>
    );
  };

  renderField = (type, dropdownOptions, key) => {
    const {
      searchCriteria,
      mode,
      handleReportOptionsChange,
      selectedReportData
    } = this.props;
    const isTemplateReport = type === strings.text.templateReportType;
    const isSavedReport = type === strings.text.resultReportType;
    let isDisabled = mode === "edit";
    let isMultiple = false;
    let isUUID = false;

    if (mode !== "edit") {
      switch (key) {
        case segmentationFields.partner:
          isDisabled =
            searchCriteria.query.partnership &&
            searchCriteria.query.partnership === strings.text.yes;
          isMultiple = true;
          isUUID = true;
          break;
        case segmentationFields.partnership:
          isDisabled =
            searchCriteria.query.partner &&
            searchCriteria.query.partner.length > 0;
          break;
        case segmentationFields.fromDate:
          if (
            searchCriteria.query.yearlyReport ||
            searchCriteria.query.monthlyReport
          ) {
            isDisabled = true;
          }
          break;
        case segmentationFields.specialist:
          isUUID = true;
          isMultiple = false;
          break;
        default:
          break;
      }
    } else if (mode === "edit" && key === segmentationFields.partner) {
      isMultiple = true;
      isUUID = true;
    }

    return (
      <>
        {isTemplateReport && dropdownOptions && (
          <ValidatedFormDropdown
            inputData={reportHelpers.handleData({
              mode,
              data: searchCriteria.query[key],
              dropdownOptions,
              isMultiple
            })}
            viewData={reportHelpers.displayViewData({
              data: searchCriteria.query[key],
              dropdownOptions,
              isMultiple,
              isUUID
            })}
            dropdownLabel={strings.label[key]}
            dropdownPlaceholder={strings.placeholder[key]}
            dropdownName={key}
            dropdownOptions={dropdownOptions}
            formFieldWidth={8}
            handleChange={handleReportOptionsChange}
            required={false}
            dropdownDisabled={isDisabled}
            dropdownMultiple={isMultiple}
          />
        )}
        {isTemplateReport && !dropdownOptions && (
          <ValidatedDateInput
            inputId={key}
            inputLabel={strings.label[key]}
            inputName={key}
            inputPlaceholder={strings.placeholder[key]}
            inputDateFormat="DD/MM/YYYY"
            maxDate={new Date()}
            inputData={reportHelpers.handleData({
              mode,
              data: searchCriteria.query[key] ? searchCriteria.query[key] : "",
              dropdownOptions
            })}
            iconPosition="left"
            formFieldWidth={8}
            handleChange={handleReportOptionsChange}
            hideMessage
            required={false}
            disabled={isDisabled}
          />
        )}
        {isSavedReport && selectedReportData[key] && (
          <ValidatedFormInput
            inputData={reportHelpers.handleData({
              mode,
              data: selectedReportData[key],
              isUUID,
              dropdownOptions
            })}
            inputLabel={strings.label[key]}
            inputPlaceholder={strings.placeholder[key]}
            inputName={key}
            formFieldWidth={8}
            handleChange={handleReportOptionsChange}
            required={false}
            disabled={isDisabled}
            mode={mode}
          />
        )}
      </>
    );
  };

  renderSegmentationFields = type => {
    const { specialistOptions } = this.props;

    return (
      <Form className="infoWrapper" as="div">
        {type === strings.text.resultReportType && (
          <Form.Group>
            {this.renderField(type, areaOptions, segmentationFields.area)}
          </Form.Group>
        )}
        <Form.Group>
          {this.renderField(
            type,
            partnershipOptions,
            segmentationFields.partnership
          )}
          {this.renderField(
            type,
            partnerOptions,
            segmentationFields.partner,
            "multiple"
          )}
        </Form.Group>
        <Form.Group>
          {this.renderField(
            type,
            specialistOptions,
            segmentationFields.specialist
          )}
          {this.renderField(
            type,
            ageCategoryOptions,
            segmentationFields.ageCategory
          )}
        </Form.Group>
        <Form.Group>
          {this.renderField(type, undefined, segmentationFields.fromDate)}
          {this.renderField(type, undefined, segmentationFields.toDate)}
        </Form.Group>
      </Form>
    );
  };

  renderSegmentations = () => {
    const { searchCriteria, handleReportOptionsChange } = this.props;
    const type = searchCriteria.query.reportViewType;

    return (
      <div className="details info">
        <fieldset>
          {this.renderSegmentationFields(type)}
          <Form.Group>
            <Checkbox
              label={strings.label.monthlyReport}
              checked={searchCriteria.query.monthlyReport || false}
              onChange={handleReportOptionsChange}
              name="monthlyReport"
              disabled={searchCriteria.query.yearlyReport}
            />
            <Checkbox
              label={strings.label.yearlyReport}
              checked={searchCriteria.query.yearlyReport || false}
              onChange={handleReportOptionsChange}
              name="yearlyReport"
              disabled={searchCriteria.query.monthlyReport}
            />
          </Form.Group>
        </fieldset>
      </div>
    );
  };

  renderSearchButtons = () => {
    const { handleFetchData, searchCriteria, handleClearSearch } = this.props;
    const reportIsSelected = searchCriteria.query.reportId;

    return (
      <>
        {reportIsSelected && (
          <Button
            primary
            inverted
            className="runButton"
            onClick={() => {
              handleFetchData();
            }}
          >
            {strings.button.runLabel}
          </Button>
        )}
        {Object.keys(searchCriteria.query).length > 1 && (
          <Button
            primary
            inverted
            className="runButton"
            onClick={() => {
              handleClearSearch();
            }}
          >
            {strings.button.clearLabel}
          </Button>
        )}
      </>
    );
  };

  renderAccordion = () => {
    const { searchCriteria, reportReferrals } = this.props;
    const isTemplateReport =
      searchCriteria.query.reportViewType === strings.text.templateReportType;

    const accordionSections = [
      {
        title: strings.header.reportOptions,
        content: this.renderReportOptions()
      }
    ];
    if (isTemplateReport) {
      accordionSections.push({
        title: strings.header.segmentation,
        content: this.renderSegmentations()
      });
    }
    if (
      reportReferrals &&
      reportReferrals.length > 0 &&
      permissions.referrals.read.includes(this.props.role)
    ) {
      accordionSections.push({
        title: strings.header.referralsList,
        content: this.renderReferralTable()
      });
    }

    return (
      <AccordionComponent
        accordionSections={accordionSections}
        defaultOpenAccordion={0}
      />
    );
  };

  renderReportInfoSection = () => {
    const {
      selectedReportData,
      mode,
      handleChange,
      totalReportReferrals,
      searchCriteria
    } = this.props;
    const isResultReport =
      searchCriteria.query.reportViewType === strings.text.resultReportType;

    return (
      <div className={mode === "edit" ? "infoBlock" : ""}>
        <div className="infoHeader">{strings.form.header.reportDetails}</div>
        <div className="infoWrapper">
          <ValidatedFormInput
            inputData={selectedReportData.result}
            inputLabel={strings.form.label.result}
            inputName="result"
            inputPlaceholder={strings.form.placeholder.result}
            formFieldWidth={8}
            disabled={mode === "edit"}
            required={false}
            hideMessage
            handleChange={handleChange}
            mode={mode}
          />
        </div>
        {isResultReport && (
          <div className="infoWrapper">
            <Form.Group>
              <ValidatedFormInput
                inputData={
                  selectedReportData.hasSavedReferrals
                    ? strings.text.yes
                    : strings.text.no
                }
                inputLabel={strings.form.label.hasReferralsSaved}
                inputName="hasReferralsSaved"
                inputPlaceholder={strings.form.placeholder.hasReferralsSaved}
                formFieldWidth={8}
                disabled
                required={false}
                hideMessage
                handleChange={handleChange}
                mode={mode}
              />
              {selectedReportData.hasSavedReferrals && (
                <ValidatedFormInput
                  inputData={totalReportReferrals.toString()}
                  inputLabel={strings.form.label.totalReportReferrals}
                  inputName="totalReportReferrals"
                  inputPlaceholder={
                    strings.form.placeholder.totalReportReferrals
                  }
                  formFieldWidth={8}
                  disabled
                  required={false}
                  hideMessage
                  handleChange={handleChange}
                  mode={mode}
                />
              )}
            </Form.Group>
          </div>
        )}
      </div>
    );
  };

  renderModifyReportSection = isSavedReport => {
    const {
      selectedReportData,
      formInvalid,
      handleChange,
      validationResults,
      mode,
      searchCriteria
    } = this.props;
    const type = searchCriteria.query.reportViewType;
    const renderSegmentations = Object.keys(segmentationFields).some(field =>
      Object.keys(selectedReportData).includes(field)
    );

    return (
      <div className={mode === "edit" ? "infoBlock" : ""}>
        {mode === "edit" && (
          <div className="infoHeader">{strings.form.header.saveReport}</div>
        )}
        <div className="infoWrapper">
          <Form.Group>
            <ValidatedFormInput
              formInvalid={formInvalid}
              inputData={selectedReportData.name}
              inputLabel={strings.form.label.name}
              inputName="name"
              inputPlaceholder={strings.form.placeholder.name}
              formFieldWidth={8}
              required={false}
              handleChange={handleChange}
              validationResult={validationResults.name}
              mode={mode}
            />
            <ValidatedFormTextArea
              formInvalid={formInvalid}
              inputData={selectedReportData.description}
              inputLabel={strings.form.label.description}
              inputName="description"
              inputPlaceholder={strings.form.placeholder.description}
              formFieldWidth={8}
              handleChange={handleChange}
              required={false}
              validationResult={validationResults.description}
              mode={mode}
            />
          </Form.Group>
        </div>
        {isSavedReport && (
          <>
            {renderSegmentations && (
              <div className="infoHeader">{strings.header.segmentation}</div>
            )}
            <div className="info">
              {this.renderSegmentationFields(type)}
              <Form.Group>
                <div className="fieldPusher"></div>
                <div className="reportDateWrapper">
                  <div className="reportDate">
                    <span className="reportDateText">
                      {`${strings.form.text.dateSaved}: `}
                    </span>
                    {selectedReportData.dateSaved
                      ? moment(selectedReportData.dateSaved)
                          .local()
                          .format("DD/MM/YYYY, HH:mm")
                      : ""}
                  </div>
                </div>
              </Form.Group>
            </div>
          </>
        )}
      </div>
    );
  };

  renderForm = () => {
    const { selectedReportData, mode } = this.props;
    const isSavedReport =
      selectedReportData.reportType &&
      selectedReportData.reportType === strings.text.resultReportType;

    return (
      <div className={mode === "view" ? "infoBlock" : ""}>
        {this.renderReportInfoSection()}
        {(mode === "edit" || isSavedReport) &&
          this.renderModifyReportSection(isSavedReport)}
      </div>
    );
  };

  renderReferralTable = () => {
    const {
      headers,
      history,
      generateReportCriteria,
      savedReportId,
      handleModalConfirm,
      confirmSaveModalOpen,
      toggleConfirmSaveModalVisibility,
      uploadSize,
      handleStateChange
    } = this.props;

    const { searchCriteria } = this.props;
    const isTemplateReport =
      searchCriteria.query.reportViewType === strings.text.templateReportType;

    const modalText = [
      `From: page ${Math.ceil(
        generateReportCriteria.from / generateReportCriteria.size
      ) + 1}`,
      `Number of pages: ${Math.ceil(uploadSize / generateReportCriteria.size)}`
    ];

    return (
      <div className="containerbuttons">
        {savedReportId ? (
          <>
            <Grid columns={2}>
              <Grid.Column>
                <ValidatedFormDropdown
                  inputData={uploadSize}
                  dropdownLabel={strings.label.uploadSize}
                  dropdownPlaceholder={strings.placeholder.uploadSize}
                  dropdownName="uploadSize"
                  dropdownOptions={uploadSizeOptions}
                  formFieldWidth={8}
                  handleChange={handleStateChange}
                  required={false}
                  hideMessage
                />
              </Grid.Column>
              <Grid.Column>
                <Button
                  className="saveButton"
                  primary
                  inverted
                  onClick={toggleConfirmSaveModalVisibility}
                  disabled={!uploadSize}
                >
                  {strings.button.uploadReferrals}
                </Button>
                <BasicModal
                  confirmMessage={strings.modal.uploadReferralsMessage}
                  additionalInfo={modalText}
                  handleModalConfirm={handleModalConfirm}
                  modalClassName="uploadReferralsModal"
                  modalOpen={confirmSaveModalOpen}
                  showNoButton={true}
                  showOkButton={false}
                  showYesButton={true}
                  toggleModal={toggleConfirmSaveModalVisibility}
                  noLabel={strings.button.noLabel}
                  yesLabel={strings.button.yesLabel}
                  okLabel={strings.button.okLabel}
                />
              </Grid.Column>
            </Grid>
            <Divider hidden />
          </>
        ) : (
          isTemplateReport && <span>{strings.text.saveToUpload}</span>
        )}
        <ReferralListContainer
          headers={headers}
          history={history}
          searchCriteria={generateReportCriteria}
          isReporting
          handleFetchData={this.props.handleFetchData}
        />
      </div>
    );
  };

  render = () => {
    const { mode, pageTitle, requestStatus, showForm } = this.props;

    return (
      <div className="container">
        <ComponentHeader
          pageTitle={pageTitle}
          mode={mode}
          requestStatus={requestStatus}
          modifyButtons={this.renderModifyButtons()}
          cancelSaveButtons={this.renderCancelSaveButtons()}
          headerList={strings.header.reportList}
          permissionsUpdate={permissions.reports.update}
          permissionsCreate={permissions.reports.create}
          formInvalid={this.props.formInvalid}
          validationResults={this.props.validationResults}
          additionalButtons={this.renderSearchButtons()}
        />
        {this.renderAccordion()}
        {showForm && (
          <Form id="reportForm">
            <fieldset disabled={mode === "view"}>{this.renderForm()}</fieldset>
          </Form>
        )}
      </div>
    );
  };
}

ModifyReport.propTypes = {
  mode: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  selectedReportData: PropTypes.object.isRequired,
  pageTitle: PropTypes.string.isRequired,
  confirmSaveModalOpen: PropTypes.bool.isRequired,
  cancelChangesModalOpen: PropTypes.bool.isRequired,
  toggleConfirmSaveModalVisibility: PropTypes.func.isRequired,
  toggleCancelChangesModalVisibility: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleModalConfirm: PropTypes.func.isRequired,
  formInvalid: PropTypes.bool.isRequired,
  validationResults: PropTypes.object,
  validateForm: PropTypes.func.isRequired,
  requestStatus: PropTypes.bool
};

export default withRouter(ModifyReport);
