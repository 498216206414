import { combineReducers } from "redux";
import userReducer from "../Users/store/reducers/user.reducer";
import organisationReducer from "../Organisations/store/reducers/organisation.reducer";
import customerReducer from "../Customers/store/reducers/customer.reducer";
import referralReducer from "../Referrals/store/reducers/referral.reducer";
import engagementReducer from "../Engagements/store/reducers/engagement.reducer";
import programmeReducer from "../Programmes/store/reducers/programme.reducer";
import baselineReducer from "../Baselines/store/reducers/baseline.reducer";
import fileReducer from "../File/store/reducers/file.reducer";
import taskReducer from "../Tasks/store/reducers/task.reducer";
import employmentReducer from "../Employments/store/reducers/employment.reducer";
import costReducer from "../Costs/store/reducers/cost.reducer";
import auth from "../App/store/reducers/auth.reducer";
import app from "../App/store/reducers/app.reducer";
import activityReducer from "../Activities/store/reducers/activity.reducer";
import assessmentSGReducer from "../AssessmentSG/store/reducers/assessmentSG.reducer";
import reportReducer from "../Reports/store/reducers/report.reducer";
import amendmentsReducer from "../Amendments/store/reducers/amendment.reducer";
import addressReducer from "../App/store/reducers/address.reducer";
import feedbackReducer from "../Feedback/store/reducers/feedback.reducer";

const appReducer = combineReducers({
  auth,
  app,
  users: userReducer,
  organisations: organisationReducer,
  customers: customerReducer,
  referrals: referralReducer,
  engagements: engagementReducer,
  activities: activityReducer,
  programmes: programmeReducer,
  file: fileReducer,
  tasks: taskReducer,
  assessmentSG: assessmentSGReducer,
  employments: employmentReducer,
  costs: costReducer,
  baselines: baselineReducer,
  reports: reportReducer,
  amendments: amendmentsReducer,
  address: addressReducer,
  feedback: feedbackReducer
});

const rootReducer = (state, action) => {
  if (action.type === "SIGNOUT_SUCCESS") {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
