import React from "react";
import PropTypes from "prop-types";
import { permissions } from "../../App/resources/AppPermissions";
import { Accordion, Icon, Button, Grid } from "semantic-ui-react";
import { EngagementListContainer } from "../../Engagements/containers/";
import { strings, engagementsNrne, engagementsNrs } from "../resources/";

import {
  AdministrationFields,
  DataProtectionFields,
  EligiblityFields,
  GeneralFields,
  MatchingFields,
  MentorFields,
  NeedsFields,
  NotesFields,
  PersonalDetailsFields,
  IdentificationStage,
  ChooseForm,
  ChooseEngagement,
  EqualOpportunitiesFields,
  SupportServiceFields
} from "./";

class FieldList extends React.Component {
  state = { activeIndex: 0, dropdownOptions: [] };

  componentDidMount = () => {
    let dropdownOptions;
    if (this.props.selectedReferralData.area === strings.areas.NRNE) {
      dropdownOptions = engagementsNrne;
    } else {
      dropdownOptions = engagementsNrs;
    }
    this.setState({ dropdownOptions: dropdownOptions });
  };

  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex, isReferralStage: undefined });
  };

  render = () => {
    const { activeIndex } = this.state;

    const administrationFields = (
      <AdministrationFields
        formInvalid={this.props.formInvalid}
        handleChange={this.props.handleChange}
        mode={this.props.mode}
        selectedReferralData={this.props.selectedReferralData}
        validationResults={this.props.validationResults}
        owner={this.props.getOrganisations}
        getInitialOwner={this.props.getInitialOwner}
        area={this.props.area}
        modifyOwnerRoles={permissions.referrals.updateOwner}
        projectLeadRole={permissions.referrals.updateProjectLeadOnly}
        roleId={this.props.roleId}
      />
    );

    const dataProtectionFields = (
      <DataProtectionFields
        formInvalid={this.props.formInvalid}
        handleChange={this.props.handleChange}
        mode={this.props.mode}
        selectedReferralData={this.props.selectedReferralData}
        validationResults={this.props.validationResults}
        area={this.props.area}
        headers={this.props.headers}
        shouldSaveFiles={this.props.shouldSaveFiles}
        filesHaveChanged={this.props.filesHaveChanged}
        handleChangeDoNotApproach={this.props.handleChangeDoNotApproach}
      />
    );

    const eligiblityFields = (
      <EligiblityFields
        formInvalid={this.props.formInvalid}
        handleChange={this.props.handleChange}
        mode={this.props.mode}
        selectedReferralData={this.props.selectedReferralData}
        validationResults={this.props.validationResults}
        area={this.props.area}
        handleChangeOffendingHistory={this.props.handleChangeOffendingHistory}
      />
    );

    const generalFields = (
      <GeneralFields
        formInvalid={this.props.formInvalid}
        handleChange={this.props.handleChange}
        mode={this.props.mode}
        selectedReferralData={this.props.selectedReferralData}
        validationResults={this.props.validationResults}
        area={this.props.area}
        projectLeadRole={permissions.referrals.updateProjectLeadOnly}
        roleId={this.props.roleId}
        linkedProgramme={this.props.linkedProgramme}
        handleChangeOutcomeOfThreshold={
          this.props.handleChangeOutcomeOfThreshold
        }
        handleChangeOutcomeReason={this.props.handleChangeOutcomeReason}
      />
    );

    const matchingFields = (
      <MatchingFields
        formInvalid={this.props.formInvalid}
        handleChange={this.props.handleChange}
        mode={this.props.mode}
        selectedReferralData={this.props.selectedReferralData}
        validationResults={this.props.validationResults}
        area={this.props.area}
      />
    );

    const mentorFields = (
      <MentorFields
        formInvalid={this.props.formInvalid}
        handleChange={this.props.handleChange}
        mode={this.props.mode}
        selectedReferralData={this.props.selectedReferralData}
        validationResults={this.props.validationResults}
        mentorsArea={this.props.mentorsArea}
        mentorsPrisonSpecialistView={this.props.mentorsPrisonSpecialistView}
        mentorsPrisonSpecialistSelect={this.props.mentorsPrisonSpecialistSelect}
        mentorsCommunitySpecialistSelect={
          this.props.mentorsCommunitySpecialistSelect
        }
        area={this.props.area}
        projectLeadRole={permissions.referrals.updateProjectLeadOnly}
        specialistAndLeadRole={permissions.referrals.updateLeadAndSpecialist}
        roleId={this.props.roleId}
        specialistDropdown={this.props.specialistDropdown}
        userId={this.props.userId}
        organisations={this.props.getOrganisations}
        handleChangePrisonOrg={this.props.handleChangePrisonOrg}
        handleChangeCommunity={this.props.handleChangeCommunity}
      />
    );

    const needsFields = (
      <NeedsFields
        formInvalid={this.props.formInvalid}
        handleChange={this.props.handleChange}
        mode={this.props.mode}
        selectedReferralData={this.props.selectedReferralData}
        validationResults={this.props.validationResults}
        area={this.props.area}
      />
    );

    const notesFields = (
      <NotesFields
        formInvalid={this.props.formInvalid}
        handleChange={this.props.handleChange}
        mode={this.props.mode}
        selectedReferralData={this.props.selectedReferralData}
        validationResults={this.props.validationResults}
        area={this.props.area}
      />
    );

    const personalDetailsFields = (
      <PersonalDetailsFields
        formInvalid={this.props.formInvalid}
        handleChange={this.props.handleChange}
        mode={this.props.mode}
        selectedReferralData={this.props.selectedReferralData}
        validationResults={this.props.validationResults}
        allowNumericalInput={this.props.allowNumericalInput}
        area={this.props.area}
        handlePostcodeChanged={this.props.handlePostcodeChanged}
        handlePostcodeClicked={this.props.handlePostcodeClicked}
        addressSearchResults={this.props.addressSearchResults}
        addressRetrieved={this.props.addressRetrieved}
        handlePostcodeSearch={this.props.handlePostcodeSearch}
        postcodeOpenStates={this.props.postcodeOpenStates}
        handleSameAddressChange={this.props.handleSameAddressChange}
        libAddressSameChecked={this.props.libAddressSameChecked}
        mailingAddressSameChecked={this.props.mailingAddressSameChecked}
        handleChangeAddress1={this.props.handleChangeAddress1}
        handleChangeAddress2={this.props.handleChangeAddress2}
        handleChangeTownOrCity={this.props.handleChangeTownOrCity}
        handleChangePostcode={this.props.handleChangePostcode}
        handleChangePrisonNrne={this.props.handleChangePrisonNrne}
        handleChangePrisonNrs={this.props.handleChangePrisonNrs}
        handleChangeLiberation={this.props.handleChangeLiberation}
      />
    );

    const equalOpportunitiesFields = (
      <EqualOpportunitiesFields
        formInvalid={this.props.formInvalid}
        handleChange={this.props.handleChange}
        mode={this.props.mode}
        selectedReferralData={this.props.selectedReferralData}
        validationResults={this.props.validationResults}
        area={this.props.area}
      />
    );

    const supportServiceFields = (
      <SupportServiceFields
        formInvalid={this.props.formInvalid}
        handleChange={this.props.handleChange}
        mode={this.props.mode}
        selectedReferralData={this.props.selectedReferralData}
        validationResults={this.props.validationResults}
        supportServiceOptions={this.props.getOrganisations}
      />
    );

    const formSelection = (
      <Grid columns={2}>
        <Grid.Column>
          <ChooseForm
            formInvalid={this.props.formInvalid}
            handleChange={this.props.handleChange}
            mode={this.props.mode}
            selectedReferralData={this.props.selectedReferralData}
            validationResults={this.props.validationResults}
            area={this.props.area}
          />
        </Grid.Column>
        <Grid.Column>
          <ChooseEngagement
            formInvalid={this.props.formInvalid}
            handleChange={this.props.handleChange}
            mode={this.props.mode}
            selectedReferralData={this.props.selectedReferralData}
            validationResults={this.props.validationResults}
            engagementOptions={this.state.dropdownOptions}
          />
        </Grid.Column>
      </Grid>
    );

    const resetFormSelection = (
      <Button
        className="formButton"
        primary
        inverted
        onClick={this.props.resetForm}
        disabled={this.props.mode === "edit"}
      >
        {strings.button.resetLabel}
      </Button>
    );

    let formFields;
    let referralStage;
    //in view mode, use the currently loaded data (as iniital is empty and is set on mode switch),
    //in edit use what it was on page load,
    //in create always show the identification stage
    switch (this.props.mode) {
      case "view":
        referralStage =
          this.props.selectedReferralData.outcomeOfIdentification ===
            strings.fieldValues.eligible ||
          this.props.selectedReferralData.outcomeOfThreshold ===
            strings.fieldValues.suitable;
        break;
      case "edit":
        referralStage =
          this.props.initialReferralData.outcomeOfIdentification ===
            strings.fieldValues.eligible ||
          this.props.initialReferralData.outcomeOfThreshold ===
            strings.fieldValues.suitable;
        break;
      default:
        referralStage = false;
    }
    if (referralStage) {
      let referralFields;
      if (
        !this.props.selectedReferralData.formType &&
        !this.props.selectedReferralData.engagementType
      ) {
        referralFields = formSelection;
      } else if (this.props.selectedReferralData.formType) {
        switch (this.props.selectedReferralData.formType) {
          case strings.fieldValues.referral:
            referralFields = (
              <>
                {administrationFields}
                {generalFields}
                {dataProtectionFields}
                {personalDetailsFields}
                {mentorFields}
                {this.props.selectedReferralData.area === strings.areas.NRS &&
                  eligiblityFields}
                {needsFields}
                {matchingFields}
                {notesFields}
              </>
            );
            break;
          case strings.fieldValues.equalOpportunities:
            referralFields = equalOpportunitiesFields;
            break;
          case strings.fieldValues.supportService:
            referralFields = supportServiceFields;
            break;
          default:
            break;
        }
      } else if (this.props.selectedReferralData.engagementType) {
        referralFields = (
          <div className="embeddedComponent">
            <EngagementListContainer
              headers={this.props.headers}
              history={this.props.history}
              engagementType={this.props.selectedReferralData.engagementType}
              referralId={this.props.selectedReferralData.referralId}
              listHeader={this.props.selectedReferralData.engagementType}
              referralCrmId={this.props.selectedReferralData.crmId}
              initialReferralData={this.props.initialReferralData}
              hideSearchBar={true}
              referralName={`${this.props.selectedReferralData.firstName} ${this.props.selectedReferralData.surname}`}
            />
          </div>
        );
      }
      formFields = (
        <>
          <div className="accordionComponent">
            <Accordion.Title
              active={activeIndex === 1}
              index={1}
              onClick={this.handleClick}
              className="accordionHeader"
            >
              <Icon name="dropdown" />
              {strings.sectionHeaders.identification}
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 1}>
              <fieldset disabled>
                <IdentificationStage
                  formInvalid={this.props.formInvalid}
                  handleChange={this.props.handleChange}
                  mode={"view"}
                  selectedReferralData={this.props.selectedReferralData}
                  validationResults={this.props.validationResults}
                  area={this.props.area}
                  projectLeadRole={permissions.referrals.updateProjectLeadOnly}
                  mentorsArea={this.props.mentorsArea}
                  handleChangeOutcomeOfThreshold={
                    this.props.handleChangeOutcomeOfThreshold
                  }
                  handleChangeOutcomeReason={
                    this.props.handleChangeOutcomeReason
                  }
                  handleChangePrisonNrs={this.props.handleChangePrisonNrs}
                  handleChangePrisonNrne={this.props.handleChangePrisonNrne}
                />
              </fieldset>
            </Accordion.Content>
          </div>
          <div className="accordionComponent">
            <Accordion.Title
              active={activeIndex === 0}
              index={0}
              onClick={this.handleClick}
              className="accordionHeader"
            >
              <Icon name="dropdown" />
              {strings.sectionHeaders.referral}
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 0}>
              {(this.props.selectedReferralData.formType ||
                this.props.selectedReferralData.engagementType) &&
                resetFormSelection}
              <fieldset
                disabled={
                  this.props.disabled &&
                  this.props.selectedReferralData.formType
                }
              >
                {referralFields}
              </fieldset>
            </Accordion.Content>
          </div>
        </>
      );
    } else {
      formFields = (
        <fieldset disabled={this.props.mode === "view"}>
          <IdentificationStage
            formInvalid={this.props.formInvalid}
            handleChange={this.props.handleChange}
            mode={this.props.mode}
            selectedReferralData={this.props.selectedReferralData}
            validationResults={this.props.validationResults}
            area={this.props.area}
            projectLeadRole={permissions.referrals.updateProjectLeadOnly}
            mentorsArea={this.props.mentorsArea}
            roleId={this.props.roleId}
            handleChangeOutcomeOfThreshold={
              this.props.handleChangeOutcomeOfThreshold
            }
            handleChangeOutcomeReason={this.props.handleChangeOutcomeReason}
            handleChangePrisonNrs={this.props.handleChangePrisonNrs}
            handleChangePrisonNrne={this.props.handleChangePrisonNrne}
          />
        </fieldset>
      );
    }
    return <Accordion fluid>{formFields}</Accordion>;
  };
}
FieldList.propTypes = {
  mode: PropTypes.string.isRequired,
  selectedReferralData: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  formInvalid: PropTypes.bool.isRequired,
  validationResults: PropTypes.object
};

export default FieldList;
