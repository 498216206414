import React from "react";
import ReactTable from "react-table";
import { BasicModal, TableFunctions } from ".";
import { strings } from "../resources";
import PropTypes from "prop-types";
import { convertFromIso } from "../helpers/index";

class TableComponent extends React.Component {
  getTdProps = (state, rowInfo, column, instance) => {
    if (column) {
      if (column.id !== "delete" && column.id !== "active") {
        return {
          onClick: () => {
            if (rowInfo) {
              localStorage.setItem("programmeId", rowInfo.original.programmeId);
              localStorage.setItem(
                "programmeName",
                `${rowInfo.original.firstName} ${rowInfo.original.surname}`
              );
              localStorage.setItem("programmeArea", rowInfo.original.area);
              localStorage.setItem(
                "parentReferralId",
                rowInfo.original.referralId
              );
              this.props.history.push(
                "/programmes/view/" + rowInfo.original.programmeId
              );
            }
          }
        };
      }
    }
    return {}; // getTdProps requires a return statement
  };

  mapSearchResultsToRows = () => {
    const { programmeListData } = this.props;
    let data = [];
    if (programmeListData) {
      programmeListData.forEach((result, index) => {
        if (result["_source"]) {
          data.push(result["_source"]);
        }
      });
    }
    return data;
  };

  render = () => {
    let data = this.mapSearchResultsToRows();
    let pages = Math.ceil(this.props.totalResults / this.props.pageSize);
    const cellTruncate = length => {
      return row => {
        let value = row.value ? row.value : "";
        let truncatedValue =
          value.length > length ? (
            <div>{value.substring(0, length)}...</div>
          ) : (
            <div>{value}</div>
          );
        return truncatedValue;
      };
    };

    const columns = [
      {
        Header: strings.table.header.crmId,
        accessor: "crmId",
        headerStyle: { textAlign: "left" },
        Cell: cellTruncate(20)
      },
      {
        Header: strings.table.header.firstName,
        accessor: "firstName",
        headerStyle: { textAlign: "left" },
        Cell: cellTruncate(20)
      },
      {
        Header: strings.table.header.surname,
        accessor: "surname",
        headerStyle: { textAlign: "left" },
        Cell: cellTruncate(20)
      },
      {
        Header: strings.table.header.pNumber,
        accessor: "pNumber",
        headerStyle: { textAlign: "left" },
        Cell: cellTruncate(20)
      },
      {
        Header: strings.table.header.scroNumber,
        accessor: "scroNumber",
        show:
          this.props.area === strings.areas.NRS ||
          this.props.area === strings.areas.both,
        headerStyle: { textAlign: "left" },
        Cell: cellTruncate(20)
      },
      {
        Header: strings.table.header.journeyStatus,
        accessor: "journeyStatus",
        headerStyle: { textAlign: "left" },
        Cell: cellTruncate(20)
      },
      {
        id: "overallEDL", //we need an id for this because the accessor is not a string
        Header: strings.table.header.overallEDL,
        accessor: prog =>
          prog.overallEDL ? convertFromIso(prog.overallEDL) : "",
        headerStyle: { textAlign: "left" }
      },
      {
        Header: strings.table.header.firstCommunityMeetingAttended,
        accessor: "firstCommunityMeetingAttended",
        headerStyle: { textAlign: "left" },
        Cell: cellTruncate(20)
      },
      {
        id: "anticipatedEndDate", //we need an id for this because the accessor is not a string
        Header: strings.table.header.anticipatedEndDate,
        accessor: prog =>
          prog.anticipatedEndDate
            ? convertFromIso(prog.anticipatedEndDate)
            : "",
        headerStyle: { textAlign: "left" }
      }
    ];
    return (
      <div>
        <TableFunctions
          headers={this.props.headers}
          history={this.props.history}
          handleRefresh={this.props.handleRefresh}
          headerList={strings.header.programmeList}
          headerClass="pageHeader"
          refreshButtonClass="refreshButton"
          hideCreate={true}
          searchBarQuery={this.props.searchBarQuery}
          handleChange={this.props.handleChange}
          handleSubmit={this.props.handleSubmit}
        />
        <div>
          <ReactTable
            manual
            data={data}
            pages={pages}
            defaultPageSize={this.props.pageSize}
            showPagination={true}
            getTdProps={this.getTdProps}
            onFetchData={(state, instance) => {
              this.props.handleFetchData(state);
              this.props.updatePageIndex(state);
            }}
            columns={columns}
            className="-highlight"
            showPageSizeOptions={false}
            minRows="1"
            noDataText="No results found."
            previousText="<"
            nextText=">"
            pageText=""
          />
          <BasicModal
            confirmMessage={strings.modal.deleteProgrammeMessage}
            handleModalConfirm={this.props.handleModalConfirm}
            modalClassName="deleteProgrammeModal"
            modalOpen={this.props.deleteModalOpen}
            showNoButton={true}
            showOkButton={false}
            showYesButton={true}
            toggleModal={this.props.toggleDeleteModal}
            noLabel={strings.button.noLabel}
            yesLabel={strings.button.yesLabel}
            okLabel={strings.button.okLabel}
          />
        </div>
      </div>
    );
  };
}

TableComponent.propTypes = {
  deleteModalOpen: PropTypes.bool.isRequired,
  handleModalConfirm: PropTypes.func.isRequired,
  programmeListData: PropTypes.array.isRequired,
  headers: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  toggleDeleteModal: PropTypes.func.isRequired,
  pageSize: PropTypes.number.isRequired,
  handleFetchData: PropTypes.func.isRequired,
  totalResults: PropTypes.number.isRequired,
  updatePageIndex: PropTypes.func.isRequired
};

export default TableComponent;
