import React from "react";
import ReactTable from "react-table";
import { TableFunctions } from ".";
import { strings } from "../resources";
import PropTypes from "prop-types";
import moment from "moment";

class TableComponent extends React.Component {
  render = () => {
    let data = this.props.outcomeListData;
    let pages = Math.ceil(this.props.totalResults / this.props.pageSize);
    const cellTruncate = length => {
      return row => {
        let value = row.value ? row.value : "";
        let truncatedValue =
          value.length > length ? (
            <div>{value.substring(0, length)}...</div>
          ) : (
            <div>{value}</div>
          );
        return truncatedValue;
      };
    };
    const columns = [
      {
        Header: strings.table.header.outcomeName,
        accessor: "outcomeName",
        headerStyle: { textAlign: "left" },
        Cell: cellTruncate(20)
      },
      {
        id: "claimed",
        Header: strings.table.header.claimed,
        accessor: outcome => {
          if (outcome.date) {
            return moment(outcome.date).local().format("DD/MM/YYYY");
          }
          return "";
        },
        headerStyle: { textAlign: "left" },
        Cell: cellTruncate(20)
      },
      {
        Header: strings.table.header.achieved,
        accessor: "outcomesAchieved",
        headerStyle: { textAlign: "left" },
        Cell: cellTruncate(20)
      },
      {
        Header: strings.table.header.workingTowards,
        accessor: "outcomesWorkedTowards",
        headerStyle: { textAlign: "left" },
        Cell: cellTruncate(20)
      }
    ];

    return (
      <div>
        <TableFunctions
          headers={this.props.headers}
          history={this.props.history}
          handleRefresh={this.props.handleRefresh}
          headerList={strings.header.outcomeList}
          buttonAddLabel={""}
          permissionsCreate={[]}
          headerClass="pageHeader"
          refreshButtonClass="refreshButton"
          addButtonClass="addButton"
          addButtonLink=""
          hideCreate={true}
          handleChange={this.props.handleChange}
          searchBarQuery={this.props.searchBarQuery}
          handleSubmit={this.props.handleSubmit}
          hideSearchBar={this.props.hideSearchBar}
          parentPage={this.props.parentPage}
          returnToParent={this.props.returnToParent}
        />
        <div>
          <ReactTable
            manual
            data={data}
            pages={pages}
            defaultPageSize={this.props.pageSize}
            showPagination={true}
            getTdProps={this.getTdProps}
            onFetchData={(state, instance) => {
              this.props.handleFetchData(state);
              this.props.updatePageIndex(state);
            }}
            columns={columns}
            className="-highlight"
            showPageSizeOptions={false}
            minRows="1"
            noDataText={strings.table.text.noResults}
            previousText="<"
            nextText=">"
            pageText=""
          />
        </div>
      </div>
    );
  };
}

TableComponent.propTypes = {
  outcomeListData: PropTypes.array.isRequired,
  headers: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  pageSize: PropTypes.number.isRequired,
  handleFetchData: PropTypes.func.isRequired,
  totalResults: PropTypes.number.isRequired,
  updatePageIndex: PropTypes.func.isRequired
};

export default TableComponent;
