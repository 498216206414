import React, { Component } from "react";
import {
  questionsList,
  parts,
  progress,
  initialDate,
  strings
} from "../../resources";

import { Grid, Checkbox } from "semantic-ui-react";

import { ValidatedDateInput } from "./../validation";

class AssessmentSG extends Component {
  state = {
    dateCompleted: initialDate,
    engagedWithService: [],
    workingOnProblems: [],
    desistFromCrime: [],
    solvingEverydayProblems: [],
    viewsOnOffending: [],
    hasAccommodation: [],
    hasMoneyIssues: [],
    hasAlcoholDrugProblems: [],
    hasFamilyRelationships: [],
    hasLeisureActivities: [],
    inEducationTraining: [],
    checked: [],
    loadedState: false
  };

  componentDidMount() {
    const challengeKeys = Object.keys(this.props.selectedAssessmentSGData);
    if (challengeKeys.length > 0) {
      this.setupExistingAssessmentSG(challengeKeys);
    } else {
      this.setupDefaultAssessmentSG();
    }
  }

  componentDidUpdate() {
    if (this.props.hasCancelled === true) {
      this.setupExistingAssessmentSG();
      this.props.resetCancelledState();
    }
  }

  setupExistingAssessmentSG = async challengeKeys => {
    await this.setupDefaultAssessmentSG();
    const stateKeys = Object.keys(this.state);
    const { selectedAssessmentSGData } = this.props;
    stateKeys.forEach(stateKey => {
      const stages = [];
      for (const stage in strings.stages) {
        stages.push(stage);
      }
      if (stateKey in this.props.selectedAssessmentSGData) {
        if (stateKey === strings.stageKeys.dateCompleted) {
          let dateCompletedData = selectedAssessmentSGData[stateKey];
          stages.forEach((stage, index) => {
            let updatedDateCompleted = this.state[stateKey];
            if (dateCompletedData[stage]) {
              updatedDateCompleted[index].value = dateCompletedData[stage];
            } else {
              updatedDateCompleted[index].value = null;
            }
            this.setState({ stateKey: updatedDateCompleted });
          });
        } else {
          const progress = ["gotBetter", "stayedSame", "gotWorse"];
          let updatedQuestion = this.state[stateKey];
          selectedAssessmentSGData[stateKey].forEach((value, i) => {
            if (value !== null) {
              if (i < 4) {
                if (updatedQuestion.data[value]) {
                  updatedQuestion.data[value][i].partName = stages[i];
                  updatedQuestion.data[value][i].checked = true;
                }
              } else {
                updatedQuestion.progress.name = progress[value];
                updatedQuestion.progress.value = value;
              }
            }
          });
          this.setState({ stateKey: updatedQuestion });
        }
      }
    });
    this.setState({ loadedState: true });
  };

  setupDefaultAssessmentSG = () => {
    questionsList.map(item => {
      return this.setState({
        [item.name]: {
          data: [...Array(item.answers.length)].map(() => {
            return [...Array(4)].map(() => ({
              partName: null,
              checked: false
            }));
          }),
          progress: {
            name: null,
            value: null
          }
        }
      });
    });
    if (this.props.mode === "create") {
      this.setState({ loadedState: true });
    }
  };

  buildReturnData = (name, questionIndex) => {
    let returnObject = { [name]: [] };
    this.state[name].data.forEach((answer, answerKey) => {
      answer.forEach((stage, stageKey) => {
        if (stage.partName) {
          if (stage.checked === true) {
            returnObject[name][stageKey] = answerKey;
          } else {
            returnObject[name][stageKey] = null;
          }
        }
      });
    });
    returnObject[name][4] = this.state[name].progress.value;
    this.props.updateSelectedAssessmentSG(name, returnObject[name]);

    let newData = returnObject[name]
      .slice(0, -1)
      .filter(data => typeof data === "number");

    if (newData.length > 1) {
      if (newData[0] < newData[newData.length - 1]) {
        returnObject[name][4] = 0;

        this.handlerProgress(
          name,
          "gotBetter",
          returnObject[name][4],
          questionIndex
        );
      } else if (newData[0] > newData[newData.length - 1]) {
        returnObject[name][4] = 2;
        this.handlerProgress(
          name,
          "gotWorse",
          returnObject[name][4],
          questionIndex
        );
      } else if (newData[0] === newData[newData.length - 1]) {
        returnObject[name][4] = 1;
        this.handlerProgress(
          name,
          "stayedSame",
          returnObject[name][4],
          questionIndex
        );
      }
    }
  };

  handlerCheckbox = (e, checkbox, index, part, partIndex, questionIndex) => {
    const fullParts = [];
    const groupName = this.state[checkbox.name];

    groupName.data.map(arr => arr.map(item => fullParts.push(item.partName)));

    const partName = fullParts.filter(item => item === part);

    const data = groupName.data.map(group => {
      return group.map(item => {
        if (item.partName === part && partName.length > 0) {
          return {
            partName: null,
            checked: false
          };
        }

        return item;
      });
    });
    data[index][partIndex] = {
      partName: part,
      checked: checkbox.checked
    };
    this.setState(
      {
        [checkbox.name]: {
          ...groupName,
          data
        }
      },
      () => {
        this.buildReturnData(checkbox.name, questionIndex);
      }
    );
  };

  buildReturnDataProgress = (name, value) => {
    if (this.props.selectedAssessmentSGData[name]) {
      let assessmentData = JSON.parse(
        JSON.stringify(this.props.selectedAssessmentSGData[name])
      );
      assessmentData[4] = value;
      this.props.updateSelectedAssessmentSG(name, assessmentData);
    } else {
      let assessmentData = [];
      assessmentData[4] = value;
      this.props.updateSelectedAssessmentSG(name, assessmentData);
    }
  };

  handlerProgress = (name, progress, index, itemIndex) => {
    let newChecked = this.state.checked;
    newChecked[itemIndex] = progress;
    this.setState(
      {
        [name]: {
          ...this.state[name],
          progress: {
            name: progress,
            value: index
          }
        },
        checked: newChecked
      },
      () => {
        this.buildReturnDataProgress(name, index);
      }
    );
  };

  buildReturnDataDate = name => {
    let returnObject = { [name]: {} };
    this.state[name].forEach(answer => {
      if (answer.value !== "") {
        returnObject[name][answer.part] = answer.value;
      } else {
        returnObject[name][answer.part] = null;
      }
    });

    this.props.updateSelectedAssessmentSG(name, returnObject[name]);
  };

  handlerDate = (e, { value }, part) => {
    const { dateCompleted } = this.state;
    this.setState(
      {
        dateCompleted: dateCompleted.map((item, i) => {
          if (item.part === part) {
            return {
              ...item,
              value
            };
          }
          return item;
        })
      },
      () => {
        this.buildReturnDataDate("dateCompleted");
      }
    );
  };

  render() {
    const { dateCompleted } = this.state;
    const { Row, Column } = Grid;

    const { formInvalid } = this.props;
    if (dateCompleted.length > 0 && this.state.loadedState) {
      return (
        <div className="ass-sg">
          <div className="infoBlock">
            <div className="infoHeader">
              {strings.form.header.sgAssessmentQuestions}
            </div>
            <div className="infoWrapper">
              <div className="ass-sg-questions">
                <Grid divided="vertically">
                  <Row>
                    <Column width={13}>
                      <Grid>
                        <Row>
                          <Column width={7} textAlign="left">
                            {strings.header.dateCompleted}
                          </Column>
                          {dateCompleted
                            .filter(date => date.part !== "progress")
                            .map((item, i) => (
                              <Column key={i} width={2} textAlign="center">
                                <ValidatedDateInput
                                  inputId={"dateCompleted" + i}
                                  formInvalid={formInvalid}
                                  inputLabel={strings.form.label.dateCompleted}
                                  inputName="dateCompleted"
                                  inputPlaceholder={
                                    strings.form.placeholder.dateCompleted
                                  }
                                  inputDateFormat="DD/MM/YYYY"
                                  inputData={item.value ? item.value : ""}
                                  iconPosition="left"
                                  handleChange={(e, field) =>
                                    this.handlerDate(e, field, item.part)
                                  }
                                  validationResult={
                                    this.props.validationResults
                                      .dateCompleted &&
                                    this.props.validationResults.dateCompleted
                                      .length > i &&
                                    this.props.validationResults.dateCompleted[
                                      i
                                    ][0]
                                      ? this.props.validationResults
                                          .dateCompleted[i]
                                      : null
                                  }
                                  required={false}
                                  disabled={
                                    this.props.mode === "view" ||
                                    this.props.isDisabled
                                  }
                                  className="sgDates"
                                />
                              </Column>
                            ))}
                        </Row>
                      </Grid>
                    </Column>
                    <Column width={3}>
                      <ValidatedDateInput
                        inputId={"dateCompleted" + 4}
                        formInvalid={formInvalid}
                        inputDateFormat="DD/MM/YYYY"
                        inputName="dateCompleted"
                        className="semantic-calendar"
                        inputLabel={strings.form.label.dateCompleted}
                        inputData={
                          dateCompleted[4].value ? dateCompleted[4].value : ""
                        }
                        inputPlaceholder={
                          strings.form.placeholder.dateCompleted
                        }
                        handleChange={(e, field) =>
                          this.handlerDate(e, field, dateCompleted[4].part)
                        }
                        animation={null}
                        validationResult={
                          this.props.validationResults.dateCompleted &&
                          this.props.validationResults.dateCompleted.length >
                            4 &&
                          this.props.validationResults.dateCompleted[4][0]
                            ? this.props.validationResults.dateCompleted[4]
                            : null
                        }
                        required={false}
                        disabled={
                          this.props.mode === "view" || this.props.isDisabled
                        }
                      />
                    </Column>
                  </Row>
                  {questionsList.map((item, i) => (
                    <Row key={i} stretched divided>
                      <Column width={13}>
                        <Grid>
                          <Row>
                            <Column width={7} textAlign="left">
                              {item.question}
                            </Column>

                            {parts.map((part, partIndex) => (
                              <Column
                                key={partIndex}
                                width={2}
                                textAlign="center"
                              >
                                {part.title}
                              </Column>
                            ))}
                          </Row>
                          {item.answers.map((answer, index) => (
                            <Row key={index}>
                              <Column width={7} textAlign="left">
                                <span className="ass-sg-answer">
                                  ({index + 1}) {answer}
                                </span>
                              </Column>

                              {parts.map((part, partIndex) => {
                                return (
                                  <Column
                                    key={partIndex}
                                    width={2}
                                    textAlign="center"
                                  >
                                    <Checkbox
                                      name={item.name}
                                      onChange={(e, value) => {
                                        this.handlerCheckbox(
                                          e,
                                          value,
                                          index,
                                          part.name,
                                          partIndex
                                        );
                                      }}
                                      checked={
                                        this.state[item.name].data[index][
                                          partIndex
                                        ].checked
                                      }
                                      disabled={
                                        this.props.mode === "view" ||
                                        this.props.isDisabled
                                      }
                                    />
                                  </Column>
                                );
                              })}
                            </Row>
                          ))}
                        </Grid>
                      </Column>

                      <Column width={3} textAlign="left">
                        <div className="ass-sg-group">
                          <span className="ass-sg-group-label">Progress</span>
                          {progress.map((progress, j) => (
                            <div key={j} className="ass-sg-checkbox">
                              <span>{progress.title}</span>
                              <Checkbox
                                value={progress.name}
                                onChange={e =>
                                  this.handlerProgress(
                                    item.name,
                                    progress.name,
                                    j,
                                    i
                                  )
                                }
                                checked={
                                  this.state[item.name].progress.value === j
                                }
                                disabled={
                                  this.props.mode === "view" ||
                                  this.props.isDisabled
                                }
                              />
                            </div>
                          ))}
                        </div>
                      </Column>
                    </Row>
                  ))}
                </Grid>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return null;
  }
}

export default AssessmentSG;
