import React from "react";
import PropTypes from "prop-types";
import { Form } from "semantic-ui-react";
import { strings, yesNoOptions, ageCategoryOptions } from "../../resources";
import { ValidatedFormInput, ValidatedFormDropdown } from "../validation";
import "../css/ReferralComponents.scss";

class EligiblityFields extends React.Component {
  render = () => {
    const isNRNE = this.props.area === strings.areas.NRNE;
    const isEditable = this.props.mode !== "view" && !isNRNE;

    return (
      <div className="infoBlock">
        <div className="infoHeader">{strings.form.header.eligiblityHeader}</div>
        <div className="infoWrapper">
          <Form.Group>
            <ValidatedFormDropdown
              formInvalid={this.props.formInvalid}
              inputData={this.props.selectedReferralData.ageCategory}
              viewData={this.props.selectedReferralData.ageCategory}
              dropdownLabel={strings.form.label.ageCategory}
              dropdownName="ageCategory"
              dropdownOptions={ageCategoryOptions}
              dropdownPlaceholder={strings.form.placeholder.ageCategory}
              formFieldWidth={8}
              handleChange={this.props.handleChange}
              validationResult={this.props.validationResults.ageCategory}
              dropdownDisabled={!isEditable}
              mode={this.props.mode}
            />
            <ValidatedFormDropdown
              formInvalid={this.props.formInvalid}
              inputData={this.props.selectedReferralData.statutorySupport}
              viewData={this.props.selectedReferralData.statutorySupport}
              dropdownLabel={strings.form.label.statutorySupport}
              dropdownName="statutorySupport"
              dropdownOptions={yesNoOptions}
              dropdownPlaceholder={strings.form.placeholder.statutorySupport}
              dropdownDisabled={!isEditable}
              formFieldWidth={8}
              handleChange={this.props.handleChange}
              validationResult={this.props.validationResults.statutorySupport}
              mode={this.props.mode}
            />
          </Form.Group>
          <Form.Group>
            <ValidatedFormDropdown
              formInvalid={this.props.formInvalid}
              inputData={this.props.selectedReferralData.shortSentence}
              viewData={this.props.selectedReferralData.shortSentence}
              dropdownLabel={strings.form.label.shortSentence}
              dropdownName="shortSentence"
              dropdownOptions={yesNoOptions}
              dropdownPlaceholder={strings.form.placeholder.shortSentence}
              formFieldWidth={8}
              handleChange={this.props.handleChange}
              validationResult={this.props.validationResults.shortSentence}
              dropdownDisabled={!isEditable}
              mode={this.props.mode}
            />
            <ValidatedFormDropdown
              formInvalid={this.props.formInvalid}
              inputData={this.props.selectedReferralData.offendingHistory}
              viewData={this.props.selectedReferralData.offendingHistory}
              dropdownLabel={strings.form.label.offendingHistory}
              dropdownName="offendingHistory"
              dropdownOptions={yesNoOptions}
              dropdownPlaceholder={strings.form.placeholder.offendingHistory}
              formFieldWidth={8}
              handleChange={this.props.handleChangeOffendingHistory}
              validationResult={this.props.validationResults.offendingHistory}
              dropdownDisabled={!isEditable}
              mode={this.props.mode}
            />
          </Form.Group>
          <Form.Group>
            {this.props.selectedReferralData.offendingHistory ===
              strings.fieldValues.no && (
              <ValidatedFormInput
                formInvalid={this.props.formInvalid}
                inputData={this.props.selectedReferralData.riskFactors}
                inputLabel={strings.form.label.riskFactors}
                inputName="riskFactors"
                inputPlaceholder={strings.form.placeholder.riskFactors}
                formFieldWidth={8}
                handleChange={this.props.handleChange}
                validationResult={this.props.validationResults.riskFactors}
                disabled={isNRNE && this.props.mode !== "view"}
                mode={this.props.mode}
              />
            )}
          </Form.Group>
        </div>
      </div>
    );
  };
}

EligiblityFields.propTypes = {
  formInvalid: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  mode: PropTypes.string.isRequired,
  selectedReferralData: PropTypes.object.isRequired,
  validationResults: PropTypes.object
};

export default EligiblityFields;
