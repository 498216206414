const canUserModifyRecord = (data, orgOfLoggedInUser) => {
  return (
    (data &&
      data.sharedWith &&
      data.sharedWith.length > 0 &&
      data.sharedWith.includes(orgOfLoggedInUser)) ||
    (data && data.sharedWith && data.sharedWith.length === 0)
  );
};

export default canUserModifyRecord;
