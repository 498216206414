const questionsList = [
  {
    question: "Is the customer engaging with services?",
    name: "engagedWithService",
    answers: [
      "They are not engaging with services (to address needs) at all",
      "They say they are keen to engage but have not",
      "They are engaging with services but need some encouragement to do so",
      "They are engaging willingly with services without the need for encouragement"
    ]
  },
  {
    question: "Is the customer ready to work on problems?",
    name: "workingOnProblems",
    answers: [
      "They are not ready or willing to work on problems and deny the need to change",
      "They are ready to work on problems but will not accept support to do so",
      "They are ready to work on problems and accept support to help them change",
      "They recognise they have problems and are actively working on them"
    ]
  },
  {
    question:
      "Does the customer believe they can/know how to desist from crime?",
    name: "desistFromCrime",
    answers: [
      "They don’t believe they can desist from crime and/or they don’t know how to desist from crime (stop offending)",
      "They do believe they can desist from crime and/or know how to desist from crime"
    ]
  },
  {
    question:
      "Is the customer solving everyday problems (actions rather than beliefs)?",
    name: "solvingEverydayProblems",
    answers: [
      "They are not solving everyday problems (such as benefits, relationship conflicts etc)",
      "They are solving everyday problems, but only with support from others",
      "They can solve problems in daily life and deal effectively with issues as they arise"
    ]
  },
  {
    question: "What are the customer views towards their offending behaviour?",
    name: "viewsOnOffending",
    answers: [
      "They consistently express views that offending is acceptable for example that offending is a justifiable means to an end; that victims are responsible; and express negative views of the law, the police, courts etc",
      "They express inconsistent views on whether offending is acceptable or not",
      "They consistently express views that offending is NOT acceptable"
    ]
  },
  {
    question: "Does the customer have accommodation?",
    name: "hasAccommodation",
    answers: [
      "No accommodation (homeless)",
      "Current accommodation unsuitable or unsafe",
      "Current accommodation provides relatively safe/stable environment but room for improvement",
      "Has suitable/safe accommodation"
    ]
  },
  {
    question: "Does the customer have money issues?",
    name: "hasMoneyIssues",
    answers: [
      "Serious money problems and/or no apparent means of support",
      "Regular money problems e.g., frequent issues with benefits claims/bills, money-lenders etc",
      "No major difficulties but needs advice or advocacy on some money issues",
      "Pattern of effective independent management of money"
    ]
  },
  {
    question: "Does the customer have any alcohol or drug problems?",
    name: "hasAlcoholDrugProblems",
    answers: [
      "They have serious alcohol/drug use which is linked to offending and interferes with daily functioning such as wellbeing, family-life/education, employment and or recreation",
      "They use alcohol or drugs which can sometimes interfere with daily functioning such as wellbeing, family-life/education, employment and or recreation and is linked to offending",
      "They use alcohol or drugs but are stabilises through medication or treatment",
      "They use recreational drug use only - not linked to offending",
      "No current issues"
    ]
  },
  {
    question: "How are the customer family relationships?",
    name: "hasFamilyRelationships",
    answers: [
      "No family support or poor family relationships that impact on behaviours and or emotional state (including family that support offending behaviour) or rejects influence of prosocial family support",
      "Some evidence of problems with some family members, or sporadically accepts/rejects influence or prosocial family",
      "Overall fairly stable relationships with family members",
      "Has active support to desist from family and good family relationships"
    ]
  },
  {
    question: "What does the customer do for leisure activities?",
    name: "hasLeisureActivities",
    answers: [
      "They don’t do anything positive in their spare time, say they are bored and/or associate with people who are linked to offending and do not have any interests that could be built on",
      "They don’t do anything positive in their spare time, say they are bored and/or associate with people who are linked to offending but have an interest that could be built on",
      "They do fill their time with positive activities but could benefit from more rewarding activity",
      "Makes constructive use of their time and find this really rewarding"
    ]
  },
  {
    question: "Is the customer working or in education/training?",
    name: "inEducationTraining",
    answers: [
      "They are not working or in training and do not want to be.  They resist efforts to help them find work etc.",
      "They are not working or in training but are making an effort/and or accepting help to find work or courses",
      "They are working or in training but would like help to find something more rewarding",
      "They are working or in training and they find this rewarding"
    ]
  }
];

const parts = [
  {
    title: "Initial Assessment",
    name: "initialAssessment"
  },
  {
    title: "Pre-Release",
    name: "preRelease"
  },
  {
    title: "Post Release",
    name: "postRelease"
  },
  {
    title: "Exit",
    name: "exit"
  }
];

const progress = [
  {
    title: "Got better",
    name: "gotBetter"
  },
  {
    title: "Stayed the same",
    name: "stayedSame"
  },
  {
    title: "Got worse",
    name: "gotWorse"
  }
];

const initialDate = [
  {
    part: "initialAssessment",
    value: ""
  },
  {
    part: "preRelease",
    value: ""
  },
  {
    part: "postRelease",
    value: ""
  },
  {
    part: "exit",
    value: ""
  },
  {
    part: "progress",
    value: ""
  }
];
export { questionsList, parts, progress, initialDate };
