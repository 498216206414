import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Layout from "./components/Layout";
import Routes from "./routes";
import { config } from "../_config/aws";
import { authActions } from "./store/actions/auth.actions";
import "./App.scss";
import moment from "moment";
import IdleTimer from "react-idle-timer";
import { BasicModal } from "./components";
import { strings } from "./resources";

const timeoutPeriodInMinutes = 120;

const idleTimerReminderInMinutes = 10;
const idleTimerResponseInMinutes = 5;

class App extends Component {
  state = {
    lastActivity: null,
    idleTimerModalOpen: false,
    isTimedOut: false
  };

  checkAuthenticationStatus = async () => {
    try {
      await this.props.checkCurrentAuthenticatedUser();
    } catch (err) {
      // Set session expired if authentication check throws error
      await this.props.setSessionExpired();
    }
  };

  checkLastActivity = async () => {
    if (this.state.lastActivity) {
      const lastActivityTime = moment(this.state.lastActivity).local();
      const currentTime = moment(+new Date()).local();
      const diffInMinutes = currentTime.diff(lastActivityTime, "minutes");
      if (diffInMinutes >= timeoutPeriodInMinutes) {
        await this.props.setSessionExpired();
      }
    }
  };

  convertMinsToMs = date => {
    return date * 60000;
  };

  setTimer = () => {
    clearTimeout(this.timeoutID);
    const timeoutPeriod = this.convertMinsToMs(
      idleTimerReminderInMinutes + idleTimerResponseInMinutes
    );

    this.timeoutID = setTimeout(() => {
      this.handleTimeout();
    }, timeoutPeriod);
  };

  resetIdleTimeout = () => {
    this.setState({ isTimedOut: false }, this.setTimer());
  };

  onAction = () => {
    this.resetIdleTimeout();
  };

  onActive = () => {
    this.resetIdleTimeout();
  };

  onIdle = async () => {
    const { isTimedOut } = this.state;

    if (isTimedOut) {
      await this.props.setSessionExpired();
    } else {
      this.toggleModal();
      if (this.idleTimer) {
        this.idleTimer.reset();
      }
      this.setState({ isTimedOut: true });
    }
  };

  toggleModal = () => {
    this.setState({ idleTimerModalOpen: !this.state.idleTimerModalOpen });
  };

  handleSubmit = () => {
    this.resetIdleTimeout();
  };

  handleTimeout = async () => {
    clearTimeout(this.timeoutID);
    this.setState({ isTimedOut: true }, this.toggleModal());
    await this.props.setSessionExpired();
  };

  handleModalConfirm = modalClassName => {
    switch (modalClassName) {
      case "idleTimerModal":
        this.handleSubmit();
        break;
      default:
        break;
    }
  };

  componentDidUpdate = async (prevProps, prevState, snapshot) => {
    if (!prevProps.isLoggedIn && this.props.isLoggedIn && this.idleTimer) {
      this.setTimer();
      this.idleTimer.reset();
      this.setState({ isTimedOut: false, idleTimerModalOpen: false });
    }

    if (
      prevProps.location.pathname !== prevProps.history.location.pathname &&
      !this.props.loggedOut
    ) {
      this.checkAuthenticationStatus();
    }
  };

  componentDidMount = async () => {
    window.scrollTo(0, 0);

    let lastActive =
      JSON.parse(localStorage.getItem("activity")) || +new Date();
    if (lastActive) {
      this.setState({ lastActivity: lastActive });
    }
    localStorage.removeItem("activity");
    this.checkLastActivity();

    window.addEventListener("beforeunload", event => {
      localStorage.setItem("activity", this.state.lastActivity);
    });
  };

  renderIdleTimer = () => {
    const idleTimerTimeout = this.convertMinsToMs(idleTimerReminderInMinutes);

    return (
      <>
        <IdleTimer
          ref={ref => {
            this.idleTimer = ref;
          }}
          onActive={this.onActive}
          onIdle={this.onIdle}
          onAction={this.onAction}
          debounce={250}
          timeout={idleTimerTimeout}
        />

        <BasicModal
          confirmMessage={strings.modal.confirmMessage}
          handleModalConfirm={this.handleModalConfirm}
          modalClassName="idleTimerModal"
          modalOpen={this.state.idleTimerModalOpen}
          showNoButton
          showYesButton
          showOkButton={false}
          toggleModal={this.toggleModal}
          noLabel={strings.modal.noLabel}
          yesLabel={strings.modal.yesLabel}
          okLabel={strings.modal.okLabel}
          handleClose={this.handleTimeout}
        />
      </>
    );
  };

  render() {
    const isLoggedIn = this.props.isLoggedIn;
    const roleId = this.props.roleId;
    const area = this.props.area;
    let headers = {};
    if (JSON.parse(localStorage.getItem("state"))) {
      headers = JSON.parse(localStorage.getItem("state")).headers;
    }

    const injectedProps = {
      headers,
      isLoggedIn,
      roleId,
      area,
      cognitoUserPool: config.cognito.UserPoolId,
      cognitoClient: config.cognito.ClientId,
      signOut: this.props.signOut
    };

    return (
      <div className="App">
        <Layout>
          {isLoggedIn && this.renderIdleTimer()}
          <Routes injectedProps={injectedProps} />
        </Layout>
      </div>
    );
  }
}

App.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  area: PropTypes.string,
  roleId: PropTypes.string,
  refreshSignInStatus: PropTypes.func.isRequired,
  checkCurrentAuthenticatedUser: PropTypes.func.isRequired,
  signOut: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const { isLoggedIn, roleId, area, loggedOut } = state.auth;

  return {
    isLoggedIn,
    roleId,
    area,
    loggedOut
  };
};

const mapDispatchToProps = dispatch => {
  return {
    refreshSignInStatus: () => {
      dispatch(authActions.refreshSignInStatus());
    },
    checkCurrentAuthenticatedUser: () => {
      return dispatch(authActions.checkCurrentAuthenticatedUser());
    },
    signOut: () => {
      return dispatch(authActions.signOut());
    },
    setSessionExpired: () => {
      return dispatch(authActions.setSessionExpired());
    }
  };
};

const connectedApp = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(App)
);
export { connectedApp as App };
