import React from "react";
import PropTypes from "prop-types";
import { Form } from "semantic-ui-react";
import { strings } from "../../resources";
import { convertFromIso } from "../../helpers/index";

import { ValidatedFormDropdown, ValidatedDateInput } from "../validation";
import "../css/ProgrammeComponents.scss";
import { yesNoOptions, whoMetAtGateOptions } from "../../resources";

const LiberationFields = props => (
  <div className="infoBlock">
    <div className="infoHeader">{strings.form.header.liberationHeader}</div>
    <div className="infoWrapper">
      <Form.Group>
        <ValidatedFormDropdown
          formInvalid={props.formInvalid}
          inputData={props.selectedProgrammeData.wantsCollected}
          viewData={props.selectedProgrammeData.wantsCollected}
          dropdownLabel={strings.form.label.wantsCollected}
          dropdownName="wantsCollected"
          dropdownOptions={yesNoOptions}
          dropdownPlaceholder={strings.form.placeholder.wantsCollected}
          formFieldWidth={8}
          handleChange={props.handleChange}
          validationResult={props.validationResults.wantsCollected}
          mode={props.mode}
        />

        {props.selectedProgrammeData.wantsCollected ===
          strings.fieldValues.no && (
          <ValidatedFormDropdown
            formInvalid={props.formInvalid}
            inputData={props.selectedProgrammeData.alternativeArrangements}
            viewData={props.selectedProgrammeData.alternativeArrangements}
            dropdownLabel={strings.form.label.alternativeArrangements}
            dropdownName="alternativeArrangements"
            dropdownOptions={yesNoOptions}
            dropdownPlaceholder={
              strings.form.placeholder.alternativeArrangements
            }
            formFieldWidth={8}
            handleChange={props.handleChange}
            validationResult={props.validationResults.alternativeArrangements}
            mode={props.mode}
          />
        )}
      </Form.Group>
      <Form.Group>
        <ValidatedFormDropdown
          formInvalid={props.formInvalid}
          inputData={props.selectedProgrammeData.gateLiberationAttended}
          viewData={props.selectedProgrammeData.gateLiberationAttended}
          dropdownLabel={strings.form.label.gateLiberationAttended}
          dropdownName="gateLiberationAttended"
          dropdownOptions={yesNoOptions}
          dropdownPlaceholder={strings.form.placeholder.gateLiberationAttended}
          formFieldWidth={8}
          handleChange={props.handleChange}
          validationResult={props.validationResults.gateLiberationAttended}
          required={false}
          mode={props.mode}
        />

        {props.selectedProgrammeData.alternativeArrangements ===
          strings.fieldValues.yes && (
          <ValidatedFormDropdown
            formInvalid={props.formInvalid}
            inputData={props.selectedProgrammeData.whoMetAtGate}
            viewData={props.selectedProgrammeData.whoMetAtGate}
            dropdownLabel={strings.form.label.whoMetAtGate}
            dropdownName="whoMetAtGate"
            dropdownOptions={whoMetAtGateOptions}
            dropdownPlaceholder={strings.form.placeholder.whoMetAtGate}
            formFieldWidth={8}
            handleChange={props.handleChange}
            validationResult={props.validationResults.whoMetAtGate}
            required={false}
            mode={props.mode}
          />
        )}
      </Form.Group>
      <Form.Group>
        <ValidatedDateInput
          inputId="actualLiberationDate"
          formInvalid={props.formInvalid}
          inputLabel={strings.form.label.actualLiberationDate}
          inputName="actualLiberationDate"
          inputPlaceholder={strings.form.placeholder.actualLiberationDate}
          inputDateFormat="DD/MM/YYYY"
          inputData={
            props.selectedProgrammeData.actualLiberationDate
              ? convertFromIso(props.selectedProgrammeData.actualLiberationDate)
              : ""
          }
          iconPosition="left"
          handleChange={props.handleChange}
          validationResult={props.validationResults.actualLiberationDate}
          formFieldWidth={8}
          mode={props.mode}
          required={
            props.selectedProgrammeData.journeyStatus ===
            strings.fieldValues.onProgrammeCommunity
          }
        />
      </Form.Group>
    </div>
  </div>
);

LiberationFields.propTypes = {
  formInvalid: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  mode: PropTypes.string.isRequired,
  selectedProgrammeData: PropTypes.object.isRequired,
  validationResults: PropTypes.object
};

export default LiberationFields;
