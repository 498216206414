import { API } from "../../services";
import { amendmentActionTypes } from "./amendment.actionTypes";

const api = new API();

const requestDeletePermission = (id, reason, headers, body) => {
  const request = () => {
    return { type: amendmentActionTypes.REQUEST_DELETE_PERMISSION_REQUEST };
  };
  const success = response => {
    return {
      type: amendmentActionTypes.REQUEST_DELETE_PERMISSION_SUCCESS,
      response
    };
  };
  const failure = error => {
    return {
      type: amendmentActionTypes.REQUEST_DELETE_PERMISSION_FAILURE,
      error
    };
  };

  api.setHeaders({
    ...headers,
    systemHeader_subsysref: "requestDeletePermission"
  });

  return dispatch => {
    dispatch(request());
    return new Promise((resolve, reject) => {
      if (id !== "") {
        api.requestDeletePermission(id, reason, body).then(
          data => {
            dispatch(success(data));
            resolve(data);
          },
          error => {
            dispatch(failure(error));
            reject(error);
          }
        );
      }
    });
  };
};

const getDeletePermissionStatus = (id, headers) => {
  const request = () => {
    return { type: amendmentActionTypes.GET_DELETE_PERMISSION_STATUS_REQUEST };
  };
  const success = response => {
    return {
      type: amendmentActionTypes.GET_DELETE_PERMISSION_STATUS_SUCCESS,
      response
    };
  };
  const failure = error => {
    return {
      type: amendmentActionTypes.GET_DELETE_PERMISSION_STATUS_FAILURE,
      error
    };
  };

  api.setHeaders({
    ...headers,
    systemHeader_subsysref: "getDeletePermissionStatus"
  });

  return dispatch => {
    dispatch(request());
    return new Promise((resolve, reject) => {
      api.getDeletePermissionStatus(id).then(
        response => {
          dispatch(success(response.data));
          resolve(response.data);
        },
        error => {
          dispatch(failure(error));
          reject(error);
        }
      );
    });
  };
};

const setDeletePermissionStatusActioned = (id, headers) => {
  const request = () => {
    return {
      type: amendmentActionTypes.SET_DELETE_PERMISSION_STATUS_ACTIVE_REQUEST
    };
  };
  const success = response => {
    return {
      type: amendmentActionTypes.SET_DELETE_PERMISSION_STATUS_ACTIVE_SUCCESS,
      response
    };
  };
  const failure = error => {
    return {
      type: amendmentActionTypes.SET_DELETE_PERMISSION_STATUS_ACTIVE_FAILURE,
      error
    };
  };

  api.setHeaders({
    ...headers,
    systemHeader_subsysref: "setDeletePermissionStatusActive"
  });

  return dispatch => {
    dispatch(request());
    return new Promise((resolve, reject) => {
      api.setDeletePermissionStatusActioned(id).then(
        response => {
          dispatch(success(response.data));
          resolve(response.data);
        },
        error => {
          dispatch(failure(error));
          reject(error);
        }
      );
    });
  };
};

const updateDeletePermissionStatus = (id, approvalStatus, headers) => {
  const request = () => {
    return {
      type: amendmentActionTypes.UPDATE_DELETE_PERMISSION_STATUS_REQUEST
    };
  };
  const success = response => {
    return {
      type: amendmentActionTypes.UPDATE_DELETE_PERMISSION_STATUS_SUCCESS,
      response
    };
  };
  const failure = error => {
    return {
      type: amendmentActionTypes.UPDATE_DELETE_PERMISSION_STATUS_FAILURE,
      error
    };
  };

  api.setHeaders({
    ...headers,
    systemHeader_subsysref: "updateDeletePermissionStatus"
  });

  return dispatch => {
    dispatch(request());
    return new Promise((resolve, reject) => {
      api.updateDeletePermissionStatus(id, approvalStatus).then(
        response => {
          dispatch(success(response.data));
          resolve(response.data);
        },
        error => {
          dispatch(failure(error));
          reject(error);
        }
      );
    });
  };
};

export const amendmentActions = {
  requestDeletePermission,
  getDeletePermissionStatus,
  setDeletePermissionStatusActioned,
  updateDeletePermissionStatus
};
