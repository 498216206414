export const convertToIso = date => {
  let regExp = new RegExp(
    "^([0-2][0-9]|(3)[0-1])(/)(((0)[0-9])|((1)[0-2]))(/)\\d{4}$"
  );
  if (regExp.test(date)) {
    const dateAsList = date.split("/");
    return `${dateAsList[2]}-${dateAsList[1]}-${dateAsList[0]}T12:00:00.000Z`;
  }
  return "";
};

export const convertFromIso = date => {
  const dateAsList = date.split("T")[0].split("-");
  return `${dateAsList[2]}/${dateAsList[1]}/${dateAsList[0]}`;
};

export const checkFormat = date => {
  let regExp = new RegExp(
    "^((0[1-9]|[12][0-9]|3[01])[/.](0[13578]|1[02])[/.](18|19|20)[0-9]{2})|((0[1-9]|[12][0-9]|30)[/.](0[469]|11)[/.](18|19|20)[0-9]{2})|((0[1-9]|1[0-9]|2[0-8])[/.](02)[/.](18|19|20)[0-9]{2})|(29[/.](02)[/.](((18|19|20)(04|08|[2468][048]|[13579][26]))|2000))$"
  );
  return regExp.test(date);
};
