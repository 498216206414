import { API } from "../../services";
import { referralActionTypes } from "./referral.actionTypes";

const api = new API();

const createReferral = (details, headers) => {
  const request = details => {
    return { type: referralActionTypes.CREATE_REFERRAL_REQUEST, details };
  };
  const success = response => {
    return { type: referralActionTypes.CREATE_REFERRAL_SUCCESS, response };
  };
  const failure = error => {
    return { type: referralActionTypes.CREATE_REFERRAL_FAILURE, error };
  };

  api.setHeaders({ ...headers, systemHeader_subsysref: "createReferral" });

  return dispatch => {
    dispatch(request(details));

    api.createReferral(details).then(
      response => dispatch(success(response)),
      error => dispatch(failure(error))
    );
  };
};

const clearReferralRequest = () => {
  const request = () => {
    return { type: referralActionTypes.CLEAR_CREATE_REFERRAL_REQUEST };
  };

  const success = () => {
    return { type: referralActionTypes.CLEAR_CREATE_REFERRAL_SUCCESS };
  };

  return dispatch => {
    dispatch(request());
    dispatch(success());
  };
};

const cancelSelectedReferralUpdate = initialReferralData => {
  const request = () => {
    return {
      type: referralActionTypes.CANCEL_SELECTED_REFERRAL_UPDATE_REQUEST
    };
  };
  const success = data => {
    return {
      type: referralActionTypes.CANCEL_SELECTED_REFERRAL_UPDATE_SUCCESS,
      data
    };
  };

  return dispatch => {
    dispatch(request());
    dispatch(success(initialReferralData));
  };
};

const updateReferral = (
  id,
  details,
  headers,
  initialDetails,
  showNotification = true
) => {
  const request = details => {
    return { type: referralActionTypes.UPDATE_REFERRAL_REQUEST, details };
  };
  const success = response => {
    return {
      type: referralActionTypes.UPDATE_REFERRAL_SUCCESS,
      response,
      showNotification
    };
  };
  const failure = error => {
    return {
      type: referralActionTypes.UPDATE_REFERRAL_FAILURE,
      error,
      initialDetails
    };
  };
  api.setHeaders({ ...headers, systemHeader_subsysref: "updateReferral" });

  return dispatch => {
    dispatch(request(details));

    api.updateReferral(id, details).then(
      response => {
        dispatch(success(response));
      },
      error => dispatch(failure(error, initialDetails))
    );
  };
};

const deleteReferral = (id, headers) => {
  const request = () => {
    return { type: referralActionTypes.DELETE_REFERRAL_REQUEST };
  };
  const success = response => {
    return { type: referralActionTypes.DELETE_REFERRAL_SUCCESS, response };
  };
  const failure = error => {
    return { type: referralActionTypes.DELETE_REFERRAL_FAILURE, error };
  };

  api.setHeaders({ ...headers, systemHeader_subsysref: "deleteReferral" });

  return dispatch => {
    dispatch(request());
    return new Promise((resolve, reject) => {
      if (id !== "") {
        api.deleteReferral(id).then(
          data => {
            dispatch(success(data));
            resolve(data);
          },
          error => {
            dispatch(failure(error));
            reject(error);
          }
        );
      }
    });
  };
};

const getReferrals = (
  headers,
  queryString,
  size,
  from,
  clear,
  fields = ["*"],
  sort = {},
  activeOnly
) => {
  const request = () => {
    return { type: referralActionTypes.GET_REFERRALS_REQUEST };
  };
  const success = response => {
    return { type: referralActionTypes.GET_REFERRALS_SUCCESS, response };
  };
  const failure = error => {
    return { type: referralActionTypes.GET_REFERRALS_FAILURE, error };
  };

  api.setHeaders({ ...headers, systemHeader_subsysref: "getReferrals" });

  return dispatch => {
    dispatch(request());
    return new Promise((resolve, reject) => {
      if (clear) {
        // If supposed to clear, replicate blank result
        dispatch(success({ hits: { hits: [] } }));
        resolve();
      } else {
        api
          .getReferrals(queryString, size, from, fields, sort, activeOnly)
          .then(
            response => {
              dispatch(success(response));
              resolve(response);
            },
            error => {
              dispatch(failure(error));
              reject(error);
            }
          );
      }
    });
  };
};

const getReferralById = (id, headers) => {
  const request = () => {
    return { type: referralActionTypes.GET_REFERRAL_BY_ID_REQUEST };
  };
  const success = response => {
    return { type: referralActionTypes.GET_REFERRAL_BY_ID_SUCCESS, response };
  };
  const failure = error => {
    return { type: referralActionTypes.GET_REFERRAL_BY_ID_FAILURE, error };
  };

  api.setHeaders({ ...headers, systemHeader_subsysref: "getReferralById" });

  return dispatch => {
    dispatch(request());
    return new Promise((resolve, reject) => {
      api.getReferralById(id).then(
        response => {
          dispatch(success(response.data));
          resolve(response.data);
        },
        error => {
          dispatch(failure(error));
          reject(error);
        }
      );
    });
  };
};

const updateSearchCriteria = (
  headers,
  queryString,
  size,
  from,
  clear,
  fields,
  sort,
  activeOnly
) => {
  const request = () => {
    return { type: referralActionTypes.UPDATE_SEARCH_CRITERIA_REQUEST };
  };
  const success = response => {
    return {
      type: referralActionTypes.UPDATE_SEARCH_CRITERIA_SUCCESS,
      response
    };
  };

  const newCriteria = {
    query: queryString,
    size: size,
    from: from,
    clear,
    fields: fields,
    sort: sort,
    activeOnly: activeOnly
  };
  return dispatch => {
    dispatch(request());
    dispatch(success(newCriteria));
    dispatch(
      getReferrals(
        headers,
        queryString,
        size,
        from,
        clear,
        fields,
        sort,
        activeOnly
      )
    );
  };
};

const updateSelectedReferral = (key, value) => {
  const request = (key, value) => {
    return {
      type: referralActionTypes.UPDATE_SELECTED_REFERRAL_REQUEST,
      key,
      value
    };
  };
  const success = (key, value) => {
    return {
      type: referralActionTypes.UPDATE_SELECTED_REFERRAL_SUCCESS,
      key,
      value
    };
  };
  const failure = () => {
    return { type: referralActionTypes.UPDATE_SELECTED_REFERRAL_FAILURE };
  };

  return dispatch => {
    dispatch(request(key, value));
    return new Promise((resolve, reject) => {
      if (key) {
        dispatch(success(key, value));
        resolve();
      } else {
        dispatch(failure());
        reject();
      }
    })
  };
};

const clearSelectedReferral = () => {
  const request = () => {
    return { type: referralActionTypes.CLEAR_SELECTED_REFERRAL_REQUEST };
  };
  const success = () => {
    return { type: referralActionTypes.CLEAR_SELECTED_REFERRAL_SUCCESS };
  };

  return dispatch => {
    dispatch(request());
    dispatch(success());
  };
};

const getReferralStats = query => {
  const request = query => {
    return { type: referralActionTypes.GET_REFERRAL_STATS_REQUEST, query };
  };

  const success = response => {
    return { type: referralActionTypes.GET_REFERRAL_STATS_SUCCESS, response };
  };

  const failure = error => {
    return { type: referralActionTypes.GET_REFERRAL_STATS_FAILURE, error };
  };

  return dispatch => {
    dispatch(request());
    return new Promise((resolve, reject) => {
      api.getReferralStats(query).then(
        response => {
          dispatch(success(response));
          resolve(response);
        },
        error => {
          dispatch(failure(error));
          reject(error);
        }
      );
    });
  };
};

export const referralActions = {
  createReferral,
  updateReferral,
  deleteReferral,
  getReferralById,
  getReferrals,
  updateSearchCriteria,
  updateSelectedReferral,
  clearSelectedReferral,
  clearReferralRequest,
  cancelSelectedReferralUpdate,
  getReferralStats
};
