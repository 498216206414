import React from "react";
import { Button, Icon, Modal } from "semantic-ui-react";
import PropTypes from "prop-types";

class TextInputModal extends React.Component {
  handleClose = () => {
    this.props.toggleModal();
  };

  handleSubmit = () => {
    this.props.toggleModal();
    this.props.handleModalConfirm(this.props.modalClassName);
  };

  render = () => {
    return (
      <Modal
        className={this.props.modalClassName}
        open={this.props.modalOpen}
        onClose={this.handleClose}
        closeIcon
      >
        <Modal.Content className="modalContent">
          <h3>{this.props.confirmMessage}</h3>
          {this.props.textInput}
        </Modal.Content>

        <Modal.Actions>
          <Button
            basic
            color="green"
            onClick={this.handleSubmit}
            style={{ display: this.props.showOkButton ? "" : "none" }}
            disabled={!this.props.deletionReason}
          >
            <Icon name="checkmark" /> {this.props.okLabel}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  };
}

TextInputModal.propTypes = {
  confirmMessage: PropTypes.string.isRequired,
  handleModalConfirm: PropTypes.func.isRequired,
  modalClassName: PropTypes.string.isRequired,
  modalOpen: PropTypes.bool.isRequired,
  showOkButton: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  okLabel: PropTypes.string.isRequired
};

export default TextInputModal;
