import React from "react";
import ModifyActivity from "../../components/ModifyActivity";
import { Loader } from "semantic-ui-react";
import { connect } from "react-redux";
import { strings, groupedTypes } from "../../resources";
import { activityActions } from "../../store/actions/activity.actions";
import { engagementActions } from "../../../Engagements/store/actions/engagement.actions";
import PropTypes from "prop-types";
import {
  functions as twgValidationFunctions,
  constraints as twgConstraints
} from "pulsion-twg-validation";
import { organisationActions } from "../../../Organisations/store/actions/organisation.actions";
import { referralActions } from "../../../Referrals/store/actions/referral.actions";
import { programmeActions } from "../../../Programmes/store/actions/programme.actions";
import {
  getOrganisations,
  convertToIso,
  canUserModifyRecord,
  isEmpty
} from "../../helpers";

class ActivityCreateContainer extends React.Component {
  state = {
    confirmSaveModalOpen: false,
    cancelChangesModalOpen: false,
    // Required to remove error since enable/disable still present even
    // in create mode, just not visisble.
    enableDisableActivityModalOpen: false,
    deleteModalOpen: false,
    formInvalid: false,
    validationResults: {},
    searchCriteria: {
      from: 0,
      query: "",
      fields: ["*"],
      clear: false,
      sort: {},
      activeOnly: false
    },
    categoryDeletionId: undefined,
    activeCategories: [],
    selectedOutcomes: {},
    outcomesList: [],
    defaultOutcomes: [],
    supportServiceSelectionRequired: false,
    canAddOutcomes: false,
    isPrePopulated: false,
    addNew: false
  };

  componentDidMount = async () => {
    const sCrit = this.state.searchCriteria;
    this.setState({ referralId: localStorage.getItem("referralId") }, () => {
      if (this.state.referralId) {
        this.props.getReferralById(this.state.referralId, this.props.headers);
      }
    });
    this.setState(
      { globalProgrammeId: localStorage.getItem("programmeId") },
      () => {
        if (this.state.globalProgrammeId) {
          this.props.getProgrammeById(
            this.state.globalProgrammeId,
            this.props.headers
          );
        }
      }
    );

    this.initialiseCategoryTable();
    this.props.getOrganisations(
      this.props.headers,
      sCrit.query,
      10000,
      0,
      sCrit.clear,
      sCrit.fields,
      sCrit.sort,
      sCrit.activeOnly
    );
    let form = document.getElementById("activityForm");
    if (form) {
      form.setAttribute("novalidate", true);
      //set the form to have the novalidate attribute to suppress the default html validation tooltips
    }
    this.props.updateSelectedActivity(
      "engagementId",
      this.props.match.params.engagementId
    );
    this.props.getEngagementById(
      this.props.match.params.engagementId,
      this.props.headers
    );
  };
  componentDidUpdate = () => {
    if (
      Object.keys(this.props.selectedEngagementData).length > 0 &&
      !this.state.isPrePopulated
    ) {
      if (this.props.selectedEngagementData.owner) {
        this.props.updateSelectedActivity(
          "owner",
          this.props.selectedEngagementData.owner
        );
      }
      if (this.props.selectedEngagementData.engagementId) {
        this.props.updateSelectedActivity(
          "engagementId",
          this.props.selectedEngagementData.engagementId
        );
      }
      if (this.props.selectedEngagementData.crmId) {
        this.props.updateSelectedActivity(
          "engagementCrmId",
          this.props.selectedEngagementData.crmId
        );
      }
      if (this.props.selectedEngagementData.area) {
        this.props.updateSelectedActivity(
          "area",
          this.props.selectedEngagementData.area
        );
      }
      if (this.props.selectedEngagementData.programmeId) {
        this.props.updateSelectedActivity(
          "programmeId",
          this.props.selectedEngagementData.programmeId
        );
      }
      if (this.props.selectedEngagementData.referralId) {
        this.props.updateSelectedActivity(
          "referralId",
          this.props.selectedEngagementData.referralId
        );
      }
      if (this.props.selectedEngagementData.firstName) {
        this.props.updateSelectedActivity(
          "firstName",
          this.props.selectedEngagementData.firstName
        );
      }
      if (this.props.selectedEngagementData.surname) {
        this.props.updateSelectedActivity(
          "surname",
          this.props.selectedEngagementData.surname
        );
      }
      if (this.props.selectedEngagementData.pNumber) {
        this.props.updateSelectedActivity(
          "pNumber",
          this.props.selectedEngagementData.pNumber
        );
      }
      if (this.props.selectedEngagementData.scroNumber) {
        this.props.updateSelectedActivity(
          "scroNumber",
          this.props.selectedEngagementData.scroNumber
        );
      }
      this.handleFetchData();
      this.setState({ isPrePopulated: true });
    }
  };

  componentWillUnmount() {
    this.props.clearSelectedActivity();
    this.props.clearSelectedEngagement();
  }

  validateFormAndAddNew = async () => {
    this.setState({ addNew: true });
    this.validateForm();
  };

  validateForm = async () => {
    let details = JSON.parse(JSON.stringify(this.props.selectedActivityData));
    for (let property in details) {
      if (!details[property]) {
        delete details[property];
      }
    }
    let results = twgValidationFunctions.validateData(
      details,
      twgConstraints.activities.modifyActivity
    );
    if (results) {
      this.setState({
        validationResults: results,
        formInvalid: true
      });
    } else {
      this.setState({ formInvalid: false });
      this.toggleConfirmSaveModalVisibility();
    }
  };

  handleFetchData = async () => {
    let engagementSearchCriteria = JSON.parse(
      JSON.stringify(this.state.searchCriteria)
    );
    engagementSearchCriteria.programmeId = this.props.selectedEngagementData.programmeId;
    engagementSearchCriteria.referralId = this.props.selectedEngagementData.referralId;
    this.props.getEngagements(
      this.props.headers,
      engagementSearchCriteria.query,
      10000,
      0,
      engagementSearchCriteria.clear,
      engagementSearchCriteria.fields,
      engagementSearchCriteria.sort,
      engagementSearchCriteria.activeOnly,
      engagementSearchCriteria.programmeId,
      engagementSearchCriteria.referralId
    );
  };

  handleSubmit = async () => {
    await this.props.createActivity(
      this.props.selectedActivityData,
      this.props.headers
    );
    if (this.state.addNew) {
      window.location.reload();
    } else if (!this.props.selectedEngagementData.programmeId) {
      this.props.history.push(
        "/referrals/view/" + this.props.selectedEngagementData.referralId
      );
    } else {
      this.props.history.push("/activities");
    }
  };

  handleCancel = () => {
    this.setState({ formInvalid: false });
    this.props.clearActivityRequest();
    this.props.history.goBack();
  };

  handleChange = async (event, data) => {
    if (data.name === "supportServiceEngaged") {
      if (data.value === strings.fieldValues.no) {
        await this.props.updateSelectedActivity("supportService", "");
      }
      this.setState({ canAddOutcomes: true });
      this.initialiseCategoryTable();
    }
    if (data.value.match(/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/)) {
      await this.props.updateSelectedActivity(
        data.name,
        convertToIso(data.value)
      );
    } else {
      await this.props.updateSelectedActivity(data.name, data.value);
    }
    if (data.name === "type") {
      this.setState({ supportServiceSelectionRequired: true });
      this.getCategoriesFromType(data.options, data.value);
      this.initialiseCategoryTable();
      if (groupedTypes.includes(data.value)) {
        await this.props.getActivityTypeCount(
          groupedTypes,
          this.props.engagementListData,
          this.props.headers
        );
        if (this.props.typeCount >= 3) {
          let newOutcomes = {
            "Attitudes & Behaviours": [["S1"], ["M1"]]
          };

          this.setState({ selectedOutcomes: newOutcomes });
        }
      }
    }
  };

  initialiseCategoryTable = async () => {
    let categories = [];
    categories.push({
      key: 0,
      category: "",
      outcomesAchieved: [],
      outcomesWorkedTowards: []
    });
    await this.props.updateSelectedActivity("categories", categories);
  };

  addCategory = async e => {
    let categories = this.props.selectedActivityData.categories || [];
    categories.push({
      key: categories.length,
      category: "",
      outcomesAchieved: [],
      outcomesWorkedTowards: []
    });
    await this.props.updateSelectedActivity("categories", categories);
  };

  removeCategory = async () => {
    let categories = this.props.selectedActivityData.categories;
    if (categories.length > 1) {
      categories.splice(this.state.categoryDeletionId, 1);
    }
    await this.props.updateSelectedActivity(
      this.getKeyByValue(
        this.props.selectedActivityData,
        this.props.selectedActivityData.categories
      ),
      categories
    );
  };

  handleModalConfirm = modalClassName => {
    switch (modalClassName) {
      case "confirmSaveModal":
        this.handleSubmit();
        break;
      case "cancelChangesModal":
        this.handleCancel();
        break;
      case "deletionCategoryModal":
        this.removeCategory(this.state.categoryDeletionId);
        break;
      default:
        break;
    }
  };

  getCategoriesFromType = (options, type) => {
    options.forEach(option => {
      if (option.value === type) {
        this.props.updateSelectedActivity(
          "supportServiceRequired",
          option.supportservicerequired ? true : false
        );
        this.setState({ selectedOutcomes: option.outcomes });
        const dropdownOptions = [];
        Object.keys(option.outcomes).forEach((text, index) => {
          dropdownOptions[index] = { key: index, text: text, value: text };
        });
        this.setState({ activeCategories: dropdownOptions });
      }
    });
  };

  getOutcomesFromCategory = async (value, index) => {
    let outcomesList = this.state.outcomesList;
    if (this.state.selectedOutcomes[value]) {
      outcomesList[index] = this.state.selectedOutcomes[value][0].concat(
        this.state.selectedOutcomes[value][1]
      );
      this.setState({ outcomesList: outcomesList });
    }
  };

  handleCategoryChange = async (e, data) => {
    let categories = this.props.selectedActivityData.categories;
    const result = data.name.split("-");
    if (result[0] === "category") {
      this.getOutcomesFromCategory(data.value, result[1]);
      categories[result[1]]["outcomesWorkedTowards"] = [];
      categories[result[1]]["outcomesAchieved"] = [];
      if (this.state.selectedOutcomes[data.value]) {
        this.state.selectedOutcomes[data.value][0].forEach(outcome => {
          categories[result[1]]["outcomesAchieved"].push(outcome);
        });
        this.setState({
          defaultOutcomes: this.state.selectedOutcomes[data.value][0]
        });
      }
    }
    if (data.type === "checkbox") {
      if (data.checked) {
        categories[result[1]][result[0]].push(data.label);
      } else {
        categories[result[1]][result[0]] = categories[result[1]][
          result[0]
        ].filter(category => category !== data.label);
      }
    } else {
      categories[result[1]][result[0]] = data.value;
    }
    await this.props.updateSelectedActivity(
      this.getKeyByValue(
        this.props.selectedActivityData,
        this.props.selectedActivityData.categories
      ),
      categories
    );
  };

  getKeyByValue = (object, value) => {
    return Object.keys(object).find(key => object[key] === value);
  };

  toggleConfirmSaveModalVisibility = () => {
    this.setState({
      confirmSaveModalOpen: !this.state.confirmSaveModalOpen
    });
  };

  toggleCancelChangesModalVisibility = () => {
    this.setState({
      cancelChangesModalOpen: !this.state.cancelChangesModalOpen
    });
  };

  toggleEnableDisableActivityModalVisibility = () => {
    this.setState({
      enableDisableActivityModalOpen: !this.state.enableDisableActivityModalOpen
    });
  };

  toggleConfirmDeleteModalVisibility = idx => {
    this.setState({
      deleteModalOpen: !this.state.deleteModalOpen,
      categoryDeletionId: idx
    });
  };

  getOrganisations = () => {
    return getOrganisations(this.props.organisationListData);
  };

  isDisabled = () => {
    return !canUserModifyRecord(
      !isEmpty(this.props.selectedReferralData)
        ? this.props.selectedReferralData
        : this.props.selectedProgrammeData,
      this.props.organisationOfLoggedInUser
    );
  };

  render = () => {
    const loading =
      this.props.loadingPage ||
      this.props.orgsLoadingPage ||
      this.props.engagementLoadingPage ||
      this.props.programmeLoadingPage ||
      this.props.referralLoadingPage;

    return (
      <div>
        <div
          style={{
            display: loading ? "" : "none"
          }}
        >
          <Loader active>{strings.header.loading}</Loader>
        </div>
        <div
          style={{
            display: loading ? "none" : ""
          }}
        >
          <ModifyActivity
            mode="create"
            role={this.props.roleId}
            pageTitle={strings.header.createActivity}
            selectedActivityData={this.props.selectedActivityData}
            confirmSaveModalOpen={this.state.confirmSaveModalOpen}
            cancelChangesModalOpen={this.state.cancelChangesModalOpen}
            deleteModalOpen={this.state.deleteModalOpen}
            enableDisableActivityModalOpen={
              this.state.enableDisableActivityModalOpen
            }
            toggleConfirmSaveModalVisibility={
              this.toggleConfirmSaveModalVisibility
            }
            toggleCancelChangesModalVisibility={
              this.toggleCancelChangesModalVisibility
            }
            toggleEnableDisableActivityModalVisibility={
              this.toggleEnableDisableActivityModalVisibility
            }
            toggleDeleteModal={this.toggleConfirmDeleteModalVisibility}
            handleChange={this.handleChange}
            handleModalConfirm={this.handleModalConfirm}
            formInvalid={this.state.formInvalid}
            validationResults={this.state.validationResults}
            validateForm={this.validateForm}
            initialActivityData={{}}
            addCategory={this.addCategory}
            removeCategory={this.addCategory}
            handleCategoryChange={this.handleCategoryChange}
            organisationList={getOrganisations(this.props.organisationListData)}
            getOrganisations={this.getOrganisations}
            activeCategories={this.state.activeCategories}
            outcomesList={this.state.outcomesList}
            defaultOutcomes={this.state.defaultOutcomes}
            supportServiceSelectionRequired={
              this.state.supportServiceSelectionRequired
            }
            canAddOutcomes={this.state.canAddOutcomes}
            validateFormAndAddNew={this.validateFormAndAddNew}
            isDisabled={this.isDisabled()}
          />
        </div>
      </div>
    );
  };
}

ActivityCreateContainer.propTypes = {
  headers: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  loadingPage: PropTypes.bool.isRequired,
  selectedActivityData: PropTypes.object.isRequired,
  updateSelectedActivity: PropTypes.func.isRequired,
  clearSelectedActivity: PropTypes.func.isRequired,
  createActivity: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const {
    activities,
    auth,
    organisations,
    engagements,
    programmes,
    referrals
  } = state;
  const { loadingPage, selectedActivityData, typeCount } = activities;
  const { roleId, organisationOfLoggedInUser } = auth;
  const { loadingPage: orgsLoadingPage, organisationListData } = organisations;
  const {
    selectedEngagementData,
    engagementListData,
    loadingPage: engagementLoadingPage
  } = engagements;
  const {
    selectedProgrammeData,
    loadingPage: programmeLoadingPage
  } = programmes;
  const { selectedReferralData, loadingPage: referralLoadingPage } = referrals;
  return {
    loadingPage,
    selectedActivityData,
    roleId,
    orgsLoadingPage,
    organisationListData,
    organisationOfLoggedInUser,
    selectedEngagementData,
    engagementListData,
    selectedProgrammeData,
    selectedReferralData,
    engagementLoadingPage,
    referralLoadingPage,
    programmeLoadingPage,
    typeCount
  };
};

const mapDispatchToProps = dispatch => {
  return {
    createActivity: async (data, headers) => {
      await dispatch(activityActions.createActivity(data, headers));
    },
    updateSelectedActivity: (key, value) => {
      dispatch(activityActions.updateSelectedActivity(key, value));
    },
    clearSelectedActivity: () => {
      dispatch(activityActions.clearSelectedActivity());
    },
    clearActivityRequest: () => {
      dispatch(activityActions.clearActivityRequest());
    },
    getOrganisations: (
      headers,
      queryString,
      size,
      from,
      clear,
      fields,
      sort,
      activeOnly
    ) => {
      dispatch(
        organisationActions.getOrganisations(
          headers,
          queryString,
          size,
          from,
          clear,
          fields,
          sort,
          activeOnly
        )
      );
    },
    getEngagements: (
      headers,
      queryString,
      size,
      from,
      clear,
      fields,
      sort,
      activeOnly,
      programmeId,
      referralId
    ) => {
      dispatch(
        engagementActions.getEngagements(
          headers,
          queryString,
          size,
          from,
          clear,
          fields,
          sort,
          activeOnly,
          programmeId,
          referralId
        )
      );
    },
    getEngagementById: (id, headers) => {
      dispatch(engagementActions.getEngagementById(id, headers));
    },
    clearSelectedEngagement: () => {
      dispatch(engagementActions.clearSelectedEngagement());
    },
    getReferralById: (id, headers) => {
      dispatch(referralActions.getReferralById(id, headers));
    },
    getProgrammeById: async (id, headers) => {
      await dispatch(programmeActions.getProgrammeById(id, headers));
    },
    getActivityTypeCount: async (groupedTypes, engagementList, headers) => {
      await dispatch(
        activityActions.getActivityTypeCount(
          groupedTypes,
          engagementList,
          headers
        )
      );
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ActivityCreateContainer);
