import React from "react";
import { Button, Grid, Message } from "semantic-ui-react";
import { strings } from "../resources";
import PropTypes from "prop-types";
import { ShowIfAuthorised } from ".";
import { listObjectValues } from "../helpers/objectHelpers";

class ComponentHeader extends React.Component {
  render = () => {
    const validationMessage = (
      <Message negative className="ValidationMessage">
        <h4>{strings.error.validationMessage}</h4>
        {this.props.validationResults &&
          listObjectValues(this.props.validationResults).map((result, idx) => {
            return <li key={idx}>{result}</li>;
          })}
      </Message>
    );
    let buttonClassName = "redirectButton";
    if (
      this.props.returnToList ||
      this.props.returnToParent ||
      Array.isArray(this.props.parentPage)
    ) {
      buttonClassName += " redirectButtonUnderline";
    }

    return (
      <>
        <Grid
          columns={3}
          stackable
          className={`ComponentHeader ${
            this.props.sticky ? "stickyHeader" : ""
          }`}
        >
          <Grid.Column>
            <div className="pageHeader">
              {this.props.parentPage &&
                (typeof this.props.parentPage === "string" ? (
                  <>
                    <Button
                      className={buttonClassName}
                      onClick={this.props.returnToParent}
                    >
                      {this.props.parentPage}
                    </Button>
                    <div className="headingDivider">/</div>
                  </>
                ) : (
                  this.props.parentPage.map((page, key) => (
                    <React.Fragment key={key}>
                      <Button
                        className={
                          page.className ? page.className : buttonClassName
                        }
                        onClick={page.returnToParent}
                      >
                        {page.parentPage}
                      </Button>
                      <div className="headingDivider">/</div>
                    </React.Fragment>
                  ))
                ))}
              {this.props.headerList && (
                <>
                  <Button
                    className={buttonClassName}
                    onClick={this.props.returnToList}
                  >
                    {this.props.headerList}
                  </Button>
                  <div className="headingDivider">/</div>
                </>
              )}
              {this.props.pageTitle}
              {this.props.journeyCount &&
                strings.header.journey + this.props.journeyCount}
            </div>
          </Grid.Column>
          <Grid.Column></Grid.Column>
          <Grid.Column className="FormButtons">
            <ShowIfAuthorised allowedRoles={this.props.permissionsUpdate}>
              {this.props.mode === "view" && this.props.additionalButtons}
              {this.props.mode === "view" &&
                this.props.requestStatus &&
                this.props.modifyButtons}
              {this.props.mode === "edit" && this.props.cancelSaveButtons}
            </ShowIfAuthorised>

            <ShowIfAuthorised allowedRoles={this.props.permissionsCreate}>
              {this.props.mode === "create" && this.props.cancelSaveButtons}
            </ShowIfAuthorised>
          </Grid.Column>
        </Grid>
        <div>{this.props.formInvalid && validationMessage}</div>
      </>
    );
  };
}

ComponentHeader.propTypes = {
  returnToParent: PropTypes.func,
  parentPage: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  returnToList: PropTypes.func,
  pageTitle: PropTypes.string.isRequired,
  mode: PropTypes.string.isRequired,
  requestStatus: PropTypes.bool,
  modifyButtons: PropTypes.object.isRequired,
  cancelSaveButtons: PropTypes.object.isRequired,
  headerList: PropTypes.string,
  permissionsUpdate: PropTypes.array.isRequired,
  permissionsCreate: PropTypes.array.isRequired,
  journeyCount: PropTypes.number,
  additionalButtons: PropTypes.object
};

export default ComponentHeader;
