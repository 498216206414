import React from "react";
import ModifyProgramme from "../../components/ModifyProgramme";
import { Loader, Divider } from "semantic-ui-react";
import { connect } from "react-redux";
import { strings } from "../../resources";
import { programmeActions } from "../../store/actions/programme.actions";
import { amendmentActions } from "../../../Amendments/store/actions/amendment.actions";
import PropTypes from "prop-types";
import { RequestFeedback } from "../../components";
import {
  getOrganisations,
  convertToIso,
  getListOfMentors,
  canUserModifyRecord,
  arrayHelpers
} from "../../helpers/index";
import {
  functions as twgValidationFunctions,
  constraints as twgConstraints
} from "pulsion-twg-validation";
import { referralActions } from "../../../Referrals/store/actions/referral.actions";
import { userActions } from "../../../Users/store/actions/user.actions";
import { organisationActions } from "../../../Organisations/store/actions/organisation.actions";

class ProgrammeEditContainer extends React.Component {
  state = {
    mode: "view",
    confirmSaveModalOpen: false,
    cancelChangesModalOpen: false,
    shareProgrammeModalOpen: false,
    enableDisableProgrammeModalOpen: false,
    initialProgrammeData: {},
    formInvalid: false,
    validationResults: {},
    unknownRequestStatus: false,
    searchCriteria: {
      size: 10000,
      from: 0,
      query: "",
      fields: ["*"],
      clear: false,
      sort: {},
      activeOnly: false
    },
    hasChoseForm: false,
    shouldSaveFiles: false,
    hasFileChanges: false,
    deletionReason: undefined,
    permissionStatus: ""
  };

  chooseForm = async () => {
    this.setState({ hasChoseForm: true });
  };

  componentDidMount = async () => {
    await this.props.getReferralStats({ stat: "communityMentor" });
    await this.props.getProgrammeById(
      this.props.match.params.id,
      this.props.headers
    );
    await this.handleFetchData();
    let form = document.getElementById("programmeForm");
    if (form) form.setAttribute("novalidate", true);

    if (this.props.selectedProgrammeData.communityMentor) {
      await this.props.getUserById(
        this.props.selectedProgrammeData.communityMentor
      );
    }
    if (this.props.selectedProgrammeData.prisonSpecialist) {
      await this.props.getPrisonMentorById(
        this.props.selectedProgrammeData.prisonSpecialist
      );
    }
    if (this.props.selectedProgrammeData.mentor) {
      await this.props.getUserById(this.props.selectedProgrammeData.mentor);
    }

    this.setState({
      initialProgrammeData: this.props.selectedProgrammeData
    });
    //set the form to have the novalidate attribute to suppress the default html validation tooltips

    if (
      this.state.mode === "view" &&
      this.props.selectedProgrammeData.requestId
    ) {
      await this.props.getDeletePermissionStatus(
        this.props.selectedProgrammeData.requestId
      );
      await this.setState(
        { permissionStatus: this.props.permissionStatus },
        async () => {
          if (this.state.permissionStatus === strings.states.rejected) {
            await this.props.updateSelectedProgramme("requestId", null);
            this.props.updateProgramme(
              this.props.selectedProgrammeData.programmeId,
              this.props.selectedProgrammeData,
              this.props.headers,
              this.state.initialProgrammeData,
              false
            );
          }
        }
      );
    }
    this.setState({ initialProgrammeData: this.props.selectedProgrammeData });
  };

  handleCancel = () => {
    this.setState({ formInvalid: false });
    this.props.history.goBack();
  };

  componentWillUnmount() {
    this.props.clearProgrammeRequest();
    this.props.clearSelectedProgramme();
    this.props.clearSelectedUser();
    this.props.clearPrisonMentor();
  }

  handleFetchData = async page => {
    const sCrit = this.state.searchCriteria;
    await this.props.getUsers(
      this.props.headers,
      sCrit.query,
      10000,
      0,
      sCrit.clear,
      sCrit.fields,
      sCrit.sort,
      true
    );
    await this.props.getOrganisations(
      this.props.headers,
      sCrit.query,
      10000,
      0,
      sCrit.clear,
      sCrit.fields,
      sCrit.sort,
      sCrit.activeOnly
    );
  };

  validateForm = async () => {
    let details = JSON.parse(JSON.stringify(this.props.selectedProgrammeData));
    for (let property in details) {
      if (!details[property]) {
        delete details[property];
      }
    }

    let results = twgValidationFunctions.validateData(
      details,
      twgConstraints.programmes.modifyProgramme
    );
    if (results) {
      this.setState({
        validationResults: results,
        formInvalid: true
      });
    } else {
      this.setState({ formInvalid: false });
      this.toggleConfirmSaveModalVisibility();
    }
  };

  allowNumericalInput = e => {
    e = e || window.event;
    const charCode = typeof e.which == "undefined" ? e.keyCode : e.which;
    const charStr = String.fromCharCode(charCode);

    if (!charStr.match(/^[0-9]+$/)) e.preventDefault();
  };

  handleRequestDeletePermission = async () => {
    await this.props.requestDeletePermission(
      this.props.selectedProgrammeData.programmeId,
      this.state.deletionReason.value,
      this.props.headers,
      this.props.selectedProgrammeData
    );
    this.props.updateSelectedProgramme("requestId", this.props.requestId);
    this.props.updateProgramme(
      this.props.selectedProgrammeData.programmeId,
      this.props.selectedProgrammeData,
      this.props.headers,
      this.state.initialProgrammeData
    );
    this.setState({
      initialProgrammeData: this.props.selectedProgrammeData
    });
  };

  handleChangeModal = (e, value) => {
    this.setState({ deletionReason: value });
  };

  handleDelete = async () => {
    await this.props.deleteProgramme(
      this.props.selectedProgrammeData.programmeId,
      this.props.headers
    );
    if (this.props.deleteSuccessful) {
      this.props.setDeletePermissionStatusActioned(
        this.props.selectedProgrammeData.requestId
      );
    }
  };

  handleSubmit = async () => {
    this.setState({ shouldSaveFiles: true });
    await this.props.updateProgramme(
      this.props.match.params.id,
      this.props.selectedProgrammeData,
      this.props.headers,
      this.state.initialProgrammeData
    );
    this.handleModeSwitch();
  };

  resetDataOnModalClose = async () => {
    await this.props.updateSelectedProgramme(
      "sharedWith",
      this.state.initialProgrammeData.sharedWith
    );
  };

  handleShare = async () => {
    let body = { sharedWith: this.props.selectedProgrammeData.sharedWith };

    await this.props.updateProgramme(
      this.props.match.params.id,
      body,
      this.props.headers,
      this.state.initialProgrammeData
    );
    this.setState({
      initialProgrammeData: this.props.selectedProgrammeData
    });
  };

  handleChange = (event, data) => {
    if (data.name === "wantsCollected") {
      this.props.updateSelectedProgramme("alternativeArrangements", "");
    } else if (data.name === "alternativeArrangements") {
      this.props.updateSelectedProgramme("whoMetAtGate", "");
    } else if (data.name === "exitReason") {
      this.props.updateSelectedProgramme("exitReasonOther", "");
    } else if (data.name === "journeyStatus") {
      this.props.updateSelectedProgramme("actualLiberationDate", "");
    }
    if (data.type === "checkbox") {
      this.props.updateSelectedProgramme(data.name, data.checked);
    } else if (
      typeof data.value === "string" &&
      data.value.match(/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/)
    ) {
      this.props.updateSelectedProgramme(data.name, convertToIso(data.value));
    } else {
      this.props.updateSelectedProgramme(data.name, data.value);
    }
  };

  resetForm = async () => {
    this.setState({ hasChoseForm: false });
    await this.props.updateSelectedProgramme("engagementType", undefined);
  };

  handleEnableDisable = async () => {
    const body = {
      active: !this.props.selectedProgrammeData.active
    };

    await this.props.updateProgramme(
      this.props.match.params.id,
      body,
      this.props.headers,
      this.state.initialProgrammeData
    );
  };

  handleModalConfirm = modalClassName => {
    switch (modalClassName) {
      case "confirmSaveModal":
        this.handleSubmit();
        break;
      case "cancelChangesModal":
        this.handleCancel();
        break;
      case "shareModal":
        this.handleShare();
        break;
      case "activationModal":
        this.handleEnableDisable();
        break;
      case "requestDeletePermissionModal":
        this.handleRequestDeletePermission();
        break;
      case "deleteModal":
        this.handleDelete();
        break;

      default:
        break;
    }
  };

  toggleConfirmSaveModalVisibility = () => {
    this.setState({
      confirmSaveModalOpen: !this.state.confirmSaveModalOpen
    });
  };

  toggleCancelChangesModalVisibility = () => {
    this.setState({
      cancelChangesModalOpen: !this.state.cancelChangesModalOpen
    });
  };

  toggleShareProgrammeModalVisibility = () => {
    this.setState({
      shareProgrammeModalOpen: !this.state.shareProgrammeModalOpen
    });
  };

  toggleEnableDisableProgrammeModalVisibility = () => {
    this.setState({
      enableDisableProgrammeModalOpen: !this.state
        .enableDisableProgrammeModalOpen
    });
  };

  handleModeSwitch = async () => {
    if (this.state.mode === "edit") {
      if (
        JSON.stringify(this.state.initialProgrammeData) !==
        JSON.stringify(this.props.selectedProgrammeData)
      ) {
        await this.props.cancelSelectedProgrammeUpdate(
          this.state.initialProgrammeData
        );
      }
      this.setState({ mode: "view", formInvalid: false });
    } else {
      this.setState({
        mode: "edit",
        initialProgrammeData: JSON.parse(
          JSON.stringify(this.props.selectedProgrammeData)
        )
      });
    }
  };

  filesHaveChanged = () => {
    this.setState({ hasFileChanges: true });
  };

  getMentors = (area, organisation) => {
    let mentors = [];
    mentors = getListOfMentors(
      area,
      this.props.userListData,
      organisation,
      this.props.refStats,
      this.props.selectedUserData,
      this.props.prisonMentor
    );

    return arrayHelpers.removeDuplicates(mentors, mentor => mentor.text);
  };

  getMentorsAll = area => {
    return getListOfMentors(
      area,
      this.props.userListData,
      undefined,
      undefined,
      this.props.selectedUserData,
      this.props.prisonMentor
    );
  };

  getOrganisations = () => {
    return getOrganisations(this.props.organisationListData);
  };

  isDisabled = () => {
    return !canUserModifyRecord(
      this.state.initialProgrammeData,
      this.props.organisationOfLoggedInUser
    );
  };

  render = () => {
    const loading =
      this.props.loadingPage ||
      this.props.orgsLoadingPage ||
      this.props.usersLoadingPage;

    return (
      <div>
        <div style={{ display: loading ? "" : "none" }}>
          <Loader active>{strings.header.loading}</Loader>
        </div>
        <div style={{ display: loading ? "none" : "" }}>
          {this.props.showNotification && (
            <RequestFeedback
              requestStatus={this.props.programmeRequestStatus}
              requestMade={this.props.programmeRequestMade}
              unknownRequestStatus={this.state.unknownRequestStatus}
              successMessage={this.props.result}
              failureMessage={this.props.error}
              processingFeedbackMessage={strings.form.feedback.processing}
              unknownFeedbackMessage={
                strings.form.feedback.programmeRequestUnknown
              }
              statusFeedbackMessage={strings.form.feedback.status}
              successFeedbackMessage={strings.form.feedback.success}
              errorDetails={this.props.errorDetails}
            />
          )}
          <Divider hidden />
          <ModifyProgramme
            requestStatus={this.props.programmeRequestStatus}
            mode={this.state.mode}
            pageTitle={
              this.state.mode === "edit"
                ? strings.header.editProgramme
                : strings.header.viewProgramme
            }
            toggleCancelChangesModalVisibility={
              this.toggleCancelChangesModalVisibility
            }
            toggleConfirmSaveModalVisibility={
              this.toggleConfirmSaveModalVisibility
            }
            toggleShareProgrammeModalVisibility={
              this.toggleShareProgrammeModalVisibility
            }
            toggleEnableDisableProgrammeModalVisibility={
              this.toggleEnableDisableProgrammeModalVisibility
            }
            selectedProgrammeData={this.props.selectedProgrammeData}
            handleChange={this.handleChange}
            handleModeSwitch={this.handleModeSwitch}
            handleModalConfirm={this.handleModalConfirm}
            cancelChangesModalOpen={this.state.cancelChangesModalOpen}
            confirmSaveModalOpen={this.state.confirmSaveModalOpen}
            shareProgrammeModalOpen={this.state.shareProgrammeModalOpen}
            enableDisableProgrammeModalOpen={
              this.state.enableDisableProgrammeModalOpen
            }
            initialProgrammeData={this.state.initialProgrammeData}
            formInvalid={this.state.formInvalid}
            allowNumericalInput={this.allowNumericalInput}
            validateForm={this.validateForm}
            validationResults={this.state.validationResults}
            getOrganisations={this.getOrganisations}
            getMentors={this.getMentors}
            getMentorsAll={this.getMentorsAll}
            area={this.props.selectedProgrammeData.area}
            userId={this.props.userId}
            roleId={this.props.roleId}
            chooseForm={this.chooseForm}
            hasChoseForm={this.state.hasChoseForm}
            headers={this.props.headers}
            history={this.props.history}
            resetForm={this.resetForm}
            shouldSaveFiles={this.state.shouldSaveFiles}
            filesHaveChanged={this.filesHaveChanged}
            hasFileChanges={this.state.hasFileChanges}
            deletePermissionStatus={this.state.permissionStatus}
            handleChangeModal={this.handleChangeModal}
            deletionReason={this.state.deletionReason}
            deleteSuccessful={this.props.deleteSuccessful}
            orgOfLoggedInUser={this.props.organisationOfLoggedInUser}
            resetDataOnModalClose={this.resetDataOnModalClose}
            isDisabled={this.isDisabled()}
          />
        </div>
      </div>
    );
  };
}

ProgrammeEditContainer.propTypes = {
  match: PropTypes.object.isRequired,
  headers: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  loadingPage: PropTypes.bool.isRequired,
  selectedProgrammeData: PropTypes.object.isRequired,
  updateSelectedProgramme: PropTypes.func.isRequired,
  updateProgramme: PropTypes.func.isRequired,
  getProgrammeById: PropTypes.func.isRequired,
  errorDetails: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

const mapStateToProps = state => {
  const { programmes, amendments, organisations, users, referrals } = state;
  const { roleId, organisationOfLoggedInUser } = state.auth;
  const { organisationListData, loadingPage: orgsLoadingPage } = organisations;
  const {
    userListData,
    loadingPage: usersLoadingPage,
    selectedUserData,
    prisonMentor
  } = users;
  const { refStats } = referrals;
  const {
    loadingPage,
    selectedProgrammeData,
    programmeRequestStatus,
    programmeRequestMade,
    result,
    error,
    errorDetails,
    deleteSuccessful,
    showNotification
  } = programmes;
  const { requestId, permissionStatus } = amendments;
  return {
    loadingPage,
    selectedProgrammeData,
    programmeRequestStatus,
    programmeRequestMade,
    result,
    error,
    errorDetails,
    organisationListData,
    userListData,
    roleId,
    requestId,
    permissionStatus,
    deleteSuccessful,
    showNotification,
    organisationOfLoggedInUser,
    refStats,
    usersLoadingPage,
    orgsLoadingPage,
    selectedUserData,
    prisonMentor
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateProgramme: (
      id,
      data,
      headers,
      initialProgrammeData,
      showNotification = true
    ) => {
      dispatch(
        programmeActions.updateProgramme(
          id,
          data,
          headers,
          initialProgrammeData,
          showNotification
        )
      );
    },
    getProgrammeById: async (id, headers) => {
      await dispatch(programmeActions.getProgrammeById(id, headers));
    },
    updateSelectedProgramme: (key, value) => {
      dispatch(programmeActions.updateSelectedProgramme(key, value));
    },
    cancelSelectedProgrammeUpdate: data => {
      dispatch(programmeActions.cancelSelectedProgrammeUpdate(data));
    },
    clearProgrammeRequest: () => {
      dispatch(programmeActions.clearProgrammeRequest());
    },
    clearSelectedProgramme: () => {
      dispatch(programmeActions.clearSelectedProgramme());
    },
    clearSelectedUser: () => {
      dispatch(userActions.clearSelectedUser());
    },
    clearPrisonMentor: () => {
      dispatch(userActions.clearPrisonMentor());
    },
    getUsers: async (
      headers,
      queryString,
      size,
      from,
      clear,
      fields,
      sort,
      activeOnly
    ) => {
      await dispatch(
        userActions.getUsers(
          headers,
          queryString,
          size,
          from,
          clear,
          fields,
          sort,
          activeOnly
        )
      );
    },
    getOrganisations: async (
      headers,
      queryString,
      size,
      from,
      clear,
      fields,
      sort,
      activeOnly
    ) => {
      await dispatch(
        organisationActions.getOrganisations(
          headers,
          queryString,
          size,
          from,
          clear,
          fields,
          sort,
          activeOnly
        )
      );
    },
    requestDeletePermission: async (id, reason, headers, body) => {
      await dispatch(
        amendmentActions.requestDeletePermission(id, reason, headers, body)
      );
    },
    getDeletePermissionStatus: async (id, headers) => {
      await dispatch(amendmentActions.getDeletePermissionStatus(id, headers));
    },
    deleteProgramme: async (id, headers) => {
      await dispatch(programmeActions.deleteProgramme(id, headers));
    },
    setDeletePermissionStatusActioned: (id, headers) => {
      dispatch(amendmentActions.setDeletePermissionStatusActioned(id, headers));
    },
    getReferralStats: query => {
      dispatch(referralActions.getReferralStats(query));
    },
    getUserById: id => {
      dispatch(userActions.getUserById(id));
    },
    getPrisonMentorById: id => {
      dispatch(userActions.getPrisonMentorById(id));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProgrammeEditContainer);
