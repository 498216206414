import React from "react";
import moment from "moment";
import { Loader, Divider } from "semantic-ui-react";
import { connect } from "react-redux";

import { strings } from "../../resources";
import { referralActions } from "../../store/actions/referral.actions";
import { customerActions } from "../../../Customers/store/actions/customer.actions";
import { amendmentActions } from "../../../Amendments/store/actions/amendment.actions";
import { taskActions } from "../../../Tasks/store/actions/task.actions";
import { userActions } from "../../../Users/store/actions/user.actions";
import { organisationActions } from "../../../Organisations/store/actions/organisation.actions";
import { addressActions } from "../../../App/store/actions/address.actions";
import { programmeActions } from "../../../Programmes/store/actions/programme.actions";
import ModifyReferral from "../../components/ModifyReferral";
import PropTypes from "prop-types";
import { RequestFeedback } from "../../components";
import {
  getListOfMentors,
  getListOfPrisonSpecialists,
  getListOfCommunityMentors,
  getOrganisations,
  convertToIso,
  allowNumericalInput,
  canUserModifyRecord,
  arrayHelpers
} from "../../helpers/index";
import { isEmpty } from "../../../App/helpers/objectHelpers";
import {
  functions as twgValidationFunctions,
  constraints as twgConstraints
} from "pulsion-twg-validation";

class ReferralEditContainer extends React.Component {
  state = {
    mode: "view",
    confirmSaveModalOpen: false,
    cancelChangesModalOpen: false,
    shareReferralModalOpen: false,
    enableDisableReferralModalOpen: false,
    confirmDuplicateModalOpen: false,
    initialReferralData: {},
    formInvalid: false,
    validationResults: {},
    unknownRequestStatus: false,
    searchCriteria: {
      size: 10000,
      from: 0,
      query: "",
      fields: ["*"],
      clear: false,
      sort: {},
      activeOnly: false
    },
    closeAfterSave: false,
    hasFileChanges: false,
    shouldSaveFiles: false,
    hasReferralData: false,
    approvedToDelete: false,
    deletionReason: undefined,
    fetchedPermissionStatus: false,
    postcodeInputValues: {
      postcode: "",
      libPostcode: "",
      mailingPostcode: "",
      additionalPostcode: ""
    },
    postcodeOpenStates: {
      postcode: false,
      libPostcode: false,
      mailingPostcode: false,
      additionalPostcode: false
    },
    libAddressSameChecked: false,
    mailingAddressSameChecked: false,
    permissionStatus: "",
    linkedProgramme: "",
    organisations: [],
    mentorsArea: [],
    mentorsPrisonSpecialistView: [],
    mentorsPrisonSpecialistSelect: [],
    mentorsCommunitySpecialistSelect: [],
    taskResult: "",
    loadingCustomers: false
  };

  componentDidMount = async () => {
    await this.props.getReferralStats({ stat: "communityMentor" });
    await this.props.getReferralById(
      this.props.match.params.id,
      this.props.headers
    );
    if (this.props.selectedReferralData.communityMentor) {
      await this.props.getUserById(
        this.props.selectedReferralData.communityMentor
      );
    }
    if (this.props.selectedReferralData.prisonSpecialist) {
      await this.props.getPrisonMentorById(
        this.props.selectedReferralData.prisonSpecialist
      );
    }
    if (this.props.selectedReferralData.mentor) {
      await this.props.getUserById(this.props.selectedReferralData.mentor);
    }

    await this.handleFetchData();
    this.setState({
      linkedProgramme: this.getlinkedProgramme()
    });
    if (
      this.state.mode === "view" &&
      this.props.selectedReferralData.requestId
    ) {
      await this.props.getDeletePermissionStatus(
        this.props.selectedReferralData.requestId
      );
      await this.setState(
        { permissionStatus: this.props.permissionStatus },
        async () => {
          if (this.state.permissionStatus === strings.states.rejected) {
            await this.props.updateSelectedReferral("requestId", null);
            this.props.updateReferral(
              this.props.selectedReferralData.referralId,
              this.props.selectedReferralData,
              this.props.headers,
              this.state.initialReferralData,
              false
            );
          }
        }
      );
    }

    this.setState({
      fetchedPermissionStatus: true,
      initialReferralData: this.props.selectedReferralData
    });
    let form = document.getElementById("referralForm");
    if (form) {
      form.setAttribute("novalidate", true);
      //set the form to have the novalidate attribute to suppress the default html validation tooltips
    }

    if (!this.props.selectedReferralData.prisonSpecialist) {
      this.props.updateSelectedReferral(
        "prisonSpecialist",
        this.getPrisonSpecialist()
      );
    }
  };

  componentDidUpdate = () => {
    if (
      !isEmpty(this.props.selectedReferralData) &&
      isEmpty(this.state.initialReferralData)
    ) {
      this.setState({ initialReferralData: this.props.selectedReferralData });
    }
  };

  componentWillUnmount() {
    this.props.clearReferralRequest();
    this.props.clearSelectedReferral();
    this.props.clearSelectedUser();
    this.props.clearPrisonMentor();
  }

  handleRequestDeletePermission = async () => {
    await this.props.requestDeletePermission(
      this.props.selectedReferralData.referralId,
      this.state.deletionReason.value,
      this.props.headers,
      this.props.selectedReferralData
    );
    this.props.updateSelectedReferral("requestId", this.props.requestId);
    this.props.updateReferral(
      this.props.selectedReferralData.referralId,
      this.props.selectedReferralData,
      this.props.headers,
      this.state.initialReferralData
    );
    this.setState({
      initialReferralData: this.props.selectedReferralData
    });
  };

  handleModalInputChange = (e, value) => {
    this.setState({ deletionReason: value });
    this.setState({ initialReferralData: this.props.selectedReferralData });
  };

  handlePostcodeChanged = async (e, data, fieldName) => {
    this.setState({
      postcodeInputValues: {
        ...this.state.postcodeInputValues,
        [data.name]: data.searchQuery
      }
    });
  };

  handlePostcodeSearch = async (e, click) => {
    await this.props.findAddress(
      this.state.postcodeInputValues[click.name],
      click.name
    );
    this.setState({
      postcodeOpenStates: {
        ...this.state.postcodeOpenStates,
        [click.name]: this.props.addressSearchResults[click.name].length > 0
      }
    });
    this.setState({
      postcodeOpenStates: {
        ...this.state.postcodeOpenStates,
        [click.name]: undefined
      }
    });
  };

  handlePostcodeClicked = async (event, click) => {
    let selectedResult = click.options[click.value];
    this.setState({
      postcodeOpenStates: {
        ...this.state.postcodeOpenStates,
        [click.name]: false
      }
    });
    if (selectedResult) await this.props.retrieveAddress(selectedResult.id);
    // AWAIT and update referral
    let data = {};
    switch (click.name) {
      case "postcode":
        data = {
          postcode: selectedResult
            ? this.props.formAddressResults.postcode
            : "",
          townOrCity: selectedResult ? this.props.formAddressResults.city : "",
          address1: selectedResult
            ? this.props.formAddressResults.addressLine1
            : "",
          address2: selectedResult
            ? this.props.formAddressResults.addressLine2
            : ""
        };
        break;
      case "libPostcode":
        data = {
          libPostcode: selectedResult
            ? this.props.formAddressResults.postcode
            : "",
          libTown: selectedResult ? this.props.formAddressResults.city : "",
          libAddress1: selectedResult
            ? this.props.formAddressResults.addressLine1
            : "",
          libAddress2: selectedResult
            ? this.props.formAddressResults.addressLine2
            : ""
        };
        break;
      case "mailingPostcode":
        data = {
          mailingPostcode: selectedResult
            ? this.props.formAddressResults.postcode
            : "",
          mailingTown: selectedResult ? this.props.formAddressResults.city : "",
          mailingAddress1: selectedResult
            ? this.props.formAddressResults.addressLine1
            : "",
          mailingAddress2: selectedResult
            ? this.props.formAddressResults.addressLine2
            : ""
        };
        break;
      case "additionalPostcode":
        data = {
          additionalPostcode: selectedResult
            ? this.props.formAddressResults.postcode
            : "",
          additionalTown: selectedResult
            ? this.props.formAddressResults.city
            : "",
          additionalAddress1: selectedResult
            ? this.props.formAddressResults.addressLine1
            : "",
          additionalAddress2: selectedResult
            ? this.props.formAddressResults.addressLine2
            : ""
        };
        break;
      default:
        return;
    }
    for (const key in data) {
      this.props.updateSelectedReferral(
        key,

        data[key]
      );
    }
  };

  handleFetchData = async page => {
    const sCrit = this.state.searchCriteria;
    await this.props.getUsers(
      this.props.headers,
      sCrit.query,
      10000,
      0,
      sCrit.clear,
      sCrit.fields,
      sCrit.sort,
      true
    );
    await this.props.getOrganisations(
      this.props.headers,
      sCrit.query,
      10000,
      0,
      sCrit.clear,
      sCrit.fields,
      sCrit.sort,
      sCrit.activeOnly
    );
    await this.props.getProgrammes(
      this.props.headers,
      this.props.selectedReferralData.referralId,
      10000,
      0,
      sCrit.clear,
      ["referralId.normalizer"],
      sCrit.sort,
      sCrit.activeOnly
    );
    this.setState({
      hasReferralData: true,
      mentorsArea: this.getMentors(this.props.selectedReferralData.area),
      mentorsPrisonSpecialistView: this.getMentors(
        this.props.selectedReferralData.area,
        this.props.selectedReferralData.prisonOrganisation
      ),
      mentorsPrisonSpecialistSelect: this.getMentors(
        this.props.selectedReferralData.area,
        this.props.selectedReferralData.prisonOrganisation,
        "prisonSpecialist",
        this.props.selectedReferralData.prisonNrs,
        null
      ),
      mentorsCommunitySpecialistSelect: this.getMentors(
        this.props.selectedReferralData.area,
        this.props.communityOrganisation,
        "communityMentor",
        null,
        this.props.selectedReferralData.localAuthorityArea
      ),
      organisations: getOrganisations(this.props.organisationListData)
    });
  };

  getInitialOwner = () => {
    let matchedOrg = undefined;
    this.props.organisationListData.forEach(organisation => {
      if (organisation._id === this.props.organisationOfLoggedInUser) {
        matchedOrg = organisation._id;
      }
    });
    this.props.updateSelectedReferral("owner", matchedOrg);
    return matchedOrg;
  };

  validateForm = async () => {
    if (!this.props.selectedReferralData.formType) {
      await this.props.updateSelectedReferral(
        "formType",
        strings.fieldValues.initialStage
      );
    }
    let constraints = {};
    switch (this.props.selectedReferralData.formType) {
      case strings.fieldValues.referral:
        constraints = twgConstraints.referrals.modifyReferral;
        break;
      case strings.fieldValues.equalOpportunities:
        constraints = {};
        break;
      case strings.fieldValues.supportService:
        constraints = twgConstraints.referrals.supportService;
        break;
      case strings.fieldValues.initialStage:
        constraints = twgConstraints.referrals.referralIdentification;
        break;
      default:
        constraints = twgConstraints.referrals.referralIdentification;
        break;
    }
    if (
      this.props.selectedReferralData.prisonSpecialist === undefined &&
      this.props.selectedReferralData.area === strings.areas.NRS
    ) {
      this.props.updateSelectedReferral(
        "prisonSpecialist",
        this.getPrisonSpecialist()
      );
    }
    let details = JSON.parse(JSON.stringify(this.props.selectedReferralData));
    for (let property in details) {
      if (!details[property]) {
        delete details[property];
      }
    }

    let results = twgValidationFunctions.validateData(details, constraints);

    if (results) {
      this.setState({
        validationResults: results,
        formInvalid: true
      });
    } else {
      if (
        this.props.selectedReferralData.area === strings.areas.NRNE &&
        !this.state.initialReferralData.consent
      ) {
        this.setState({ loadingCustomers: true });
        await this.props.getCustomerByPNumber(
          this.props.headers,
          this.props.selectedReferralData.pNumber
        );
        this.setState({ loadingCustomers: false });
        if (Object.keys(this.props.customerData).length > 0) {
          this.toggleConfirmDuplicateModalVisibility();
        } else {
          this.setState({ formInvalid: false });
          this.toggleConfirmSaveModalVisibility();
        }
      } else {
        this.setState({ formInvalid: false });
        this.toggleConfirmSaveModalVisibility();
      }
    }
  };

  validateFormAndClose = async () => {
    this.setState({ closeAfterSave: true });
    this.validateForm();
  };

  handleAreaChange = async (event, data) => {
    await this.props.updateSelectedReferral(data.name, data.value);
    this.setState({ selectedArea: data.value });
  };

  handleSubmit = async () => {
    const sCrit = this.state.searchCriteria;
    if (
      this.props.selectedReferralData.returningCustomer ===
      strings.fieldValues.newJourney
    ) {
      sCrit.fields = ["pNumber.normalizer"];
      sCrit.query = this.props.selectedReferralData.pNumber;
      await this.props.getReferrals(
        this.props.headers,
        sCrit.query,
        10000,
        0,
        sCrit.clear,
        sCrit.fields,
        sCrit.sort,
        sCrit.activeOnly
      );
      let numberOfJournies = this.props.referralListData.length;
      this.props.updateSelectedReferral(
        "returningCustomer",
        strings.fieldValues.yes
      );
      this.props.updateSelectedReferral("journeyCount", numberOfJournies);
      let taskDetails = this.createTaskDetails(this.props.selectedReferralData);
      this.props.createTask(
        taskDetails,
        this.props.headers,
        "Request for duplicate creation submitted"
      );
      this.setState({ taskResult: "Request for duplicate creation submitted" });
      if (this.props.referralListData[0]._id) {
        this.props.history.push("/referrals/");
      }
    } else {
      this.setState({ shouldSaveFiles: true });
      await this.props.updateReferral(
        this.props.match.params.id,
        this.props.selectedReferralData,
        this.props.headers,
        this.state.initialReferralData
      );
    }
    if (this.state.closeAfterSave) {
      this.props.history.push("/referrals");
    } else {
      this.handleModeSwitch();
    }
  };

  createTaskDetails = data => {
    let assignedSpecialistIds = [];
    this.props.userListData.forEach(user => {
      if (
        user._source.roleId === strings.fieldValues.teamLead &&
        (user._source.area === data.area ||
          user._source.area === strings.areas.both)
      ) {
        assignedSpecialistIds.push(user._id);
      }
    });
    let returnData = {};
    returnData.assignedSpecialistIds = assignedSpecialistIds;
    returnData.type = "duplicateWarning";
    returnData.customerForename = data.firstName;
    returnData.customerSurname = data.surname;
    returnData.prisonNumber = data.pNumber;
    returnData.dueDate = moment().add(2, "w").toISOString();
    returnData.details = data;
    returnData.gcrId = this.props.customerData[0].gcrId;
    return returnData;
  };

  handleCancel = () => {
    this.setState({ formInvalid: false });
    this.props.history.goBack();
  };

  handleDelete = async () => {
    await this.props.deleteReferral(
      this.props.selectedReferralData.referralId,
      this.props.headers
    );
    if (this.props.deleteSuccessful) {
      this.props.setDeletePermissionStatusActioned(
        this.props.selectedReferralData.requestId
      );
    }
  };

  filesHaveChanged = () => {
    this.setState({ hasFileChanges: true });
  };

  handleChange = (event, data) => {
    if (data.type === "checkbox") {
      this.props.updateSelectedReferral(data.name, data.checked);
    } else if (
      typeof data.value === "string" &&
      data.value.match(/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/)
    ) {
      this.props.updateSelectedReferral(data.name, convertToIso(data.value));
    } else {
      this.props.updateSelectedReferral(data.name, data.value);
    }
  };

  handleChangeLiberation = async (event, data) => {
    await this.props.updateSelectedReferral(data.name, data.value);
    let newCommunity = this.getCommunityOrg(data.value);
    await this.props.updateSelectedReferral(
      "communityOrganisation",
      newCommunity
    );
    this.props.updateSelectedReferral(
      "communityMentor",
      this.getCommunityMentor(
        getListOfCommunityMentors(
          this.props.selectedReferralData.area,
          this.props.userListData,
          newCommunity,
          this.props.selectedReferralData.liberationLocalArea,
          this.props.refStats
        )
      )
    );
    this.setState({
      mentorsCommunitySpecialistSelect: this.getMentors(
        this.props.selectedReferralData.area,
        newCommunity,
        "communityMentor",
        null,
        data.value
      )
    });
  };

  handleChangeCommunity = async (event, data) => {
    await this.props.updateSelectedReferral(data.name, data.value);
    this.props.updateSelectedReferral(
      "communityMentor",
      this.getCommunityMentor(
        getListOfCommunityMentors(
          this.props.selectedReferralData.area,
          this.props.userListData,
          data.value,
          this.props.selectedReferralData.liberationLocalArea
        )
      )
    );
    this.setState({
      mentorsCommunitySpecialistSelect: this.getMentors(
        this.props.selectedReferralData.area,
        data.value,
        "communityMentor",
        null,
        this.props.selectedReferralData.liberationLocalArea
      )
    });
  };

  handleChangePrisonNrs = async (event, data) => {
    await this.props.updateSelectedReferral(data.name, data.value);
    await this.props.updateSelectedReferral(
      "prisonOrganisation",
      this.getPrisonOrg(data.value)
    );
    this.props.updateSelectedReferral(
      "prisonSpecialist",
      this.getPrisonSpecialist()
    );
    this.setState({
      mentorsPrisonSpecialistView: this.getMentors(
        this.props.selectedReferralData.area,
        this.props.selectedReferralData.prisonOrganisation
      ),
      mentorsPrisonSpecialistSelect: this.getMentors(
        this.props.selectedReferralData.area,
        this.props.selectedReferralData.prisonOrganisation,
        "prisonSpecialist",
        data.value,
        null
      )
    });
  };

  handleChangePrisonOrg = (event, data) => {
    this.props.updateSelectedReferral(data.name, data.value);
    this.props.updateSelectedReferral(
      "prisonSpecialist",
      this.getPrisonSpecialist()
    );
    this.setState({
      mentorsPrisonSpecialistView: this.getMentors(
        this.props.selectedReferralData.area,
        data.value
      ),
      mentorsPrisonSpecialistSelect: this.getMentors(
        this.props.selectedReferralData.area,
        data.value,
        "prisonSpecialist",
        this.props.selectedReferralData.prisonNrs,
        null
      )
    });
  };

  handleChangeDoNotApproach = (event, data) => {
    this.props.updateSelectedReferral(data.name, data.value);
    this.props.updateSelectedReferral("whoNotApproach", "");
  };

  handleChangeOffendingHistory = (event, data) => {
    this.props.updateSelectedReferral(data.name, data.value);
    this.props.updateSelectedReferral("riskFactors", "");
  };

  handleChangeOutcomeOfThreshold = (event, data) => {
    this.props.updateSelectedReferral(data.name, data.value);
    this.props.updateSelectedReferral("outcomeReason", "");
  };

  handleChangeOutcomeReason = (event, data) => {
    this.props.updateSelectedReferral(data.name, data.value);
    this.props.updateSelectedReferral("otherOutcomeReason", "");
  };

  handleChangePrisonNrne = (event, data) => {
    this.props.updateSelectedReferral(data.name, data.value);
    this.props.updateSelectedReferral("prisonNrneOther", "");
  };

  handleChangeAddress1 = (event, data) => {
    this.props.updateSelectedReferral(data.name, data.value);
    if (this.state.libAddressSameChecked) {
      this.props.updateSelectedReferral("libAddress1", data.value);
    }
    if (this.state.mailingAddressSameChecked) {
      this.props.updateSelectedReferral("mailingAddress1", data.value);
    }
  };

  handleChangeAddress2 = (event, data) => {
    this.props.updateSelectedReferral(data.name, data.value);
    if (this.state.libAddressSameChecked) {
      this.props.updateSelectedReferral("libAddress2", data.value);
    }
    if (this.state.mailingAddressSameChecked) {
      this.props.updateSelectedReferral("mailingAddress2", data.value);
    }
  };

  handleChangeTownOrCity = (event, data) => {
    this.props.updateSelectedReferral(data.name, data.value);
    if (this.state.libAddressSameChecked) {
      this.props.updateSelectedReferral("libTown", data.value);
    }
    if (this.state.mailingAddressSameChecked) {
      this.props.updateSelectedReferral("mailingTown", data.value);
    }
  };

  handleChangePostcode = (event, data) => {
    this.props.updateSelectedReferral(data.name, data.value);
    if (this.state.libAddressSameChecked) {
      this.props.updateSelectedReferral("libPostcode", data.value);
    }
    if (this.state.mailingAddressSameChecked) {
      this.props.updateSelectedReferral("mailingPostcode", data.value);
    }
  };

  getCommunityMentor = mentors => {
    /*Return mentor with the lowest caseload*/
    let mentorWithLowestCaseload = undefined;
    let teamLeadWithLowestCaseload = undefined;

    if (mentors.length > 0) {
      let lowest = mentors[0];
      mentors.forEach(mentor => {
        if (
          (mentor.caseload < lowest.caseload &&
            mentor.caseload < mentor.caseloadmax &&
            mentor.roleid === strings.fieldValues.mentor) ||
          lowest.roleid === strings.fieldValues.teamLead
        ) {
          lowest = mentor;
        }
      });
      if (lowest.caseload < lowest.caseloadmax) {
        mentorWithLowestCaseload = lowest.value;
      } else {
        mentorWithLowestCaseload = undefined;
      }
    }
    if (!mentorWithLowestCaseload) {
      let lowestTeamLead = mentors[0];
      mentors.forEach(user => {
        if (
          (user.caseload < lowestTeamLead.caseload &&
            user.organisation ===
              this.props.selectedReferralData.communityOrganisation &&
            user.roleid === strings.fieldValues.teamLead) ||
          lowestTeamLead.roleid === strings.fieldValues.mentor
        ) {
          lowestTeamLead = user;
        }
      });
      teamLeadWithLowestCaseload = lowestTeamLead.value;
    }
    if (mentorWithLowestCaseload) {
      return mentorWithLowestCaseload;
    } else {
      return teamLeadWithLowestCaseload;
    }
  };

  handleSameAddressChange = (event, data) => {
    if (data.name === "libAddressSame") {
      this.setState({ libAddressSameChecked: data.checked });
      if (data.checked) {
        this.props.updateSelectedReferral(
          "libAddress1",
          this.props.selectedReferralData.address1
        );
        this.props.updateSelectedReferral(
          "libAddress2",
          this.props.selectedReferralData.address2
        );
        this.props.updateSelectedReferral(
          "libTown",
          this.props.selectedReferralData.townOrCity
        );
        this.props.updateSelectedReferral(
          "libPostcode",
          this.props.selectedReferralData.postcode
        );
      }
    }
    if (data.name === "mailingAddressSame") {
      this.setState({ mailingAddressSameChecked: data.checked });
      if (data.checked) {
        this.props.updateSelectedReferral(
          "mailingAddress1",
          this.props.selectedReferralData.address1
        );
        this.props.updateSelectedReferral(
          "mailingAddress2",
          this.props.selectedReferralData.address2
        );
        this.props.updateSelectedReferral(
          "mailingTown",
          this.props.selectedReferralData.townOrCity
        );
        this.props.updateSelectedReferral(
          "mailingPostcode",
          this.props.selectedReferralData.postcode
        );
      }
    }
  };

  resetDataOnModalClose = async () => {
    await this.props.updateSelectedReferral(
      "sharedWith",
      this.state.initialReferralData.sharedWith
    );
  };

  resetForm = async () => {
    await this.props.updateSelectedReferral("formType", undefined);
    await this.props.updateSelectedReferral("engagementType", undefined);
  };

  handleShare = async () => {
    let body = { sharedWith: this.props.selectedReferralData.sharedWith };
    await this.props.updateReferral(
      this.props.match.params.id,
      body,
      this.props.headers,
      this.state.initialReferralData
    );
    this.setState({
      initialReferralData: this.props.selectedReferralData
    });
  };

  handleModalConfirm = async modalClassName => {
    switch (modalClassName) {
      case "confirmSaveModal":
        this.handleSubmit();
        break;
      case "cancelChangesModal":
        this.handleCancel();
        break;
      case "shareModal":
        this.handleShare();
        break;
      case "requestDeletePermissionModal":
        this.handleRequestDeletePermission();
        break;
      case "deleteModal":
        this.handleDelete();
        break;
      case "confirmDuplicateModal":
        await this.props.updateSelectedReferral(
          "returningCustomer",
          strings.fieldValues.newJourney
        );
        this.handleSubmit();
        break;
      default:
        break;
    }
  };

  toggleConfirmSaveModalVisibility = () => {
    this.setState({
      confirmSaveModalOpen: !this.state.confirmSaveModalOpen
    });
  };

  toggleCancelChangesModalVisibility = () => {
    this.setState({
      cancelChangesModalOpen: !this.state.cancelChangesModalOpen
    });
  };

  toggleConfirmDuplicateModalVisibility = () => {
    this.setState({
      confirmDuplicateModalOpen: !this.state.confirmDuplicateModalOpen
    });
  };

  toggleShareReferralModalVisibility = () => {
    this.setState({
      shareReferralModalOpen: !this.state.shareReferralModalOpen
    });
  };

  toggleEnableDisableReferralModalVisibility = () => {
    this.setState({
      enableDisableReferralModalOpen: !this.state.enableDisableReferralModalOpen
    });
  };

  handleModeSwitch = async () => {
    if (this.state.mode === "edit") {
      if (
        JSON.stringify(this.state.initialReferralData) !==
        JSON.stringify(this.props.selectedReferralData)
      ) {
        await this.props.cancelSelectedReferralUpdate(
          this.state.initialReferralData
        );
      }
      this.setState({ mode: "view", formInvalid: false });
    } else {
      this.setState({
        mode: "edit",
        initialReferralData: this.props.selectedReferralData
      });
    }
  };

  getMentors = (
    area,
    organisation,
    inputName = null,
    prison = null,
    liberationLocalArea = null
  ) => {
    let mentors = [];
    if (inputName === "prisonSpecialist") {
      mentors = getListOfPrisonSpecialists(
        area,
        this.props.userListData,
        organisation,
        prison,
        this.props.prisonMentor
      );
    } else if (inputName === "communityMentor") {
      mentors = getListOfCommunityMentors(
        area,
        this.props.userListData,
        organisation,
        liberationLocalArea,
        this.props.refStats,
        this.props.selectedUserData
      );
    } else {
      mentors = getListOfMentors(
        area,
        this.props.userListData,
        organisation,
        this.props.refStats,
        this.props.selectedUserData,
        this.props.prisonMentor
      );
    }
    return this.filterMentorsOnCaseloads(mentors);
  };

  removeDuplicates = (mentors, key) => {
    return [...new Map(mentors.map(mentor => [key(mentor), mentor])).values()];
  };

  filterMentorsOnCaseloads = mentors => {
    /*Return only mentors that haven't met their max case load*/
    let availableMentors = [];
    let key = mentors.length;
    if (mentors && mentors.length >= 0) {
      mentors.forEach(mentor => {
        if (mentor.caseloadmax > mentor.caseload) {
          availableMentors.push(mentor);
        }
        if (mentor.roleid === strings.fieldValues.teamLead) {
          availableMentors.push(mentor);
        }
      });

      if (
        this.props.selectedUserData &&
        !isEmpty(this.props.selectedUserData)
      ) {
        availableMentors.push({
          key: key,
          text:
            this.props.selectedUserData.firstName +
            " " +
            this.props.selectedUserData.surname,
          value: this.props.selectedUserData.userId,
          roleid: this.props.selectedUserData.roleId,
          caseloadmax: this.props.selectedUserData.caseloadMax,
          organisation: this.props.selectedUserData.organisationId,
          localauthorityarea: this.props.selectedUserData.localAuthorityArea,
          caseload: 0
        });
      }
    }
    let uniqueAvailableMentors = arrayHelpers.removeDuplicates(
      availableMentors,
      mentor => mentor.text
    );

    return uniqueAvailableMentors;
  };

  getCommunityOrg = localAuthority => {
    let organisation;
    this.props.organisationListData.forEach(org => {
      if (
        Array.isArray(org["_source"].localAuthorityArea) &&
        org["_source"].localAuthorityArea.includes(localAuthority) &&
        (org["_source"].mainType === strings.orgTypes.endToEndPartner ||
          org["_source"].mainType === strings.orgTypes.leadPartner)
      ) {
        organisation = org["_source"].organisationId;
      }
    });
    return organisation;
  };

  getPrisonOrg = prison => {
    let organisation;
    this.props.organisationListData.forEach(org => {
      if (
        Array.isArray(org["_source"].prisons) &&
        org["_source"].prisons.includes(prison) &&
        (org["_source"].mainType === strings.orgTypes.leadPartner ||
          org["_source"].mainType === strings.orgTypes.endToEndPartner)
      ) {
        organisation = org["_source"].organisationId;
      }
    });
    return organisation;
  };

  getPrisonSpecialist = () => {
    let matchedMentor = undefined;
    const mentors = this.getMentors(
      this.props.selectedReferralData.area,
      this.props.selectedReferralData.prisonOrganisation,
      "prisonSpecialist",
      this.props.selectedReferralData.prisonNrs
    );

    mentors.forEach(mentor => {
      if (
        mentor.prison &&
        mentor.prison.includes(this.props.selectedReferralData.prisonNrs)
      ) {
        matchedMentor = mentor.value;
      }
    });
    return matchedMentor;
  };

  isDisabled = () => {
    return !canUserModifyRecord(
      this.state.initialReferralData,
      this.props.organisationOfLoggedInUser
    );
  };

  getlinkedProgramme = () => {
    return this.props.programmeListData[0]
      ? this.props.programmeListData[0]["_source"]
      : "";
  };

  render = () => {
    const loading =
      this.props.loadingPage ||
      !this.state.fetchedPermissionStatus ||
      this.props.usersLoadingPage ||
      this.props.orgsLoadingPage ||
      this.state.loadingCustomers;
    return (
      <div>
        <div
          style={{
            display: loading ? "" : "none"
          }}
        >
          <Loader active>{strings.header.loading}</Loader>
        </div>
        <div
          style={{
            display: loading ? "none" : ""
          }}
        >
          {this.state.taskResult && (
            <RequestFeedback
              requestStatus={this.props.taskRequestStatus}
              requestMade={this.props.taskRequestMade}
              unknownRequestStatus={this.state.unknownRequestStatus}
              successMessage={this.state.taskResult}
              failureMessage={this.props.taskError}
              processingFeedbackMessage={strings.feedback.processing}
              unknownFeedbackMessage={strings.feedback.requestUnknown}
              statusFeedbackMessage={strings.feedback.status}
              successFeedbackMessage={strings.feedback.success}
              errorDetails={this.props.taskErrorDetails}
            />
          )}
          {this.props.showNotification && (
            <RequestFeedback
              requestStatus={this.props.referralRequestStatus}
              requestMade={this.props.referralRequestMade}
              unknownRequestStatus={this.state.unknownRequestStatus}
              processingMessage={strings.form.feedback.processing}
              successMessage={this.props.result}
              failureMessage={this.props.error}
              processingFeedbackMessage={strings.form.feedback.processing}
              unknownFeedbackMessage={
                strings.form.feedback.referralRequestUnknown
              }
              statusFeedbackMessage={strings.form.feedback.status}
              successFeedbackMessage={strings.form.feedback.success}
              errorDetails={this.props.errorDetails}
            />
          )}
          <Divider hidden />
          {(this.state.hasReferralData || this.state.mode !== "view") && (
            <ModifyReferral
              requestStatus={this.props.referralRequestStatus}
              mode={this.state.mode}
              pageTitle={
                this.state.mode === "edit"
                  ? strings.header.editReferral
                  : strings.header.viewReferral
              }
              toggleCancelChangesModalVisibility={
                this.toggleCancelChangesModalVisibility
              }
              toggleConfirmSaveModalVisibility={
                this.toggleConfirmSaveModalVisibility
              }
              toggleEnableDisableReferralModalVisibility={
                this.toggleEnableDisableReferralModalVisibility
              }
              toggleShareReferralModalVisibility={
                this.toggleShareReferralModalVisibility
              }
              toggleConfirmDuplicateModalVisibility={
                this.toggleConfirmDuplicateModalVisibility
              }
              selectedReferralData={this.props.selectedReferralData}
              handleChange={this.handleChange}
              handleChangeAddress1={this.handleChangeAddress1}
              handleChangeAddress2={this.handleChangeAddress2}
              handleChangeCommunity={this.handleChangeCommunity}
              handleChangeDoNotApproach={this.handleChangeDoNotApproach}
              handleChangeLiberation={this.handleChangeLiberation}
              handleChangeOffendingHistory={this.handleChangeOffendingHistory}
              handleChangeOutcomeOfThreshold={
                this.handleChangeOutcomeOfThreshold
              }
              handleChangeOutcomeReason={this.handleChangeOutcomeReason}
              handleChangePostcode={this.handleChangePostcode}
              handleChangePrisonNrne={this.handleChangePrisonNrne}
              handleChangePrisonNrs={this.handleChangePrisonNrs}
              handleChangePrisonOrg={this.handleChangePrisonOrg}
              handleChangeTownOrCity={this.handleChangeTownOrCity}
              handleModeSwitch={this.handleModeSwitch}
              handleModalConfirm={this.handleModalConfirm}
              cancelChangesModalOpen={this.state.cancelChangesModalOpen}
              confirmSaveModalOpen={this.state.confirmSaveModalOpen}
              shareReferralModalOpen={this.state.shareReferralModalOpen}
              confirmDuplicateModalOpen={this.state.confirmDuplicateModalOpen}
              enableDisableReferralModalOpen={
                this.state.enableDisableReferralModalOpen
              }
              initialReferralData={this.state.initialReferralData}
              formInvalid={this.state.formInvalid}
              allowNumericalInput={allowNumericalInput}
              validateForm={this.validateForm}
              validateFormAndClose={this.validateFormAndClose}
              validationResults={this.state.validationResults}
              area={this.props.selectedReferralData.area}
              roleId={this.props.roleId}
              getOrganisations={this.state.organisations}
              userId={this.props.userId}
              mentorsArea={this.state.mentorsArea}
              mentorsPrisonSpecialistView={
                this.state.mentorsPrisonSpecialistView
              }
              mentorsPrisonSpecialistSelect={
                this.state.mentorsPrisonSpecialistSelect
              }
              mentorsCommunitySpecialistSelect={
                this.state.mentorsCommunitySpecialistSelect
              }
              handleAreaChange={this.handleChange}
              headers={this.props.headers}
              history={this.props.history}
              resetForm={this.resetForm}
              shouldSaveFiles={this.state.shouldSaveFiles}
              filesHaveChanged={this.filesHaveChanged}
              hasFileChanges={this.state.hasFileChanges}
              deletePermissionStatus={this.state.permissionStatus}
              handleModalInputChange={this.handleModalInputChange}
              deletionReason={this.state.deletionReason}
              deleteSuccessful={this.props.deleteSuccessful}
              orgOfLoggedInUser={this.props.organisationOfLoggedInUser}
              resetDataOnModalClose={this.resetDataOnModalClose}
              canUserModifyRecord={this.canUserModifyRecord}
              handlePostcodeChanged={this.handlePostcodeChanged}
              handlePostcodeClicked={this.handlePostcodeClicked}
              addressSearchResults={this.props.addressSearchResults}
              addressRetrieved={this.props.addressRetrieved}
              handlePostcodeSearch={this.handlePostcodeSearch}
              postcodeOpenStates={this.state.postcodeOpenStates}
              isDisabled={this.isDisabled()}
              handleSameAddressChange={this.handleSameAddressChange}
              libAddressSameChecked={this.state.libAddressSameChecked}
              mailingAddressSameChecked={this.state.mailingAddressSameChecked}
              linkedProgramme={this.state.linkedProgramme}
              getInitialOwner={this.getInitialOwner}
            />
          )}
        </div>
      </div>
    );
  };
}

ReferralEditContainer.propTypes = {
  match: PropTypes.object.isRequired,
  headers: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  loadingPage: PropTypes.bool.isRequired,
  selectedReferralData: PropTypes.object.isRequired,
  updateSelectedReferral: PropTypes.func.isRequired,
  updateReferral: PropTypes.func.isRequired,
  getReferralById: PropTypes.func.isRequired,
  errorDetails: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

const mapStateToProps = state => {
  const {
    referrals,
    amendments,
    address,
    customers,
    tasks,
    programmes
  } = state;
  const { roleId, area, organisationOfLoggedInUser } = state.auth;
  const {
    userListData,
    loadingPage: usersLoadingPage,
    selectedUserData,
    prisonMentor
  } = state.users;
  const { organisationListData, orgsLoadingPage } = state.organisations;
  const { customerData } = customers;
  const {
    loadingPage: taskLoadingPage,
    taskRequestStatus,
    taskRequestMade,
    result: taskResult,
    error: taskError,
    totalResults: taskTotalResults,
    errorDetails: taskErrorDetails
  } = tasks;
  const {
    loadingPage,
    selectedReferralData,
    referralRequestStatus,
    referralRequestMade,
    result,
    error,
    errorDetails,
    referralListData,
    deleteSuccessful,
    showNotification,
    refStats
  } = referrals;
  const { requestId, permissionStatus } = amendments;
  const {
    addressSearchResults,
    formAddressResults,
    addressRetrieved
  } = address;
  const { programmeListData } = programmes;
  return {
    loadingPage,
    selectedReferralData,
    referralRequestStatus,
    referralRequestMade,
    result,
    error,
    roleId,
    area,
    userListData,
    organisationListData,
    errorDetails,
    referralListData,
    requestId,
    permissionStatus,
    deleteSuccessful,
    showNotification,
    organisationOfLoggedInUser,
    addressSearchResults,
    formAddressResults,
    addressRetrieved,
    refStats,
    customerData,
    usersLoadingPage,
    orgsLoadingPage,
    taskLoadingPage,
    taskRequestStatus,
    taskRequestMade,
    taskResult,
    taskError,
    taskTotalResults,
    taskErrorDetails,
    programmeListData,
    selectedUserData,
    prisonMentor
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateReferral: (
      id,
      data,
      headers,
      initialReferralData,
      showNotification = true
    ) => {
      dispatch(
        referralActions.updateReferral(
          id,
          data,
          headers,
          initialReferralData,
          showNotification
        )
      );
    },
    getReferralById: async (id, headers) => {
      await dispatch(referralActions.getReferralById(id, headers));
    },
    updateSelectedReferral: async (key, value) => {
      await dispatch(referralActions.updateSelectedReferral(key, value));
    },
    cancelSelectedReferralUpdate: data => {
      dispatch(referralActions.cancelSelectedReferralUpdate(data));
    },
    clearReferralRequest: () => {
      dispatch(referralActions.clearReferralRequest());
    },
    getUserById: id => {
      dispatch(userActions.getUserById(id));
    },
    getPrisonMentorById: id => {
      dispatch(userActions.getPrisonMentorById(id));
    },
    getUsers: (
      headers,
      queryString,
      size,
      from,
      clear,
      fields,
      sort,
      activeOnly
    ) => {
      dispatch(
        userActions.getUsers(
          headers,
          queryString,
          size,
          from,
          clear,
          fields,
          sort,
          activeOnly
        )
      );
    },
    getOrganisations: async (
      headers,
      queryString,
      size,
      from,
      clear,
      fields,
      sort,
      activeOnly
    ) => {
      await dispatch(
        organisationActions.getOrganisations(
          headers,
          queryString,
          size,
          from,
          clear,
          fields,
          sort,
          activeOnly
        )
      );
    },
    getReferrals: async (
      headers,
      queryString,
      size,
      from,
      clear,
      fields,
      sort,
      activeOnly
    ) => {
      await dispatch(
        referralActions.getReferrals(
          headers,
          queryString,
          size,
          from,
          clear,
          fields,
          sort,
          activeOnly
        )
      );
    },
    getCustomerByPNumber: async (headers, pNumber) => {
      await dispatch(customerActions.getCustomerByPNumber(headers, pNumber));
    },
    getProgrammes: async (
      headers,
      queryString,
      size,
      from,
      clear,
      fields,
      sort,
      activeOnly
    ) => {
      await dispatch(
        programmeActions.getProgrammes(
          headers,
          queryString,
          size,
          from,
          clear,
          fields,
          sort,
          activeOnly
        )
      );
    },
    requestDeletePermission: async (id, reason, headers, body) => {
      await dispatch(
        amendmentActions.requestDeletePermission(id, reason, headers, body)
      );
    },
    getDeletePermissionStatus: async (id, headers) => {
      await dispatch(amendmentActions.getDeletePermissionStatus(id, headers));
    },
    deleteReferral: async (id, headers) => {
      await dispatch(referralActions.deleteReferral(id, headers));
    },
    setDeletePermissionStatusActioned: (id, headers) => {
      dispatch(amendmentActions.setDeletePermissionStatusActioned(id, headers));
    },
    findAddress: async (value, fieldName) => {
      await dispatch(addressActions.findAddress(value, fieldName));
    },
    retrieveAddress: async id => {
      await dispatch(addressActions.retrieveAddress(id));
    },
    getReferralStats: query => {
      dispatch(referralActions.getReferralStats(query));
    },
    clearSelectedReferral: () => {
      dispatch(referralActions.clearSelectedReferral());
    },
    createTask: (details, headers, createTaskMessage = "") => {
      dispatch(taskActions.createTask(details, headers, createTaskMessage));
    },
    clearSelectedUser: () => {
      dispatch(userActions.clearSelectedUser());
    },
    clearPrisonMentor: () => {
      dispatch(userActions.clearPrisonMentor());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReferralEditContainer);
