import React from "react";
import ReactTable from "react-table";
import { TableFunctions } from ".";
import { strings } from "../resources";
import PropTypes from "prop-types";
import { permissions } from "../../App/resources";
import { convertFromIso } from "../helpers/index";

class TableComponent extends React.Component {
  state = {
    entityType: "",
    entityId: ""
  };

  getHeaderList = () => {
    if (this.props.referralCrmId) {
      return (
        strings.header.embeddedEngagementListStart +
        this.props.referralCrmId +
        strings.header.embeddedEngagementListMiddle +
        this.props.engagementType +
        strings.header.embeddedEngagementListEnd
      );
    } else if (this.props.programmeCrmId) {
      return (
        strings.header.embeddedEngagementListStart +
        this.props.programmeCrmId +
        strings.header.embeddedEngagementListMiddle +
        this.props.engagementType +
        strings.header.embeddedEngagementListEnd
      );
    } else {
      return strings.header.engagementList;
    }
  };

  getTdProps = (state, rowInfo, column, instance) => {
    if (column) {
      if (column.id !== "delete" && column.id !== "active") {
        return {
          onClick: () => {
            if (rowInfo) {
              localStorage.setItem("engagementCrmId", rowInfo.original.crmId);
              localStorage.setItem(
                "engagementId",
                rowInfo.original.engagementId
              );
              if (localStorage.getItem("referralId") || this.props.referralId) {
                this.props.history.push(
                  "/engagements/view/" +
                    rowInfo.original.engagementId +
                    "/" +
                    (localStorage.getItem("referralId") ||
                      this.props.referralId)
                );
              } else {
                this.props.history.push(
                  "/engagements/view/" + rowInfo.original.engagementId
                );
              }
            }
          }
        };
      }
    }
    return {}; // getTdProps requires a return statement
  };

  componentDidMount = () => {
    this.getEntityId();
  };

  getEntityId = () => {
    if (localStorage.getItem("referralId") || this.props.referralId) {
      this.setState({
        entityId: localStorage.getItem("referralId") || this.props.referralId,
        entityType: "/referral/"
      });
    } else if (localStorage.getItem("programmeId") || this.props.programmeId) {
      this.setState({
        entityId: localStorage.getItem("programmeId") || this.props.programmeId,
        entityType: "/programme/"
      });
    } else {
      this.props.history.goBack();
    }
  };

  mapSearchResultsToRows = () => {
    const { engagementListData } = this.props;
    let data = [];
    if (engagementListData) {
      engagementListData.forEach((result, index) => {
        if (result["_source"]) {
          data.push(result["_source"]);
        }
      });
    }
    return data;
  };

  render = () => {
    let data = this.mapSearchResultsToRows();
    let pages = Math.ceil(this.props.totalResults / this.props.pageSize);
    const cellTruncate = length => {
      return row => {
        let value = row.value ? row.value : "";
        let truncatedValue =
          value.length > length ? (
            <div>{value.substring(0, length)}...</div>
          ) : (
            <div>{value}</div>
          );
        return truncatedValue;
      };
    };

    const columns = [
      {
        Header: strings.table.header.firstName,
        accessor: "firstName",
        headerStyle: { textAlign: "left" },
        Cell: cellTruncate(20)
      },
      {
        Header: strings.table.header.surname,
        accessor: "surname",
        headerStyle: { textAlign: "left" },
        Cell: cellTruncate(20)
      },
      {
        Header: strings.table.header.pNumber,
        accessor: "pNumber",
        headerStyle: { textAlign: "left" },
        Cell: cellTruncate(20)
      },
      {
        Header: strings.table.header.scroNumber,
        accessor: "scroNumber",
        show:
          this.props.area === strings.areas.NRS ||
          this.props.area === strings.areas.both,
        headerStyle: { textAlign: "left" },
        Cell: cellTruncate(20)
      },
      {
        id: "dateOfEngagement",
        Header: strings.table.header.date,
        accessor: eng =>
          eng.dateOfEngagement ? convertFromIso(eng.dateOfEngagement) : "",
        headerStyle: { textAlign: "left" },
        Cell: cellTruncate(20)
      },
      {
        Header: strings.table.header.type,
        accessor: "type",
        headerStyle: { textAlign: "left" },
        Cell: cellTruncate(20)
      },
      {
        Header: strings.table.header.location,
        accessor: "location",
        headerStyle: { textAlign: "left" },
        Cell: cellTruncate(20)
      },
      {
        Header: strings.table.header.method,
        accessor: "method",
        headerStyle: { textAlign: "left" },
        Cell: cellTruncate(20)
      },
      {
        id: "isSuccessfulValue",
        Header: strings.table.header.isSuccessfulValue,
        accessor: "isSuccessfulValue",
        headerStyle: { textAlign: "left" },
        Cell: cellTruncate(20)
      }
    ];
    return (
      <div>
        <TableFunctions
          headers={this.props.headers}
          history={this.props.history}
          handleRefresh={this.props.handleRefresh}
          headerList={this.getHeaderList()}
          buttonAddLabel={strings.button.addEngagementLabel}
          permissionsCreate={permissions.engagements.create}
          headerClass="pageHeader"
          refreshButtonClass="refreshButton"
          addButtonClass="addButton"
          addButtonLink={
            "/engagements/create/" +
            this.state.entityId +
            this.state.entityType +
            (this.props.engagementType ? this.props.engagementType : "")
          }
          handleChange={this.props.handleChange}
          searchBarQuery={this.props.searchBarQuery}
          handleSubmit={this.props.handleSubmit}
          sharedWith={this.props.selectedReferralData.sharedWith}
          orgOfLoggedInUser={this.props.orgOfLoggedInUser}
          isCreateDisabled={
            this.props.referralId
              ? this.props.isCreateDisabled(
                  this.props.initialReferralStateData,
                  this.props.initialReferralPropsData
                )
              : this.props.isCreateDisabled(
                  this.props.initialProgrammeStateData,
                  this.props.initialProgrammePropsData
                )
          }
          hideSearchBar={this.props.hideSearchBar}
          parentPage={this.props.parentPage}
          returnToParent={this.props.returnToParent}
        />
        <div>
          <ReactTable
            manual
            data={data}
            pages={pages}
            defaultPageSize={this.props.pageSize}
            showPagination={true}
            getTdProps={this.getTdProps}
            onFetchData={(state, instance) => {
              this.props.handleFetchData(state);
              this.props.updatePageIndex(state);
            }}
            columns={columns}
            className="-highlight"
            showPageSizeOptions={false}
            minRows="1"
            noDataText={strings.table.text.noResults}
            previousText="<"
            nextText=">"
            pageText=""
          />
        </div>
      </div>
    );
  };
}

TableComponent.propTypes = {
  handleModalConfirm: PropTypes.func.isRequired,
  engagementListData: PropTypes.array.isRequired,
  headers: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  pageSize: PropTypes.number.isRequired,
  handleFetchData: PropTypes.func.isRequired,
  totalResults: PropTypes.number.isRequired,
  updatePageIndex: PropTypes.func.isRequired,
  isCreateDisabled: PropTypes.func.isRequired
};

export default TableComponent;
