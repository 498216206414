import { reportActionTypes } from "../actions/report.actionTypes";

const initialState = {
  loadingPage: false,
  selectedReportData: {},
  reportListData: [{}],
  totalResults: 0,
  reportToDelete: "",
  result: "",
  error: "",
  reportRequestStatus: undefined,
  reportRequestMade: false,
  reportOptions: [],
  reportReferrals: [],
  totalReportReferrals: 0,
  savedReportId: "",
  specialistOptions: []
};

export default (state = initialState, action) => {
  let rawHits = [];
  let newTotal = 0;

  switch (action.type) {
    case reportActionTypes.SAVE_REPORT_REQUEST:
      return {
        ...state,
        reportRequestStatus: undefined,
        reportRequestMade: true,
        loadingPage: true,
        savedReportId: initialState.savedReportId
      };
    case reportActionTypes.SAVE_REPORT_SUCCESS:
      let newReportOptions = state.reportOptions;
      newReportOptions.push({
        key: state.reportOptions.length + 1,
        value: action.response.data.ID,
        text: action.details.name,
        reporttype: action.details.reportType
      });
      return {
        ...state,
        result: action.response.data.Reason,
        reportRequestStatus: true,
        reportOptions: newReportOptions,
        loadingPage: false,
        savedReportId: action.response.data.ID
      };
    case reportActionTypes.SAVE_REPORT_FAILURE:
      return {
        ...state,
        error: action.error.response.data.Reason,
        reportRequestStatus: false,
        loadingPage: false
      };

    case reportActionTypes.CLEAR_CREATE_REPORT_REQUEST:
      return { ...state };
    case reportActionTypes.CLEAR_CREATE_REPORT_SUCCESS:
      return {
        ...state,
        reportRequestMade: false,
        reportRequestStatus: undefined
      };
    case reportActionTypes.CLEAR_CREATE_REPORT_FAILURE:
      return { ...state };

    case reportActionTypes.DELETE_REPORT_REQUEST:
      return {
        ...state,
        reportRequestStatus: undefined,
        reportRequestMade: true,
        loadingPage: true
      };
    case reportActionTypes.DELETE_REPORT_SUCCESS:
      return {
        ...state,
        result: action.response.data.Reason,
        reportRequestStatus: true,
        loadingPage: false
      };
    case reportActionTypes.DELETE_REPORT_FAILURE:
      return {
        ...state,
        error: action.error.response.data.Reason,
        reportRequestStatus: false,
        loadingPage: false
      };

    case reportActionTypes.GET_REPORTS_REQUEST:
      return {
        ...state,
        loadingPage: true,
        savedReportId: initialState.savedReportId
      };
    case reportActionTypes.GET_REPORTS_SUCCESS:
      rawHits = [];
      newTotal = action.response.data.Body.total;
      let reportOptions = [];
      if (action.response.data.Body.hits) {
        rawHits = action.response.data.Body.hits.hits;
        rawHits.forEach((report, index) =>
          reportOptions.push({
            key: index,
            value: report._id,
            text: report._source.name,
            reporttype: report._source.reportType,
            area: report._source.area,
            report: report._source.report,
            stage: report._source.stage,
            measure: report._source.measure,
            conversion: report._source.conversion,
            hassavedreferrals: report._source.hasSavedReferrals
              ? "true"
              : undefined
          })
        );
      }
      return {
        ...state,
        reportListData: rawHits,
        totalResults: newTotal,
        reportOptions: reportOptions,
        loadingPage: false
      };
    case reportActionTypes.GET_REPORTS_FAILURE:
      return { ...state, reportListData: [{}], loadingPage: false };

    case reportActionTypes.SEARCH_REPORT_REFERRALS_REQUEST:
      return {
        ...state,
        loadingPage: true,
        reportRequestStatus: undefined
      };
    case reportActionTypes.SEARCH_REPORT_REFERRALS_SUCCESS:
      rawHits = [];
      newTotal = action.response.data.Body.hits.total;
      if (action.response.data.Body.hits) {
        rawHits = action.response.data.Body.hits.hits;
      }
      return {
        ...state,
        reportReferrals: rawHits,
        totalReportReferrals: newTotal,
        loadingPage: false,
        reportRequestStatus: true
      };
    case reportActionTypes.SEARCH_REPORT_REFERRALS_FAILURE:
      return {
        ...state,
        reportReferrals: initialState.reportReferrals,
        loadingPage: false,
        totalReportReferrals: initialState.totalReportReferrals,
        reportRequestStatus: false
      };

    case reportActionTypes.GET_REPORT_BY_ID_REQUEST:
      return {
        ...state,
        loadingPage: true,
        reportRequestStatus: undefined,
        selectedReportData: {},
        savedReportId: initialState.savedReportId,
        reportReferrals: initialState.reportReferrals,
        totalReportReferrals: initialState.totalReportReferrals
      };
    case reportActionTypes.GET_REPORT_BY_ID_SUCCESS:
      let reportData = {};
      if (action.response) {
        reportData = action.response;
      }
      return {
        ...state,
        loadingPage: false,
        selectedReportData: { ...reportData },
        reportRequestStatus: true
      };
    case reportActionTypes.GET_REPORT_BY_ID_FAILURE:
      return {
        ...state,
        selectedReportData: {},
        loadingPage: false,
        reportRequestMade: true,
        reportRequestStatus: false,
        reportReferrals: initialState.reportReferrals,
        totalReportReferrals: initialState.totalReportReferrals
      };

    case reportActionTypes.GENERATE_REPORT_REQUEST:
      return {
        ...state,
        loadingPage: true,
        reportRequestStatus: undefined
      };
    case reportActionTypes.GENERATE_REPORT_SUCCESS:
      rawHits = [];
      newTotal = 0;
      if (action.response.referrals) {
        newTotal = action.response.result;
        rawHits = action.response.referrals.slice();
        rawHits.forEach((result, index) => {
          let newReferral = Object.assign({}, result["_source"]);
          rawHits[index]["_source"] = newReferral;
        });
      } else {
        rawHits = [];
      }
      return {
        ...state,
        result: action.response.Reason,
        reportRequestStatus: true,
        selectedReportData: {
          ...state.selectedReportData,
          result: action.response.result.toString(),
          fromDate:
            action.response.fromDate && action.response.fromDate !== "0"
              ? action.response.fromDate
              : undefined,
          toDate: action.response.toDate
        },
        loadingPage: false,
        reportReferrals: rawHits,
        totalReportReferrals: newTotal
      };
    case reportActionTypes.GENERATE_REPORT_FAILURE:
      return {
        ...state,
        error:
          action.error && action.error.response
            ? action.error.response.data.Reason
            : action.error,
        loadingPage: false,
        selectedReportData: {},
        reportRequestStatus: false,
        reportRequestMade: true
      };

    case reportActionTypes.UPLOAD_REFERRALS_REQUEST:
      return {
        ...state,
        loadingPage: true,
        reportRequestMade: true,
        reportRequestStatus: undefined
      };
    case reportActionTypes.UPLOAD_REFERRALS_SUCCESS:
      return {
        ...state,
        loadingPage: false,
        reportRequestMade: true,
        reportRequestStatus: true,
        result: action.responses[0].data.Reason
      };
    case reportActionTypes.UPLOAD_REFERRALS_FAILURE:
      return {
        ...state,
        loadingPage: false,
        reportRequestMade: true,
        reportRequestStatus: false,
        error: action.errors.response.data.Reason
      };

    case reportActionTypes.UPDATE_SELECTED_REPORT_REQUEST:
      return { ...state };
    case reportActionTypes.UPDATE_SELECTED_REPORT_SUCCESS:
      return {
        ...state,
        selectedReportData: {
          ...state.selectedReportData,
          [action.key]: action.value
        }
      };
    case reportActionTypes.UPDATE_SELECTED_REPORT_FAILURE:
      return { ...state };

    case reportActionTypes.CLEAR_SELECTED_REPORT_REQUEST:
      return { ...state };
    case reportActionTypes.CLEAR_SELECTED_REPORT_SUCCESS:
      return {
        ...state,
        selectedReportData: {},
        reportReferrals: initialState.reportReferrals,
        totalReportReferrals: initialState.totalReportReferrals
      };
    case reportActionTypes.CLEAR_SELECTED_REPORT_FAILURE:
      return { ...state };

    case reportActionTypes.CLEAR_SAVED_REPORT_SUCCESS:
      return { ...state, savedReportId: initialState.savedReportId };

    case reportActionTypes.CANCEL_SELECTED_REPORT_UPDATE_REQUEST:
      return { ...state };
    case reportActionTypes.CANCEL_SELECTED_REPORT_UPDATE_SUCCESS:
      return { ...state, selectedReportData: action.data };
    case reportActionTypes.CANCEL_SELECTED_REPORT_UPDATE_FAILURE:
      return { ...state };

    case reportActionTypes.STORE_SPECIALIST_OPTIONS_SUCCESS:
      return { ...state, specialistOptions: action.users };

    default:
      return state;
  }
};
