import React from "react";
import PropTypes from "prop-types";
import { Button, Form, Input } from "semantic-ui-react";
import { strings } from "../resources";
import { ValidatedFormDropdown, ValidatedFormInput } from "./validation";
import { ComponentHeader, BasicModal, TextInputModal } from ".";
import "./css/AssessmentSGComponents.scss";
import { withRouter } from "react-router-dom";
import { permissions } from "../../App/resources/AppPermissions";
import { dropdownHelper, allowNumericalInput, getUsers } from "../helpers";
import ValidatedAssessmentSG from "./validation/ValidatedAssessmentSG";

class ModifyAssessmentSG extends React.Component {
  returnToParent = () => {
    this.props.history.push(
      "/programmes/view/" + localStorage.getItem("programmeId")
    );
  };

  renderModifyButtons = () => {
    const { isDisabled } = this.props;

    const modalTextInput = (
      <Input
        placeholder={strings.form.placeholder.stateReason}
        fluid
        onChange={this.props.handleModalInputChange}
      ></Input>
    );

    return (
      <div className="containerButtons">
        {!this.props.deleteSuccessful &&
          (!isDisabled ? (
            <Button.Group>
              <Button
                className="editButton"
                primary
                onClick={() => {
                  this.props.handleModeSwitch("edit");
                }}
                disabled={!this.props.selectedAssessmentSGData.active}
              >
                {strings.button.editLabel}
              </Button>
            </Button.Group>
          ) : (
            <span className="errorText">
              {strings.form.errorText.notShared}
            </span>
          ))}
        {this.props.deletePermissionStatus !== strings.states.approved &&
          !this.props.deleteSuccessful &&
          !isDisabled && (
            <Button.Group>
              <Button
                className="deleteButton"
                secondary
                inverted
                onClick={
                  this.props.toggleEnableDisableAssessmentSGModalVisibility
                }
                type="button"
                disabled={this.props.selectedAssessmentSGData.requestId}
              >
                {strings.button.requestDeleteLabel}
              </Button>
              <TextInputModal
                confirmMessage={strings.modal.requestDeletePermissionMessage}
                handleModalConfirm={this.props.handleModalConfirm}
                modalClassName="requestDeletePermissionModal"
                modalOpen={this.props.enableDisableAssessmentSGModalOpen}
                showOkButton={true}
                textInput={modalTextInput}
                toggleModal={
                  this.props.toggleEnableDisableAssessmentSGModalVisibility
                }
                okLabel={strings.button.submitLabel}
                deletionReason={this.props.deletionReason}
              />
            </Button.Group>
          )}
        {this.props.deletePermissionStatus === strings.states.approved &&
          !this.props.deleteSuccessful &&
          !isDisabled && (
            <Button.Group>
              <Button
                className="deleteButton"
                secondary
                onClick={
                  this.props.toggleEnableDisableAssessmentSGModalVisibility
                }
                type="button"
              >
                {strings.button.deleteLabel}
              </Button>
              <BasicModal
                confirmMessage={strings.modal.deleteAssessmentSGMessage}
                handleModalConfirm={this.props.handleModalConfirm}
                modalClassName="deleteModal"
                modalOpen={this.props.enableDisableAssessmentSGModalOpen}
                showOkButton={false}
                showYesButton={true}
                showNoButton={true}
                toggleModal={
                  this.props.toggleEnableDisableAssessmentSGModalVisibility
                }
                noLabel={strings.button.noLabel}
                yesLabel={strings.button.yesLabel}
                okLabel={strings.button.okLabel}
              />
            </Button.Group>
          )}
      </div>
    );
  };
  renderCancelSaveButtons = () => {
    const {
      selectedAssessmentSGData,
      handleModeSwitch,
      handleModalConfirm,
      toggleCancelChangesModalVisibility,
      toggleConfirmSaveModalVisibility,
      initialAssessmentSGData,
      validateForm,
      confirmSaveModalOpen,
      mode,
      cancelChangesModalOpen,
      isDisabled
    } = this.props;

    return (
      <div className="containerButtons">
        <Button.Group>
          <Button
            className="saveButton"
            primary
            inverted
            onClick={validateForm}
            disabled={
              JSON.stringify(selectedAssessmentSGData) ===
                JSON.stringify(initialAssessmentSGData) || isDisabled
            }
          >
            {strings.button.saveLabel}
          </Button>
          <BasicModal
            confirmMessage={strings.modal.saveChangesMessage}
            handleModalConfirm={handleModalConfirm}
            modalClassName="confirmSaveModal"
            modalOpen={confirmSaveModalOpen}
            showNoButton={true}
            showOkButton={false}
            showYesButton={true}
            toggleModal={toggleConfirmSaveModalVisibility}
            noLabel={strings.button.noLabel}
            yesLabel={strings.button.yesLabel}
            okLabel={strings.button.okLabel}
          />
        </Button.Group>
        <Button.Group>
          <Button
            className="cancelButton"
            primary
            onClick={toggleCancelChangesModalVisibility}
            type="button"
            disabled={isDisabled}
          >
            {strings.button.cancelLabel}
          </Button>
          <BasicModal
            confirmMessage={strings.modal.cancelChangesMessage}
            handleModalConfirm={
              mode === "create" ? handleModalConfirm : handleModeSwitch
            }
            modalClassName="cancelChangesModal"
            modalOpen={cancelChangesModalOpen}
            showOkButton={false}
            showYesButton={true}
            showNoButton={true}
            toggleModal={toggleCancelChangesModalVisibility}
            noLabel={strings.button.noLabel}
            yesLabel={strings.button.yesLabel}
            okLabel={strings.button.okLabel}
          />
        </Button.Group>
      </div>
    );
  };
  renderForm = () => {
    const {
      selectedAssessmentSGData,
      handleChange,
      mode,
      formInvalid,
      validationResults,
      requestStatus,
      getOrganisations,
      isDisabled
    } = this.props;

    return (
      <>
        <div className="infoBlock">
          <div className="infoHeader">{strings.form.header.administration}</div>
          <div className="infoWrapper">
            <Form.Group>
              {mode !== "create" && (
                <ValidatedFormInput
                  formInvalid={formInvalid}
                  inputData={selectedAssessmentSGData.crmId}
                  inputLabel={strings.form.label.crmId}
                  inputName="crmId"
                  inputPlaceholder={strings.form.placeholder.crmId}
                  formFieldWidth={8}
                  handleChange={handleChange}
                  validationResult={validationResults.crmId}
                  disabled={mode === "edit"}
                />
              )}
              <ValidatedFormDropdown
                formInvalid={formInvalid}
                inputData={selectedAssessmentSGData.owner}
                viewData={
                  dropdownHelper.getNameFromValues(
                    selectedAssessmentSGData.owner,
                    getOrganisations()
                  ) || ""
                }
                dropdownLabel={strings.form.label.owner}
                dropdownName="owner"
                dropdownDisabled={true}
                dropdownOptions={getOrganisations() || []}
                dropdownPlaceholder={strings.form.placeholder.owner}
                formFieldWidth={8}
                handleChange={handleChange}
                validationResult={validationResults.owner}
                mode={mode}
              />
            </Form.Group>
          </div>
        </div>

        <div className="infoBlock">
          <div className="infoHeader">
            {strings.form.header.customerDetails}
          </div>
          <div className="infoWrapper">
            <Form.Group>
              <ValidatedFormInput
                formInvalid={this.props.formInvalid}
                inputData={selectedAssessmentSGData.customerName}
                inputLabel={strings.form.label.customerName}
                inputName="customerName"
                inputPlaceholder={strings.form.placeholder.customerName}
                formFieldWidth={8}
                handleChange={this.props.handleChange}
                validationResult={this.props.validationResults.customerName}
                disabled={mode !== "view"}
              />
              <ValidatedFormInput
                formInvalid={this.props.formInvalid}
                inputData={selectedAssessmentSGData.sNumber}
                inputLabel={strings.form.label.sNumber}
                inputName="sNumber"
                onKeyPress={allowNumericalInput}
                inputPlaceholder={strings.form.placeholder.sNumber}
                formFieldWidth={8}
                handleChange={this.props.handleChange}
                validationResult={this.props.validationResults.sNumber}
                disabled={mode !== "view"}
              />
            </Form.Group>
            <Form.Group>
              <ValidatedFormDropdown
                formInvalid={formInvalid}
                inputData={selectedAssessmentSGData.specialist}
                viewData={
                  dropdownHelper.getNameFromValues(
                    selectedAssessmentSGData.specialist,
                    getUsers(this.props.userListData)
                  ) || ""
                }
                dropdownLabel={strings.form.label.specialist}
                dropdownName="specialist"
                dropdownDisabled={mode !== "view"}
                dropdownOptions={getUsers(this.props.userListData)}
                dropdownPlaceholder={strings.form.placeholder.specialist}
                formFieldWidth={8}
                handleChange={handleChange}
                validationResult={validationResults.specialist}
                mode={mode}
              />
              <ValidatedFormInput
                formInvalid={this.props.formInvalid}
                inputData={selectedAssessmentSGData.pNumber}
                inputLabel={strings.form.label.pNumber}
                inputName="pNumber"
                onKeyPress={allowNumericalInput}
                inputPlaceholder={strings.form.placeholder.pNumber}
                formFieldWidth={8}
                handleChange={this.props.handleChange}
                validationResult={this.props.validationResults.pNumber}
                disabled={mode !== "view"}
              />
            </Form.Group>
          </div>
        </div>
        <div className="infoBlock">
          {(requestStatus || mode === "create") && (
            <ValidatedAssessmentSG
              selectedAssessmentSGData={selectedAssessmentSGData}
              formInvalid={formInvalid}
              validationResults={validationResults}
              handleChange={handleChange}
              allowNumericalInput={allowNumericalInput}
              savePage={this.props.savePage}
              getAssessmentData={this.props.getAssessmentData}
              updateSelectedAssessmentSG={this.props.updateSelectedAssessmentSG}
              mode={mode}
              isDisabled={isDisabled}
              hasCancelled={this.props.hasCancelled}
              resetCancelledState={this.props.resetCancelledState}
            />
          )}
        </div>
      </>
    );
  };

  render = () => {
    const { mode, formInvalid, pageTitle, requestStatus } = this.props;

    let parentPage = localStorage.getItem("programmeName")
      ? localStorage.getItem("programmeName")
      : undefined;

    return (
      <div className="container">
        <ComponentHeader
          parentPage={parentPage}
          returnToParent={this.returnToParent}
          pageTitle={pageTitle}
          mode={mode}
          requestStatus={requestStatus}
          modifyButtons={this.renderModifyButtons()}
          cancelSaveButtons={this.renderCancelSaveButtons()}
          permissionsUpdate={permissions.assessmentSG.update}
          permissionsCreate={permissions.assessmentSG.create}
          formInvalid={formInvalid}
          validationResults={this.props.validationResults}
          sticky
        />

        <Form id="assessmentSGForm" className="stickyForm">
          <fieldset disabled={mode === "view"}>{this.renderForm()}</fieldset>
        </Form>
      </div>
    );
  };
}

ModifyAssessmentSG.propTypes = {
  mode: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  selectedAssessmentSGData: PropTypes.object.isRequired,
  pageTitle: PropTypes.string.isRequired,
  confirmSaveModalOpen: PropTypes.bool.isRequired,
  cancelChangesModalOpen: PropTypes.bool.isRequired,
  toggleConfirmSaveModalVisibility: PropTypes.func.isRequired,
  toggleCancelChangesModalVisibility: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleModeSwitch: PropTypes.func,
  handleModalConfirm: PropTypes.func.isRequired,
  formInvalid: PropTypes.bool.isRequired,
  validationResults: PropTypes.object,
  validateForm: PropTypes.func.isRequired,
  requestStatus: PropTypes.bool,
  initialAssessmentSGData: PropTypes.object.isRequired,
  userListData: PropTypes.array.isRequired,
  getOrganisations: PropTypes.func.isRequired,
  updateSelectedAssessmentSG: PropTypes.func.isRequired,
  deletePermissionStatus: PropTypes.string,
  handleModalInputChange: PropTypes.func,
  deletionReason: PropTypes.object,
  isDisabled: PropTypes.bool.isRequired
};

export default withRouter(ModifyAssessmentSG);
