import React from "react";
import PropTypes from "prop-types";
import { Form } from "semantic-ui-react";
import {
  strings,
  outcomeNrsOptions,
  outcomeOfThresholdOptions,
  outcomeReasonEligibleOptions
} from "../../resources";
import { ShowIfSameArea } from "../../../App/components/";
import { convertFromIso } from "../../helpers/index";
import { Link } from "react-router-dom";
import {
  ValidatedFormDropdown,
  ValidatedDateInput,
  ValidatedFormInput
} from "../validation";
import "../css/ReferralComponents.scss";

class GeneralFields extends React.Component {
  render = () => {
    return (
      <div className="infoBlock">
        <div className="infoHeader">{strings.form.header.generalHeader}</div>
        <div className="infoWrapper">
          {this.props.linkedProgramme && (
            <Form.Group>
              <Link
                className="programmeLink"
                to={
                  "/programmes/view/" + this.props.linkedProgramme.programmeId
                }
              >
                {strings.button.programmeLinkText +
                  " " +
                  this.props.linkedProgramme.crmId}
              </Link>
            </Form.Group>
          )}
          <Form.Group>
            <ValidatedDateInput
              inputId="referralMeetingDate"
              formInvalid={this.props.formInvalid}
              inputLabel={strings.form.label.referralMeetingDate}
              inputName="referralMeetingDate"
              inputPlaceholder={strings.form.placeholder.referralMeetingDate}
              inputDateFormat="DD/MM/YYYY"
              inputData={
                this.props.selectedReferralData.referralMeetingDate
                  ? convertFromIso(
                      this.props.selectedReferralData.referralMeetingDate
                    )
                  : ""
              }
              iconPosition="left"
              required
              handleChange={this.props.handleChange}
              validationResult={
                this.props.validationResults.referralMeetingDate
              }
              formFieldWidth={8}
              mode={this.props.mode}
            />
            <ShowIfSameArea
              area={this.props.area}
              allowedArea={strings.areas.NRS}
            >
              <ValidatedFormDropdown
                formInvalid={this.props.formInvalid}
                inputData={this.props.selectedReferralData.outcomeOfReferralNrs}
                viewData={this.props.selectedReferralData.outcomeOfReferralNrs}
                dropdownLabel={strings.form.label.outcomeOfReferralNrs}
                dropdownName="outcomeOfReferralNrs"
                dropdownOptions={outcomeNrsOptions}
                dropdownPlaceholder={
                  strings.form.placeholder.outcomeOfReferralNrs
                }
                formFieldWidth={8}
                handleChange={this.props.handleChange}
                validationResult={
                  this.props.validationResults.outcomeOfReferralNrs
                }
                mode={this.props.mode}
              />
            </ShowIfSameArea>
            <ShowIfSameArea
              area={this.props.area}
              allowedArea={strings.areas.NRNE}
            >
              <ValidatedFormDropdown
                formInvalid={this.props.formInvalid}
                inputData={this.props.selectedReferralData.outcomeOfThreshold}
                viewData={this.props.selectedReferralData.outcomeOfThreshold}
                dropdownLabel={strings.form.label.outcomeOfThreshold}
                dropdownName="outcomeOfThreshold"
                dropdownOptions={outcomeOfThresholdOptions}
                dropdownPlaceholder={
                  strings.form.placeholder.outcomeOfThreshold
                }
                dropdownDisabled={this.props.mode === "edit"}
                formFieldWidth={8}
                handleChange={this.props.handleChangeOutcomeOfThreshold}
                validationResult={
                  this.props.validationResults.outcomeOfThreshold
                }
                mode={this.props.mode}
              />
            </ShowIfSameArea>
          </Form.Group>
          <ShowIfSameArea
            area={this.props.area}
            allowedArea={strings.areas.NRNE}
          >
            <Form.Group>
              {this.props.selectedReferralData.outcomeOfThreshold ===
                strings.fieldValues.suitable && (
                <ValidatedFormDropdown
                  formInvalid={this.props.formInvalid}
                  inputData={this.props.selectedReferralData.outcomeReason}
                  viewData={this.props.selectedReferralData.outcomeReason}
                  dropdownLabel={strings.form.label.outcomeReason}
                  dropdownName="outcomeReason"
                  dropdownOptions={outcomeReasonEligibleOptions}
                  dropdownPlaceholder={strings.form.placeholder.outcomeReason}
                  dropdownDisabled={this.props.mode === "edit"}
                  formFieldWidth={8}
                  handleChange={this.props.handleChangeOutcomeReason}
                  validationResult={this.props.validationResults.outcomeReason}
                  mode={this.props.mode}
                />
              )}
              {this.props.selectedReferralData.outcomeOfThreshold ===
                strings.fieldValues.unsuitable && (
                <ValidatedFormInput
                  formInvalid={this.props.formInvalid}
                  inputData={this.props.selectedReferralData.outcomeReason}
                  inputLabel={strings.form.label.outcomeReason}
                  inputName="outcomeReason"
                  disabled={this.props.mode === "edit"}
                  inputPlaceholder={strings.form.placeholder.outcomeReason}
                  formFieldWidth={8}
                  handleChange={this.props.handleChange}
                  validationResult={this.props.validationResults.outcomeReason}
                  mode={this.props.mode}
                />
              )}
            </Form.Group>
          </ShowIfSameArea>
        </div>
      </div>
    );
  };
}

GeneralFields.propTypes = {
  formInvalid: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  mode: PropTypes.string.isRequired,
  selectedReferralData: PropTypes.object.isRequired,
  validationResults: PropTypes.object
};

export default GeneralFields;
