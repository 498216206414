export const taskTitleMappings = {
  customerTransfer: "Customer Transfer",
  duplicateWarning: "Duplicate Warning",
  baselinePrerelease: "Baseline Pre-release",
  baselineFourWeek: "Baseline Four Week",
  baselineExit: "Baseline Exit",
  govFourWeek: "Gov Four Week",
  govMidpoint: "Gov Midpoint",
  govExit: "Gov Exit",
  signupMeeting: "Signup Meeting",
  prereleaseMeeting: "Pre-release Meeting",
  midpointMeeting: "Midpoint Meeting",
  exitMeeting: "Exit Meeting",
  feedbackPrerelease: "Feedback Pre-release",
  feedbackFourWeeks: "Feedback Four Weeks",
  feedbackExit: "Feedback Exit",
  changeAmendment: "Change Amendment",
  deleteAmendment: "Delete Amendment",
  gateLiberation: "GateLiberation",
  mentorAssigned: "Mentor Assigned",
  weeklyEngagement: "Weekly Meeting",
  monthlyEngagement: "Monthly Meeting"
};
