import LocalizedStrings from "react-localization";

export const strings = new LocalizedStrings({
  en: {
    scanner: {
      uploadTextDragActive: "Drop the files here ...",
      uploadText: " Drag and drop some files here, or click to select files",
      scan: "Scan",
      reset: "Reset Outputs",
      json: "Download as JSON",
      typeSuccess: "Form Type: ",
      error: {
        typeFailure: "Form Type could not be detected",
        pNumberFailure: "No Prison Number Detected",
        noRelatedRecord: "No record with a matching prisoner number was found",
        onlyOneColumn: "Please only enter one column (stage) at a time"
      },
      feedback: {
        processing: "Processing request...",
        RequestSuccess: " created successfully!",
        RequestFailure: " creation failed.",
        RequestUnknown: "Something went wrong with the request.",
        status: "Status",
        success: "Success",
        failure: "Failure"
      },
      headerList: "OCR Scanner"
    },
    button: {
      submit: "Submit Form",
      yesLabel: "Yes",
      noLabel: "No",
      okLabel: "Confirm"
    },
    fieldValues: {
      prisonernumber: "prisonernumber",
      dateCompleted: "dateCompleted",
      yes: "Yes",
      no: "No",
      teamLead: "teamLead"
    },
    modal: {
      saveDuplicateMessage:
        "A customer with this prison number already exists, would you like to request to start an additional journey?",
      saveChangesMessage: "Are you sure you want to upload this form?"
    },
    areas: { NRS: "NRS", both: "both", NRNE: "NRNE" },
    userMessage: {
      validationError: "Validation Error",
      duplicateRequest: "Request for duplicate creation submitted"
    }
  }
});
