import LocalizedStrings from "react-localization";

export const strings = new LocalizedStrings({
  en: {
    header: {
      login: "Login",
      referralList: "Referrals",
      createReferral: "Create New Referral",
      editReferral: "Edit Referral",
      viewReferral: "Referral Details",
      loading: "Loading Information",
      engagementReferralsList: "Referral Meeting",
      engagementSignupList: "Signup Meeting"
    },
    form: {
      header: {
        dataProtectionHeader: "Data Protection",
        eligiblityHeader: "Eligibility",
        generalHeader: "General",
        matchingHeader: "Matching",
        mentorHeader: "Mentor Details",
        needsHeader: "Needs",
        notesHeader: "Notes",
        personalDetailsHeader: "Personal Details",
        referralInfo: "Administration",
        EqualOpportunitiesHeader: "Protected Characteristics",
        areaHeader: "What area is the referral for?",
        identificationMeeting: "Identification Meeting",
        thresholdMeeting: "Threshold Meeting",
        formType: "Referral Forms",
        supportService: "Support Service",
        chooseEngagement: "Engagements"
      },
      feedback: {
        processing: "Processing request...",
        referralRequestSuccess: "Referral created successfully!",
        referralRequestRefreshPrompt:
          "Use the refresh function to see an updated referrals list.",
        referralRequestFailure: "Referral creation failed.",
        status: "Status",
        success: "Success",
        failure: "Failure",
        referralRequestUnknown:
          "Something went wrong with the referral request."
      },
      label: {
        crmId: "CRM ID",
        owner: "Owner",
        referralMeetingDate: "Referral Meeting Date",
        referralSourceNrs: "Referral Source",
        referralSourceNrne: "Referral Source",
        dateOfReferral: "Date Identified",
        outcomeOfIdentification: "Outcome of Identification",
        otherReason: "Please give details",
        outcomeOfReferralNrs: "Outcome of Referral",
        prisonOrganisation: "Prison Organisation",
        prisonSpecialist: "Prison Specialist",
        communityOrganisation: "Community Organisation",
        communityMentor: "Community Specialist",
        consent: "Agree to data protection",
        goodNewsStories: "Agree to New Routes good news stories",
        newRoutesMarketing: "Agree to New Routes Marketing",
        doNotApproach: "Anyone the customer does not wish to be approached",
        whoNotApproach: "Who not to approach",
        firstName: "First Name",
        surname: "Last Name",
        scroNumber: "S Number",
        pNumber: "Prison Number",
        nin: "NI Number",
        dateOfBirth: "Date Of Birth",
        email: "E-Mail address",
        phone1: "Telephone number",
        phone2: "Mobile Number",
        address1: "Pre-Conviction Address Line 1 ",
        address2: "Pre-Conviction Address Line 2 ",
        townOrCity: "Pre-Conviction Town/City",
        postcode: "Pre-Conviction Postcode",
        libAddress1: "Liberation Address Line 1 ",
        libAddress2: "Liberation Address Line 2 ",
        libTown: "Liberation Address Town/City",
        libPostcode: "Liberation Postcode",
        mailingAddress1: "Mailing Address Line 1 ",
        mailingAddress2: "Mailing Address Line 2 ",
        mailingTown: "Mailing Address Town/City",
        mailingPostcode: "Mailing Address Postcode",
        sameAddressCheckbox: "Same As Pre-Conviction Address?",
        additionalAddress1: "Additional Address Line 1 ",
        additionalAddress2: "Additional Address Line 2 ",
        additionalTown: "Additional Address Town/City",
        additionalPostcode: "Additional Address Postcode",
        emergencyContactName: "Emergency Contact Name",
        relationship: "Relationship",
        emergencyContactNum: "Emergency Contact Number",
        armedForces: "Armed Forces Service",
        liberationLocalArea: "Liberation Local Authority Area",
        prisonNrs: "Prison",
        prisonNrne: "Prison",
        hallName: "Hall Name",
        level: "Level",
        convictionDate: "Conviction Date",
        years: "Sentence Years",
        months: "Sentence Months",
        days: "Sentence Days",
        outstandingCharges: "Outstanding Charges",
        hdcQualificationDate: "HDC Qualification Date",
        overallEDL: "Overall EDL",
        hasChildren: "Do you have any children?",
        contactChildren:
          "Are there any legal conditions that prevent you from contacting your children?",
        custodialSentences: "Total number of custodial sentences",
        nonCustodialSentences: "Total number of non-custodial sentences",
        ageCategory: "Age Category",
        statutorySupport: "Receiving statutory support",
        shortSentence: "Short term sentence of less than 4 years",
        offendingHistory: "Prolific offending history (approx. 10 convictions)",
        riskFactors: "Please state risk factors",
        prisonNeeds: "What are the customers needs in prison at this stage?",
        communityNeeds:
          "What are the customers needs in community at this stage?",
        additionalNeedsInfo: "Additional Information",
        threeWayMeeting: "Three Way Meeting Required?",
        warmHandover: "Warm Handover Attended",
        matchedToMentor: "Matched to mentor",
        reasonNotMatched: "Reason if not matched",
        dateMatched: "Date matched to mentor",
        notes: "Notes",
        signupMeetingDate: "Sign-Up meeting date",
        signupMeetingOutcome: "Outcome of Sign-Up meeting",
        hideProtected: "Hide Protected Characteristics",
        birthGender: "Gender at birth",
        disability: "Disability",
        maritalStatus: "Marital Status",
        maritalStatusOther: "Marital other (please state)",
        currentGender: "Gender Currently",
        currentGenderOther: "Gender Other (please state)",
        religion: "Religion",
        religionOther: "Religion Other (please state)",
        sexualOrientation: "Sexual Orientation",
        ethnicity: "Ethnicity",
        ethnicityOther: "Ethnic Other (please state)",
        isPregnant: "Are you pregnant?",
        isMaternityPeriod:
          "Are you within the maternity period? (26 weeks after birth)",
        area: "Area",
        customerLocalAuthorityArea: "Customer Local Authority Area",
        wingCellNumber: "Wing/Cell Number",
        mentor: "Mentor",
        initials: "Initials",
        childRisk: "Child risk",
        knownAdultRisk: "Risk to known adults",
        staffRisk: "Risk to staff",
        publicRisk: "Risk to public",
        needsAnalysis: "Needs Analysis",
        releaseDate: "Release Date",
        outcomeOfThreshold: "Outcome of Threshold",
        outcomeReason: "Outcome Reason",
        otherOutcomeReason: "Other Reason",
        linkWorker: "Link Worker",
        communityRisk: "Risk (in community)",
        prisonHistory: "Prison History & Activity",
        prisonNrneOther: "Other",
        formType: "Choose Form Type",
        supportService: "Support Service",
        engagementType: "View or Add an Engagement",
        sharedWith: "",
        postcodeSearch: "Postcode Search",
        returningCustomer: ""
      },
      placeholder: {
        stateReason: "State your reason...",
        crmId: "",
        owner: "- select -",
        referralMeetingDate: "",
        referralSourceNrs: "- select -",
        referralSourceNrne: "- select -",
        dateOfReferral: "",
        outcomeOfIdentification: "- select -",
        otherReason: "",
        outcomeOfReferralNrs: "- select -",
        prisonOrganisation: "- select -",
        prisonSpecialist: "- select -",
        communityOrganisation: "- select -",
        communityMentor: "- select -",
        consent: "- select -",
        goodNewsStories: "- select -",
        newRoutesMarketing: "- select -",
        doNotApproach: "- select -",
        whoNotApproach: "",
        firstName: "",
        surname: "",
        scroNumber: "",
        pNumber: "",
        nin: "",
        dateOfBirth: "",
        email: "",
        phone1: "",
        phone2: "",
        address1: "",
        address2: "",
        townOrCity: "",
        postcode: "",
        libAddress1: "",
        libAddress2: "",
        libTown: "",
        libPostcode: "",
        mailingAddress1: "",
        mailingAddress2: "",
        mailingTown: "",
        mailingPostcode: "",
        additionalAddress1: "",
        additionalAddress2: "",
        additionalTown: "",
        additionalPostcode: "",
        emergencyContactName: "",
        relationship: "- select -",
        emergencyContactNum: "",
        armedForces: "- select -",
        liberationLocalArea: "- select -",
        prisonNrs: "- select -",
        prisonNrne: "- select -",
        hallName: "",
        level: "",
        convictionDate: "",
        years: "- select -",
        months: "- select -",
        days: "- select -",
        outstandingCharges: "",
        hdcQualificationDate: "",
        overallEDL: "",
        hasChildren: "- select -",
        contactChildren: "- select -",
        custodialSentences: "",
        nonCustodialSentences: "",
        ageCategory: "- select -",
        statutorySupport: "- select -",
        shortSentence: "- select -",
        offendingHistory: "- select -",
        riskFactors: "",
        prisonNeeds: "",
        communityNeeds: "",
        additionalNeedsInfo: "",
        threeWayMeeting: "- select -",
        warmHandover: "- select -",
        matchedToMentor: "- select -",
        reasonNotMatched: "",
        dateMatched: "",
        notes: "",
        signupMeetingDate: "",
        signupMeetingOutcome: "- select -",
        hideProtected: "",
        birthGender: "- select -",
        disability: "- select -",
        maritalStatus: "- select -",
        maritalStatusOther: "",
        currentGender: "- select -",
        currentGenderOther: "",
        religion: "- select -",
        religionOther: "",
        sexualOrientation: "- select -",
        ethnicity: "- select -",
        ethnicityOther: "",
        isPregnant: "- select -",
        isMaternityPeriod: "- select -",
        area: "- select -",
        customerLocalAuthorityArea: "- select -",
        wingCellNumber: "",
        mentor: "- select -",
        initials: "",
        needsAnalysis: "",
        releaseDate: "",
        outcomeOfThreshold: "- select -",
        outcomeReason: "- select -",
        outcomeReasonText: "",
        otherOutcomeReason: "",
        childRisk: "- select -",
        knownAdultRisk: "- select -",
        staffRisk: "- select -",
        publicRisk: "- select -",
        linkWorker: "",
        communityRisk: "",
        prisonHistory: "",
        prisonNrneOther: "",
        formType: "- select -",
        supportService: "- search & select -",
        engagementType: "- select -",
        sharedWith: "- select -",
        returningCustomer: "- action -"
      },
      fileUpload: "Upload files",
      accordion: {
        mainAddress: "Pre Conviction Address",
        libAddress: "Liberation Address",
        mailingAddress: "Mailing Address",
        additionalAddress: "AdditionalAddress"
      },
      errorText: {
        notShared:
          "Your organisation does not currently have access to this record."
      }
    },
    feedback: {
      processing: "Processing request...",
      referralRequestSuccess: "Referral created successfully!",
      referralRequestRefreshPrompt:
        "Use the refresh function to see an updated referrals list.",
      referralRequestFailure: "Referral creation failed.",
      status: "Status",
      success: "Success",
      failure: "Failure",
      requestUnknown: "Something went wrong with the request",
      referralRequestUnknown: "Something went wrong with the referral request."
    },
    modal: {
      createReferralMessage: "Save referral?",
      modifyReferralMessage: "Are you sure you want to save these changes?",
      deleteReferralMessage:
        "Are you sure you want to delete this referral? (This action cannot be undone)",
      saveChangesMessage: "Are you sure you want to save these changes?",
      cancelChangesMessage: "Cancel changes?",
      setActiveMessage: "Set this referral's status to active?",
      setInactiveMessage: "Set this referral's status to inactive?",
      requestDeletePermissionMessage: "Please submit a reason for deletion:",
      shareMessage: "What organisations should this record be shared with?",
      saveDuplicateMessage:
        "A customer with this prison number already exists, would you like to request to start an additional journey?"
    },
    button: {
      loginLabel: "Login",
      editLabel: "Edit",
      deleteLabel: "Delete",
      requestDeleteLabel: "Request Delete Permission",
      cancelLabel: "Cancel",
      shareLabel: "Manage Sharing",
      saveLabel: "Save",
      saveAndCloseLabel: "Save and Close",
      yesLabel: "Yes",
      noLabel: "No",
      okLabel: "Confirm",
      addReferralLabel: "New Referral",
      resetLabel: "Go back to form selection",
      submitLabel: "Submit",
      searchLabel: "Search",
      programmeLinkText: "View Programme"
    },
    tooltip: {
      editReferral: "Edit Referral",
      deleteReferral: "Delete Referral",
      toggleStatus: "Click to change status",
      referralTypeWarning: "Referral Type cannot be changed after creation.",
      postcodeHomelessFormatHeader: "Postcode Format",
      postcodeHomelessFormatContent:
        "Please use the same format as one of the following: 'AA9A 9AA', 'A9A 9AA', 'A9 9AA', 'A99 9AA', 'AA9 9AA' or 'AA99 9AA'. Spaces aren't required.  If homeless, only the first half is necessary to indicate local authority area",
      postcodeFormatHeader: "Postcode Format",
      postcodeFormatContent:
        "Please use the same format as one of the following: 'AA9A 9AA', 'A9A 9AA', 'A9 9AA', 'A99 9AA', 'AA9 9AA' or 'AA99 9AA'. Spaces aren't required.",
      ninFormatHeader: "National Insurance Format",
      ninFormatContent:
        "Please enter the NIN in the same format as 'QQ123456C'. The following characters are not allowed. First Letter: DFIQUV. Second Letter: DFIQUVO. Final Letter must be: ABCD."
    },
    table: {
      header: {
        crmId: "Referral ID",
        dateOfReferral: "Date Of Referral Meeting",
        updatedDate: "Updated",
        createdDate: "Created",
        hdcQualificationDate: "HDC Qualification Date",
        active: "Active",
        firstName: "First Name",
        surname: "Last Name",
        initials: "Initials",
        scroNumber: "S Number",
        prisonSpecialist: "Prison Specialist",
        mentor: "Specialist",
        communityMentor: "Community Mentor",
        pNumber: "Prison Number",
        outcomeOfThreshold: "Outcome of Threshold",
        outcomeOfReferralNrs: "Outcome of Referral NRS",
        returningCustomer: "Returning Customer"
      },
      text: {
        noResults: "No results found."
      }
    },
    sectionHeaders: {
      identification: "Referral Identification Stage",
      referral: "Referral"
    },
    areas: {
      NRS: "NRS",
      NRNE: "NRNE",
      both: "both"
    },
    fieldValues: {
      yes: "Yes",
      no: "No",
      eligible: "Eligible",
      unsuitable: "Not Suitable",
      suitable: "Suitable",
      other: "Other",
      referral: "Referral",
      equalOpportunities: "Equal Opportunities",
      supportService: "Support Service",
      initialStage: "initial Stage",
      newJourney: "Start New Journey",
      updateJourney: "Update Existing Record",
      teamLead: "teamLead",
      mentor: "mentor"
    },
    states: {
      approved: "Approved",
      rejected: "Rejected"
    },
    orgTypes: {
      endToEndPartner: "End to End Partner",
      leadPartner: "Lead Partner"
    }
  }
});
