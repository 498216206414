/*global awsEnvUsersApiEndpoint, awsEnvOrganisationsApiEndpoint, awsEnvCustomersApiEndpoint, awsEnvReferralsApiEndpoint, awsEnvEngagementsApiEndpoint, awsEnvProgrammesApiEndpoint, awsEnvActivitiesApiEndpoint, awsEnvAssessmentsApiEndpoint, awsEnvFilesApiEndpoint, awsEnvEmploymentsApiEndpoint, awsEnvTasksApiEndpoint, awsEnvAmendmentsApiEndpoint, awsEnvCostsApiEndpoint, awsEnvReportsApiEndpoint, awsEnvFeedbackApiEndpoint*/

const development = {
  usersEndpoint: `${awsEnvUsersApiEndpoint}/users`,
  organisationsEndpoint: `${awsEnvOrganisationsApiEndpoint}/organisations`,
  customersEndpoint: `${awsEnvCustomersApiEndpoint}/customers`,
  referralsEndpoint: `${awsEnvReferralsApiEndpoint}/referrals`,
  activitiesEndpoint: `${awsEnvActivitiesApiEndpoint}/activities`,
  engagementsEndpoint: `${awsEnvEngagementsApiEndpoint}/engagements`,
  programmesEndpoint: `${awsEnvProgrammesApiEndpoint}/programmes`,
  assessmentsEndpoint: `${awsEnvAssessmentsApiEndpoint}/assessments`,
  filesEndpoint: `${awsEnvFilesApiEndpoint}/files`,
  tasksEndpoint: `${awsEnvTasksApiEndpoint}/`,
  employmentsEndpoint: `${awsEnvEmploymentsApiEndpoint}/employments`,
  costsEndpoint: `${awsEnvCostsApiEndpoint}/costs`,
  reportsEndpoint: `${awsEnvReportsApiEndpoint}/reports`,
  amendmentsEndpoint: `${awsEnvAmendmentsApiEndpoint}/requests`,
  addressEndpoint: `${awsEnvOrganisationsApiEndpoint}/postcode`,
  feedbacksEndpoint: `${awsEnvFeedbackApiEndpoint}/feedback`
};

const uat = {
  usersEndpoint: `${awsEnvUsersApiEndpoint}/users`,
  organisationsEndpoint: `${awsEnvOrganisationsApiEndpoint}/organisations`,
  customersEndpoint: `${awsEnvCustomersApiEndpoint}/customers`,
  referralsEndpoint: `${awsEnvReferralsApiEndpoint}/referrals`,
  activitiesEndpoint: `${awsEnvActivitiesApiEndpoint}/activities`,
  engagementsEndpoint: `${awsEnvEngagementsApiEndpoint}/engagements`,
  programmesEndpoint: `${awsEnvProgrammesApiEndpoint}/programmes`,
  assessmentsEndpoint: `${awsEnvAssessmentsApiEndpoint}/assessments`,
  filesEndpoint: `${awsEnvFilesApiEndpoint}/files`,
  tasksEndpoint: `${awsEnvTasksApiEndpoint}/`,
  employmentsEndpoint: `${awsEnvEmploymentsApiEndpoint}/employments`,
  costsEndpoint: `${awsEnvCostsApiEndpoint}/costs`,
  reportsEndpoint: `${awsEnvReportsApiEndpoint}/reports`,
  amendmentsEndpoint: `${awsEnvAmendmentsApiEndpoint}/requests`,
  addressEndpoint: `${awsEnvOrganisationsApiEndpoint}/postcode`,
  feedbacksEndpoint: `${awsEnvFeedbackApiEndpoint}/feedback`
};

const pp = {
  usersEndpoint: `${awsEnvUsersApiEndpoint}/users`,
  organisationsEndpoint: `${awsEnvOrganisationsApiEndpoint}/organisations`,
  customersEndpoint: `${awsEnvCustomersApiEndpoint}/customers`,
  referralsEndpoint: `${awsEnvReferralsApiEndpoint}/referrals`,
  activitiesEndpoint: `${awsEnvActivitiesApiEndpoint}/activities`,
  engagementsEndpoint: `${awsEnvEngagementsApiEndpoint}/engagements`,
  programmesEndpoint: `${awsEnvProgrammesApiEndpoint}/programmes`,
  assessmentsEndpoint: `${awsEnvAssessmentsApiEndpoint}/assessments`,
  filesEndpoint: `${awsEnvFilesApiEndpoint}/files`,
  tasksEndpoint: `${awsEnvTasksApiEndpoint}/`,
  employmentsEndpoint: `${awsEnvEmploymentsApiEndpoint}/employments`,
  costsEndpoint: `${awsEnvCostsApiEndpoint}/costs`,
  reportsEndpoint: `${awsEnvReportsApiEndpoint}/reports`,
  amendmentsEndpoint: `${awsEnvAmendmentsApiEndpoint}/requests`,
  addressEndpoint: `${awsEnvOrganisationsApiEndpoint}/postcode`,
  feedbacksEndpoint: `${awsEnvFeedbackApiEndpoint}/feedback`
};

const production = {
  usersEndpoint: `${awsEnvUsersApiEndpoint}/users`,
  organisationsEndpoint: `${awsEnvOrganisationsApiEndpoint}/organisations`,
  customersEndpoint: `${awsEnvCustomersApiEndpoint}/customers`,
  referralsEndpoint: `${awsEnvReferralsApiEndpoint}/referrals`,
  activitiesEndpoint: `${awsEnvActivitiesApiEndpoint}/activities`,
  engagementsEndpoint: `${awsEnvEngagementsApiEndpoint}/engagements`,
  programmesEndpoint: `${awsEnvProgrammesApiEndpoint}/programmes`,
  assessmentsEndpoint: `${awsEnvAssessmentsApiEndpoint}/assessments`,
  filesEndpoint: `${awsEnvFilesApiEndpoint}/files`,
  tasksEndpoint: `${awsEnvTasksApiEndpoint}/`,
  employmentsEndpoint: `${awsEnvEmploymentsApiEndpoint}/employments`,
  costsEndpoint: `${awsEnvCostsApiEndpoint}/costs`,
  reportsEndpoint: `${awsEnvReportsApiEndpoint}/reports`,
  amendmentsEndpoint: `${awsEnvAmendmentsApiEndpoint}/requests`,
  addressEndpoint: `${awsEnvOrganisationsApiEndpoint}/postcode`,
  feedbacksEndpoint: `${awsEnvFeedbackApiEndpoint}/feedback`
};

let environmentConfig = {};

switch (process.env.REACT_APP_STAGE) {
  case "development":
  default:
    environmentConfig = development;
    break;
  case "uat":
    environmentConfig = uat;
    break;
  case "pp":
    environmentConfig = pp;
    break;
  case "production":
    environmentConfig = production;
    break;
}

export const config = {
  ...environmentConfig
};
