import React from "react";
import TableComponent from "../../components/ReferralTable";
import { Loader, Divider } from "semantic-ui-react";
import { connect } from "react-redux";
import { referralActions } from "../../store/actions/referral.actions";
import { strings } from "../../resources";
import PropTypes from "prop-types";
import { RequestFeedback } from "../../components";
import { userActions } from "../../../Users/store/actions/user.actions";
import { taskActions } from "../../../Tasks/store/actions/task.actions";

const requestTimeout = 20000;

class ReferralListContainer extends React.Component {
  state = {
    unknownRequestStatus: false,
    pageIndex: 0,
    pages: 1,
    searchCriteria: !this.props.isReporting
      ? {
          size: 20,
          from: 0,
          query: "",
          fields: ["*"],
          clear: false,
          sort: {},
          activeOnly: false
        }
      : this.props.searchCriteria,
    searchBarQuery: ""
  };

  componentDidMount = async () => {
    this.timerID = setTimeout(() => {
      if (
        this.props.referralRequestStatus === undefined &&
        this.props.referralRequestMade
      ) {
        this.setState({ unknownRequestStatus: true });
      }
    }, requestTimeout);
    localStorage.removeItem("programmeId");
    localStorage.removeItem("programmeName");
    localStorage.removeItem("parentReferralId");
  };

  componentWillUnmount() {
    clearTimeout(this.timerID);
    this.props.clearReferralRequest();
    this.props.clearCreateTask();
  }

  handleRefresh = async () => {
    this.setState({ searchBarQuery: "" });
    await this.props.clearReferralRequest();
    await this.handleFetchData(this.state);
    await this.props.clearCreateTask();
  };

  handleChange = event => {
    let searchCriteria = { ...this.state.searchCriteria };
    this.setState({ searchBarQuery: event.target.value });
    searchCriteria.query = this.state.searchBarQuery;
    this.setState({ searchCriteria: searchCriteria });
  };

  handleSubmit = async e => {
    e.preventDefault();
    await this.handleFetchData(this.state);
  };

  handleFetchData = async state => {
    const sCrit = { ...this.state.searchCriteria };
    let sortObject = {};
    if (state.sorted) {
      state.sorted.forEach(sortCondition => {
        sortCondition.desc
          ? (sortObject[sortCondition.id] = { order: "desc" })
          : (sortObject[sortCondition.id] = { order: "asc" });
      });
    }

    sCrit.query = this.state.searchBarQuery;
    sCrit.fields = [
      "firstName.normalizer",
      "surname.normalizer",
      "pNumber.normalizer",
      "scroNumber.normalizer"
    ];

    if (!this.props.isReporting && !this.props.handleFetchData) {
      await this.props.updateSearchCriteria(
        this.props.headers,
        sCrit.query,
        sCrit.size,
        state.page * sCrit.size,
        sCrit.clear,
        sCrit.fields,
        sortObject,
        sCrit.activeOnly
      );
    } else {
      await this.props.handleFetchData(state);
    }

    if (sCrit.query.reportId) {
      sCrit.query.reportId = undefined;
    }

    await this.props.getUsers(
      this.props.headers,
      sCrit.query,
      10000,
      0,
      sCrit.clear,
      sCrit.fields,
      sCrit.sort,
      sCrit.activeOnly
    );
  };

  updatePageIndex = page => {
    this.setState({
      pageIndex: page
    });
  };

  getUsers = userListData => {
    let inputData = [];
    if (userListData) {
      userListData.forEach((result, index) => {
        if (result["_source"]) {
          inputData.push(result["_source"]);
        }
      });
    }
    let dropdownFields = [];
    let i = 0;
    inputData.forEach((object, key) => {
      dropdownFields.push({
        key: i,
        text: object.username,
        value: object.userId
      });
      i++;
    });
    return dropdownFields;
  };

  render = () => {
    const loading =
      this.props.loadingPage ||
      this.props.userLoadingPage ||
      this.props.reportsLoadingPage;

    return (
      <div>
        <div
          style={{
            display: loading ? "" : "none"
          }}
        >
          <Loader active>{strings.header.loading}</Loader>
        </div>
        <div
          style={{
            display: loading ? "none" : ""
          }}
        >
          <RequestFeedback
            requestStatus={this.props.referralRequestStatus}
            requestMade={this.props.referralRequestMade}
            unknownRequestStatus={this.state.unknownRequestStatus}
            processingMessage={strings.form.feedback.processing}
            successMessage={this.props.result}
            failureMessage={this.props.error}
            optionalRefreshMessage={
              strings.form.feedback.referralRequestRefreshPrompt
            }
            processingFeedbackMessage={strings.form.feedback.processing}
            unknownFeedbackMessage={
              strings.form.feedback.referralRequestUnknown
            }
            statusFeedbackMessage={strings.form.feedback.status}
            successFeedbackMessage={strings.form.feedback.success}
            errorDetails={this.props.errorDetails}
          />
          <RequestFeedback
            requestStatus={this.props.taskRequestStatus}
            requestMade={this.props.taskRequestMade}
            unknownRequestStatus={this.state.unknownRequestStatus}
            successMessage={this.props.createTaskMessage}
            failureMessage={this.props.taskError}
            processingFeedbackMessage={strings.feedback.processing}
            unknownFeedbackMessage={strings.feedback.requestUnknown}
            statusFeedbackMessage={strings.feedback.status}
            successFeedbackMessage={strings.feedback.success}
            errorDetails={this.props.taskErrorDetails}
          />
          <Divider hidden />
          <TableComponent
            isReporting={this.props.isReporting}
            headers={this.props.headers}
            history={this.props.history}
            referralListData={
              !this.props.isReporting
                ? this.props.referralListData
                : this.props.reportReferrals
            }
            handleRefresh={this.handleRefresh}
            pageSize={this.state.searchCriteria.size}
            pages={this.state.pages}
            handleFetchData={this.handleFetchData}
            totalResults={
              !this.props.isReporting
                ? this.props.totalResults
                : this.props.totalReportReferrals
            }
            updatePageIndex={this.updatePageIndex}
            roleId={this.props.roleId}
            area={this.props.area}
            userList={this.getUsers(this.props.userListData)}
            searchBarQuery={this.state.searchBarQuery}
            handleChange={this.handleChange}
            handleSubmit={this.handleSubmit}
          />
        </div>
      </div>
    );
  };
}

ReferralListContainer.propTypes = {
  referralListData: PropTypes.array.isRequired,
  loadingPage: PropTypes.bool.isRequired,
  getReferrals: PropTypes.func.isRequired,
  updateSearchCriteria: PropTypes.func.isRequired,
  headers: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  errorDetails: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  isReporting: PropTypes.bool,
  reportReferrals: PropTypes.array
};

const mapStateToProps = state => {
  const { referrals, tasks } = state;
  const { roleId, area } = state.auth;
  const { userListData, loadingPage: userLoadingPage } = state.users;
  const {
    loadingPage: reportsLoadingPage,
    reportReferrals,
    totalReportReferrals
  } = state.reports;
  const {
    referralListData,
    loadingPage,
    referralRequestStatus,
    referralRequestMade,
    result,
    error,
    totalResults,
    errorDetails
  } = referrals;

  const {
    taskRequestStatus,
    taskRequestMade,
    result: taskResult,
    createTaskMessage,
    error: taskError
  } = tasks;
  return {
    referralListData,
    loadingPage,
    referralRequestStatus,
    referralRequestMade,
    result,
    error,
    totalResults,
    roleId,
    area,
    errorDetails,
    userListData,
    userLoadingPage,
    reportsLoadingPage,
    reportReferrals,
    totalReportReferrals,
    taskRequestStatus,
    taskRequestMade,
    taskResult,
    createTaskMessage,
    taskError
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getReferrals: (
      headers,
      queryString,
      size,
      from,
      clear,
      fields,
      sort,
      activeOnly
    ) => {
      dispatch(
        referralActions.getReferrals(
          headers,
          queryString,
          size,
          from,
          clear,
          fields,
          sort,
          activeOnly
        )
      );
    },
    updateSearchCriteria: (
      headers,
      data,
      size,
      from,
      clear,
      fields,
      sort,
      activeOnly
    ) => {
      dispatch(
        referralActions.updateSearchCriteria(
          headers,
          data,
          size,
          from,
          clear,
          fields,
          sort,
          activeOnly
        )
      );
    },
    clearReferralRequest: () => {
      dispatch(referralActions.clearReferralRequest());
    },
    getUsers: async (
      headers,
      queryString,
      size,
      from,
      clear,
      fields,
      sort,
      activeOnly
    ) => {
      await dispatch(
        userActions.getUsers(
          headers,
          queryString,
          size,
          from,
          clear,
          fields,
          sort,
          activeOnly
        )
      );
    },
    clearCreateTask: () => {
      dispatch(taskActions.clearCreateTask());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReferralListContainer);
