import React from "react";
import {
  Button,
  List,
  Segment,
  Popup,
  Icon,
  Input,
  Loader,
  Label
} from "semantic-ui-react";
import { connect } from "react-redux";
import { fileActions } from "../store/actions/file.actions";
import { strings } from "../resources/Strings";
import "./css/FileManager.scss";
import PropTypes from "prop-types";
import fetch from "isomorphic-fetch";

// Props passed in:
// filePath: the file path in the s3 bucket to work with
// allowEdit: whether or not to display the upload functionality

class FileManager extends React.Component {
  componentWillUnmount = () => {
    this.props.purgeState();
  };

  componentDidMount = async () => {
    if (this.props.shouldSaveFiles) {
      await this.attemptUpload();
    }
    this.props.getFiles(
      this.props.filePath + this.props.entityId + "/",
      this.props.headers
    );
  };

  componentDidUpdate = async () => {
    if (this.props.shouldSaveFiles) {
      await this.attemptUpload();
    }
  };

  referFile = () => {
    const fileProps = {
      file: this.uploadInput.files[0]
    };
    this.handleUpload(fileProps);
  };

  handleUpload = async fileProps => {
    if (fileProps.file) {
      // Read file to a base64 string (then trim prefix)
      const reader = new FileReader();
      reader.readAsDataURL(fileProps.file);
      reader.onload = () => {
        const encodedFile = reader.result;
        fetch(encodedFile)
          .then(result => result.blob())
          .then(blob => {
            if (blob) {
              const newFile = {
                fileName: fileProps.file.name,
                url: "",
                description: "",
                fileContent: blob,
                new: true
              };
              this.props.addLocalFile(newFile);
            }
          })
          .catch(err => {
            console.log(err);
          });
      };
    }
  };

  attemptUpload = async () => {
    return this.props.commitChanges(
      this.props.localData,
      this.props.remoteData,
      this.props.filePath,
      this.props.headers,
      this.props.entity,
      this.props.entityId
    );
  };

  onDescriptionChange = (index, newDesc) => {
    const newValue = this.props.localData.slice()[index];
    newValue.description = newDesc;
    this.props.updateLocalFiles(index, newValue);
  };

  render = () => {
    const classNames =
      "field fileUpload" + (this.props.disabled ? " fileDisabled" : "");
    // Only show if there are files or the user can upload files
    if (this.props.localData.length !== 0 || this.props.allowEdit) {
      return (
        <div className={classNames}>
          <label className="fileUploadLabel">{this.props.filesLabel}</label>
          <Segment className="fileSegment" style={{ marginTop: 0.3 + "em" }}>
            {!this.props.loadingFileData ? (
              <List relaxed divided>
                {this.props.localData.map((item, key) => (
                  <List.Item className="uploadSubHeader" key={"item" + key}>
                    <List.Content key={"content" + key}>
                      <div className="fileActions">
                        <Popup
                          trigger={
                            <Icon
                              style={{
                                display: this.props.allowEdit ? "" : "none"
                              }}
                              circular
                              name="delete"
                              color="red"
                              link
                              size="small"
                              onClick={e => {
                                if (!this.props.disabled) {
                                  this.props.deleteLocalFile(key);
                                  this.props.filesHaveChanged();
                                } else {
                                  return false;
                                }
                              }}
                            />
                          }
                          content={<div>{strings.delete + item.fileName}</div>}
                        />
                        {/* Below trims filenames to 28 chars and appends ...  */}
                        {item.url !== "" ? (
                          <span>
                            <a className="fileText" href={item.url}>
                              <List.Icon name="file" />
                              {item.fileName.substring(0, 28)}
                              {item.fileName.length > 28 && "..."}
                            </a>
                          </span>
                        ) : (
                            <span>
                              <Loader
                                indeterminate
                                inline
                                size="mini"
                                active
                                className="pendingFileLoader"
                              />
                              {item.fileName.substring(0, 28)}
                              {item.fileName.length > 28 && "..."}
                            </span>
                          )}
                      </div>
                      {this.props.allowEdit ? (
                        <Input
                          className="fileDescInput"
                          placeholder={strings.placeholder}
                          label={{ icon: "bars" }}
                          labelPosition="left corner"
                          size="mini"
                          value={item.description || ""}
                          onChange={(e, data) => {
                            this.onDescriptionChange(key, data.value);
                            this.props.filesHaveChanged();
                          }}
                        />
                      ) : (
                          <Label className="fileDescReadOnly" basic>
                            {item.description}
                          </Label>
                        )}
                    </List.Content>
                  </List.Item>
                ))}
                <List.Item
                  className="uploadSubHeader"
                  style={{
                    display: this.props.localData.length === 0 ? "" : "none"
                  }}
                >
                  {strings.noFilesFound}
                </List.Item>
              </List>
            ) : this.props.error ? (
              <div className="fileLoader">{this.props.error.message}</div>
            ) : (
                  <div className="fileLoader">
                    <Loader active>{strings.loadingFiles}</Loader>
                  </div>
                )}
            {!this.props.loadingFileData && this.props.allowEdit && (
              <div>
                <Button
                  as="label"
                  htmlFor="fileUploadButton"
                  color="green"
                  basic
                  disabled={this.props.disabled}
                >
                  <Icon name="cloud upload" />
                  {strings.uploadFile}
                </Button>
                <input
                  id="fileUploadButton"
                  hidden
                  name="files"
                  ref={ref => {
                    this.uploadInput = ref;
                  }}
                  type="file"
                  onChange={() => {
                    this.referFile();
                    this.props.filesHaveChanged();
                  }}
                />
              </div>
            )}
          </Segment>
        </div>
      );
    } else {
      return null;
    }
  };
}

FileManager.propTypes = {
  headers: PropTypes.object.isRequired,
  filePath: PropTypes.string.isRequired,
  allowEdit: PropTypes.bool.isRequired,
  localData: PropTypes.array.isRequired,
  remoteData: PropTypes.array.isRequired
};

const mapStateToProps = state => {
  const { file } = state;
  const { localData, remoteData, loadingFileData, error } = file;
  return {
    localData,
    remoteData,
    loadingFileData,
    error
  };
};

const mapDispatchToProps = dispatch => {
  return {
    purgeState: () => {
      dispatch(fileActions.purgeState());
    },
    getFiles: (path, headers) => {
      dispatch(fileActions.getFiles(path, headers));
    },
    deleteLocalFile: index => {
      dispatch(fileActions.deleteLocalFile(index));
    },
    addLocalFile: newFile => {
      dispatch(fileActions.addLocalFile(newFile));
    },
    updateLocalFiles: (index, newVal) => {
      dispatch(fileActions.updateLocalFiles(index, newVal));
    },
    commitChanges: (
      localData,
      remoteData,
      filePath,
      headers,
      entity,
      entityId
    ) => {
      return dispatch(
        fileActions.commitChanges(
          localData,
          remoteData,
          filePath,
          headers,
          entity,
          entityId
        )
      );
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true
})(FileManager);
