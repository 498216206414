import React from "react";
import PropTypes from "prop-types";
import { Form } from "semantic-ui-react";
import { strings } from "../../resources";
import { ValidatedFormDropdown, ValidatedFormInput } from "../validation";
import "../css/ProgrammeComponents.scss";
import { yesNoOptions, livingWithOptions } from "../../resources";

const HouseholdStatusFields = props => (
  <div className="infoBlock">
    <div className="infoHeader">
      {strings.form.header.householdStatusHeader}
    </div>
    <div className="infoWrapper">
      <Form.Group>
        <ValidatedFormDropdown
          formInvalid={props.formInvalid}
          inputData={props.selectedProgrammeData.livingWith}
          viewData={props.selectedProgrammeData.livingWith}
          dropdownLabel={strings.form.label.livingWith}
          dropdownName="livingWith"
          dropdownOptions={livingWithOptions}
          dropdownPlaceholder={strings.form.placeholder.livingWith}
          formFieldWidth={8}
          handleChange={props.handleChange}
          validationResult={props.validationResults.livingWith}
          mode={props.mode}
        />
        <ValidatedFormDropdown
          formInvalid={props.formInvalid}
          inputData={props.selectedProgrammeData.hasTenancy}
          viewData={props.selectedProgrammeData.hasTenancy}
          dropdownLabel={strings.form.label.hasTenancy}
          dropdownName="hasTenancy"
          dropdownOptions={yesNoOptions}
          dropdownPlaceholder={strings.form.placeholder.hasTenancy}
          formFieldWidth={8}
          handleChange={props.handleChange}
          validationResult={props.validationResults.hasTenancy}
          mode={props.mode}
        />
      </Form.Group>
      <Form.Group>
        <ValidatedFormInput
          formInvalid={props.formInvalid}
          inputData={props.selectedProgrammeData.homelessDuration}
          inputLabel={strings.form.label.homelessDuration}
          inputName="homelessDuration"
          inputPlaceholder={strings.form.placeholder.homelessDuration}
          formFieldWidth={8}
          handleChange={props.handleChange}
          validationResult={props.validationResults.homelessDuration}
          required={false}
          mode={props.mode}
        />
        <ValidatedFormDropdown
          formInvalid={props.formInvalid}
          inputData={props.selectedProgrammeData.homelessCaseworkReferral}
          viewData={props.selectedProgrammeData.homelessCaseworkReferral}
          dropdownLabel={strings.form.label.homelessCaseworkReferral}
          dropdownName="homelessCaseworkReferral"
          dropdownOptions={yesNoOptions}
          dropdownPlaceholder={
            strings.form.placeholder.homelessCaseworkReferral
          }
          formFieldWidth={8}
          handleChange={props.handleChange}
          validationResult={props.validationResults.homelessCaseworkReferral}
          required={false}
          mode={props.mode}
        />
      </Form.Group>
      <Form.Group>
        <ValidatedFormInput
          formInvalid={props.formInvalid}
          inputData={props.selectedProgrammeData.stayingWhereOnRelease}
          inputLabel={strings.form.label.stayingWhereOnRelease}
          inputName="stayingWhereOnRelease"
          inputPlaceholder={strings.form.placeholder.stayingWhereOnRelease}
          formFieldWidth={8}
          handleChange={props.handleChange}
          validationResult={props.validationResults.stayingWhereOnRelease}
          required={false}
          mode={props.mode}
        />
        <ValidatedFormDropdown
          formInvalid={props.formInvalid}
          inputData={props.selectedProgrammeData.hasFamilySupport}
          viewData={props.selectedProgrammeData.hasFamilySupport}
          dropdownLabel={strings.form.label.hasFamilySupport}
          dropdownName="hasFamilySupport"
          dropdownOptions={yesNoOptions}
          dropdownPlaceholder={strings.form.placeholder.hasFamilySupport}
          formFieldWidth={8}
          handleChange={props.handleChange}
          validationResult={props.validationResults.hasFamilySupport}
          mode={props.mode}
        />
      </Form.Group>
    </div>
  </div>
);

HouseholdStatusFields.propTypes = {
  formInvalid: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  mode: PropTypes.string.isRequired,
  selectedProgrammeData: PropTypes.object.isRequired,
  validationResults: PropTypes.object
};

export default HouseholdStatusFields;
