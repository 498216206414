import React from "react";
import { Form, Message } from "semantic-ui-react";
import PropTypes from "prop-types";
import DisplayValidatedInfo from "./DisplayValidatedInfo";

const ValidatedFormDropdown = props => {
  const viewData = props.viewData
    ? Array.isArray(props.viewData)
      ? props.viewData.join(", ")
      : props.viewData
    : "";
  return (props.mode && props.mode === "view") || props.dropdownDisabled ? (
    <DisplayValidatedInfo
      className="formInputs"
      inputData={viewData}
      inputLabel={props.dropdownLabel}
      formFieldWidth={props.formFieldWidth}
      popUp={props.popUp}
      required={props.required === false ? false : true}
    />
  ) : (
    <Form.Field width={props.formFieldWidth}>
      <Form.Dropdown
        label={props.dropdownLabel}
        name={props.dropdownName}
        onChange={props.handleChange}
        placeholder={props.dropdownPlaceholder}
        fluid={props.dropdownFluid}
        search
        selection
        multiple={props.dropdownMultiple}
        required={props.required === false ? false : true}
        options={props.dropdownOptions}
        value={props.inputData || ""}
        disabled={props.dropdownDisabled}
        onSearchChange={props.onSearchChange}
        closeOnChange={props.closeOnChange}
        open={props.open}
        clearable={!props.unclearable && !props.dropdownDisabled}
        noResultsMessage={props.noResultsMessage}
      />
      <Message
        className={props.dropdownClassname}
        attached="bottom"
        error
        hidden={props.hideMessage}
        visible={props.formInvalid && (props.validationResult ? true : false)}
        content={
          props.validationResult ? props.validationResult.toString() : ""
        }
      />
    </Form.Field>
  );
};

ValidatedFormDropdown.propTypes = {
  formInvalid: PropTypes.bool,
  inputData: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  dropdownLabel: PropTypes.string.isRequired,
  dropdownName: PropTypes.string.isRequired,
  dropdownOptions: PropTypes.array.isRequired,
  dropdownPlaceholder: PropTypes.string.isRequired,
  formFieldWidth: PropTypes.number.isRequired,
  handleChange: PropTypes.func.isRequired,
  validationResult: PropTypes.array,
  mode: PropTypes.string,
  viewData: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onSearchChange: PropTypes.func,
  closeOnChange: PropTypes.bool,
  open: PropTypes.bool,
  noResultsMessage: PropTypes.string
};

export default React.memo(ValidatedFormDropdown);
