import React from "react";
import PropTypes from "prop-types";
import { Form } from "semantic-ui-react";
import { strings } from "../../resources";

import { ValidatedFormDropdown, ValidatedFormInput } from "../validation";
import "../css/ProgrammeComponents.scss";
import { yesNoOptions, prisonSupportServiceOptions } from "../../resources";

const PrisonSupportServiceFields = props => (
  <div className="infoBlock">
    <div className="infoHeader">
      {strings.form.header.prisonSupportServiceHeader}
    </div>
    <div className="infoWrapper">
      <Form.Group>
        <ValidatedFormDropdown
          formInvalid={props.formInvalid}
          inputData={props.selectedProgrammeData.requiresPrisonSupport}
          viewData={props.selectedProgrammeData.requiresPrisonSupport}
          dropdownLabel={strings.form.label.requiresPrisonSupport}
          dropdownName="requiresPrisonSupport"
          dropdownOptions={yesNoOptions}
          dropdownPlaceholder={strings.form.placeholder.requiresPrisonSupport}
          formFieldWidth={8}
          handleChange={props.handleChange}
          validationResult={props.validationResults.requiresPrisonSupport}
          required={false}
          mode={props.mode}
        />
        <ValidatedFormDropdown
          formInvalid={props.formInvalid}
          inputData={props.selectedProgrammeData.prisonSupportService || []}
          viewData={props.selectedProgrammeData.prisonSupportService}
          dropdownLabel={strings.form.label.prisonSupportService}
          dropdownName="prisonSupportService"
          dropdownOptions={prisonSupportServiceOptions}
          dropdownPlaceholder={strings.form.placeholder.prisonSupportService}
          formFieldWidth={8}
          handleChange={props.handleChange}
          dropdownMultiple={true}
          validationResult={props.validationResults.prisonSupportService}
          required={false}
          mode={props.mode}
        />
      </Form.Group>
      <Form.Group>
        <ValidatedFormInput
          formInvalid={props.formInvalid}
          inputData={props.selectedProgrammeData.prisonSupportServiceOther}
          inputLabel={strings.form.label.prisonSupportServiceOther}
          inputName="prisonSupportServiceOther"
          inputPlaceholder={strings.form.placeholder.prisonSupportServiceOther}
          formFieldWidth={8}
          handleChange={props.handleChange}
          validationResult={props.validationResults.prisonSupportServiceOther}
          required={false}
          mode={props.mode}
        />
      </Form.Group>
    </div>
  </div>
);

PrisonSupportServiceFields.propTypes = {
  formInvalid: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  mode: PropTypes.string.isRequired,
  selectedProgrammeData: PropTypes.object.isRequired,
  validationResults: PropTypes.object
};

export default PrisonSupportServiceFields;
