import React from "react";
import PropTypes from "prop-types";
import { Form } from "semantic-ui-react";
import { strings } from "../../resources";
import { dropdownHelper } from "../../helpers/index";

import { ShowIfSameArea } from "../../../App/components/";
import { ValidatedFormDropdown } from "../validation";
import "../css/ReferralComponents.scss";

class MentorFields extends React.Component {
  render = () => {
    return (
      <div className="infoBlock">
        <div className="infoHeader">{strings.form.header.mentorHeader}</div>
        <div className="infoWrapper">
          <ShowIfSameArea
            area={this.props.area}
            allowedArea={strings.areas.NRS}
          >
            <Form.Group>
              <ValidatedFormDropdown
                formInvalid={this.props.formInvalid}
                inputData={this.props.selectedReferralData.prisonOrganisation}
                viewData={
                  dropdownHelper.getNameFromValues(
                    this.props.selectedReferralData.prisonOrganisation,
                    this.props.organisations
                  ) || ""
                }
                dropdownLabel={strings.form.label.prisonOrganisation}
                dropdownName="prisonOrganisation"
                dropdownOptions={this.props.organisations.filter(
                  org =>
                    org.maintype === strings.orgTypes.endToEndPartner ||
                    org.maintype === strings.orgTypes.leadPartner
                )}
                dropdownPlaceholder={
                  strings.form.placeholder.prisonOrganisation
                }
                formFieldWidth={8}
                handleChange={this.props.handleChangePrisonOrg}
                validationResult={
                  this.props.validationResults.prisonOrganisation
                }
                mode={this.props.mode}
              />
              <ValidatedFormDropdown
                formInvalid={this.props.formInvalid}
                inputData={this.props.selectedReferralData.prisonSpecialist}
                viewData={
                  dropdownHelper.getNameFromValues(
                    this.props.selectedReferralData.prisonSpecialist,
                    this.props.mentorsPrisonSpecialistView
                  ) || ""
                }
                dropdownLabel={strings.form.label.prisonSpecialist}
                dropdownName="prisonSpecialist"
                dropdownPlaceholder={strings.form.placeholder.prisonSpecialist}
                dropdownOptions={this.props.mentorsPrisonSpecialistSelect || []}
                formFieldWidth={8}
                handleChange={this.props.handleChange}
                validationResult={this.props.validationResults.prisonSpecialist}
                mode={this.props.mode}
              />
            </Form.Group>
            <Form.Group>
              <ValidatedFormDropdown
                formInvalid={this.props.formInvalid}
                inputData={
                  this.props.selectedReferralData.communityOrganisation
                }
                viewData={
                  dropdownHelper.getNameFromValues(
                    this.props.selectedReferralData.communityOrganisation,
                    this.props.organisations
                  ) || ""
                }
                dropdownLabel={strings.form.label.communityOrganisation}
                dropdownName="communityOrganisation"
                dropdownOptions={this.props.organisations.filter(
                  org =>
                    org.maintype === strings.orgTypes.endToEndPartner ||
                    org.maintype === strings.orgTypes.leadPartner
                )}
                dropdownPlaceholder={
                  strings.form.placeholder.communityOrganisation
                }
                formFieldWidth={8}
                handleChange={this.props.handleChangeCommunity}
                validationResult={
                  this.props.validationResults.communityOrganisation
                }
                mode={this.props.mode}
              />
              <ValidatedFormDropdown
                formInvalid={this.props.formInvalid}
                inputData={this.props.selectedReferralData.communityMentor}
                viewData={
                  dropdownHelper.getNameFromValues(
                    this.props.selectedReferralData.communityMentor,
                    this.props.mentorsArea
                  ) || ""
                }
                dropdownLabel={strings.form.label.communityMentor}
                dropdownName="communityMentor"
                dropdownOptions={
                  this.props.mentorsCommunitySpecialistSelect || []
                }
                dropdownPlaceholder={strings.form.placeholder.communityMentor}
                formFieldWidth={8}
                handleChange={this.props.handleChange}
                validationResult={this.props.validationResults.communityMentor}
                mode={this.props.mode}
              />
            </Form.Group>
          </ShowIfSameArea>
          <ShowIfSameArea
            area={this.props.area}
            allowedArea={strings.areas.NRNE}
          >
            <Form.Group>
              <ValidatedFormDropdown
                formInvalid={this.props.formInvalid}
                inputData={this.props.selectedReferralData.mentor}
                viewData={
                  dropdownHelper.getNameFromValues(
                    this.props.selectedReferralData.mentor,
                    this.props.mentorsArea
                  ) || ""
                }
                dropdownLabel={strings.form.label.mentor}
                dropdownName="mentor"
                dropdownOptions={this.props.mentorsArea || []}
                dropdownPlaceholder={strings.form.placeholder.mentor}
                formFieldWidth={8}
                handleChange={this.props.handleChange}
                validationResult={this.props.validationResults.mentor}
                mode={this.props.mode}
              />
            </Form.Group>
          </ShowIfSameArea>
        </div>
      </div>
    );
  };
}

MentorFields.propTypes = {
  formInvalid: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  mode: PropTypes.string.isRequired,
  selectedReferralData: PropTypes.object.isRequired,
  validationResults: PropTypes.object
};

export default MentorFields;
