import { isEmpty } from "./objectHelpers";

const getListOfMentors = (
  area,
  userListData,
  organisation,
  refStats,
  assignedCommunityMentor,
  assignedPrisonMentor
) => {
  let communityMentorCaseLoads = refStats;
  //only add the ones of the correct role type to the list and are in the correct area.
  //If organisation is not set, return all, else return only ones of matching org
  let data = [];
  if (userListData) {
    userListData.forEach(result => {
      if (result["_source"]) {
        if (
          (hasAreaAndRole(result["_source"], area) ||
            hasTeamLeadRole(result["_source"])) &&
          !organisation
        ) {
          data.push(result["_source"]);
        } else if (
          (hasAreaAndRole(result["_source"], area) &&
            hasValue(result["_source"], "organisationId", organisation)) ||
          hasTeamLeadRole(result["_source"])
        ) {
          data.push(result["_source"]);
        }
      }
    });
  }

  if (
    !isEmpty(assignedCommunityMentor) &&
    !isEmpty(assignedPrisonMentor) &&
    assignedCommunityMentor.userId === assignedPrisonMentor.userId
  ) {
    data.push(assignedCommunityMentor);
  } else if (
    !isEmpty(assignedCommunityMentor) &&
    !isEmpty(assignedPrisonMentor)
  ) {
    data.push(assignedCommunityMentor);
    data.push(assignedPrisonMentor);
  } else if (!isEmpty(assignedCommunityMentor)) {
    data.push(assignedCommunityMentor);
  } else if (!isEmpty(assignedPrisonMentor)) {
    data.push(assignedPrisonMentor);
  }

  let dropdownFields = [];
  data.forEach((object, key) => {
    //format the data in an appropriate format for a dropdown list
    dropdownFields.push({
      key: key,
      text: object.firstName + " " + object.surname,
      value: object.userId,
      caseloadmax: object.caseloadMax,
      organisation: object.organisationId,
      prison: object.prison,
      localauthorityarea: object.localAuthorityArea,
      caseload: 0,
      roleid: object.roleId
    });
  });

  //add caseloads to the mentors
  if (
    dropdownFields &&
    dropdownFields.length >= 0 &&
    communityMentorCaseLoads &&
    communityMentorCaseLoads.length >= 0
  ) {
    dropdownFields.forEach(user => {
      communityMentorCaseLoads.forEach(communityMentor => {
        if (user.value === communityMentor.key) {
          user.caseload = communityMentor.doc_count;
        }
      });
    });
  }

  return dropdownFields;
};

const hasValue = (obj, key, value) => {
  return obj.hasOwnProperty(key) && obj[key] === value;
};

const hasAreaAndRole = (source, area) => {
  return hasValue(source, "area", area) && hasValue(source, "roleId", "mentor");
};

const hasTeamLeadRole = source => {
  return hasValue(source, "roleId", "teamLead");
};

export default getListOfMentors;
