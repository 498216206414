export const amendmentActionTypes = {
  REQUEST_DELETE_PERMISSION_REQUEST: "REQUEST_DELETE_PERMISSION_REQUEST",
  REQUEST_DELETE_PERMISSION_SUCCESS: "REQUEST_DELETE_PERMISSION_SUCCESS",
  REQUEST_DELETE_PERMISSION_FAILURE: "REQUEST_DELETE_PERMISSION_FAILURE",

  GET_DELETE_PERMISSION_STATUS_REQUEST: "GET_DELETE_PERMISSION_STATUS_REQUEST",
  GET_DELETE_PERMISSION_STATUS_SUCCESS: "GET_DELETE_PERMISSION_STATUS_SUCCESS",
  GET_DELETE_PERMISSION_STATUS_FAILURE: "GET_DELETE_PERMISSION_STATUS_FAILURE",

  SET_DELETE_PERMISSION_STATUS_ACTIVE_REQUEST:
    "SET_DELETE_PERMISSION_STATUS_ACTIVE_REQUEST",
  SET_DELETE_PERMISSION_STATUS_ACTIVE_SUCCESS:
    "SET_DELETE_PERMISSION_STATUS_ACTIVE_SUCCESS",
  SET_DELETE_PERMISSION_STATUS_ACTIVE_FAILURE:
    "SET_DELETE_PERMISSION_STATUS_ACTIVE_FAILURE",

  UPDATE_DELETE_PERMISSION_STATUS_REQUEST:
    "UPDATE_DELETE_PERMISSION_STATUS_REQUEST",
  UPDATE_DELETE_PERMISSION_STATUS_SUCCESS:
    "UPDATE_DELETE_PERMISSION_STATUS_SUCCESS",
  UPDATE_DELETE_PERMISSION_STATUS_FAILURE:
    "UPDATE_DELETE_PERMISSION_STATUS_FAILURE"
};
