import React from "react";
import ReactTable from "react-table";
import { BasicModal, TableFunctions } from ".";
import { strings } from "../resources";
import PropTypes from "prop-types";
import moment from "moment";
import { permissions } from "../../App/resources";

class TableComponent extends React.Component {
  getTdProps = (state, rowInfo, column, instance) => {
    if (column) {
      if (column.id !== "delete" && column.id !== "active") {
        return {
          onClick: () => {
            if (rowInfo) {
              this.props.history.push(
                "/activities/view/" +
                  this.props.referralId +
                  "/" +
                  rowInfo.original.activityId
              );
            }
          }
        };
      }
    }
    return {}; // getTdProps requires a return statement
  };

  mapSearchResultsToRows = () => {
    const { activityListData } = this.props;
    let data = [];
    if (activityListData) {
      activityListData.forEach((result, index) => {
        if (result["_source"]) {
          data.push(result["_source"]);
        }
      });
    }
    return data;
  };

  render = () => {
    let data = this.mapSearchResultsToRows();
    let pages = Math.ceil(this.props.totalResults / this.props.pageSize);
    const cellTruncate = length => {
      return row => {
        let value = row.value ? row.value : "";
        let truncatedValue =
          value.length > length ? (
            <div>{value.substring(0, length)}...</div>
          ) : (
            <div>{value}</div>
          );
        return truncatedValue;
      };
    };
    const columns = [
      {
        Header: strings.table.header.firstName,
        accessor: "firstName",
        headerStyle: { textAlign: "left" },
        Cell: cellTruncate(20)
      },
      {
        Header: strings.table.header.surname,
        accessor: "surname",
        headerStyle: { textAlign: "left" },
        Cell: cellTruncate(20)
      },
      {
        Header: strings.table.header.pNumber,
        accessor: "pNumber",
        headerStyle: { textAlign: "left" },
        Cell: cellTruncate(20)
      },
      {
        Header: strings.table.header.scroNumber,
        accessor: "scroNumber",
        show:
          this.props.area === strings.areas.NRS ||
          this.props.area === strings.areas.both,
        headerStyle: { textAlign: "left" },
        Cell: cellTruncate(20)
      },
      {
        id: "dateOfActivity",
        Header: strings.table.header.dateOfActivity,
        accessor: act =>
          moment(act.dateOfActivity).local().format("DD/MM/YYYY"),
        headerStyle: { textAlign: "left" },
        Cell: cellTruncate(20)
      },
      {
        Header: strings.table.header.type,
        accessor: "type",
        headerStyle: { textAlign: "left" },
        Cell: cellTruncate(20)
      },
      {
        Header: strings.table.header.supportServiceEngaged,
        accessor: "supportServiceEngaged",
        headerStyle: { textAlign: "left" },
        Cell: cellTruncate(20)
      }
    ];

    return (
      <div>
        <TableFunctions
          headers={this.props.headers}
          history={this.props.history}
          handleRefresh={this.props.handleRefresh}
          headerList={strings.header.activityList}
          buttonAddLabel={strings.button.addActivityLabel}
          permissionsCreate={permissions.activities.create}
          headerClass="pageHeader"
          refreshButtonClass="refreshButton"
          addButtonClass="addButton"
          addButtonLink="/activities/create"
          hideCreate={this.props.hideCreate}
          handleChange={this.props.handleChange}
          searchBarQuery={this.props.searchBarQuery}
          handleSubmit={this.props.handleSubmit}
          hideSearchBar={this.props.hideSearchBar}
          parentPage={this.props.parentPage}
          returnToParent={this.props.returnToParent}
        />
        <div>
          <ReactTable
            manual
            data={data}
            pages={pages}
            defaultPageSize={this.props.pageSize}
            showPagination={true}
            getTdProps={this.getTdProps}
            onFetchData={(state, instance) => {
              this.props.handleFetchData(state);
              this.props.updatePageIndex(state);
            }}
            columns={columns}
            className="-highlight"
            showPageSizeOptions={false}
            minRows="1"
            noDataText={strings.table.text.noResults}
            previousText="<"
            nextText=">"
            pageText=""
          />
          <BasicModal
            confirmMessage={strings.modal.deleteActivityMessage}
            handleModalConfirm={this.props.handleModalConfirm}
            modalClassName="deleteActivityModal"
            modalOpen={this.props.deleteModalOpen}
            showNoButton={true}
            showOkButton={false}
            showYesButton={true}
            toggleModal={this.props.toggleDeleteModal}
            noLabel={strings.button.noLabel}
            yesLabel={strings.button.yesLabel}
            okLabel={strings.button.okLabel}
          />
        </div>
      </div>
    );
  };
}

TableComponent.propTypes = {
  deleteModalOpen: PropTypes.bool.isRequired,
  handleModalConfirm: PropTypes.func.isRequired,
  activityListData: PropTypes.array.isRequired,
  headers: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  toggleDeleteModal: PropTypes.func.isRequired,
  pageSize: PropTypes.number.isRequired,
  handleFetchData: PropTypes.func.isRequired,
  totalResults: PropTypes.number.isRequired,
  updatePageIndex: PropTypes.func.isRequired
};

export default TableComponent;
