import { API } from "../../services";
import { activityActionTypes } from "./activity.actionTypes";

const api = new API();

const createActivity = (details, headers) => {
  const request = details => {
    return { type: activityActionTypes.CREATE_ACTIVITY_REQUEST, details };
  };
  const success = response => {
    return { type: activityActionTypes.CREATE_ACTIVITY_SUCCESS, response };
  };
  const failure = error => {
    return { type: activityActionTypes.CREATE_ACTIVITY_FAILURE, error };
  };
  api.setHeaders({ ...headers, systemHeader_subsysref: "createActivity" });

  return dispatch => {
    dispatch(request(details));
    return new Promise((resolve, reject) => {
      api.createActivity(details).then(
        response => {
          dispatch(success(response));
          resolve(response);
        },
        error => {
          dispatch(failure(error));
          reject(error);
        }
      );
    });
  };
};
const clearActivityRequest = () => {
  const request = () => {
    return { type: activityActionTypes.CLEAR_CREATE_ACTIVITY_REQUEST };
  };

  const success = () => {
    return { type: activityActionTypes.CLEAR_CREATE_ACTIVITY_SUCCESS };
  };

  return dispatch => {
    dispatch(request());
    dispatch(success());
  };
};

const updateActivity = (
  id,
  details,
  headers,
  initialDetails,
  showNotification = true
) => {
  const request = details => {
    return { type: activityActionTypes.UPDATE_ACTIVITY_REQUEST, details };
  };
  const success = response => {
    return {
      type: activityActionTypes.UPDATE_ACTIVITY_SUCCESS,
      response,
      showNotification
    };
  };
  const failure = error => {
    return {
      type: activityActionTypes.UPDATE_ACTIVITY_FAILURE,
      error,
      initialDetails
    };
  };
  api.setHeaders({ ...headers, systemHeader_subsysref: "updateActivity" });

  return dispatch => {
    dispatch(request(details));
    const body = {
      ...details
    };

    api.updateActivity(id, body).then(
      response => {
        dispatch(success(response));
      },
      error => dispatch(failure(error, initialDetails))
    );
  };
};

const deleteActivity = (id, headers) => {
  const request = () => {
    return { type: activityActionTypes.DELETE_ACTIVITY_REQUEST };
  };
  const success = response => {
    return { type: activityActionTypes.DELETE_ACTIVITY_SUCCESS, response };
  };
  const failure = error => {
    return { type: activityActionTypes.DELETE_ACTIVITY_FAILURE, error };
  };

  api.setHeaders({ ...headers, systemHeader_subsysref: "deleteActivity" });

  return dispatch => {
    dispatch(request());
    return new Promise((resolve, reject) => {
      if (id !== "") {
        api.deleteActivity(id).then(
          data => {
            dispatch(success(data));
            resolve(data);
          },
          error => {
            dispatch(failure(error));
            reject(error);
          }
        );
      }
    });
  };
};

const getActivities = (
  headers,
  queryString,
  size,
  from,
  clear,
  fields = ["*"],
  sort = {},
  activeOnly,
  programmeId,
  referralId
) => {
  const request = () => {
    return { type: activityActionTypes.GET_ACTIVITIES_REQUEST };
  };
  const success = response => {
    return { type: activityActionTypes.GET_ACTIVITIES_SUCCESS, response };
  };
  const failure = error => {
    return { type: activityActionTypes.GET_ACTIVITIES_FAILURE, error };
  };

  api.setHeaders({ ...headers, systemHeader_subsysref: "getActivities" });

  return dispatch => {
    dispatch(request());
    if (clear) {
      // If supposed to clear, replicate blank result
      dispatch(success({ hits: { hits: [] } }));
    } else {
      api
        .getActivities(
          queryString,
          size,
          from,
          fields,
          sort,
          activeOnly,
          programmeId,
          referralId
        )
        .then(
          response => {
            dispatch(success(response));
          },
          error => dispatch(failure(error))
        );
    }
  };
};

const updateSearchCriteria = (
  headers,
  queryString,
  size,
  from,
  clear,
  fields,
  sort,
  activeOnly,
  engagementId,
  programmeId,
  referralId
) => {
  const request = () => {
    return { type: activityActionTypes.UPDATE_SEARCH_CRITERIA_REQUEST };
  };
  const success = response => {
    return {
      type: activityActionTypes.UPDATE_SEARCH_CRITERIA_SUCCESS,
      response
    };
  };
  const newCriteria = {
    query: queryString,
    size: size,
    from: from,
    clear,
    fields: fields,
    sort: sort,
    activeOnly: activeOnly,
    engagementId: engagementId,
    programmeId: programmeId,
    referralId: referralId
  };
  return dispatch => {
    dispatch(request());
    dispatch(success(newCriteria));
    if (engagementId) {
      dispatch(
        getActivitiesByEngagement(
          headers,
          queryString,
          size,
          from,
          clear,
          fields,
          sort,
          activeOnly,
          engagementId,
          programmeId
        )
      );
    } else {
      dispatch(
        getActivities(
          headers,
          queryString,
          size,
          from,
          clear,
          fields,
          sort,
          activeOnly,
          programmeId,
          referralId
        )
      );
    }
  };
};

const getActivitiesByEngagement = (
  headers,
  queryString,
  size,
  from,
  clear,
  fields = ["*"],
  sort = {},
  activeOnly,
  engagementId
) => {
  const request = () => {
    return { type: activityActionTypes.GET_ACTIVITIES_BY_ENGAGEMENT_REQUEST };
  };
  const success = response => {
    return {
      type: activityActionTypes.GET_ACTIVITIES_BY_ENGAGEMENT_SUCCESS,
      response
    };
  };
  const failure = error => {
    return {
      type: activityActionTypes.GET_ACTIVITIES_BY_ENGAGEMENT_FAILURE,
      error
    };
  };

  api.setHeaders({
    ...headers,
    systemHeader_subsysref: "getActivitiesByEngagement"
  });

  return dispatch => {
    dispatch(request());
    if (clear) {
      // If supposed to clear, replicate blank result
      dispatch(success({ hits: { hits: [] } }));
    } else {
      api
        .getActivitiesByEngagement(
          queryString,
          size,
          from,
          fields,
          sort,
          activeOnly,
          engagementId
        )
        .then(
          response => {
            dispatch(success(response));
          },
          error => dispatch(failure(error))
        );
    }
  };
};

const getActivityById = (id, headers) => {
  const request = () => {
    return { type: activityActionTypes.GET_ACTIVITY_BY_ID_REQUEST };
  };
  const success = response => {
    return { type: activityActionTypes.GET_ACTIVITY_BY_ID_SUCCESS, response };
  };
  const failure = error => {
    return { type: activityActionTypes.GET_ACTIVITY_BY_ID_FAILURE, error };
  };

  api.setHeaders({ ...headers, systemHeader_subsysref: "getActivityById" });

  return dispatch => {
    dispatch(request());
    return new Promise((resolve, reject) => {
      api.getActivityById(id).then(
        response => {
          dispatch(success(response.data));
          resolve(response.data);
        },
        error => {
          dispatch(failure(error));
          reject(error);
        }
      );
    });
  };
};

const updateSelectedActivity = (key, value) => {
  const request = (key, value) => {
    return {
      type: activityActionTypes.UPDATE_SELECTED_ACTIVITY_REQUEST,
      key,
      value
    };
  };
  const success = (key, value) => {
    return {
      type: activityActionTypes.UPDATE_SELECTED_ACTIVITY_SUCCESS,
      key,
      value
    };
  };
  const failure = () => {
    return { type: activityActionTypes.UPDATE_SELECTED_ACTIVITY_FAILURE };
  };

  return dispatch => {
    dispatch(request(key, value));
    if (key) {
      dispatch(success(key, value));
    } else {
      dispatch(failure());
    }
  };
};

const clearSelectedActivity = () => {
  const request = () => {
    return { type: activityActionTypes.CLEAR_SELECTED_ACTIVITY_REQUEST };
  };
  const success = () => {
    return { type: activityActionTypes.CLEAR_SELECTED_ACTIVITY_SUCCESS };
  };

  return dispatch => {
    dispatch(request());
    dispatch(success());
  };
};

const cancelSelectedActivityUpdate = initialActivityData => {
  const request = () => {
    return {
      type: activityActionTypes.CANCEL_SELECTED_ACTIVITY_UPDATE_REQUEST
    };
  };
  const success = data => {
    return {
      type: activityActionTypes.CANCEL_SELECTED_ACTIVITY_UPDATE_SUCCESS,
      data
    };
  };

  return dispatch => {
    dispatch(request());
    dispatch(success(initialActivityData));
  };
};

const getActivityTypeCount = (groupedTypes, engagementList, headers) => {
  const request = () => {
    return { type: activityActionTypes.GET_ACTIVITY_TYPE_COUNT_REQUEST };
  };
  const success = response => {
    return {
      type: activityActionTypes.GET_ACTIVITY_TYPE_COUNT_SUCCESS,
      response
    };
  };
  const failure = error => {
    return { type: activityActionTypes.GET_ACTIVITY_TYPE_COUNT_FAILURE, error };
  };
  api.setHeaders({
    ...headers,
    systemHeader_subsysref: "getActivityTypeCount"
  });

  return dispatch => {
    dispatch(request());
    return new Promise((resolve, reject) => {
      api.getActivityTypeCount(groupedTypes, engagementList).then(
        response => {
          dispatch(success(response));
          resolve(success);
        },
        error => {
          dispatch(failure(error));
          reject(failure);
        }
      );
    });
  };
};

export const activityActions = {
  createActivity,
  updateActivity,
  deleteActivity,
  getActivityById,
  getActivities,
  updateSearchCriteria,
  updateSelectedActivity,
  clearSelectedActivity,
  clearActivityRequest,
  cancelSelectedActivityUpdate,
  getActivityTypeCount
};
