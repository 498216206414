import { API } from '../../services/API'
import { fileConstants } from './file.actionTypes'
import fileHelper from '../../helpers/fileHelper'

const api = new API();

const purgeState = () => {
  const purge = () => { return { type: fileConstants.PURGE_STATE } }
  return dispatch => {
    dispatch(purge())
  }
}

const getFiles = (path, headers) => {
  const request = path => { return { type: fileConstants.GET_FILES_REQUEST, payload: path } }
  const success = data => { return { type: fileConstants.GET_FILES_SUCCESS, payload: data } }
  const failure = error => { return { type: fileConstants.GET_FILES_FAILURE, payload: error } }
  api.setHeaders({ ...headers, systemHeader_subsysref: "getFiles" });

  return dispatch => {
    dispatch(request(path))
    dispatch(setLoadingFiles(true))
    api.getRequest(path).then(
      data => {
        dispatch(success(data))
        dispatch(setLoadingFiles(false))
      },
      error => dispatch(failure(error))
    )
  }
}

const setLoadingFiles = (status) => {
  const success = status => { return { type: fileConstants.SET_LOADING_FILES_SUCCESS, payload: status } }

  return dispatch => {
    dispatch(success(status))
  }
}

const deleteLocalFile = (index) => {
  const success = data => { return { type: fileConstants.DELETE_LOCAL_FILE_SUCCESS, payload: data } }

  return dispatch => {
    dispatch(success(index))
  }
}

const updateLocalFiles = (index, newVal) => {
  const success = data => { return { type: fileConstants.UPDATE_LOCAL_FILES_SUCCESS, payload: data } }

  const data = {
    key: index,
    value: newVal
  }
  return dispatch => {
    dispatch(success(data))
  }
}

const addLocalFile = (newFile) => {
  const success = newFile => { return { type: fileConstants.ADD_LOCAL_FILE_SUCCESS, payload: newFile } }

  return dispatch => {
    dispatch(success(newFile))
  }
}

const setUploadProgress = (progress) => {
  const success = data => { return { type: fileConstants.SET_UPLOAD_PROGRESS, payload: data } }

  return dispatch => {
    dispatch(success(progress))
  }
}

const setUploading = (status) => {
  const success = data => { return { type: fileConstants.SET_UPLOADING, payload: data } }

  return dispatch => {
    dispatch(success(status))
  }
}

const commitChanges = (localData, remoteData, filePath, headers, entity, entityId) => {
  const request = data => { return { type: fileConstants.COMMIT_FILE_CHANGES_REQUEST, payload: data } }
  const success = data => { return { type: fileConstants.COMMIT_FILE_CHANGES_SUCCESS, payload: data } }
  api.setHeaders({ ...headers, systemHeader_subsysref: "commitChanges" });

  const changesToCommit = fileHelper.getDiffs(localData, remoteData, filePath, entity, entityId)
  const numChanges = (changesToCommit.uploads.length + changesToCommit.updates.length + changesToCommit.deletes.length)
  let numChangesLeft = numChanges
  return dispatch => {
    dispatch(request())
    if (numChanges > 0) {
      dispatch(setUploading(true))
      const requestStatus = []
      changesToCommit.deletes.forEach(deleteEvent => {
        const deleteSuccess = api.deleteRequest(deleteEvent)
          .then(result => {
            const newProgress = Math.round(100 - (100 * (--numChangesLeft / numChanges)))
            dispatch(setUploadProgress(newProgress))
          })
        requestStatus.push(deleteSuccess)
      })
      changesToCommit.updates.forEach(updateEvent => {
        const updateSuccess = api.putRequest(updateEvent)
          .then(result => {
            const newProgress = Math.round(100 - (100 * (--numChangesLeft / numChanges)))
            dispatch(setUploadProgress(newProgress))
          })
        requestStatus.push(updateSuccess)

      })
      changesToCommit.uploads.forEach(uploadEvent => {
        const uploadSuccess = api.postRequest(uploadEvent)
          .then(result => {
            const newProgress = Math.round(100 - (100 * (--numChangesLeft / numChanges)))
            dispatch(setUploadProgress(newProgress))
          })
        requestStatus.push(uploadSuccess)
      })

      return Promise.all(requestStatus)
        .then(result => {
          dispatch(success())
          dispatch(setUploadProgress(0))
          dispatch(setUploading(false))
        })
    }
  }
}

export const fileActions = {
  purgeState,
  commitChanges,
  getFiles,
  setUploadProgress,
  deleteLocalFile,
  addLocalFile,
  updateLocalFiles
}
