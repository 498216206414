import { API } from "../../services";
import { programmeActionTypes } from "./programme.actionTypes";

const api = new API();

const createProgramme = (details, headers) => {
  const request = details => {
    return { type: programmeActionTypes.CREATE_PROGRAMME_REQUEST, details };
  };
  const success = response => {
    return { type: programmeActionTypes.CREATE_PROGRAMME_SUCCESS, response };
  };
  const failure = error => {
    return { type: programmeActionTypes.CREATE_PROGRAMME_FAILURE, error };
  };

  api.setHeaders({ ...headers, systemHeader_subsysref: "createProgramme" });

  return dispatch => {
    dispatch(request(details));

    api.createProgramme(details).then(
      response => dispatch(success(response)),
      error => dispatch(failure(error))
    );
  };
};

const clearProgrammeRequest = () => {
  const request = () => {
    return { type: programmeActionTypes.CLEAR_CREATE_PROGRAMME_REQUEST };
  };

  const success = () => {
    return { type: programmeActionTypes.CLEAR_CREATE_PROGRAMME_SUCCESS };
  };

  return dispatch => {
    dispatch(request());
    dispatch(success());
  };
};

const cancelSelectedProgrammeUpdate = initialProgrammeData => {
  const request = () => {
    return {
      type: programmeActionTypes.CANCEL_SELECTED_PROGRAMME_UPDATE_REQUEST
    };
  };
  const success = data => {
    return {
      type: programmeActionTypes.CANCEL_SELECTED_PROGRAMME_UPDATE_SUCCESS,
      data
    };
  };

  return dispatch => {
    dispatch(request());
    dispatch(success(initialProgrammeData));
  };
};

const updateProgramme = (
  id,
  details,
  headers,
  initialDetails,
  showNotification = true
) => {
  const request = details => {
    return { type: programmeActionTypes.UPDATE_PROGRAMME_REQUEST, details };
  };
  const success = response => {
    return {
      type: programmeActionTypes.UPDATE_PROGRAMME_SUCCESS,
      response,
      showNotification
    };
  };
  const failure = error => {
    return {
      type: programmeActionTypes.UPDATE_PROGRAMME_FAILURE,
      error,
      initialDetails
    };
  };

  api.setHeaders({ ...headers, systemHeader_subsysref: "updateProgramme" });

  return dispatch => {
    dispatch(request(details));
    api.updateProgramme(id, details).then(
      response => {
        dispatch(success(response));
      },
      error => dispatch(failure(error, initialDetails))
    );
  };
};

const deleteProgramme = (id, headers) => {
  const request = () => {
    return { type: programmeActionTypes.DELETE_PROGRAMME_REQUEST };
  };
  const success = response => {
    return { type: programmeActionTypes.DELETE_PROGRAMME_SUCCESS, response };
  };
  const failure = error => {
    return { type: programmeActionTypes.DELETE_PROGRAMME_FAILURE, error };
  };

  api.setHeaders({ ...headers, systemHeader_subsysref: "deleteProgramme" });

  return dispatch => {
    dispatch(request());
    return new Promise((resolve, reject) => {
      if (id !== "") {
        api.deleteProgramme(id).then(
          data => {
            dispatch(success(data));
            resolve(data);
          },
          error => {
            dispatch(failure(error));
            reject(error);
          }
        );
      }
    });
  };
};

const getProgrammes = (
  headers,
  queryString,
  size,
  from,
  clear,
  fields = ["*"],
  sort = {},
  activeOnly
) => {
  const request = () => {
    return { type: programmeActionTypes.GET_PROGRAMMES_REQUEST };
  };
  const success = response => {
    return { type: programmeActionTypes.GET_PROGRAMMES_SUCCESS, response };
  };
  const failure = error => {
    return { type: programmeActionTypes.GET_PROGRAMMES_FAILURE, error };
  };

  api.setHeaders({ ...headers, systemHeader_subsysref: "getProgrammes" });

  return dispatch => {
    dispatch(request());
    return new Promise((resolve, reject) => {
      if (clear) {
        // If supposed to clear, replicate blank result
        dispatch(success({ hits: { hits: [] } }));
        resolve();
      } else {
        api
          .getProgrammes(queryString, size, from, fields, sort, activeOnly)
          .then(
            response => {
              dispatch(success(response));
              resolve(response);
            },
            error => {
              dispatch(failure(error));
              reject(error);
            }
          );
      }
    });
  };
};

const updateSearchCriteria = (
  headers,
  queryString,
  size,
  from,
  clear,
  fields,
  sort,
  activeOnly
) => {
  const request = () => {
    return { type: programmeActionTypes.UPDATE_SEARCH_CRITERIA_REQUEST };
  };
  const success = response => {
    return {
      type: programmeActionTypes.UPDATE_SEARCH_CRITERIA_SUCCESS,
      response
    };
  };

  const newCriteria = {
    query: queryString,
    size: size,
    from: from,
    clear,
    fields: fields,
    sort: sort,
    activeOnly: activeOnly
  };
  return dispatch => {
    dispatch(request());
    dispatch(success(newCriteria));
    dispatch(
      getProgrammes(
        headers,
        queryString,
        size,
        from,
        clear,
        fields,
        sort,
        activeOnly
      )
    );
  };
};

const getProgrammeById = (id, headers) => {
  const request = () => {
    return { type: programmeActionTypes.GET_PROGRAMME_BY_ID_REQUEST };
  };
  const success = response => {
    return { type: programmeActionTypes.GET_PROGRAMME_BY_ID_SUCCESS, response };
  };
  const failure = error => {
    return { type: programmeActionTypes.GET_PROGRAMME_BY_ID_FAILURE, error };
  };

  api.setHeaders({ ...headers, systemHeader_subsysref: "getProgrammeById" });

  return dispatch => {
    dispatch(request());
    return new Promise((resolve, reject) => {
      api.getProgrammeById(id).then(
        response => {
          dispatch(success(response.data));
          resolve(response.data);
        },
        error => {
          dispatch(failure(error));
          reject(error);
        }
      );
    });
  };
};

const updateSelectedProgramme = (key, value) => {
  const request = (key, value) => {
    return {
      type: programmeActionTypes.UPDATE_SELECTED_PROGRAMME_REQUEST,
      key,
      value
    };
  };
  const success = (key, value) => {
    return {
      type: programmeActionTypes.UPDATE_SELECTED_PROGRAMME_SUCCESS,
      key,
      value
    };
  };
  const failure = () => {
    return { type: programmeActionTypes.UPDATE_SELECTED_PROGRAMME_FAILURE };
  };

  return dispatch => {
    dispatch(request(key, value));
    if (key) {
      dispatch(success(key, value));
    } else {
      dispatch(failure());
    }
  };
};

const clearSelectedProgramme = () => {
  const request = () => {
    return { type: programmeActionTypes.CLEAR_SELECTED_PROGRAMME_REQUEST };
  };
  const success = () => {
    return { type: programmeActionTypes.CLEAR_SELECTED_PROGRAMME_SUCCESS };
  };

  return dispatch => {
    dispatch(request());
    dispatch(success());
  };
};

const getProgrammeOutcomes = (id, headers) => {
  const request = () => {
    return { type: programmeActionTypes.GET_PROGRAMME_OUTCOMES_REQUEST };
  };
  const success = response => {
    return {
      type: programmeActionTypes.GET_PROGRAMME_OUTCOMES_SUCCESS,
      response
    };
  };
  const failure = error => {
    return { type: programmeActionTypes.GET_PROGRAMME_OUTCOMES_FAILURE, error };
  };

  api.setHeaders({ ...headers, systemHeader_subsysref: "outcomesStatistics" });

  return dispatch => {
    dispatch(request());
    return new Promise((resolve, reject) => {
      api.outcomesStatistics(id).then(
        response => {
          dispatch(success(response.data));
          resolve(response.data);
        },
        error => {
          dispatch(failure(error));
          reject(error);
        }
      );
    });
  };
};
export const programmeActions = {
  createProgramme,
  updateProgramme,
  deleteProgramme,
  getProgrammeById,
  getProgrammes,
  updateSearchCriteria,
  updateSelectedProgramme,
  clearSelectedProgramme,
  clearProgrammeRequest,
  cancelSelectedProgrammeUpdate,
  getProgrammeOutcomes
};
