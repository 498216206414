import React from "react";
import ModifyActivity from "../../components/ModifyActivity";
import { Loader, Divider } from "semantic-ui-react";
import { connect } from "react-redux";
import { strings, activityTypeOptions, groupedTypes } from "../../resources";
import { activityActions } from "../../store/actions/activity.actions";
import { engagementActions } from "../../../Engagements/store/actions/engagement.actions";
import { amendmentActions } from "../../../Amendments/store/actions/amendment.actions";
import { referralActions } from "../../../Referrals/store/actions/referral.actions";
import { programmeActions } from "../../../Programmes/store/actions/programme.actions";
import PropTypes from "prop-types";
import { RequestFeedback } from "../../components";
import {
  functions as twgValidationFunctions,
  constraints as twgConstraints
} from "pulsion-twg-validation";
import { organisationActions } from "../../../Organisations/store/actions/organisation.actions";
import {
  getOrganisations,
  convertToIso,
  isEmpty,
  canUserModifyRecord
} from "../../helpers";

class ActivityEditContainer extends React.Component {
  state = {
    mode: "view",
    confirmSaveModalOpen: false,
    cancelChangesModalOpen: false,
    enableDisableActivityModalOpen: false,
    deleteModalOpen: false,
    initialActivityData: {},
    formInvalid: false,
    validationResults: {},
    unknownRequestStatus: false,
    searchCriteria: {
      from: 0,
      query: "",
      fields: ["*"],
      clear: false,
      sort: {},
      activeOnly: false
    },
    categoryDeletionId: undefined,
    activeCategories: [],
    selectedOutcomes: {},
    outcomesList: [],
    defaultOutcomes: [],
    supportServiceSelectionRequired: false,
    canAddOutcomes: false,
    deletionReason: undefined,
    fetchedPermissionStatus: false,
    permissionStatus: ""
  };

  componentDidMount = async () => {
    await this.props.getActivityById(
      this.props.match.params.id,
      this.props.headers
    );
    this.setState({ globalProgrammeId: localStorage.getItem("programmeId") });
    this.getInitialCategories();
    this.getInitialOutcomeList();
    const sCrit = this.state.searchCriteria;
    this.props.getOrganisations(
      this.props.headers,
      sCrit.query,
      10000,
      0,
      sCrit.clear,
      sCrit.fields,
      sCrit.sort,
      sCrit.activeOnly
    );

    if (
      this.state.mode === "view" &&
      this.props.selectedActivityData.requestId
    ) {
      await this.props.getDeletePermissionStatus(
        this.props.selectedActivityData.requestId
      );
      await this.setState(
        { permissionStatus: this.props.permissionStatus },
        async () => {
          if (this.state.permissionStatus === strings.states.rejected) {
            await this.props.updateSelectedActivity("requestId", null);
            this.props.updateActivity(
              this.props.selectedActivityData.activityId,
              this.props.selectedActivityData,
              this.props.headers,
              this.state.initialActivityData,
              false
            );
          }
        }
      );
    }
    this.setState({
      fetchedPermissionStatus: true,
      initialActivityData: this.props.selectedActivityData
    });
    let form = document.getElementById("engagementForm");
    if (form) {
      form.setAttribute("novalidate", true);
      //set the form to have the novalidate attribute to suppress the default html validation tooltips
    }

    if (
      this.props.match.params.referralId &&
      this.props.match.params.referralId !== "undefined"
    ) {
      await this.props.getReferralById(
        this.props.match.params.referralId,
        this.props.headers
      );
    }
    if (this.state.globalProgrammeId) {
      await this.props.getProgrammeById(
        this.state.globalProgrammeId,
        this.props.headers
      );
    }
    this.handleFetchData();
  };

  componentWillUnmount() {
    this.props.clearActivityRequest();
    this.props.clearSelectedActivity();
  }

  handleFetchData = async () => {
    let engagementSearchCriteria = JSON.parse(
      JSON.stringify(this.state.searchCriteria)
    );
    engagementSearchCriteria.programmeId = this.props.selectedActivityData.programmeId;
    engagementSearchCriteria.referralId = this.props.selectedActivityData.referralId;
    this.props.getEngagements(
      this.props.headers,
      engagementSearchCriteria.query,
      10000,
      0,
      engagementSearchCriteria.clear,
      engagementSearchCriteria.fields,
      engagementSearchCriteria.sort,
      engagementSearchCriteria.activeOnly,
      engagementSearchCriteria.programmeId,
      engagementSearchCriteria.referralId
    );
  };

  validateForm = async () => {
    let details = JSON.parse(JSON.stringify(this.props.selectedActivityData));
    for (let property in details) {
      if (!details[property]) {
        delete details[property];
      }
    }
    let results = twgValidationFunctions.validateData(
      details,
      twgConstraints.activities.modifyActivity
    );
    if (results) {
      this.setState({
        validationResults: results,
        formInvalid: true
      });
    } else {
      this.setState({ formInvalid: false });
      this.toggleConfirmSaveModalVisibility();
    }
  };

  handleSubmit = async () => {
    await this.props.updateActivity(
      this.props.match.params.id,
      this.props.selectedActivityData,
      this.props.headers,
      this.state.initialActivityData
    );
    this.handleModeSwitch();
  };

  handleCancel = () => {
    this.setState({ formInvalid: false });
    this.props.history.goBack();
  };

  handleRequestDeletePermission = async () => {
    await this.props.requestDeletePermission(
      this.props.selectedActivityData.activityId,
      this.state.deletionReason.value,
      this.props.headers,
      this.props.selectedActivityData
    );
    this.props.updateSelectedActivity("requestId", this.props.requestId);
    this.props.updateActivity(
      this.props.selectedActivityData.activityId,
      this.props.selectedActivityData,
      this.props.headers,
      this.state.initialActivityData
    );
    this.setState({
      initialActivityData: this.props.selectedActivityData
    });
  };

  handleModalInputChange = (e, value) => {
    this.setState({ deletionReason: value });
  };

  handleDelete = async () => {
    await this.props.deleteActivity(
      this.props.selectedActivityData.activityId,
      this.props.headers
    );
    if (this.props.deleteSuccessful) {
      this.props.setDeletePermissionStatusActioned(
        this.props.selectedActivityData.requestId
      );
    }
  };

  handleChange = async (event, data) => {
    if (data.name === "supportServiceEngaged") {
      if (data.value === strings.fieldValues.no) {
        await this.props.updateSelectedActivity("supportService", "");
      }
      this.initialiseCategoryTable();
    }
    if (data.value.match(/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/)) {
      await this.props.updateSelectedActivity(
        data.name,
        convertToIso(data.value)
      );
    } else {
      await this.props.updateSelectedActivity(data.name, data.value);
    }
    if (data.name === "type") {
      this.setState({ supportServiceSelectionRequired: true });
      this.getCategoriesFromType(data.options, data.value);
      this.initialiseCategoryTable();
      if (groupedTypes.includes(data.value)) {
        await this.props.getActivityTypeCount(
          groupedTypes,
          this.props.engagementListData,
          this.props.headers
        );
        if (this.props.typeCount >= 3) {
          let newOutcomes = {
            "Attitudes & Behaviours": [["S1"], ["M1"]]
          };

          this.setState({ selectedOutcomes: newOutcomes });
        }
      }
    }
  };

  initialiseCategoryTable = async () => {
    let categories = [];
    categories.push({
      key: 0,
      category: "",
      outcomesAchieved: [],
      outcomesWorkedTowards: []
    });
    await this.props.updateSelectedActivity("categories", categories);
  };

  addCategory = async e => {
    let categories = this.props.selectedActivityData.categories || [];
    categories.push({
      key: categories.length,
      category: "",
      outcomesAchieved: [],
      outcomesWorkedTowards: []
    });
    categories.length > 1
      ? await this.props.updateSelectedActivity(
          this.getKeyByValue(
            this.props.selectedActivityData,
            this.props.selectedActivityData.categories
          ),
          categories
        )
      : await this.props.updateSelectedActivity("categories", categories);
  };

  removeCategory = async () => {
    let categories = this.props.selectedActivityData.categories;
    if (categories.length > 1) {
      categories.splice(this.state.categoryDeletionId, 1);
    }
    await this.props.updateSelectedActivity(
      this.getKeyByValue(
        this.props.selectedActivityData,
        this.props.selectedActivityData.categories
      ),
      categories
    );
  };

  handleEnableDisable = async () => {
    const body = {
      active: !this.props.selectedActivityData.active
    };
    await this.props.updateActivity(
      this.props.match.params.id,
      body,
      this.props.headers,
      this.state.initialActivityData
    );
  };

  handleModalConfirm = modalClassName => {
    switch (modalClassName) {
      case "confirmSaveModal":
        this.handleSubmit();
        break;
      case "cancelChangesModal":
        this.handleCancel();
        break;
      case "activationModal":
        this.handleEnableDisable();
        break;
      case "deletionCategoryModal":
        this.removeCategory(this.state.categoryDeletionId);
        break;
      case "requestDeletePermissionModal":
        this.handleRequestDeletePermission();
        break;
      case "deleteModal":
        this.handleDelete();
        break;

      default:
        break;
    }
  };

  getCategoriesFromType = (options, type) => {
    options.forEach(option => {
      if (option.value === type) {
        this.props.updateSelectedActivity(
          "supportServiceRequired",
          option.supportservicerequired ? true : false
        );
        this.setState({ selectedOutcomes: option.outcomes });
        let dropdownOptions = [];
        Object.keys(option.outcomes).forEach((text, index) => {
          dropdownOptions[index] = { key: index, text: text, value: text };
        });
        this.setState({ activeCategories: dropdownOptions });
      }
    });
  };

  getInitialCategories = () => {
    const activeOption = activityTypeOptions.find(
      activityType =>
        activityType.value === this.props.selectedActivityData.type
    );
    this.setState({ selectedOutcomes: activeOption.outcomes });
    const dropdownOptions = [];
    Object.keys(activeOption.outcomes).forEach((text, index) => {
      dropdownOptions[index] = { key: index, text: text, value: text };
    });
    this.setState({
      activeCategories: dropdownOptions
    });
  };

  getInitialOutcomeList = () => {
    if (this.props.selectedActivityData.categories) {
      this.props.selectedActivityData.categories.forEach((category, index) => {
        if (this.state.selectedOutcomes[category.category]) {
          let outcomesList = this.state.outcomesList;
          outcomesList[index] = this.state.selectedOutcomes[
            category.category
          ][0].concat(this.state.selectedOutcomes[category.category][1]);
          this.setState({
            outcomesList: outcomesList,
            defaultOutcomes: this.state.selectedOutcomes[category.category][0]
          });
        }
      });
      this.setState({
        supportServiceSelectionRequired: true,
        canAddOutcomes: true
      });
    } else if (
      this.props.selectedActivityData.supportServiceEngaged &&
      this.props.selectedActivityData.supportServiceEngaged ===
        strings.fieldValues.no
    ) {
      this.setState({
        supportServiceSelectionRequired: true,
        canAddOutcomes: true
      });
    } else {
      this.setState({ supportServiceSelectionRequired: true });
    }
  };

  getOutcomesFromCategory = async (value, index) => {
    let outcomesList = this.state.outcomesList;
    if (
      this.state.selectedOutcomes[value] &&
      this.state.selectedOutcomes[value][0]
    ) {
      outcomesList[index] = this.state.selectedOutcomes[value][0].concat(
        this.state.selectedOutcomes[value][1]
      );
      this.setState({ outcomesList: outcomesList });
    }
  };

  handleCategoryChange = async (e, data) => {
    let categories = this.props.selectedActivityData.categories;
    const result = data.name.split("-");
    if (result[0] === "category") {
      this.getOutcomesFromCategory(data.value, result[1]);
      categories[result[1]]["outcomesWorkedTowards"] = [];
      categories[result[1]]["outcomesAchieved"] = [];
      if (
        this.state.selectedOutcomes[data.value] &&
        this.state.selectedOutcomes[data.value][0]
      ) {
        this.state.selectedOutcomes[data.value][0].forEach(outcome => {
          categories[result[1]]["outcomesAchieved"].push(outcome);
        });
        this.setState({
          defaultOutcomes: this.state.selectedOutcomes[data.value][0]
        });
      }
    }
    if (data.type === "checkbox") {
      if (data.checked) {
        categories[result[1]][result[0]].push(data.label);
      } else {
        categories[result[1]][result[0]] = categories[result[1]][
          result[0]
        ].filter(category => category !== data.label);
      }
    } else {
      categories[result[1]][result[0]] = data.value;
    }
    await this.props.updateSelectedActivity(
      this.getKeyByValue(
        this.props.selectedActivityData,
        this.props.selectedActivityData.categories
      ),
      categories
    );
  };

  getKeyByValue = (object, value) => {
    return Object.keys(object).find(key => object[key] === value);
  };

  toggleConfirmSaveModalVisibility = () => {
    this.setState({
      confirmSaveModalOpen: !this.state.confirmSaveModalOpen
    });
  };

  toggleConfirmDeleteModalVisibility = idx => {
    this.setState({
      deleteModalOpen: !this.state.deleteModalOpen,
      categoryDeletionId: idx
    });
  };

  toggleCancelChangesModalVisibility = () => {
    this.setState({
      cancelChangesModalOpen: !this.state.cancelChangesModalOpen
    });
  };

  toggleEnableDisableActivityModalVisibility = () => {
    this.setState({
      enableDisableActivityModalOpen: !this.state.enableDisableActivityModalOpen
    });
  };

  handleModeSwitch = async () => {
    if (this.state.mode === "edit") {
      if (
        JSON.stringify(this.state.initialActivityData) !==
        JSON.stringify(this.props.selectedActivityData)
      ) {
        await this.props.cancelSelectedActivityUpdate(
          this.state.initialActivityData
        );
      }
      this.setState({ mode: "view", formInvalid: false });
    } else {
      this.setState({
        mode: "edit",
        initialActivityData: JSON.parse(
          JSON.stringify(this.props.selectedActivityData)
        )
      });
    }
  };

  getOrganisations = () => {
    return getOrganisations(this.props.organisationListData);
  };

  isDisabled = () => {
    return !canUserModifyRecord(
      !isEmpty(this.props.selectedReferralData)
        ? this.props.selectedReferralData
        : this.props.selectedProgrammeData,
      this.props.organisationOfLoggedInUser
    );
  };

  render = () => {
    const loading =
      this.props.loadingPage ||
      this.props.orgsLoadingPage ||
      !this.state.fetchedPermissionStatus ||
      this.props.referralLoadingPage ||
      this.props.engagementLoadingPage ||
      this.props.programmeLoadingPage;
    return (
      <div>
        <div
          style={{
            display: loading ? "" : "none"
          }}
        >
          <Loader active>{strings.header.loading}</Loader>
        </div>
        <div
          style={{
            display: loading ? "none" : ""
          }}
        >
          {this.props.showNotification && (
            <RequestFeedback
              requestStatus={this.props.activityRequestStatus}
              requestMade={this.props.activityRequestMade}
              unknownRequestStatus={this.state.unknownRequestStatus}
              successMessage={this.props.result}
              failureMessage={this.props.error}
              processingFeedbackMessage={strings.form.feedback.processing}
              unknownFeedbackMessage={
                strings.form.feedback.activityRequestUnknown
              }
              statusFeedbackMessage={strings.form.feedback.status}
              successFeedbackMessage={strings.form.feedback.success}
              errorDetails={this.props.errorDetails}
            />
          )}
          <Divider hidden />
          <ModifyActivity
            mode={this.state.mode}
            role={this.props.roleId}
            requestStatus={this.props.activityRequestStatus}
            pageTitle={
              this.state.mode === "edit"
                ? strings.header.editActivity
                : strings.header.viewActivity
            }
            toggleCancelChangesModalVisibility={
              this.toggleCancelChangesModalVisibility
            }
            toggleConfirmSaveModalVisibility={
              this.toggleConfirmSaveModalVisibility
            }
            toggleEnableDisableActivityModalVisibility={
              this.toggleEnableDisableActivityModalVisibility
            }
            selectedActivityData={this.props.selectedActivityData}
            handleChange={this.handleChange}
            handleModeSwitch={this.handleModeSwitch}
            handleModalConfirm={this.handleModalConfirm}
            cancelChangesModalOpen={this.state.cancelChangesModalOpen}
            confirmSaveModalOpen={this.state.confirmSaveModalOpen}
            enableDisableActivityModalOpen={
              this.state.enableDisableActivityModalOpen
            }
            deleteModalOpen={this.state.deleteModalOpen}
            toggleDeleteModal={this.toggleConfirmDeleteModalVisibility}
            initialActivityData={this.state.initialActivityData}
            formInvalid={this.state.formInvalid}
            validateForm={this.validateForm}
            validationResults={this.state.validationResults}
            addCategory={this.addCategory}
            removeCategory={this.removeCategory}
            handleCategoryChange={this.handleCategoryChange}
            organisationList={getOrganisations(this.props.organisationListData)}
            getOrganisations={this.getOrganisations}
            activeCategories={this.state.activeCategories}
            outcomesList={this.state.outcomesList}
            defaultOutcomes={this.state.defaultOutcomes}
            supportServiceSelectionRequired={
              this.state.supportServiceSelectionRequired
            }
            canAddOutcomes={this.state.canAddOutcomes}
            deletePermissionStatus={this.state.permissionStatus}
            handleModalInputChange={this.handleModalInputChange}
            deletionReason={this.state.deletionReason}
            deleteSuccessful={this.props.deleteSuccessful}
            isDisabled={this.isDisabled()}
          />
        </div>
      </div>
    );
  };
}

ActivityEditContainer.propTypes = {
  match: PropTypes.object.isRequired,
  headers: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  loadingPage: PropTypes.bool.isRequired,
  selectedActivityData: PropTypes.object.isRequired,
  updateSelectedActivity: PropTypes.func.isRequired,
  updateActivity: PropTypes.func.isRequired,
  getActivityById: PropTypes.func.isRequired,
  errorDetails: PropTypes.array
};

const mapStateToProps = state => {
  const {
    activities,
    auth,
    organisations,
    amendments,
    referrals,
    programmes
  } = state;
  const {
    loadingPage,
    selectedActivityData,
    activityRequestStatus,
    activityRequestMade,
    result,
    error,
    errorDetails,
    deleteSuccessful,
    showNotification,
    typeCount
  } = activities;
  const { selectedReferralData, loadingPage: referralLoadingPage } = referrals;
  const {
    selectedProgrammeData,
    loadingPage: programmeLoadingPage
  } = programmes;
  const { roleId, organisationOfLoggedInUser } = auth;
  const { organisationListData, loadingPage: orgsLoadingPage } = organisations;
  const {
    engagementListData,
    loadingPage: engagementLoadingPage
  } = state.engagements;
  const { requestId, permissionStatus } = amendments;
  return {
    loadingPage,
    selectedActivityData,
    activityRequestStatus,
    activityRequestMade,
    result,
    error,
    roleId,
    errorDetails,
    organisationListData,
    orgsLoadingPage,
    engagementListData,
    requestId,
    permissionStatus,
    deleteSuccessful,
    showNotification,
    organisationOfLoggedInUser,
    selectedReferralData,
    selectedProgrammeData,
    referralLoadingPage,
    engagementLoadingPage,
    programmeLoadingPage,
    typeCount
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateActivity: (
      id,
      data,
      headers,
      initialActivityData,
      showNotification = true
    ) => {
      dispatch(
        activityActions.updateActivity(
          id,
          data,
          headers,
          initialActivityData,
          showNotification
        )
      );
    },
    getActivityById: async (id, headers) => {
      await dispatch(activityActions.getActivityById(id, headers));
    },
    updateSelectedActivity: (key, value) => {
      dispatch(activityActions.updateSelectedActivity(key, value));
    },
    cancelSelectedActivityUpdate: data => {
      dispatch(activityActions.cancelSelectedActivityUpdate(data));
    },
    clearActivityRequest: () => {
      dispatch(activityActions.clearActivityRequest());
    },
    clearSelectedActivity: () => {
      dispatch(activityActions.clearSelectedActivity());
    },
    getOrganisations: (
      headers,
      queryString,
      size,
      from,
      clear,
      fields,
      sort,
      activeOnly
    ) => {
      dispatch(
        organisationActions.getOrganisations(
          headers,
          queryString,
          size,
          from,
          clear,
          fields,
          sort,
          activeOnly
        )
      );
    },
    getEngagements: (
      headers,
      queryString,
      size,
      from,
      clear,
      fields,
      sort,
      activeOnly,
      programmeId,
      referralId
    ) => {
      dispatch(
        engagementActions.getEngagements(
          headers,
          queryString,
          size,
          from,
          clear,
          fields,
          sort,
          activeOnly,
          programmeId,
          referralId
        )
      );
    },
    requestDeletePermission: async (id, reason, headers, body) => {
      await dispatch(
        amendmentActions.requestDeletePermission(id, reason, headers, body)
      );
    },
    getDeletePermissionStatus: async (id, headers) => {
      await dispatch(amendmentActions.getDeletePermissionStatus(id, headers));
    },
    deleteActivity: async (id, headers) => {
      await dispatch(activityActions.deleteActivity(id, headers));
    },
    setDeletePermissionStatusActioned: (id, headers) => {
      dispatch(amendmentActions.setDeletePermissionStatusActioned(id, headers));
    },
    getReferralById: (id, headers) => {
      dispatch(referralActions.getReferralById(id, headers));
    },
    getProgrammeById: async (id, headers) => {
      await dispatch(programmeActions.getProgrammeById(id, headers));
    },
    getActivityTypeCount: (groupedTypes, engagementList, headers) => {
      dispatch(
        activityActions.getActivityTypeCount(
          groupedTypes,
          engagementList,
          headers
        )
      );
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ActivityEditContainer);
