import LocalizedStrings from "react-localization";

export const strings = new LocalizedStrings({
  en: {
    header: {
      login: "Login",
      programmeList: "Programmes",
      createProgramme: "New Programme",
      editProgramme: "Edit Programme",
      viewProgramme: "Programme Details",
      loading: "Loading Information"
    },
    form: {
      errorText: {
        notShared:
          "Your organisation does not currently have access to this record."
      },
      header: {
        administrationHeader: "Administration",
        dependantsHeader: "PRP - Dependants",
        employmentAndBenefitsHeader: "PRP - Employment & Benefits",
        exitHeader: "Exit",
        generalHealthHeader: "PRP - General Health",
        hobbiesHeader: "Hobbies and interests",
        householdStatusHeader: "PRP - Household Status",
        keyDatesAndMilestonesHeader: "Key Dates & Milestones",
        liberationHeader: "Liberation",
        mentalHealthHeader: "PRP - Mental Health",
        notesHeader: "Notes",
        prisonSupportServiceHeader: "Prison Based Support Services",
        generalHeader: "General",
        mentorHeader: "Mentor Details",
        addictionsHeader: "PRP - Addictions",
        programmeInfo: "Programme",
        chooseEngagement: "Engagements",
        formType: "Programme Form"
      },
      label: {
        crmId: "CRM ID",
        owner: "Owner",
        prisonOrganisation: "Prison Organisation",
        prisonSpecialist: "Prison Specialist",
        communityOrganisation: "Community Organisation",
        communityMentor: "Community Mentor",
        referral: "Referral",
        scroNumber: "S Number",
        hdcQualificationDate: "HDC Qualification Date",
        journeyStatus: "Journey Status",
        overallEDL: "Earliest Date of Liberation",
        anticipatedEndDate: "Anticipated End Date",
        dateOfReferral: "Date Of Referral Meeting",
        firstCommunityMeetingAttended: "First Community Meeting Attended",
        signupMeetingDate: "Sign Up Meeting Date",
        firstCommunityMeetingDate: "First Community Meeting Date",
        revisedEDL: "Revised EDL date",
        wantsCollected: "Do you want a Mentor to collect you from the gates?",
        actualLiberationDate: "Actual Liberation Date",
        alternativeArrangements:
          "If no, have you made alternative arrangements",
        gateLiberationAttended: "Gate liberation attended?",
        whoMetAtGate: "If yes, please detail who you have arranged to meet",
        livingWith: "Living with",
        hasTenancy: "Do you have your own tenancy",
        homelessDuration: "How long have you been homeless",
        homelessCaseworkReferral:
          "If homeless, has a referral been made to the Homeless Casework Team?",
        stayingWhereOnRelease: "Where will you be staying on your release",
        hasFamilySupport:
          "Have your family/ significant other supported you whilst you were in prison",
        hasDependants: "Do you have Dependants",
        hasCaringResponsibilities: "Do you have any Caring responsibilities",
        hasChildrenDependants: "Dependants: Children",
        hasChildrenAccess: "Do you have access to your children",
        hasRelativeDependants: "Dependants: Relative",
        isLoneParent: "Are you a lone parent",
        hasOtherDependants: "Dependant: Other",
        otherDependantsDetail: "If other, please specify",
        hasHealthIssues: "Do you have any health issues?",
        hasGPRegistered: "Are you registered with a GP?",
        hasDentistRegistered: "Are you registered with a Dentist?",
        hasMentalHealthIssues: "Do you have any mental health issues?",
        mentalHealthMedication: "What medication?",
        takingMentalHealthMedication:
          "Are you taking any prescribed medication for this condition?",
        mentalHealthMedicationQuantity: "How much medication?",
        hasAddiction: "Do you believe you have an addiction?",
        takingPrescribedMedication: "Are you taking any prescribed medication?",
        medicationDetails: "If yes, please provide details",
        whatMedication: "What medication?",
        hasAddictionAffectedOffending:
          "Are your addiction issues related to your offending",
        medicationQuantity: "How much medication?",
        inReceiptOfSupport: "Are you in receipt of additional support?",
        keyWorkerName: "Key worker name (if applicable)",
        employmentStatus: "Employment Status (prior to conviction)",
        lengthOfEmployment: "Length of Employment",
        participatingWorkProgramme: "Participating on Work Programme",
        benefitsNone: "None",
        benefitsCarersAllowance: "Carers Allowance",
        benefitsCouncilTax: "Council Tax Benefit",
        benefitsHousing: "Housing Benefit",
        benefitsJobseekers: "Job Seekers Allowance",
        benefitsChild: "Child Benefit",
        benefitsIncapacity: "Incapacity Benefit",
        benefitsDisability: "Disability Living Allowance",
        benefitsPersonalIndependence: "Personal Independence",
        benefitsWorkingTaxCredit: "Working Tax Credit",
        benefitsUniversalCredit: "Universal Credit",
        benefitsIncomeSupport: "Income Support",
        benefitsEmploymentSupport: "Employment Support Allowance",
        benefitsChildcareWTC: "Childcare element of WTC",
        benefitsOther: "Other",
        benefitDetailsOther: "If other, please specify",
        jobCentrePlusAppointment:
          "Have you arranged/completed an appointment with Job Centre Plus?",
        isCareLeaver: "Are you a care leaver?",
        hasItaAccountAvailable: "ITA Account available",
        itaDateApplied: "Date ITA applied for",
        requiresPrisonSupport:
          "Based on the information provided, do you require to be referred to prison based support services?",
        prisonSupportService: "If yes, which services?",
        prisonSupportServiceOther: "If other please state service",
        hobbiesAndInterests: "Hobbies and interests",
        notes: "Enter a note",
        notesFile: "Enter a note (file)",
        actualEndDate: "Actual End Date",
        onRemand: "On Remand",
        typeOfExit: "Type of Exit",
        hasCompletedExitSurvey: "Exit Survey Completed?",
        exitReason: "Exit Reason",
        exitReasonOther: "If other, please state",
        dateOfLastContact: "Date of Last Contact",
        benefitsDetails: "Benefits Details",
        mentor: "Mentor",
        engagementType: "View or Add an Engagement",
        viewProgrammeLabel: "View or Update Programme",
        firstName: "First Name",
        surname: "Last Name",
        sharedWith: ""
      },
      placeholder: {
        stateReason: "State your reason...",
        crmId: "",
        owner: "",
        prisonOrganisation: "",
        prisonSpecialist: "",
        communityOrganisation: "",
        communityMentor: "",
        referral: "",
        scroNumber: "",
        hdcQualificationDate: "",
        journeyStatus: "",
        overallEDL: "",
        anticipatedEndDate: "",
        dateOfReferral: "",
        firstCommunityMeetingAttended: "",
        signupMeetingDate: "",
        firstCommunityMeetingDate: "",
        revisedEDL: "",
        wantsCollected: "",
        actualLiberationDate: "",
        alternativeArrangements: "",
        gateLiberationAttended: "",
        whoMetAtGate: "",
        livingWith: "",
        hasTenancy: "",
        homelessDuration: "",
        homelessCaseworkReferral: "",
        stayingWhereOnRelease: "",
        hasFamilySupport: "",
        hasDependants: "",
        hasCaringResponsibilities: "",
        hasChildrenDependents: "",
        hasChildrenAccess: "",
        hasRelativeDependants: "",
        isLoneParent: "",
        hasOtherDependents: "",
        otherDependentsDetail: "",
        hasHealthIssues: "",
        hasGPRegistered: "",
        hasDentistRegistered: "",
        hasMentalHealthIssues: "",
        mentalHealthMedication: "",
        takingMentalHealthMedication: "",
        mentalHealthMedicationQuantity: "",
        hasAddiction: "",
        takingPrescribedMedication: "",
        medicationDetails: "",
        whatMedication: "",
        hasAddictionAffectedOffending: "",
        medicationQuantity: "",
        inReceiptOfSupport: "",
        keyWorkerName: "",
        employmentStatus: "",
        lengthOfEmployment: "",
        participatingWorkProgramme: "",
        benefitDetails: "",
        benefitDetailsOther: "",
        jobCentrePlusAppointment: "",
        isCareLeaver: "",
        hasItaAccountAvailable: "",
        itaDateApplied: "",
        requiresPrisonSupport: "",
        prisonSupportService: "",
        prisonSupportServiceOther: "",
        hobbiesAndInterests: "",
        notes: "",
        notesFile: "",
        actualEndDate: "",
        onRemand: "",
        typeOfExit: "",
        hasCompletedExitSurvey: "",
        exitReason: "",
        exitReasonOther: "",
        dateOfLastContact: "",
        otherDependantsDetail: "",
        mentor: "- select -",
        engagementType: "- select - ",
        firstName: "",
        surname: "",
        sharedWith: "- select -"
      },
      feedback: {
        processing: "Processing request...",
        programmeRequestSuccess: "Programme created successfully!",
        programmeRequestRefreshPrompt:
          "Use the refresh function to see an updated programmes list.",
        programmeRequestFailure: "Programme creation failed.",
        status: "Status",
        success: "Success",
        failure: "Failure",
        programmeRequestUnknown:
          "Something went wrong with the programme request."
      },
      fileUpload: "Upload files"
    },
    modal: {
      createProgrammeMessage: "Save programme?",
      modifyProgrammeMessage: "Are you sure you want to save these changes?",
      deleteProgrammeMessage:
        "Are you sure you want to delete this programme? (This action cannot be undone)",
      saveChangesMessage: "Are you sure you want to save these changes?",
      cancelChangesMessage: "Cancel changes?",
      setActiveMessage: "Set this programme's status to active?",
      setInactiveMessage: "Set this programme's status to inactive?",
      requestDeletePermissionMessage: "Please submit a reason for deletion:",
      shareMessage: "What organisations should this record be shared with?"
    },
    button: {
      loginLabel: "Login",
      editLabel: "Edit",
      deleteLabel: "Delete",
      cancelLabel: "Cancel",
      shareLabel: "Manage Sharing",
      deactivateLabel: "Deactivate",
      activateLabel: "Activate",
      saveLabel: "Save",
      yesLabel: "Yes",
      noLabel: "No",
      okLabel: "Ok",
      addProgrammeLabel: "New Programme",
      createProgrammeProgramme: "Create a Programme",
      referralLinkText: "View Referral",
      chooseForm: "View",
      resetLabel: "Go back to form selection",
      requestDeleteLabel: "Request Delete Permission",
      submitLabel: "Submit"
    },
    tooltip: {
      editProgramme: "Edit Programme",
      deleteProgramme: "Delete Programme",
      toggleStatus: "Click to change status",
      programmeTypeWarning: "Programme Type cannot be changed after creation.",
      ninFormatHeader: "National Insurance Format",
      ninFormatContent:
        "Please enter the NIN in the same format as 'QQ123456C'. The following characters are not allowed. First Letter: DFIQUV. Second Letter: DFIQUVO. Final Letter must be: ABCD."
    },
    table: {
      header: {
        createdDate: "Created",
        crmId: "Programme ID",
        owner: "Owner",
        referralCrmId: "Referral ID",
        region: "Region",
        firstName: "First Name",
        surname: "Last Name",
        scroNumber: "S Number",
        prisonSpecialist: "Prison Specialist",
        mentor: "Specialist",
        communityMentor: "Community Mentor",
        pNumber: "Prison Number",
        journeyStatus: "Journey Status",
        overallEDL: "Overall EDL",
        firstCommunityMeetingAttended: "First Community Meeting",
        anticipatedEndDate: "Anticipated End Date"
      }
    },
    areas: {
      NRS: "NRS",
      NRNE: "NRNE",
      both: "both"
    },
    fieldValues: {
      yes: "Yes",
      no: "No",
      other: "Other",
      onProgrammeCommunity: "On Programme - In Community",
      exit: "Exit"
    },
    states: {
      approved: "Approved",
      rejected: "Rejected"
    },
    orgTypes: {
      supportPartner: "Support Partner"
    }
  }
});
