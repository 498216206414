import React from "react";
import PropTypes from "prop-types";
import { Button, Form, Icon, Popup, Input } from "semantic-ui-react";
import {
  strings,
  userRoleOptions,
  areaOptions,
  areaOptionsMentor
} from "../resources";
import { ValidatedFormInput, ValidatedFormDropdown } from "./validation";
import { ComponentHeader, BasicModal, TextInputModal } from ".";
import { withRouter } from "react-router-dom";
import { permissions } from "../../App/resources/AppPermissions";
import { dropdownHelper } from "../helpers";
import { localAuthorityAreaOptions as localAuthorityAreasNrs } from "../../Organisations/resources/DropdownOptions.js";
import {
  prisonNrneOptions,
  prisonNrsOptions,
  customerLocalAuthorityAreaOptions as localAuthorityAreasNrne
} from "../../Referrals/resources/DropdownOptions.js";

class ModifyUser extends React.Component {
  returnToList = () => {
    this.props.history.push("/users");
  };

  renderModifyButtons = () => {
    const modalTextInput = (
      <Input
        placeholder={strings.form.placeholder.stateReason}
        fluid
        onChange={this.props.handleModalInputChange}
      ></Input>
    );

    return (
      <div className="containerButtons">
        {!this.props.deleteSuccessful && (
          <Button.Group>
            <Button
              className="editButton"
              primary
              onClick={() => {
                this.props.handleModeSwitch("edit");
              }}
              disabled={!this.props.selectedUserData.active}
            >
              {strings.button.editLabel}
            </Button>
          </Button.Group>
        )}
        <Button.Group>
          <Button
            className="activationButton"
            primary
            inverted
            onClick={() => this.props.toggleModalVisibility("disableModal")}
            type="button"
          >
            {this.props.selectedUserData.active &&
              strings.button.deactivateLabel}
            {!this.props.selectedUserData.active &&
              strings.button.activateLabel}
          </Button>
        </Button.Group>
        <BasicModal
          confirmMessage={
            this.props.selectedUserData.active
              ? strings.modal.setInactiveMessage
              : strings.modal.setActiveMessage
          }
          handleModalConfirm={this.props.handleModalConfirm}
          modalClassName="activationModal"
          modalOpen={this.props.enableDisableUserModalOpen}
          showOkButton={false}
          showYesButton={true}
          showNoButton={true}
          toggleModal={() => this.props.toggleModalVisibility("disableModal")}
          noLabel={strings.button.noLabel}
          yesLabel={strings.button.yesLabel}
          okLabel={strings.button.okLabel}
        />

        {this.props.deletePermissionStatus !== strings.states.approved &&
          !this.props.deleteSuccessful && (
            <Button.Group>
              <Button
                className="deleteButton"
                secondary
                inverted
                onClick={() => this.props.toggleModalVisibility("deleteModal")}
                type="button"
                disabled={this.props.selectedUserData.requestId}
              >
                {strings.button.requestDeleteLabel}
              </Button>
              <TextInputModal
                confirmMessage={strings.modal.requestDeletePermissionMessage}
                handleModalConfirm={this.props.handleModalConfirm}
                modalClassName="requestDeletePermissionModal"
                modalOpen={this.props.requestDeleteUserModalOpen}
                showOkButton={true}
                textInput={modalTextInput}
                toggleModal={() =>
                  this.props.toggleModalVisibility("deleteModal")
                }
                okLabel={strings.button.submitLabel}
                deletionReason={this.props.deletionReason}
              />
            </Button.Group>
          )}
        {this.props.deletePermissionStatus === strings.states.approved &&
          !this.props.deleteSuccessful && (
            <Button.Group>
              <Button
                className="deleteButton"
                secondary
                onClick={() => this.props.toggleModalVisibility("deleteModal")}
                type="button"
              >
                {strings.button.deleteLabel}
              </Button>
              <BasicModal
                confirmMessage={strings.modal.deleteUserMessage}
                handleModalConfirm={this.props.handleModalConfirm}
                modalClassName="deleteModal"
                modalOpen={this.props.requestDeleteUserModalOpen}
                showOkButton={false}
                showYesButton={true}
                showNoButton={true}
                toggleModal={() =>
                  this.props.toggleModalVisibility("deleteModal")
                }
                noLabel={strings.button.noLabel}
                yesLabel={strings.button.yesLabel}
                okLabel={strings.button.okLabel}
              />
            </Button.Group>
          )}
      </div>
    );
  };
  renderCancelSaveButtons = () => {
    return (
      <div className="containerButtons">
        <Button.Group>
          <Button
            className="saveButton"
            primary
            inverted
            onClick={this.props.validateForm}
            disabled={
              JSON.stringify(this.props.selectedUserData) ===
              JSON.stringify(this.props.initialUserData)
            }
          >
            {strings.button.saveLabel}
          </Button>
          <BasicModal
            confirmMessage={strings.modal.saveChangesMessage}
            handleModalConfirm={this.props.handleModalConfirm}
            modalClassName="confirmSaveModal"
            modalOpen={this.props.confirmSaveModalOpen}
            showNoButton={true}
            showOkButton={false}
            showYesButton={true}
            toggleModal={this.props.toggleConfirmSaveModalVisibility}
            noLabel={strings.button.noLabel}
            yesLabel={strings.button.yesLabel}
            okLabel={strings.button.okLabel}
          />
        </Button.Group>
        <Button.Group>
          <Button
            className="cancelButton"
            primary
            onClick={this.props.toggleCancelChangesModalVisibility}
            type="button"
          >
            {strings.button.cancelLabel}
          </Button>
          <BasicModal
            confirmMessage={strings.modal.cancelChangesMessage}
            handleModalConfirm={
              this.props.mode === "create"
                ? this.props.handleModalConfirm
                : this.props.handleModeSwitch
            }
            modalClassName="cancelChangesModal"
            modalOpen={this.props.cancelChangesModalOpen}
            showOkButton={false}
            showYesButton={true}
            showNoButton={true}
            toggleModal={this.props.toggleCancelChangesModalVisibility}
            noLabel={strings.button.noLabel}
            yesLabel={strings.button.yesLabel}
            okLabel={strings.button.okLabel}
          />
        </Button.Group>
      </div>
    );
  };
  renderForm = () => {
    return (
      <>
        <div className="infoHeader">{strings.form.header.userInfo}</div>
        <div className="infoWrapper">
          <Form.Group>
            <ValidatedFormDropdown
              formInvalid={this.props.formInvalid}
              inputData={this.props.selectedUserData.roleId}
              viewData={
                dropdownHelper.getNameFromValues(
                  this.props.selectedUserData.roleId,
                  userRoleOptions
                ) || ""
              }
              dropdownLabel={strings.form.label.role}
              dropdownName="roleId"
              dropdownOptions={userRoleOptions}
              dropdownPlaceholder={strings.form.placeholder.role}
              formFieldWidth={8}
              handleChange={this.props.handleChange}
              validationResult={this.props.validationResults.roleId}
              mode={this.props.mode}
            />
            <ValidatedFormDropdown
              formInvalid={this.props.formInvalid}
              inputData={this.props.selectedUserData.area}
              viewData={this.props.selectedUserData.area || ""}
              dropdownLabel={strings.form.label.area}
              dropdownName="area"
              dropdownOptions={
                this.props.selectedUserData.roleId !==
                strings.fieldValues.mentorId
                  ? areaOptions
                  : areaOptionsMentor
              }
              dropdownPlaceholder={strings.form.placeholder.area}
              formFieldWidth={8}
              handleChange={this.props.handleChange}
              validationResult={this.props.validationResults.area}
              mode={this.props.mode}
            />
          </Form.Group>
          <Form.Group>
            <ValidatedFormInput
              formInvalid={this.props.formInvalid}
              inputData={this.props.selectedUserData.username}
              inputLabel={strings.form.label.userName}
              inputName="username"
              inputPlaceholder={strings.form.placeholder.userName}
              formFieldWidth={8}
              handleChange={this.props.handleChange}
              mode={this.props.mode}
              required={this.props.mode !== "edit"}
              disabled={this.props.mode === "edit"}
              validationResult={this.props.validationResults.username}
            />
            {this.props.mode === "create" && (
              <ValidatedFormInput
                formInvalid={this.props.formInvalid}
                inputData={this.props.selectedUserData.password}
                inputLabel={strings.form.label.password}
                inputName="password"
                inputPlaceholder={strings.form.placeholder.password}
                formFieldWidth={8}
                handleChange={this.props.handleChange}
                inputType="password"
                validationResult={this.props.validationResults.password}
                popUp={
                  <Popup
                    position="top left"
                    content={strings.tooltip.passwordFormatContent}
                    key="password"
                    header={strings.tooltip.passwordFormatHeader}
                    trigger={
                      <Icon className="info tooltip" name="info circle" />
                    }
                  />
                }
              />
            )}
          </Form.Group>
          <Form.Group>
            <ValidatedFormInput
              formInvalid={this.props.formInvalid}
              inputData={this.props.selectedUserData.firstName}
              inputLabel={strings.form.label.firstName}
              inputPlaceholder={strings.form.placeholder.firstName}
              inputName="firstName"
              formFieldWidth={8}
              handleChange={this.props.handleChange}
              validationResult={this.props.validationResults.firstName}
              mode={this.props.mode}
            />
            <ValidatedFormInput
              formInvalid={this.props.formInvalid}
              inputData={this.props.selectedUserData.surname}
              inputLabel={strings.form.label.surname}
              inputName="surname"
              inputPlaceholder={strings.form.placeholder.surname}
              formFieldWidth={8}
              handleChange={this.props.handleChange}
              validationResult={this.props.validationResults.surname}
              mode={this.props.mode}
            />
          </Form.Group>
          <Form.Group>
            <ValidatedFormInput
              formInvalid={this.props.formInvalid}
              inputData={this.props.selectedUserData.email}
              inputLabel={strings.form.label.email}
              inputName="email"
              inputPlaceholder={strings.form.placeholder.email}
              formFieldWidth={8}
              handleChange={this.props.handleChange}
              validationResult={this.props.validationResults.email}
              mode={this.props.mode}
              required={this.props.mode !== "edit"}
              disabled={this.props.mode === "edit"}
            />
            <ValidatedFormDropdown
              formInvalid={this.props.formInvalid}
              inputData={this.props.selectedUserData.organisationId}
              viewData={
                dropdownHelper.getNameFromValues(
                  this.props.selectedUserData.organisationId,
                  this.props.organisationList
                ) || ""
              }
              dropdownLabel={strings.form.label.organisation}
              dropdownName="organisationId"
              dropdownOptions={this.props.organisationList || []}
              dropdownPlaceholder={strings.form.placeholder.organisation}
              formFieldWidth={8}
              handleChange={this.props.handleChange}
              validationResult={this.props.validationResults.organisationId}
              mode={this.props.mode}
            />
          </Form.Group>

          {this.props.selectedUserData.roleId ===
            strings.fieldValues.mentorId && (
            <Form.Group>
              <ValidatedFormInput
                formInvalid={this.props.formInvalid}
                inputData={this.props.selectedUserData.caseloadMax}
                inputLabel={strings.form.label.caseloadMax}
                inputName="caseloadMax"
                inputPlaceholder={strings.form.placeholder.caseloadMax}
                formFieldWidth={8}
                onKeyPress={this.props.allowNumericalInput}
                handleChange={this.props.handleChange}
                validationResult={this.props.validationResults.caseloadMax}
                mode={this.props.mode}
              />
              {this.props.selectedUserData.organisationId && (
                <ValidatedFormDropdown
                  formInvalid={this.props.formInvalid}
                  inputData={this.props.selectedUserData.prison || []}
                  viewData={this.props.selectedUserData.prison}
                  dropdownLabel={strings.form.label.prison}
                  dropdownName="prison"
                  dropdownOptions={
                    this.props.selectedUserData.area === strings.areas.NRS
                      ? prisonNrsOptions
                      : prisonNrneOptions
                  }
                  dropdownPlaceholder={strings.form.placeholder.prison}
                  formFieldWidth={8}
                  handleChange={this.props.handleChange}
                  validationResult={this.props.validationResults.prison}
                  dropdownMultiple={true}
                  required={false}
                  mode={this.props.mode}
                />
              )}
            </Form.Group>
          )}
          <Form.Group>
            {this.props.selectedUserData.roleId ===
              strings.fieldValues.mentorId &&
              this.props.selectedUserData.organisationId && (
                <>
                  <ValidatedFormDropdown
                    formInvalid={this.props.formInvalid}
                    inputData={
                      this.props.selectedUserData.localAuthorityArea || []
                    }
                    viewData={this.props.selectedUserData.localAuthorityArea}
                    dropdownLabel={strings.form.label.localAuthorityArea}
                    dropdownName="localAuthorityArea"
                    dropdownOptions={
                      this.props.selectedUserData.area === strings.areas.NRS
                        ? localAuthorityAreasNrs
                        : localAuthorityAreasNrne
                    }
                    dropdownPlaceholder={
                      strings.form.placeholder.localAuthorityArea
                    }
                    formFieldWidth={8}
                    handleChange={this.props.handleChange}
                    validationResult={
                      this.props.validationResults.localAuthorityArea
                    }
                    dropdownMultiple={true}
                    required={false}
                    mode={this.props.mode}
                  />
                </>
              )}
          </Form.Group>
        </div>
      </>
    );
  };

  render = () => {
    return (
      <div className="container">
        <ComponentHeader
          returnToList={this.returnToList}
          pageTitle={this.props.pageTitle}
          mode={this.props.mode}
          requestStatus={this.props.requestStatus}
          modifyButtons={this.renderModifyButtons()}
          cancelSaveButtons={this.renderCancelSaveButtons()}
          headerList={strings.header.userList}
          permissionsUpdate={permissions.users}
          permissionsCreate={permissions.users}
          formInvalid={this.props.formInvalid}
          validationResults={this.props.validationResults}
          sticky
        />

        <Form id="userForm" className="infoBlock stickyForm">
          <fieldset disabled={this.props.mode === "view"}>
            {this.renderForm()}
          </fieldset>
        </Form>
      </div>
    );
  };
}

ModifyUser.propTypes = {
  mode: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  selectedUserData: PropTypes.object.isRequired,
  pageTitle: PropTypes.string.isRequired,
  confirmSaveModalOpen: PropTypes.bool.isRequired,
  cancelChangesModalOpen: PropTypes.bool.isRequired,
  toggleConfirmSaveModalVisibility: PropTypes.func.isRequired,
  toggleCancelChangesModalVisibility: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleModalConfirm: PropTypes.func.isRequired,
  formInvalid: PropTypes.bool.isRequired,
  validationResults: PropTypes.object,
  validateForm: PropTypes.func.isRequired,
  requestStatus: PropTypes.bool,
  initialUserData: PropTypes.object.isRequired,
  deletePermissionStatus: PropTypes.string,
  handleModalInputChange: PropTypes.func,
  deletionReason: PropTypes.object
};

export default withRouter(ModifyUser);
