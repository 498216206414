import React from "react";
import ModifyCost from "../../components/ModifyCost";
import { Loader, Divider } from "semantic-ui-react";
import { connect } from "react-redux";
import { strings } from "../../resources";
import { costActions } from "../../store/actions/cost.actions";
import { organisationActions } from "../../../Organisations/store/actions/organisation.actions";
import { programmeActions } from "../../../Programmes/store/actions/programme.actions";
import { amendmentActions } from "../../../Amendments/store/actions/amendment.actions";
import { userActions } from "../../../Users/store/actions/user.actions";
import PropTypes from "prop-types";
import { RequestFeedback } from "../../components";
import {
  functions as twgValidationFunctions,
  constraints as twgConstraints
} from "pulsion-twg-validation";
import {
  convertToIso,
  getOrganisations,
  canUserModifyRecord
} from "../../helpers";

class CostEditContainer extends React.Component {
  state = {
    mode: "view",
    confirmSaveModalOpen: false,
    cancelChangesModalOpen: false,
    enableDisableCostModalOpen: false,
    initialCostData: {},
    formInvalid: false,
    validationResults: {},
    unknownRequestStatus: false,
    searchCriteria: {
      from: 0,
      query: "",
      fields: ["*"],
      clear: false,
      sort: {},
      activeOnly: false
    },
    hasPageLoaded: false,
    isPrePopulated: false,
    deletionReason: undefined,
    permissionStatus: ""
  };

  componentDidMount = async () => {
    await this.handleFetchData();
    await this.props.getCostById(
      this.props.match.params.id,
      this.props.headers
    );

    let form = document.getElementById("costForm");
    if (form) {
      form.setAttribute("novalidate", true);
      //set the form to have the novalidate attribute to suppress the default html validation tooltips
    }
    this.setState({ hasPageLoaded: true });

    if (this.state.mode === "view" && this.props.selectedCostData.requestId) {
      await this.props.getDeletePermissionStatus(
        this.props.selectedCostData.requestId
      );
      await this.setState(
        { permissionStatus: this.props.permissionStatus },
        async () => {
          if (this.state.permissionStatus === strings.states.rejected) {
            await this.props.updateSelectedCost("requestId", null);
            this.props.updateCost(
              this.props.selectedCostData.costId,
              this.props.selectedCostData,
              this.props.headers,
              this.state.initialCostData,
              false
            );
          }
        }
      );
    }
    this.setState({ initialCostData: this.props.selectedCostData });
  };

  componentWillUnmount() {
    this.props.clearSelectedCost();
  }

  handleFetchData = async page => {
    const sCrit = this.state.searchCriteria;
    sCrit.size = 10000;
    await this.props.getOrganisations(
      this.props.headers,
      sCrit.query,
      sCrit.size,
      page * sCrit.size,
      sCrit.clear,
      sCrit.fields,
      sCrit.sort,
      sCrit.activeOnly
    );
    await this.props.getUsers(
      this.props.headers,
      sCrit.query,
      sCrit.size,
      page * sCrit.size,
      sCrit.clear,
      sCrit.fields,
      sCrit.sort,
      sCrit.activeOnly
    );
    await this.props.getProgrammeById(localStorage.getItem("programmeId"));
  };

  handleRequestDeletePermission = async () => {
    await this.props.requestDeletePermission(
      this.props.selectedCostData.costId,
      this.state.deletionReason.value,
      this.props.headers,
      this.props.selectedCostData
    );
    this.props.updateSelectedCost("requestId", this.props.requestId);
    this.props.updateCost(
      this.props.selectedCostData.costId,
      this.props.selectedCostData,
      this.props.headers,
      this.state.initialCostData
    );
    this.setState({
      initialCostData: this.props.selectedCostData
    });
  };

  handleModalInputChange = (e, value) => {
    this.setState({ deletionReason: value });
  };

  handleDelete = async () => {
    await this.props.deleteCost(
      this.props.selectedCostData.costId,
      this.props.headers
    );
    if (this.props.deleteSuccessful) {
      this.props.setDeletePermissionStatusActioned(
        this.props.selectedCostData.requestId
      );
    }
  };

  validateForm = async () => {
    let details = JSON.parse(JSON.stringify(this.props.selectedCostData));
    for (let property in details) {
      if (!details[property]) {
        delete details[property];
      }
    }
    let results = twgValidationFunctions.validateData(
      details,
      twgConstraints.costs.modifyCost
    );
    if (results) {
      this.setState({
        validationResults: results,
        formInvalid: true
      });
    } else {
      this.setState({ formInvalid: false });
      this.toggleConfirmSaveModalVisibility();
    }
  };

  handleSubmit = async () => {
    await this.props.updateCost(
      this.props.match.params.id,
      this.props.selectedCostData,
      this.props.headers,
      this.state.initialCostData
    );
    this.handleModeSwitch();
  };

  handleCancel = () => {
    this.setState({ formInvalid: false });
    this.props.history.goBack();
  };

  handleChange = async (event, data) => {
    if (
      typeof data.value === "string" &&
      data.value.match(/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/)
    ) {
      await this.props.updateSelectedCost(data.name, convertToIso(data.value));
    } else {
      await this.props.updateSelectedCost(data.name, data.value);
    }
  };

  handleEnableDisable = async () => {
    const body = {
      active: !this.props.selectedCostData.active
    };
    await this.props.updateCost(
      this.props.match.params.id,
      body,
      this.props.headers,
      this.state.initialCostData
    );
  };

  handleModalConfirm = modalClassName => {
    switch (modalClassName) {
      case "confirmSaveModal":
        this.handleSubmit();
        break;
      case "cancelChangesModal":
        this.handleCancel();
        break;
      case "activationModal":
        this.handleEnableDisable();
        break;
      case "requestDeletePermissionModal":
        this.handleRequestDeletePermission();
        break;
      case "deleteModal":
        this.handleDelete();
        break;

      default:
        break;
    }
  };

  getOrganisations = () => {
    return getOrganisations(this.props.organisationListData);
  };

  toggleConfirmSaveModalVisibility = () => {
    this.setState({
      confirmSaveModalOpen: !this.state.confirmSaveModalOpen
    });
  };

  toggleCancelChangesModalVisibility = () => {
    this.setState({
      cancelChangesModalOpen: !this.state.cancelChangesModalOpen
    });
  };

  toggleEnableDisableCostModalVisibility = () => {
    this.setState({
      enableDisableCostModalOpen: !this.state.enableDisableCostModalOpen
    });
  };

  handleModeSwitch = async () => {
    if (this.state.mode === "edit") {
      if (
        JSON.stringify(this.state.initialCostData) !==
        JSON.stringify(this.props.selectedCostData)
      ) {
        await this.props.cancelSelectedCostUpdate(this.state.initialCostData);
      }
      this.setState({ mode: "view", formInvalid: false });
    } else {
      this.setState({
        mode: "edit",
        initialCostData: JSON.parse(JSON.stringify(this.props.selectedCostData))
      });
    }
  };

  isDisabled = () => {
    return !canUserModifyRecord(
      this.props.selectedProgrammeData,
      this.props.organisationOfLoggedInUser
    );
  };

  render = () => {
    const loading =
      this.props.loadingPage ||
      this.props.orgsLoadingPage ||
      this.props.usersLoadingPage ||
      this.props.programmeLoadingPage;

    return (
      <div>
        <div style={{ display: loading ? "" : "none" }}>
          <Loader active>{strings.header.loading}</Loader>
        </div>
        <div style={{ display: loading ? "none" : "" }}>
          {this.props.showNotification && (
            <RequestFeedback
              requestStatus={this.props.costRequestStatus}
              requestMade={this.props.costRequestMade}
              unknownRequestStatus={this.state.unknownRequestStatus}
              successMessage={this.props.result}
              failureMessage={this.props.error}
              processingFeedbackMessage={strings.form.feedback.processing}
              unknownFeedbackMessage={strings.form.feedback.costRequestUnknown}
              statusFeedbackMessage={strings.form.feedback.status}
              successFeedbackMessage={strings.form.feedback.success}
              errorDetails={this.props.errorDetails}
            />
          )}
          <Divider hidden />
          <ModifyCost
            mode={this.state.mode}
            requestStatus={this.props.costRequestStatus}
            pageTitle={
              this.state.mode === "edit"
                ? strings.header.editCost
                : strings.header.viewCost
            }
            toggleCancelChangesModalVisibility={
              this.toggleCancelChangesModalVisibility
            }
            toggleConfirmSaveModalVisibility={
              this.toggleConfirmSaveModalVisibility
            }
            toggleEnableDisableCostModalVisibility={
              this.toggleEnableDisableCostModalVisibility
            }
            selectedCostData={this.props.selectedCostData}
            handleChange={this.handleChange}
            handleModeSwitch={this.handleModeSwitch}
            handleModalConfirm={this.handleModalConfirm}
            cancelChangesModalOpen={this.state.cancelChangesModalOpen}
            confirmSaveModalOpen={this.state.confirmSaveModalOpen}
            enableDisableCostModalOpen={this.state.enableDisableCostModalOpen}
            initialCostData={this.state.initialCostData}
            formInvalid={this.state.formInvalid}
            validateForm={this.validateForm}
            validationResults={this.state.validationResults}
            type={this.state.costType}
            headers={this.props.headers}
            getOrganisations={this.getOrganisations}
            hasPageLoaded={this.state.hasPageLoaded}
            area={this.props.selectedProgrammeData.area}
            userList={this.props.userListData}
            deletePermissionStatus={this.state.permissionStatus}
            handleModalInputChange={this.handleModalInputChange}
            deletionReason={this.state.deletionReason}
            deleteSuccessful={this.props.deleteSuccessful}
            isDisabled={this.isDisabled()}
          />
        </div>
      </div>
    );
  };
}

CostEditContainer.propTypes = {
  match: PropTypes.object.isRequired,
  headers: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  loadingPage: PropTypes.bool.isRequired,
  selectedCostData: PropTypes.object.isRequired,
  updateSelectedCost: PropTypes.func.isRequired,
  updateCost: PropTypes.func.isRequired,
  getCostById: PropTypes.func.isRequired,
  errorDetails: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

const mapStateToProps = state => {
  const { costs, auth, organisations, programmes, users, amendments } = state;
  const {
    loadingPage,
    selectedCostData,
    costRequestStatus,
    costRequestMade,
    result,
    error,
    errorDetails,
    costListData,
    deleteSuccessful,
    showNotification
  } = costs;
  const { headers, organisationOfLoggedInUser } = auth;
  const { organisationListData, loadingPage: orgsLoadingPage } = organisations;
  const {
    selectedProgrammeData,
    loadingPage: programmeLoadingPage
  } = programmes;
  const { userListData, loadingPage: usersLoadingPage } = users;
  const { requestId, permissionStatus } = amendments;
  return {
    headers,
    loadingPage,
    selectedCostData,
    costRequestStatus,
    costRequestMade,
    result,
    error,
    errorDetails,
    costListData,
    organisationListData,
    selectedProgrammeData,
    userListData,
    requestId,
    permissionStatus,
    deleteSuccessful,
    showNotification,
    organisationOfLoggedInUser,
    programmeLoadingPage,
    orgsLoadingPage,
    usersLoadingPage
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateCost: (
      id,
      data,
      headers,
      initialCostData,
      showNotification = true
    ) => {
      dispatch(
        costActions.updateCost(
          id,
          data,
          headers,
          initialCostData,
          showNotification
        )
      );
    },
    getCostById: async (id, headers) => {
      await dispatch(costActions.getCostById(id, headers));
    },
    updateSelectedCost: (key, value) => {
      dispatch(costActions.updateSelectedCost(key, value));
    },
    cancelSelectedCostUpdate: data => {
      dispatch(costActions.cancelSelectedCostUpdate(data));
    },
    clearCostRequest: () => {
      dispatch(costActions.clearCostRequest());
    },
    clearSelectedCost: () => {
      dispatch(costActions.clearSelectedCost());
    },
    getOrganisations: (
      headers,
      queryString,
      size,
      from,
      clear,
      fields,
      sort,
      activeOnly
    ) => {
      dispatch(
        organisationActions.getOrganisations(
          headers,
          queryString,
          size,
          from,
          clear,
          fields,
          sort,
          activeOnly
        )
      );
    },
    getProgrammeById: (id, headers) => {
      dispatch(programmeActions.getProgrammeById(id, headers));
    },
    clearSelectedProgramme: () => {
      dispatch(programmeActions.clearSelectedProgramme());
    },
    getUsers: async (
      headers,
      queryString,
      size,
      from,
      clear,
      fields,
      sort,
      activeOnly
    ) => {
      await dispatch(
        userActions.getUsers(
          headers,
          queryString,
          size,
          from,
          clear,
          fields,
          sort,
          activeOnly
        )
      );
    },
    requestDeletePermission: async (id, reason, headers, body) => {
      await dispatch(
        amendmentActions.requestDeletePermission(id, reason, headers, body)
      );
    },
    getDeletePermissionStatus: async (id, headers) => {
      await dispatch(amendmentActions.getDeletePermissionStatus(id, headers));
    },
    deleteCost: async (id, headers) => {
      await dispatch(costActions.deleteCost(id, headers));
    },
    setDeletePermissionStatusActioned: (id, headers) => {
      dispatch(amendmentActions.setDeletePermissionStatusActioned(id, headers));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CostEditContainer);
