import { API } from "../../services";
import { employmentActionTypes } from "./employment.actionTypes";

const api = new API();

const createEmployment = (details, headers) => {
  const request = details => {
    return { type: employmentActionTypes.CREATE_EMPLOYMENT_REQUEST, details };
  };
  const success = response => {
    return { type: employmentActionTypes.CREATE_EMPLOYMENT_SUCCESS, response };
  };
  const failure = error => {
    return { type: employmentActionTypes.CREATE_EMPLOYMENT_FAILURE, error };
  };

  api.setHeaders({ ...headers, systemHeader_subsysref: "createEmployment" });

  return dispatch => {
    dispatch(request(details));
    const body = {
      ...details
    };
    return new Promise((resolve, reject) => {
      api.createEmployment(body).then(
        response => {
          dispatch(success(response));
          resolve(response);
        },
        error => {
          dispatch(failure(error));
          reject(error);
        }
      );
    });
  };
};

const clearEmploymentRequest = () => {
  const request = () => {
    return { type: employmentActionTypes.CLEAR_CREATE_EMPLOYMENT_REQUEST };
  };

  const success = () => {
    return { type: employmentActionTypes.CLEAR_CREATE_EMPLOYMENT_SUCCESS };
  };

  return dispatch => {
    dispatch(request());
    dispatch(success());
  };
};

const updateEmployment = (
  id,
  details,
  headers,
  initialDetails,
  showNotification = true
) => {
  const request = details => {
    return { type: employmentActionTypes.UPDATE_EMPLOYMENT_REQUEST, details };
  };
  const success = response => {
    return {
      type: employmentActionTypes.UPDATE_EMPLOYMENT_SUCCESS,
      response,
      showNotification
    };
  };
  const failure = error => {
    return {
      type: employmentActionTypes.UPDATE_EMPLOYMENT_FAILURE,
      error,
      initialDetails
    };
  };

  api.setHeaders({ ...headers, systemHeader_subsysref: "updateEmployment" });

  return dispatch => {
    dispatch(request(details));
    const body = {
      ...details
    };

    api.updateEmployment(id, body).then(
      response => {
        dispatch(success(response));
      },
      error => dispatch(failure(error, initialDetails))
    );
  };
};

const deleteEmployment = (id, headers) => {
  const request = () => {
    return { type: employmentActionTypes.DELETE_EMPLOYMENT_REQUEST };
  };
  const success = response => {
    return { type: employmentActionTypes.DELETE_EMPLOYMENT_SUCCESS, response };
  };
  const failure = error => {
    return { type: employmentActionTypes.DELETE_EMPLOYMENT_FAILURE, error };
  };

  api.setHeaders({ ...headers, systemHeader_subsysref: "deleteEmployment" });

  return dispatch => {
    dispatch(request());
    return new Promise((resolve, reject) => {
      if (id !== "") {
        api.deleteEmployment(id).then(
          data => {
            dispatch(success(data));
            resolve(data);
          },
          error => {
            dispatch(failure(error));
            reject(error);
          }
        );
      }
    });
  };
};

const getEmployments = (
  headers,
  queryString,
  size,
  from,
  clear,
  fields = ["*"],
  sort = {},
  activeOnly,
  programmeId
) => {
  const request = () => {
    return { type: employmentActionTypes.GET_EMPLOYMENTS_REQUEST };
  };
  const success = response => {
    return { type: employmentActionTypes.GET_EMPLOYMENTS_SUCCESS, response };
  };
  const failure = error => {
    return { type: employmentActionTypes.GET_EMPLOYMENTS_FAILURE, error };
  };

  api.setHeaders({ ...headers, systemHeader_subsysref: "getEmployments" });

  return dispatch => {
    dispatch(request());
    if (clear) {
      // If supposed to clear, replicate blank result
      dispatch(success({ hits: { hits: [] } }));
    } else {
      api
        .getEmployments(
          queryString,
          size,
          from,
          fields,
          sort,
          activeOnly,
          programmeId
        )
        .then(
          response => {
            dispatch(success(response));
          },
          error => dispatch(failure(error))
        );
    }
  };
};

const updateSearchCriteria = (
  headers,
  queryString,
  size,
  from,
  clear,
  fields,
  sort,
  activeOnly,
  programmeId
) => {
  const request = () => {
    return { type: employmentActionTypes.UPDATE_SEARCH_CRITERIA_REQUEST };
  };
  const success = response => {
    return {
      type: employmentActionTypes.UPDATE_SEARCH_CRITERIA_SUCCESS,
      response
    };
  };
  const newCriteria = {
    query: queryString,
    size: size,
    from: from,
    clear,
    fields: fields,
    sort: sort,
    activeOnly: activeOnly,
    programmeId: programmeId
  };
  return dispatch => {
    dispatch(request());
    dispatch(success(newCriteria));
    if (programmeId) {
      dispatch(
        getEmployments(
          headers,
          queryString,
          size,
          from,
          clear,
          fields,
          sort,
          activeOnly,
          programmeId
        )
      );
    }
  };
};

const getEmploymentById = (id, headers) => {
  const request = () => {
    return { type: employmentActionTypes.GET_EMPLOYMENT_BY_ID_REQUEST };
  };
  const success = response => {
    return {
      type: employmentActionTypes.GET_EMPLOYMENT_BY_ID_SUCCESS,
      response
    };
  };
  const failure = error => {
    return { type: employmentActionTypes.GET_EMPLOYMENT_BY_ID_FAILURE, error };
  };

  api.setHeaders({ ...headers, systemHeader_subsysref: "getEmploymentById" });

  return dispatch => {
    dispatch(request());
    return new Promise((resolve, reject) => {
      api.getEmploymentById(id).then(
        response => {
          dispatch(success(response.data));
          resolve(response.data);
        },
        error => {
          dispatch(failure(error));
          reject(error);
        }
      );
    });
  };
};

const updateSelectedEmployment = (key, value) => {
  const request = (key, value) => {
    return {
      type: employmentActionTypes.UPDATE_SELECTED_EMPLOYMENT_REQUEST,
      key,
      value
    };
  };
  const success = (key, value) => {
    return {
      type: employmentActionTypes.UPDATE_SELECTED_EMPLOYMENT_SUCCESS,
      key,
      value
    };
  };
  const failure = () => {
    return { type: employmentActionTypes.UPDATE_SELECTED_EMPLOYMENT_FAILURE };
  };

  return dispatch => {
    dispatch(request(key, value));
    if (key) {
      dispatch(success(key, value));
    } else {
      dispatch(failure());
    }
  };
};

const clearSelectedEmployment = () => {
  const request = () => {
    return { type: employmentActionTypes.CLEAR_SELECTED_EMPLOYMENT_REQUEST };
  };
  const success = () => {
    return { type: employmentActionTypes.CLEAR_SELECTED_EMPLOYMENT_SUCCESS };
  };

  return dispatch => {
    dispatch(request());
    dispatch(success());
  };
};

const cancelSelectedEmploymentUpdate = initialEmploymentData => {
  const request = () => {
    return {
      type: employmentActionTypes.CANCEL_SELECTED_EMPLOYMENT_UPDATE_REQUEST
    };
  };
  const success = data => {
    return {
      type: employmentActionTypes.CANCEL_SELECTED_EMPLOYMENT_UPDATE_SUCCESS,
      data
    };
  };

  return dispatch => {
    dispatch(request());
    dispatch(success(initialEmploymentData));
  };
};

export const employmentActions = {
  createEmployment,
  updateEmployment,
  deleteEmployment,
  getEmploymentById,
  getEmployments,
  updateSearchCriteria,
  updateSelectedEmployment,
  clearSelectedEmployment,
  clearEmploymentRequest,
  cancelSelectedEmploymentUpdate
};
