import React from "react";
import TableComponent from "../../components/ActivityTable";
import { Loader, Divider } from "semantic-ui-react";
import { connect } from "react-redux";
import { activityActions } from "../../store/actions/activity.actions";
import { organisationActions } from "../../../Organisations/store/actions/organisation.actions";
import { strings } from "../../resources";
import PropTypes from "prop-types";
import { RequestFeedback } from "../../components";
import { getOrganisations, returnToParent } from "../../helpers";

const requestTimeout = 20000;

class ActivityListContainer extends React.Component {
  state = {
    deleteModalOpen: false,
    unknownRequestStatus: false,
    pageIndex: 0,
    pages: 1,
    activityDeletionId: "",
    searchCriteria: {
      size: 20,
      from: 0,
      query: "",
      fields: ["*"],
      clear: false,
      sort: {},
      activeOnly: false
    },
    orgsSearchCriteria: {
      from: 0,
      query: "",
      fields: ["*"],
      clear: false,
      sort: {},
      activeOnly: false
    },
    programmeId: localStorage.getItem("programmeId"),
    searchBarQuery: ""
  };

  componentDidMount = async () => {
    if (!localStorage.getItem("programmeId") && !this.props.engagementId) {
      this.props.history.goBack();
    }
    const sCrit = this.state.orgsSearchCriteria;
    this.props.getOrganisations(
      this.props.headers,
      sCrit.query,
      10000,
      0,
      sCrit.clear,
      sCrit.fields,
      sCrit.sort,
      sCrit.activeOnly
    );
    this.timerID = setTimeout(() => {
      if (
        this.props.activityRequestStatus === undefined &&
        this.props.activityRequestMade
      ) {
        this.setState({ unknownRequestStatus: true });
      }
    }, requestTimeout);
  };

  componentWillUnmount() {
    clearTimeout(this.timerID);
    this.props.clearActivityRequest();
  }

  handleDelete = () => {
    this.props.deleteActivity(
      this.state.activityDeletionId,
      this.props.headers
    );
  };

  handleRefresh = async () => {
    this.setState({ searchBarQuery: "" });
    await this.props.clearActivityRequest();
    await this.handleFetchData(this.state);
  };

  handleSubmit = async () => {
    await this.handleFetchData(this.state);
  };

  handleChange = event => {
    let searchCriteria = { ...this.state.searchCriteria };
    this.setState({ searchBarQuery: event.target.value });
    searchCriteria.query = this.state.searchBarQuery;
    this.setState({ searchCriteria: searchCriteria });
  };

  handleFetchData = async state => {
    const sCrit = this.state.searchCriteria;
    let sortObject = {};
    if (state.sorted) {
      state.sorted.forEach(sortCondition => {
        sortCondition.desc
          ? (sortObject[sortCondition.id] = { order: "desc" })
          : (sortObject[sortCondition.id] = { order: "asc" });
      });
    }

    if (this.state.programmeId || this.props.engagementId) {
      sCrit.fields = ["type.normalizer"];
      sCrit.programmeId = this.state.programmeId;
      sCrit.query = this.state.searchBarQuery;
    }
    sCrit.engagementId = this.props.engagementId;
    sCrit.referralId = localStorage.getItem("parentReferralId");
    await this.props.updateSearchCriteria(
      this.props.headers,
      sCrit.query,
      sCrit.size,
      state.page * sCrit.size,
      sCrit.clear,
      sCrit.fields,
      sortObject,
      sCrit.activeOnly,
      sCrit.engagementId,
      sCrit.programmeId,
      sCrit.referralId
    );
  };

  updatePageIndex = state => {
    this.setState({
      pageIndex: state.page
    });
  };

  handleModalConfirm = modalClassName => {
    switch (modalClassName) {
      case "deleteActivityModal":
        this.handleDelete();
        break;
      default:
        break;
    }
  };

  toggleConfirmDeleteModalVisibility = id => {
    this.setState({
      deleteModalOpen: !this.state.deleteModalOpen,
      activityDeletionId: id
    });
  };

  render = () => {
    const loading = this.props.loadingPage || this.props.orgsLoadingPage;

    let parentPage;
    if (localStorage.getItem("programmeName")) {
      parentPage = localStorage.getItem("programmeName");
    } else if (localStorage.getItem("referralName")) {
      parentPage = localStorage.getItem("referralName");
    }

    return (
      <div>
        <div
          style={{
            display: loading ? "" : "none"
          }}
        >
          <Loader active>{strings.header.loading}</Loader>
        </div>
        <div
          style={{
            display: loading ? "none" : ""
          }}
        >
          <RequestFeedback
            requestStatus={this.props.activityRequestStatus}
            requestMade={this.props.activityRequestMade}
            unknownRequestStatus={this.state.unknownRequestStatus}
            successMessage={this.props.result}
            failureMessage={this.props.error}
            optionalRefreshMessage={
              strings.form.feedback.activityRequestRefreshPrompt
            }
            processingFeedbackMessage={strings.form.feedback.processing}
            unknownFeedbackMessage={
              strings.form.feedback.activityRequestUnknown
            }
            statusFeedbackMessage={strings.form.feedback.status}
            successFeedbackMessage={strings.form.feedback.success}
            errorDetails={this.props.errorDetails}
          />
          <Divider hidden />
          <TableComponent
            headers={this.props.headers}
            history={this.props.history}
            activityListData={this.props.activityListData}
            toggleDeleteModal={this.toggleConfirmDeleteModalVisibility}
            deleteModalOpen={this.state.deleteModalOpen}
            handleModalConfirm={this.handleModalConfirm}
            handleRefresh={this.handleRefresh}
            pageSize={this.state.searchCriteria.size}
            pages={this.state.pages}
            handleFetchData={this.handleFetchData}
            totalResults={this.props.totalResults}
            updatePageIndex={this.updatePageIndex}
            roleId={this.props.roleId}
            hideCreate={true}
            organisationList={getOrganisations(this.props.organisationListData)}
            searchBarQuery={this.state.searchBarQuery}
            handleChange={this.handleChange}
            handleSubmit={this.handleSubmit}
            referralId={this.props.referralId}
            hideSearchBar={this.props.hideSearchBar}
            area={this.props.area}
            parentPage={parentPage}
            returnToParent={() => {
              returnToParent(this.props.history);
            }}
          />
        </div>
      </div>
    );
  };
}

ActivityListContainer.propTypes = {
  activityListData: PropTypes.array.isRequired,
  activityToDelete: PropTypes.string.isRequired,
  deleteActivity: PropTypes.func.isRequired,
  loadingPage: PropTypes.bool.isRequired,
  getActivities: PropTypes.func.isRequired,
  updateSearchCriteria: PropTypes.func.isRequired,
  headers: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  errorDetails: PropTypes.array
};

const mapStateToProps = state => {
  const { activities, auth } = state;
  const { roleId, area } = auth;
  const {
    activityListData,
    loadingPage,
    activityToDelete,
    activityRequestStatus,
    activityRequestMade,
    result,
    error,
    totalResults,
    errorDetails
  } = activities;
  const {
    organisationListData,
    loadingPage: orgsLoadingPage
  } = state.organisations;
  return {
    activityListData,
    loadingPage,
    activityToDelete,
    activityRequestStatus,
    activityRequestMade,
    roleId,
    result,
    error,
    totalResults,
    errorDetails,
    organisationListData,
    orgsLoadingPage,
    area
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getActivities: (
      headers,
      queryString,
      size,
      from,
      clear,
      fields,
      sort,
      activeOnly
    ) => {
      dispatch(
        activityActions.getActivities(
          headers,
          queryString,
          size,
          from,
          clear,
          fields,
          sort,
          activeOnly
        )
      );
    },
    updateSearchCriteria: (
      headers,
      data,
      size,
      from,
      clear,
      fields,
      sort,
      activeOnly,
      engagementId,
      programmeId,
      referralId
    ) => {
      dispatch(
        activityActions.updateSearchCriteria(
          headers,
          data,
          size,
          from,
          clear,
          fields,
          sort,
          activeOnly,
          engagementId,
          programmeId,
          referralId
        )
      );
    },
    deleteActivity: (id, headers) => {
      dispatch(activityActions.deleteActivity(id, headers));
    },
    clearActivityRequest: () => {
      dispatch(activityActions.clearActivityRequest());
    },
    getOrganisations: (
      headers,
      queryString,
      size,
      from,
      clear,
      fields,
      sort,
      activeOnly
    ) => {
      dispatch(
        organisationActions.getOrganisations(
          headers,
          queryString,
          size,
          from,
          clear,
          fields,
          sort,
          activeOnly
        )
      );
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ActivityListContainer);
