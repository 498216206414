import axios from "./axios-amendments";

export class API {
  constructor() {
    this.headers = {};

    this.setHeaders.bind(this);
  }

  setHeaders = headers => {
    this.headers = headers;
  };

  requestDeletePermission = (id, reason, body) => {
    const config = {
      headers: this.headers
    };
    const query = {
      recordId: id,
      requestType: "DELETE",
      requestDetails: { reason: reason, body: body }
    };
    return new Promise((resolve, reject) => {
      axios
        .post("/", JSON.stringify(query), config)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          console.log("Error requesting delete permission: ", error.message);
          reject(error);
        });
    });
  };

  getDeletePermissionStatus = id => {
    const config = {
      headers: this.headers
    };

    return new Promise((resolve, reject) => {
      axios
        .get(`/${id}`, config)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          console.log("Error requesting permission: ", error.message);
          reject(error);
        });
    });
  };

  setDeletePermissionStatusActioned = id => {
    const config = {
      headers: this.headers
    };

    const body = {
      status: "Actioned"
    };

    return new Promise((resolve, reject) => {
      axios
        .put(`/${id}`, JSON.stringify(body), config)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          console.log("Error setting status: ", error.message);
          reject(error);
        });
    });
  };

  updateDeletePermissionStatus = (id, approvalStatus) => {
    const config = {
      headers: this.headers
    };

    const body = {
      status: approvalStatus
    };

    return new Promise((resolve, reject) => {
      axios
        .put(`/${id}`, JSON.stringify(body), config)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          console.log("Error setting status: ", error.message);
          reject(error);
        });
    });
  };
}
