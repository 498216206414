import React from "react";
import PropTypes from "prop-types";
import { Form } from "semantic-ui-react";
import { strings, yesNoOptions } from "../../resources";
import { ValidatedFormInput, ValidatedFormDropdown } from "../validation";
import "../css/ReferralComponents.scss";
import FileManager from "../../../File/components/FileManager";

class DataProtectionFields extends React.Component {
  render = () => {
    return (
      <div className="infoBlock">
        <div className="infoHeader">
          {strings.form.header.dataProtectionHeader}
        </div>
        <div className="infoWrapper">
          <Form.Group>
            <ValidatedFormDropdown
              formInvalid={this.props.formInvalid}
              inputData={this.props.selectedReferralData.consent}
              viewData={this.props.selectedReferralData.consent}
              dropdownLabel={strings.form.label.consent}
              dropdownName="consent"
              dropdownOptions={yesNoOptions}
              dropdownPlaceholder={strings.form.placeholder.consent}
              formFieldWidth={8}
              handleChange={this.props.handleChange}
              validationResult={this.props.validationResults.consent}
              mode={this.props.mode}
            />
            <ValidatedFormDropdown
              formInvalid={this.props.formInvalid}
              inputData={this.props.selectedReferralData.goodNewsStories}
              viewData={this.props.selectedReferralData.goodNewsStories}
              dropdownLabel={strings.form.label.goodNewsStories}
              dropdownName="goodNewsStories"
              dropdownOptions={yesNoOptions}
              dropdownPlaceholder={strings.form.placeholder.goodNewsStories}
              formFieldWidth={8}
              handleChange={this.props.handleChange}
              validationResult={this.props.validationResults.goodNewsStories}
              mode={this.props.mode}
            />
          </Form.Group>
          <Form.Group>
            <ValidatedFormDropdown
              formInvalid={this.props.formInvalid}
              inputData={this.props.selectedReferralData.newRoutesMarketing}
              viewData={this.props.selectedReferralData.newRoutesMarketing}
              dropdownLabel={strings.form.label.newRoutesMarketing}
              dropdownName="newRoutesMarketing"
              dropdownOptions={yesNoOptions}
              dropdownPlaceholder={strings.form.placeholder.newRoutesMarketing}
              formFieldWidth={8}
              handleChange={this.props.handleChange}
              validationResult={this.props.validationResults.newRoutesMarketing}
              mode={this.props.mode}
            />
            <ValidatedFormDropdown
              formInvalid={this.props.formInvalid}
              inputData={this.props.selectedReferralData.doNotApproach}
              viewData={this.props.selectedReferralData.doNotApproach}
              dropdownLabel={strings.form.label.doNotApproach}
              dropdownName="doNotApproach"
              dropdownOptions={yesNoOptions}
              dropdownPlaceholder={strings.form.placeholder.doNotApproach}
              formFieldWidth={8}
              handleChange={this.props.handleChangeDoNotApproach}
              validationResult={this.props.validationResults.doNotApproach}
              mode={this.props.mode}
            />
          </Form.Group>
          {this.props.selectedReferralData.doNotApproach ===
            strings.fieldValues.yes && (
            <Form.Group>
              <ValidatedFormInput
                formInvalid={this.props.formInvalid}
                inputData={this.props.selectedReferralData.whoNotApproach}
                inputLabel={strings.form.label.whoNotApproach}
                inputName="whoNotApproach"
                inputPlaceholder={strings.form.placeholder.whoNotApproach}
                formFieldWidth={8}
                handleChange={this.props.handleChange}
                validationResult={this.props.validationResults.whoNotApproach}
                mode={this.props.mode}
              />
            </Form.Group>
          )}
          <Form.Group>
            <FileManager
              headers={this.props.headers}
              allowEdit={true}
              filePath="referrals/"
              filesLabel={strings.form.fileUpload}
              disabled={this.props.mode === "view"}
              entityId={this.props.selectedReferralData.referralId}
              entity="referral"
              shouldSaveFiles={this.props.shouldSaveFiles}
              filesHaveChanged={this.props.filesHaveChanged}
            />
          </Form.Group>
        </div>
      </div>
    );
  };
}

DataProtectionFields.propTypes = {
  formInvalid: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  mode: PropTypes.string.isRequired,
  selectedReferralData: PropTypes.object.isRequired,
  validationResults: PropTypes.object
};

export default DataProtectionFields;
