import { API } from "../../services";
import { baselineActionTypes } from "./baseline.actionTypes";

const api = new API();

const createBaseline = (details, headers) => {
  const request = details => {
    return { type: baselineActionTypes.CREATE_BASELINE_REQUEST, details };
  };
  const success = response => {
    return { type: baselineActionTypes.CREATE_BASELINE_SUCCESS, response };
  };
  const failure = error => {
    return { type: baselineActionTypes.CREATE_BASELINE_FAILURE, error };
  };

  api.setHeaders({ ...headers, systemHeader_subsysref: "createBaseline" });

  return dispatch => {
    dispatch(request(details));
    const body = {
      ...details
    };
    return new Promise((resolve, reject) => {
      api.createBaseline(body).then(
        response => {
          dispatch(success(response));
          resolve(response);
        },
        error => {
          dispatch(failure(error));
          reject(error);
        }
      );
    });
  };
};

const clearBaselineRequest = () => {
  const request = () => {
    return { type: baselineActionTypes.CLEAR_CREATE_BASELINE_REQUEST };
  };

  const success = () => {
    return { type: baselineActionTypes.CLEAR_CREATE_BASELINE_SUCCESS };
  };

  return dispatch => {
    dispatch(request());
    dispatch(success());
  };
};

const updateBaseline = (
  id,
  details,
  headers,
  initialDetails,
  showNotification = true
) => {
  const request = details => {
    return { type: baselineActionTypes.UPDATE_BASELINE_REQUEST, details };
  };
  const success = response => {
    return {
      type: baselineActionTypes.UPDATE_BASELINE_SUCCESS,
      response,
      showNotification
    };
  };
  const failure = error => {
    return {
      type: baselineActionTypes.UPDATE_BASELINE_FAILURE,
      error,
      initialDetails
    };
  };

  api.setHeaders({ ...headers, systemHeader_subsysref: "updateBaseline" });

  return dispatch => {
    dispatch(request(details));
    const body = {
      ...details
    };

    api.updateBaseline(id, body).then(
      response => {
        dispatch(success(response));
      },
      error => dispatch(failure(error, initialDetails))
    );
  };
};

const deleteBaseline = (id, headers) => {
  const request = () => {
    return { type: baselineActionTypes.DELETE_BASELINE_REQUEST };
  };
  const success = response => {
    return { type: baselineActionTypes.DELETE_BASELINE_SUCCESS, response };
  };
  const failure = error => {
    return { type: baselineActionTypes.DELETE_BASELINE_FAILURE, error };
  };

  api.setHeaders({ ...headers, systemHeader_subsysref: "deleteBaseline" });

  return dispatch => {
    dispatch(request());
    return new Promise((resolve, reject) => {
      if (id !== "") {
        api.deleteBaseline(id).then(
          data => {
            dispatch(success(data));
            resolve(data);
          },
          error => {
            dispatch(failure(error));
            reject(error);
          }
        );
      }
    });
  };
};

const getBaselinesByProgramme = (
  headers,
  queryString,
  size,
  from,
  clear,
  fields = ["*"],
  sort = {},
  activeOnly,
  programmeId,
  type
) => {
  const request = () => {
    return { type: baselineActionTypes.GET_BASELINES_BY_PROGRAMME_REQUEST };
  };
  const success = response => {
    return {
      type: baselineActionTypes.GET_BASELINES_BY_PROGRAMME_SUCCESS,
      response
    };
  };
  const failure = error => {
    return {
      type: baselineActionTypes.GET_BASELINES_BY_PROGRAMME_FAILURE,
      error
    };
  };

  api.setHeaders({
    ...headers,
    systemHeader_subsysref: "getBaselinesByProgramme"
  });

  return dispatch => {
    dispatch(request());
    return new Promise((resolve, reject) => {
      if (clear) {
        // If supposed to clear, replicate blank result
        dispatch(success({ hits: { hits: [] } }));
        resolve({ hits: { hits: [] } });
      } else {
        api
          .getBaselinesByProgramme(
            queryString,
            size,
            from,
            fields,
            sort,
            activeOnly,
            programmeId,
            type
          )
          .then(
            response => {
              dispatch(success(response));
              resolve(response);
            },
            error => {
              dispatch(failure(error));
              reject(error);
            }
          );
      }
    });
  };
};

const updateSearchCriteria = (
  headers,
  queryString,
  size,
  from,
  clear,
  fields,
  sort,
  activeOnly,
  referralId,
  programmeId,
  type
) => {
  const request = () => {
    return { type: baselineActionTypes.UPDATE_SEARCH_CRITERIA_REQUEST };
  };
  const success = response => {
    return {
      type: baselineActionTypes.UPDATE_SEARCH_CRITERIA_SUCCESS,
      response
    };
  };
  const newCriteria = {
    query: queryString,
    size: size,
    from: from,
    clear,
    fields: fields,
    sort: sort,
    activeOnly: activeOnly,
    referralId: referralId,
    programmeId: programmeId
  };
  return dispatch => {
    dispatch(request());
    dispatch(success(newCriteria));
    dispatch(
      getBaselinesByProgramme(
        headers,
        queryString,
        size,
        from,
        clear,
        fields,
        sort,
        activeOnly,
        programmeId,
        type
      )
    );
  };
};

const getBaselineById = (id, headers) => {
  const request = () => {
    return { type: baselineActionTypes.GET_BASELINE_BY_ID_REQUEST };
  };
  const success = response => {
    return {
      type: baselineActionTypes.GET_BASELINE_BY_ID_SUCCESS,
      response
    };
  };
  const failure = error => {
    return { type: baselineActionTypes.GET_BASELINE_BY_ID_FAILURE, error };
  };

  api.setHeaders({ ...headers, systemHeader_subsysref: "getBaselineById" });

  return dispatch => {
    dispatch(request());
    return new Promise((resolve, reject) => {
      api.getBaselineById(id).then(
        response => {
          dispatch(success(response.data));
          resolve(response.data);
        },
        error => {
          dispatch(failure(error));
          reject(error);
        }
      );
    });
  };

};

const updateSelectedBaseline = (key, value) => {
  const request = (key, value) => {
    return {
      type: baselineActionTypes.UPDATE_SELECTED_BASELINE_REQUEST,
      key,
      value
    };
  };
  const success = (key, value) => {
    return {
      type: baselineActionTypes.UPDATE_SELECTED_BASELINE_SUCCESS,
      key,
      value
    };
  };
  const failure = () => {
    return { type: baselineActionTypes.UPDATE_SELECTED_BASELINE_FAILURE };
  };

  return dispatch => {
    dispatch(request(key, value));
    if (key) {
      dispatch(success(key, value));
    } else {
      dispatch(failure());
    }
  };
};

const clearSelectedBaseline = () => {
  const request = () => {
    return { type: baselineActionTypes.CLEAR_SELECTED_BASELINE_REQUEST };
  };
  const success = () => {
    return { type: baselineActionTypes.CLEAR_SELECTED_BASELINE_SUCCESS };
  };

  return dispatch => {
    dispatch(request());
    dispatch(success());
  };
};

const cancelSelectedBaselineUpdate = initialBaselineData => {
  const request = () => {
    return {
      type: baselineActionTypes.CANCEL_SELECTED_BASELINE_UPDATE_REQUEST
    };
  };
  const success = data => {
    return {
      type: baselineActionTypes.CANCEL_SELECTED_BASELINE_UPDATE_SUCCESS,
      data
    };
  };

  return dispatch => {
    dispatch(request());
    dispatch(success(initialBaselineData));
  };
};

export const baselineActions = {
  createBaseline,
  updateBaseline,
  deleteBaseline,
  getBaselineById,
  updateSearchCriteria,
  updateSelectedBaseline,
  clearSelectedBaseline,
  clearBaselineRequest,
  cancelSelectedBaselineUpdate,
  getBaselinesByProgramme
};
