import React from "react";
import { Button, Icon, Table, Form, Checkbox } from "semantic-ui-react";
import { BasicModal } from "..";
import PropTypes from "prop-types";
import { dropdownHelper } from "../../helpers";

import { ValidatedFormDropdown } from ".";

const ValidatedMultipleCategories = props => {
  const activityOutcomeCheckboxes = (
    categoryId,
    selectedOutcomes,
    currentOutcomes,
    id,
    mode
  ) => (
    <>
      {props.outcomesList[id] &&
        props.outcomesList[id].map((outcome, index) => {
          if (!selectedOutcomes) {
            return (
              <Checkbox
                name={categoryId}
                label={outcome}
                onChange={props.handleCategoryChange}
                key={index}
                checked={currentOutcomes.includes(outcome)}
                disabled={mode === "view"}
              />
            );
          }
          return (
            Array.isArray(selectedOutcomes) &&
            !selectedOutcomes.includes(outcome) && (
              <Checkbox
                name={categoryId}
                label={outcome}
                onChange={props.handleCategoryChange}
                key={index}
                checked={currentOutcomes.includes(outcome)}
                disabled={mode === "view"}
              />
            )
          );
        })}
    </>
  );
  return (
    <>
      <Table basic="very">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={5}>
              {props.strings.form.label.category}
            </Table.HeaderCell>
            <Table.HeaderCell width={5}>
              {props.strings.form.label.outcomesAchieved}
            </Table.HeaderCell>
            <Table.HeaderCell width={5}>
              {props.strings.form.label.outcomesWorked}
            </Table.HeaderCell>
            <Table.HeaderCell width={2} />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {props.selectedActivityData.categories
            ? props.selectedActivityData.categories.map((val, idx) => {
                let outcomesAchieved = `outcomesAchieved-${idx}`;
                let outcomesWorked = `outcomesWorkedTowards-${idx}`;
                let category = `category-${idx}`;
                let currentOutcomes = props.selectedActivityData.categories[idx]
                  ? props.selectedActivityData.categories[idx].outcomesAchieved
                  : undefined;
                let longTermOutcomes = props.selectedActivityData.categories[
                  idx
                ]
                  ? props.selectedActivityData.categories[idx]
                      .outcomesWorkedTowards
                  : undefined;
                return (
                  <Table.Row key={idx}>
                    <Table.Cell>
                      {props.mode !== "view" ? (
                        <ValidatedFormDropdown
                          required={false}
                          formInvalid={props.formInvalid}
                          inputData={
                            props.selectedActivityData.categories[idx].category
                          }
                          dropdownLabel=""
                          dropdownName={category}
                          dropdownOptions={props.dropdownOptions}
                          dropdownPlaceholder={
                            props.strings.form.placeholder.category
                          }
                          formFieldWidth={16}
                          dropdownFluid
                          handleChange={props.handleCategoryChange}
                          validationResult={
                            props.validationResults.categories &&
                            props.validationResults.categories[idx]
                              ? props.validationResults.categories[idx].length >
                                0
                                ? props.validationResults.categories[idx]
                                : undefined
                              : undefined
                          }
                        />
                      ) : (
                        <Form.Input
                          label={props.strings.form.label.category}
                          name={category}
                          placeholder={props.strings.form.placeholder.category}
                          required
                          value={dropdownHelper.getNameFromValues(
                            props.selectedActivityData.categories[idx].category,
                            props.dropdownOptions
                          )}
                          width={8}
                        />
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      {props.selectedActivityData.categories[idx].category &&
                        activityOutcomeCheckboxes(
                          outcomesAchieved,
                          undefined,
                          currentOutcomes,
                          idx,
                          props.mode
                        )}
                    </Table.Cell>
                    <Table.Cell>
                      {props.selectedActivityData.categories[idx].category &&
                        activityOutcomeCheckboxes(
                          outcomesWorked,
                          props.selectedActivityData.categories[idx]
                            ? props.selectedActivityData.categories[idx]
                                .outcomesAchieved
                            : undefined,
                          longTermOutcomes,
                          idx,
                          props.mode
                        )}
                    </Table.Cell>
                    {props.mode !== "view" &&
                      props.selectedActivityData.categories.length > 1 &&
                      idx > 0 && (
                        <Table.Cell textAlign="center">
                          <Icon
                            className="contactDeleteIcon"
                            name="trash outline alternate"
                            onClick={() =>
                              props.selectedActivityData.categories.length >
                                1 && props.toggleDeleteModal(idx)
                            }
                          />
                        </Table.Cell>
                      )}
                  </Table.Row>
                );
              })
            : undefined}
        </Table.Body>
        <Table.Footer fullWidth>
          <Table.Row>
            <Table.HeaderCell />
            <Table.HeaderCell colSpan="4">
              <Button
                className="addCategoryButton"
                primary
                floated="right"
                size="small"
                onClick={props.addCategory}
              >
                {props.strings.form.button.addCategory}
              </Button>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
      <BasicModal
        confirmMessage={props.strings.modal.deleteCategoryMessage}
        handleModalConfirm={props.handleModalConfirm}
        modalClassName="deletionCategoryModal"
        modalOpen={props.deleteModalOpen}
        showNoButton={true}
        showOkButton={false}
        showYesButton={true}
        toggleModal={props.toggleDeleteModal}
        noLabel={props.strings.button.noLabel}
        yesLabel={props.strings.button.yesLabel}
        okLabel={props.strings.button.okLabel}
      />
    </>
  );
};

ValidatedMultipleCategories.propTypes = {
  strings: PropTypes.object.isRequired,
  mode: PropTypes.string.isRequired,
  selectedActivityData: PropTypes.object.isRequired,
  validationResults: PropTypes.object,
  formInvalid: PropTypes.bool.isRequired,
  handleCategoryChange: PropTypes.func.isRequired,
  toggleDeleteModal: PropTypes.func.isRequired,
  handleModalConfirm: PropTypes.func.isRequired,
  deleteModalOpen: PropTypes.bool.isRequired,
  dropdownOptions: PropTypes.array.isRequired
};

export default ValidatedMultipleCategories;
