import LocalizedStrings from "react-localization";

export const strings = new LocalizedStrings({
  en: {
    header: {
      login: "Login",
      baselineList: "Programmes",
      createBaseline: "New Baseline Assessment",
      editBaseline: "Edit Baseline Assessment",
      viewBaseline: "Baseline Assessment",
      loading: "Loading Information",
      embeddedBaselineListStart: "Baselines for '",
      embeddedBaselineListMiddle: "' of type '",
      embeddedBaselineListEnd: "'"
    },
    form: {
      errorText: {
        notShared:
          "Your organisation does not currently have access to this record."
      },
      header: {
        baselineInfo: "Customer Details",
        baselineAssessment: "Baseline Assessments"
      },
      label: {
        customerName: "Customer Name",
        sNumber: "S Number",
        dateCompleted: "",
        blank: "",
        crmId: "CRM ID",
        owner: "Owner"
      },
      placeholder: {
        stateReason: "State your reason...",
        customerName: "",
        sNumber: "",
        dateCompleted: "",
        blank: "",
        crmId: "",
        owner: "- select -"
      },
      feedback: {
        processing: "Processing request...",
        baselineRequestSuccess: "Baseline created successfully!",
        baselineRequestRefreshPrompt:
          "Use the refresh function to see an updated baselines list.",
        baselineRequestFailure: "Baseline creation failed.",
        baselineRequestUnknown:
          "Something went wrong with the baseline request.",
        status: "Status",
        success: "Success",
        failure: "Failure"
      },
      text: {
        yes: "Yes",
        no: "No"
      }
    },
    modal: {
      createBaselineMessage: "Save baseline?",
      modifyBaselineMessage: "Are you sure you want to save these changes?",
      deleteBaselineMessage:
        "Are you sure you want to delete this baseline? (This action cannot be undone)",
      saveChangesMessage: "Are you sure you want to save these changes?",
      cancelChangesMessage: "Cancel changes?",
      setActiveMessage: "Set this baseline's status to active?",
      setInactiveMessage: "Set this baseline's status to inactive?",
      requestDeletePermissionMessage: "Please submit a reason for deletion:"
    },
    button: {
      loginLabel: "Login",
      editLabel: "Edit",
      deleteLabel: "Delete",
      cancelLabel: "Cancel",
      deactivateLabel: "Deactivate",
      activateLabel: "Activate",
      saveLabel: "Save",
      yesLabel: "Yes",
      noLabel: "No",
      okLabel: "Ok",
      nextLabel: "Next",
      addBaseline: "Add Baseline",
      requestDeleteLabel: "Request Delete Permission",
      submitLabel: "Submit"
    },
    tooltip: {},
    table: {
      header: {
        date: "Date of Baseline",
        type: "Type",
        location: "Location",
        method: "Method",
        isSuccessfulValue: "Successful",
        numActivities: "No. Activities",
        crmId: "CRM ID"
      },
      text: {
        yes: "Yes",
        no: "No",
        noResults: "No results found."
      }
    },
    fieldValues: {
      additionalLowercase: "additional",
      additionalUppercase: "Additional",
      dateCompleted: "dateCompleted",
      stage1: "stage1"
    },
    states: {
      approved: "Approved",
      rejected: "Rejected"
    }
  }
});
