import React from "react";
import PropTypes from "prop-types";
import { Button } from "semantic-ui-react";
import { strings } from "../../resources";
import "../css/ProgrammeComponents.scss";

const ChooseForm = props => (
  <div className="infoBlock">
    <div className="infoHeader">{strings.form.header.formType}</div>

    <div className="infoWrapper">
      <label className="viewProgrammeLabel">
        {strings.form.label.viewProgrammeLabel}
      </label>
      <Button
        className="formButton"
        primary
        inverted
        onClick={props.chooseForm}
      >
        {strings.button.chooseForm}
      </Button>
    </div>
  </div>
);

ChooseForm.propTypes = {
  chooseForm: PropTypes.func.isRequired
};
export default ChooseForm;
