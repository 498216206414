import { API } from "../../services";
import { customerActionTypes } from "./customer.actionTypes";

const api = new API();

const createCustomer = (details, headers) => {
  const request = details => {
    return { type: customerActionTypes.CREATE_CUSTOMER_REQUEST, details };
  };
  const success = response => {
    return { type: customerActionTypes.CREATE_CUSTOMER_SUCCESS, response };
  };
  const failure = error => {
    return { type: customerActionTypes.CREATE_CUSTOMER_FAILURE, error };
  };
  api.setHeaders({ ...headers, systemHeader_subsysref: "createCustomer" });

  return dispatch => {
    dispatch(request(details));

    api.createCustomer(details).then(
      response => dispatch(success(response)),
      error => dispatch(failure(error))
    );
  };
};

const clearCustomerRequest = () => {
  const request = () => {
    return { type: customerActionTypes.CLEAR_CREATE_CUSTOMER_REQUEST };
  };

  const success = () => {
    return { type: customerActionTypes.CLEAR_CREATE_CUSTOMER_SUCCESS };
  };

  return dispatch => {
    dispatch(request());
    dispatch(success());
  };
};

const cancelSelectedCustomerUpdate = initialCustomerData => {
  const request = () => {
    return {
      type: customerActionTypes.CANCEL_SELECTED_CUSTOMER_UPDATE_REQUEST
    };
  };
  const success = data => {
    return {
      type: customerActionTypes.CANCEL_SELECTED_CUSTOMER_UPDATE_SUCCESS,
      data
    };
  };

  return dispatch => {
    dispatch(request());
    dispatch(success(initialCustomerData));
  };
};

const updateCustomer = (
  id,
  details,
  headers,
  initialDetails,
  showNotification = true
) => {
  const request = details => {
    return { type: customerActionTypes.UPDATE_CUSTOMER_REQUEST, details };
  };
  const success = response => {
    return {
      type: customerActionTypes.UPDATE_CUSTOMER_SUCCESS,
      response,
      showNotification
    };
  };
  const failure = error => {
    return {
      type: customerActionTypes.UPDATE_CUSTOMER_FAILURE,
      error,
      initialDetails
    };
  };
  api.setHeaders({ ...headers, systemHeader_subsysref: "updateCustomer" });

  return dispatch => {
    dispatch(request(details));
    api.updateCustomer(id, details).then(
      response => {
        dispatch(success(response));
      },
      error => dispatch(failure(error, initialDetails))
    );
  };
};

const deleteCustomer = (id, headers) => {
  const request = () => {
    return { type: customerActionTypes.DELETE_CUSTOMER_REQUEST };
  };
  const success = response => {
    return { type: customerActionTypes.DELETE_CUSTOMER_SUCCESS, response };
  };
  const failure = error => {
    return { type: customerActionTypes.DELETE_CUSTOMER_FAILURE, error };
  };

  api.setHeaders({ ...headers, systemHeader_subsysref: "deleteCustomer" });

  return dispatch => {
    dispatch(request());
    return new Promise((resolve, reject) => {
      if (id !== "") {
        api.deleteCustomer(id).then(
          data => {
            dispatch(success(data));
            resolve(data);
          },
          error => {
            dispatch(failure(error));
            reject(error);
          }
        );
      }
    });
  };
};

const getCustomers = (
  headers,
  queryString,
  size,
  from,
  clear,
  fields = ["*"],
  sort = {},
  activeOnly,
  globalContactOnly
) => {
  const request = () => {
    return { type: customerActionTypes.GET_CUSTOMERS_REQUEST };
  };
  const success = response => {
    return { type: customerActionTypes.GET_CUSTOMERS_SUCCESS, response };
  };
  const failure = error => {
    return { type: customerActionTypes.GET_CUSTOMERS_FAILURE, error };
  };

  api.setHeaders({ ...headers, systemHeader_subsysref: "getCustomers" });

  return dispatch => {
    dispatch(request());
    return new Promise((resolve, reject) => {
      if (clear) {
        // If supposed to clear, replicate blank result
        dispatch(success({ hits: { hits: [] } }));
      } else {
        api
          .getCustomers(
            queryString,
            size,
            from,
            fields,
            sort,
            activeOnly,
            globalContactOnly
          )
          .then(
            response => {
              dispatch(success(response));
              resolve(response.data);
            },
            error => {
              dispatch(failure(error));
              reject(error);
            }
          );
      }
    });
  };
};

const updateSearchCriteria = (
  headers,
  queryString,
  size,
  from,
  clear,
  fields,
  sort,
  activeOnly,
  globalContactOnly
) => {
  const request = () => {
    return { type: customerActionTypes.UPDATE_SEARCH_CRITERIA_REQUEST };
  };
  const success = response => {
    return {
      type: customerActionTypes.UPDATE_SEARCH_CRITERIA_SUCCESS,
      response
    };
  };

  const newCriteria = {
    query: queryString,
    size: size,
    from: from,
    clear,
    fields: fields,
    sort: sort,
    activeOnly: activeOnly,
    globalContactOnly: globalContactOnly
  };
  return dispatch => {
    dispatch(request());
    dispatch(success(newCriteria));
    dispatch(
      getCustomers(
        headers,
        queryString,
        size,
        from,
        clear,
        fields,
        sort,
        activeOnly,
        globalContactOnly
      )
    );
  };
};

const getCustomerById = (id, headers) => {
  const request = () => {
    return { type: customerActionTypes.GET_CUSTOMER_BY_ID_REQUEST };
  };
  const success = response => {
    return { type: customerActionTypes.GET_CUSTOMER_BY_ID_SUCCESS, response };
  };
  const failure = error => {
    return { type: customerActionTypes.GET_CUSTOMER_BY_ID_FAILURE, error };
  };

  api.setHeaders({ ...headers, systemHeader_subsysref: "getCustomerById" });

  return dispatch => {
    dispatch(request());
    return new Promise((resolve, reject) => {
      api.getCustomerById(id).then(
        response => {
          dispatch(success(response.data));
          resolve(response.data);
        },
        error => {
          dispatch(failure(error));
          reject(error);
        }
      );
    });
  };
};

const updateSelectedCustomer = (key, value) => {
  const request = (key, value) => {
    return {
      type: customerActionTypes.UPDATE_SELECTED_CUSTOMER_REQUEST,
      key,
      value
    };
  };
  const success = (key, value) => {
    return {
      type: customerActionTypes.UPDATE_SELECTED_CUSTOMER_SUCCESS,
      key,
      value
    };
  };
  const failure = () => {
    return { type: customerActionTypes.UPDATE_SELECTED_CUSTOMER_FAILURE };
  };

  return dispatch => {
    dispatch(request(key, value));
    if (key) {
      dispatch(success(key, value));
    } else {
      dispatch(failure());
    }
  };
};

const getCustomerByPNumber = (headers, pNumber) => {
  const request = () => {
    return { type: customerActionTypes.GET_CUSTOMERS_BY_PNUMBER_REQUEST };
  };
  const success = response => {
    return {
      type: customerActionTypes.GET_CUSTOMERS_BY_PNUMBER_SUCCESS,
      response
    };
  };
  const failure = error => {
    return {
      type: customerActionTypes.GET_CUSTOMERS_BY_PNUMBER_FAILURE,
      error
    };
  };

  api.setHeaders({
    ...headers,
    systemHeader_subsysref: "getCustomersByPNumber"
  });
  return dispatch => {
    dispatch(request());
    return new Promise((resolve, reject) => {
      api.getCustomerByPNumber(pNumber).then(
        response => {
          dispatch(success(response));
          resolve(response.data);
        },
        error => {
          dispatch(failure(error));
          reject(error);
        }
      );
    });
  };
};

const clearSelectedCustomer = () => {
  const request = () => {
    return { type: customerActionTypes.CLEAR_SELECTED_CUSTOMER_REQUEST };
  };
  const success = () => {
    return { type: customerActionTypes.CLEAR_SELECTED_CUSTOMER_SUCCESS };
  };

  return dispatch => {
    dispatch(request());
    dispatch(success());
  };
};

export const customerActions = {
  createCustomer,
  updateCustomer,
  deleteCustomer,
  getCustomerById,
  getCustomers,
  updateSearchCriteria,
  updateSelectedCustomer,
  clearSelectedCustomer,
  clearCustomerRequest,
  cancelSelectedCustomerUpdate,
  getCustomerByPNumber
};
