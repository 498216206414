
export const referralToOptions = [
  { key: 1, text: "NRS", value: "NRS" },
  { key: 2, text: "NRNE", value: "NRNE" },
  { key: 3, text: "TtG", value: "TtG" }
];

export const referralFromOptions = [
  { key: 1, text: "NRS", value: "NRS" },
  { key: 2, text: "NRNE", value: "NRNE" },
  { key: 3, text: "TtG", value: "TtG" }
];

export const referralInformationNeedOptions = [
  { key: 1, text: "Training", value: "Training" },
  { key: 2, text: "Employment", value: "Employment" },
  { key: 3, text: "Energy Advice", value: "Energy Advice" }
];

export const referralInformationContactOptions = [
  { key: 1, text: "Phone", value: "Phone" },
  { key: 2, text: "Text", value: "Text" },
  { key: 3, text: "Email", value: "Email" },
  { key: 4, text: "Post", value: "Post" }

];

export const outcomeOfReferral = [
  { key: 1, text: "Unable to contact customer", value: "Unable to contact customer" },
  { key: 2, text: "Customer not suitable", value: "Customer not suitable" },
  { key: 3, text: "Customer start on programme", value: "Customer start on programme" }
];

export const yesNoOptions = [
  { key: 1, text: "Yes", value: "Yes" },
  { key: 2, text: "No", value: "No" }
];