import axios from "./axios-files";
import fetch from 'isomorphic-fetch'

export class API {
  constructor() {
    this.headers = {};

    this.setHeaders.bind(this);
  }

  setHeaders = headers => {
    this.headers = headers;
  };

  postRequest = body => {
    const config = {
      headers: this.headers
    };

    return new Promise((resolve, reject) => {
      const urlRequest = {
        filePath: body.filePath,
        metadata: {
          description: body.metadata.description
        },
        entityId: body.entityId,
        entity: body.entity
      }
      axios
        .post("", JSON.stringify(urlRequest), config)
        .then(signedUrl => {
          const signedPUTHeaders = {
            'x-amz-tagging': `description=${body.metadata.description}`,
            'content-disposition': 'attachment',
            'content-encoding': 'base64',
          }
          fetch(signedUrl.data.content, {
            method: 'PUT',
            headers: signedPUTHeaders,
            body: body.fileContent
          })
            .then(result => {
              resolve(result)
            })
        })
        .catch(error => {
          console.log('Error creating file: ', error.message)
          reject(error.message)
        })
    });
  };

  putRequest = body => {
    const config = {
      headers: this.headers
    };
    return new Promise((resolve, reject) => {
      axios
        .put(`/`, JSON.stringify(body), config)
        .then(data => {
          resolve(data)
        })
        .catch(error => {
          console.log('Error updating file: ', error.message)
          reject(error);
        })
    });
  };

  deleteRequest = body => {
    const config = {
      headers: this.headers
    };
    return new Promise((resolve, reject) => {
      let params = { params: body }
      console.log(params);
      axios
        .delete(`/`, params, config)
        .then(data => {
          resolve(data)
        })
        .catch(error => {
          console.log('Error deleting file: ', error.message)
          reject(error);
        })
    });
  };

  getRequest = path => {
    const config = {
      headers: this.headers
    };
    return new Promise((resolve, reject) => {
      axios
        .get(`/?filePath=${path}`, config)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.log('Error getting files: ', error.message);
          reject(error);
        }
        )
    });
  };
}
