import LocalizedStrings from "react-localization";

export const enums = new LocalizedStrings({
  en: {
    formTypes: {
      SPS_EligList: "Identification Stage",
      REF_ToProg: "Programme Referral",
      SGA_Tool: "SG Assessment",
      THR_RefForm: "Threshold Stage",
      REF_Form: "Referral",
      RSK_Assess: "Risk Assessment",
      EQA_OppForm: "Equal Opportunities",
      SUP_ServiceRef: "Support Service",
      PER_ReleasePlan: "Personal Release Plan",
      PER_Review: "Personal Release Plan Review",
      BAS_MonTool: "Baseline Monitoring Tool"
    },
    forms: {
      "Identification Stage": {
        dateOfReferral_initial: "Date Identified",
        days_initial: "Sentence Days",
        firstName_initial: "Forename",
        hallName_initial: "Hall Name",
        hdcQualificationDate_initial: "HDC Qualification Date",
        ineligibleReason_initial: "Reason for being Ineligible",
        level_initial: "Level",
        months_initial: "Sentence Months",
        otherReason_initial: "Other Reason for being Ineligible",
        outcomeOfIdentification_initial: "Outcome of Identification",
        overallEDL_initial: "Overall EDL",
        pNumber_initial: "Prisoner Number",
        prisonNrs_initial: "Prison",
        referralSourceNrs_initial: "Referral source",
        scroNumber_initial: "S Number",
        surname_initial: "Surname",
        years_initial: "Sentence Years",
        consent_initial: "Agree to data protection"
      },
      "Threshold Stage": {
        childRisk_initial: "risk (community – children)",
        dateOfReferral_initial: "Date Identified",
        hdcQualificationDate_initial: "HDC Qualification Date",
        initials_initial: "Initial",
        knownAdultRisk_initial: "risk (community – known adults)",
        mentor_initial: "CJ Specialist",
        needsAnalysis_initial: "Needs Analysis",
        outcomeOfThreshold_initial: "Outcome of threshold meeting",
        outcomeReason_initial: "Outcome Reason",
        prisonNrneOther_initial: "Other Prison",
        prisonNrne_initial: "Prison",
        publicRisk_initial: "risk (community – public)",
        referralSourceNrne_initial: "Referral source",
        releaseDate_initial: "release date",
        staffRisk_initial: "risk (community – staff)"
      },
      "Programme Referral": {},
      Referral: {
        additionalAddress1: "Additional Address (1)",
        additionalAddress2: "Additional Address (2)",
        additionalNeedsInfo: "Additional Information ",
        additionalPostcode: "Additional Address Post Code",
        additionalTown: "Additional Address Town/City",
        address1: "Pre-Conviction Address (1)",
        address2: "Pre-Conviction Address (2)",
        ageCategory: "Age category ",
        armedForces: "Armed Forces Service",
        communityNeeds:
          "What are the customers needs in community at this stage?",
        communityRisk: "Risk (in community)",
        consent: "Agree to Data Protection",
        contactChildren:
          "Are there any legal conditions that prevent you from contacting your children?",
        custodialSentences:
          "Total number of custodial sentences (from prison system)",
        customerLocalAuthorityArea: "Customer Local Authority area",
        dateOfBirth: "Date of Birth",
        doNotApproach: "Anyone the customer does not wish to be approached?",
        email: "E-mail address",
        emergencyContactName: "Emergency contact Name",
        emergencyContactNum: "Emergency Contact Number",
        goodNewsStories: " Agree to New Routes good news stories?",
        hasChildren: "Do you have any children?",
        libAddress1: "Liberation Address (1)",
        libAddress2: "Liberation Address (2)",
        libPostcode: "Liberation Postcode",
        libTown: "Liberation Address Town/City",
        liberationLocalArea: "Liberation Local Authority Area",
        linkWorker: "Link Worker",
        mailingAddress1: "Mailing Address (1)",
        mailingAddress2: "Mailing Address (2)",
        mailingPostcode: "Mailing Address Post Code",
        mailingTown: "Mailing Address Town/City",
        needsAnalysis: "Needs Analysis",
        newRoutesMarketing: "Agree to New Routes Marketing?",
        nin: "NI Number",
        nonCustodialSentences:
          "Total number of non-custodial sentences (from customer)",
        offendingHistory: "Prolific offending history (approx. 10 convictions)",
        outcomeOfIdentification: "Outcome of Identification",
        outcomeOfReferralNrs: "Outcome of Referral",
        outcomeOfThreshold: "Outcome of threshold meeting",
        phone1: "Telephone Number",
        phone2: "Mobile Number",
        postcode: "Pre-Conviction Postcode",
        prisonHistory: "Prison History & Activity",
        prisonNeeds: "What are the customers needs in prison at this stage?",
        referralMeetingDate: "Referral Meeting date",
        relationship: "Relationship",
        riskFactors: "Please State Risk Factors",
        shortSentence: "Short term sentence of less than 4 years",
        statutorySupport: "Receiving statutory support",
        threeWayMeeting: "Three way referral meeting required?",
        townOrCity: "Pre-Conviction Town/City",
        whoNotApproach: "Who not to be approached?",
        wingCellNumber: "Wing/Cell Number"
      },
      "Risk Assessment": {},
      "Equal Opportunities": {
        hideProtected: "Hide Protected Characteristics",
        birthGender: "Gender at birth",
        disability: "Disability",
        maritalStatus: "Marital Status",
        maritalStatusOther: "Marital Other (please state)",
        currentGender: "Gender Currently",
        currentGenderOther: "Gender Other (please state)",
        religion: "Religion",
        religionOther: "Religion Other (please state)",
        sexualOrientation: "Sexual Orientation",
        ethnicity: "Ethnicity",
        ethnicityOther: "Ethnic Other (please state)",
        isPregnant: "Are you Pregnant?",
        isMaternityPeriod:
          "Are you within the maternity period? (26 weeks after birth)"
      },
      "Support Service": {
        supportService: "Support Service"
      },
      "Personal Release Plan": {
        employmentStatus: "Employment Status (prior to conviction)",
        hasAddiction: "Do you believe you have an addiction?",
        hasAddictionAffectedOffending:
          "Are your addiction issues related to your offending",
        hasCaringResponsibilities: "Do you have any Caring responsibilities",
        hasChildrenAccess: "Do you have access to your children",
        hasChildrenDependants: "Dependants: Children",
        hasDentistRegistered: "Are you registered with a Dentist?",
        hasDependants: "Do you have Dependants",
        hasFamilySupport:
          "Have your family/ significant other supported you whilst you were in prison",
        hasGPRegistered: "Are you registered with a GP?",
        hasHealthIssues: "Do you have any health issues?",
        hasItaAccountAvailable: "ITA Account available",
        hasMentalHealthIssues: "Do you have any mental health issues?",
        hasOtherDependants: "Dependant: Other",
        hasRelativeDependants: "Dependants: Relative",
        hasTenancy: "Do you have your own tenancy",
        hobbiesAndInterests: "Hobbies and interests",
        homelessCaseworkReferral:
          "If homeless, has a referral been made to the Homeless Casework Team?",
        homelessDuration: "How long have you been homeless",
        inReceiptOfSupport: "Are you in receipt of additional support?",
        isCareLeaver: "Are you a care leaver",
        isLoneParent: "Are you a lone parent",
        itaDateApplied: "Date ITA applied for",
        keyWorkerName: "Key worker name (if applicable)",
        lengthOfEmployment: "Length of Employment",
        livingWith: "Living with",
        medicationDetails: "If yes, please provide details",
        medicationQuantity: "How much medication?",
        otherDependantsDetail: "If other, please specify",
        participatingWorkProgramme: "Participating on Work Programme",
        prisonSupportService: "If yes, which services?",
        prisonSupportServiceOther: "If other please state service",
        requiresPrisonSupport:
          "Based on the information provided, do you require to be referred to prison based support services?",
        stayingWhereOnRelease: "Where will you be staying on your release",
        takingMentalHealthMedication:
          "Are you taking any prescribed medication for this condition?",
        takingPrescribedMedication: "Are you taking any prescribed medication?",
        whatMedication: "What medication?"
      },
      "Personal Release Plan Review": {},
      "Baseline Monitoring Tool": {
        1: "Stage 1 (sign up) date",
        2: "Stage 2 - date",
        3: "Stage 3 - date",
        4: "stage 4 - date",
        addictions: "Substance Misuse",
        educationTraining: "Education & training",
        employment: "Employment",
        finance: "Finance",
        generalHealth: "Physical Health",
        mentalHealth: "Mental Health",
        relationships: "Relationships",
        housing: "Accommodation",
        attitudeBehaviour: "Attitudes & behaviours",
        socialLeisure: "Social & Leisure",
        customerName: "Customer Name"
      },
      "SG Assessment": {
        1: "Initial",
        2: "Pre-release",
        3: "Post-release",
        4: "Exit",
        dateCompleted: "Date Completed",
        workingOnProblems: "Ready to work on problems",
        desistFromCrime: "Desist from crime",
        engagedWithService: "Engaging with service",
        solvingEverydayProblems: "Solving everyday problems",
        viewsOnOffending: "Views on offending behaviour",
        hasAccommodation: "Has accommodation",
        hasMoneyIssues: "Has money issues",
        inEducationTraining: "Work/education/training",
        hasAlcoholDrugProblems: "Has alcohol/drug issues",
        hasFamilyRelationships: "Family/Relationships",
        hasLeisureActivities: "Leisure activities",
        gotBetter: "Got Better",
        stayedSame: "Stayed the same",
        gotWorse: "Got Worse",
        customerName: "Customer Name"
      }
    },
    dateTypes: [
      "dateOfReferral_initial",
      "hdcQualificationDate_initial",
      "overallEDL_initial",
      "releaseDate_initial",
      "dateOfBirth",
      "referralMeetingDate",
      "itaDateApplied"
    ],
    dateStrings: {
      dateOfReferral_initial: "Date Identified",
      hdcQualificationDate_initial: "HDC Qualification Date",
      overallEDL_initial: "Overall EDL",
      releaseDate_initial: "release date",
      dateOfBirth: "Date of Birth",
      referralMeetingDate: "Referral Meeting date",
      itaDateApplied: "Date ITA applied for"
    },
    SGA_Tool: "SGA_Tool",
    BAS_MonTool: "BAS_MonTool"
  }
});
