import React from "react";
import TableComponent from "../../components/EngagementTable";
import { Loader, Divider } from "semantic-ui-react";
import { connect } from "react-redux";
import { engagementActions } from "../../store/actions/engagement.actions";
import { referralActions } from "../../../Referrals/store/actions/referral.actions";
import { programmeActions } from "../../../Programmes/store/actions/programme.actions";
import { strings } from "../../resources";
import PropTypes from "prop-types";
import { RequestFeedback } from "../../components";
import { isEmpty, canUserModifyRecord } from "../../helpers";
import { returnToParent } from "../../helpers";

const requestTimeout = 20000;

class EngagementListContainer extends React.Component {
  state = {
    unknownRequestStatus: false,
    pageIndex: 0,
    pages: 1,
    searchCriteria: {
      size: 20,
      from: 0,
      query: "",
      fields: ["*"],
      clear: false,
      sort: {},
      activeOnly: false,
      referralId: undefined,
      programmeId: undefined,
      type: undefined
    },
    globalProgrammeId: localStorage.getItem("programmeId"),
    searchBarQuery: "",
    initialReferralData: undefined,
    initialProgrammeData: undefined,
    referralId: localStorage.getItem("referralId")
  };

  componentDidMount = async () => {
    this.timerID = setTimeout(() => {
      if (
        this.props.engagementRequestStatus === undefined &&
        this.props.engagementRequestMade
      ) {
        this.setState({ unknownRequestStatus: true });
      }
    }, requestTimeout);
  };

  componentDidUpdate = () => {
    if (this.state.referralId && isEmpty(this.props.selectedReferralData)) {
      this.props.getReferralById(this.state.referralId);
    }
    if (
      this.state.globalProgrammeId &&
      isEmpty(this.props.selectedProgrammeData)
    ) {
      this.props.getProgrammeById(this.state.globalProgrammeId);
    }
    if (
      !isEmpty(this.props.selectedReferralData) &&
      isEmpty(this.state.initialReferralData)
    ) {
      if (!this.props.initialReferralData) {
        this.setState({ initialReferralData: this.props.selectedReferralData });
      }
    }

    if (
      !isEmpty(this.props.selectedProgrammeData) &&
      isEmpty(this.state.initialProgrammeData)
    ) {
      if (!this.props.initialProgrammeData) {
        this.setState({
          initialProgrammeData: this.props.selectedProgrammeData
        });
      }
    }
  };

  componentWillUnmount() {
    clearTimeout(this.timerID);
    this.props.clearEngagementRequest();
    this.setState({ initialReferralData: undefined });
  }

  handleRefresh = async () => {
    this.setState({ searchBarQuery: "" });
    await this.props.clearEngagementRequest();
    await this.handleFetchData(this.state);
  };

  handleChange = event => {
    let searchCriteria = { ...this.state.searchCriteria };
    this.setState({ searchBarQuery: event.target.value });
    searchCriteria.query = this.state.searchBarQuery;
    this.setState({ searchCriteria: searchCriteria });
  };

  handleSubmit = async () => {
    await this.handleFetchData(this.state);
  };

  handleFetchData = async state => {
    const sCrit = this.state.searchCriteria;
    let sortObject = {};
    if (state.sorted) {
      state.sorted.forEach(sortCondition => {
        sortCondition.desc
          ? (sortObject[sortCondition.id] = { order: "desc" })
          : (sortObject[sortCondition.id] = { order: "asc" });
      });
    }
    if (this.state.globalProgrammeId) {
      sCrit.fields = [
        "location.normalizer",
        "type.normalizer",
        "method.normalizer"
      ];
      sCrit.query = this.state.searchBarQuery;
    }
    sCrit.referralId = this.props.referralId
      ? this.props.referralId
      : localStorage.getItem("parentReferralId");
    sCrit.programmeId = this.state.globalProgrammeId;
    sCrit.type = this.props.engagementType;

    await this.props.updateSearchCriteria(
      this.props.headers,
      sCrit.query,
      sCrit.size,
      state.page * sCrit.size,
      sCrit.clear,
      sCrit.fields,
      sortObject,
      sCrit.activeOnly,
      sCrit.referralId,
      sCrit.programmeId,
      sCrit.type
    );
    if (this.props.referralId) {
      localStorage.setItem("referralId", this.props.referralId);
      localStorage.setItem("referralName", this.props.referralName);
    }
  };

  updatePageIndex = state => {
    this.setState({
      pageIndex: state.page
    });
  };

  handleModalConfirm = modalClassName => {
    switch (modalClassName) {
      default:
        break;
    }
  };

  isCreateDisabled = (stateData, propsData) => {
    if (!propsData) {
      return !canUserModifyRecord(
        stateData,
        this.props.organisationOfLoggedInUser
      );
    } else {
      return !canUserModifyRecord(
        propsData,
        this.props.organisationOfLoggedInUser
      );
    }
  };

  render = () => {
    const loading =
      this.props.loadingPage ||
      this.props.referralLoadingPage ||
      this.props.programmeLoadingPage;

    let parentPage;
    if (localStorage.getItem("programmeName")) {
      parentPage = localStorage.getItem("programmeName");
    } else if (localStorage.getItem("referralName")) {
      parentPage = localStorage.getItem("referralName");
    }

    return (
      <div>
        <div style={{ display: loading ? "" : "none" }}>
          <Loader active>{strings.header.loading}</Loader>
        </div>
        <div style={{ display: loading ? "none" : "" }}>
          <RequestFeedback
            requestStatus={this.props.engagementRequestStatus}
            requestMade={this.props.engagementRequestMade}
            unknownRequestStatus={this.state.unknownRequestStatus}
            successMessage={this.props.result}
            failureMessage={this.props.error}
            optionalRefreshMessage={
              strings.form.feedback.engagementRequestRefreshPrompt
            }
            processingFeedbackMessage={strings.form.feedback.processing}
            unknownFeedbackMessage={
              strings.form.feedback.engagementRequestUnknown
            }
            statusFeedbackMessage={strings.form.feedback.status}
            successFeedbackMessage={strings.form.feedback.success}
            errorDetails={this.props.errorDetails}
          />
          <Divider hidden />
          <TableComponent
            headers={this.props.headers}
            history={this.props.history}
            engagementListData={this.props.engagementListData}
            handleModalConfirm={this.handleModalConfirm}
            handleRefresh={this.handleRefresh}
            pageSize={this.state.searchCriteria.size}
            pages={this.state.pages}
            handleFetchData={this.handleFetchData}
            totalResults={this.props.totalResults}
            updatePageIndex={this.updatePageIndex}
            roleId={this.props.roleId}
            engagementType={this.props.engagementType}
            referralCrmId={this.props.referralCrmId}
            programmeCrmId={this.props.programmeCrmId}
            area={this.props.area}
            searchBarQuery={this.state.searchBarQuery}
            handleChange={this.handleChange}
            handleSubmit={this.handleSubmit}
            selectedReferralData={this.props.selectedReferralData}
            selectedProgrammeData={this.props.selectedProgrammeData}
            orgOfLoggedInUser={this.props.organisationOfLoggedInUser}
            isCreateDisabled={this.isCreateDisabled}
            hideSearchBar={this.props.hideSearchBar}
            initialReferralPropsData={this.props.initialReferralData}
            initialReferralStateData={this.state.initialReferralData}
            initialProgrammePropsData={this.props.initialProgrammeData}
            initialProgrammeStateData={this.state.initialProgrammeData}
            referralId={this.props.referralId}
            programmeId={this.props.programmeId}
            parentPage={parentPage}
            returnToParent={() => {
              returnToParent(this.props.history);
            }}
          />
        </div>
      </div>
    );
  };
}

EngagementListContainer.propTypes = {
  engagementListData: PropTypes.array.isRequired,
  loadingPage: PropTypes.bool.isRequired,
  getEngagements: PropTypes.func.isRequired,
  updateSearchCriteria: PropTypes.func.isRequired,
  headers: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  errorDetails: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

const mapStateToProps = state => {
  const { engagements, auth, referrals, programmes } = state;
  const { roleId, organisationOfLoggedInUser } = auth;
  const { selectedReferralData, loadingPage: referralLoadingPage } = referrals;
  const {
    selectedProgrammeData,
    loadingPage: programmeLoadingPage
  } = programmes;
  const {
    engagementListData,
    loadingPage,
    engagementRequestStatus,
    engagementRequestMade,
    result,
    error,
    totalResults,
    errorDetails
  } = engagements;
  return {
    engagementListData,
    loadingPage,
    engagementRequestStatus,
    engagementRequestMade,
    roleId,
    result,
    error,
    totalResults,
    errorDetails,
    selectedReferralData,
    organisationOfLoggedInUser,
    selectedProgrammeData,
    referralLoadingPage,
    programmeLoadingPage
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getEngagements: (
      headers,
      queryString,
      size,
      from,
      clear,
      fields,
      sort,
      activeOnly
    ) => {
      dispatch(
        engagementActions.getEngagements(
          headers,
          queryString,
          size,
          from,
          clear,
          fields,
          sort,
          activeOnly
        )
      );
    },
    updateSearchCriteria: (
      headers,
      data,
      size,
      from,
      clear,
      fields,
      sort,
      activeOnly,
      referralId,
      programmeId,
      type
    ) => {
      dispatch(
        engagementActions.updateSearchCriteria(
          headers,
          data,
          size,
          from,
          clear,
          fields,
          sort,
          activeOnly,
          referralId,
          programmeId,
          type
        )
      );
    },
    clearEngagementRequest: () => {
      dispatch(engagementActions.clearEngagementRequest());
    },
    getReferralById: (id, headers) => {
      dispatch(referralActions.getReferralById(id, headers));
    },
    getProgrammeById: (id, headers) => {
      dispatch(programmeActions.getProgrammeById(id, headers));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EngagementListContainer);
