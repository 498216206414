import React from "react";
import PropTypes from "prop-types";
import { Button, Form, Input, Dropdown } from "semantic-ui-react";
import { strings } from "../resources";
import { convertFromIso } from "../helpers/index";
import {
  ValidatedFormInput,
  ValidatedDateInput,
  ValidatedFormTextArea
} from "./validation";
import { ComponentHeader, BasicModal, InputModal } from ".";
import { withRouter } from "react-router-dom";
import { permissions } from "../../App/resources/AppPermissions";
import { dropDownOptions } from "../resources/DropdownOptions";

class ModifyTask extends React.Component {
  returnToList = () => {
    this.props.history.push("/mywork");
  };

  renderModifyButtons = () => {
    const clearReasonInput = (
      <Input
        name="clearReason"
        placeholder={strings.form.placeholder.stateReason}
        fluid
        onChange={this.props.handleChangeModal}
      />
    );

    const rejectionReasonInput = (
      <Input
        name="rejectionReason"
        placeholder={strings.form.placeholder.stateReason}
        fluid
        onChange={this.props.handleChangeModal}
      />
    );

    const approvalDropdown = (
      <Dropdown
        placeholder={strings.form.placeholder.approveReject}
        selection
        options={dropDownOptions}
        onChange={this.props.handleApprovalDropdown}
      />
    );

    return (
      <div className="containerButtons">
        {!this.props.selectedTaskData.cleared && (
          <Button.Group>
            <Button
              className="activationButton"
              primary
              inverted
              onClick={this.props.toggleEnableDisableTaskModalVisibility}
              type="button"
            >
              {strings.form.label.clearTask}
            </Button>
          </Button.Group>
        )}
        <InputModal
          input={clearReasonInput}
          confirmMessage={strings.modal.clearTaskReasonMessage}
          handleModalConfirm={this.props.handleModalConfirm}
          modalClassName={"deleteTaskModal"}
          modalOpen={this.props.enableDisableTaskModalOpen}
          showOkButton={true}
          toggleModal={this.props.toggleEnableDisableTaskModalVisibility}
          okLabel={strings.button.okLabel}
          resetDataOnModalClose={() => this.props.resetDataOnModalClose()}
          inputRequired={this.props.selectedTaskData.requireClearReason}
          inputValue={this.props.clearTaskReason}
        />
        {(this.props.selectedTaskData.type ===
          strings.amendmentTypes.deleteAmendment ||
          this.props.selectedTaskData.type ===
            strings.amendmentTypes.changeAmendment) &&
          !this.props.selectedTaskData.cleared && (
            <>
              <Button.Group>
                <Button
                  className="activationButton"
                  primary
                  inverted
                  onClick={
                    this.props.toggleEnableDisableTaskApprovalModalVisibility
                  }
                  type="button"
                >
                  {strings.button.actionLabel}
                </Button>
              </Button.Group>
              <InputModal
                confirmMessage={strings.modal.actionTaskMessage}
                handleModalConfirm={this.props.handleModalConfirm}
                modalClassName="approveRequestModal"
                modalOpen={this.props.enableDisableTaskApprovalModalOpen}
                showOkButton={true}
                toggleModal={
                  this.props.toggleEnableDisableTaskApprovalModalVisibility
                }
                okLabel={strings.button.confirm}
                resetDataOnModalClose={() => this.props.resetDataOnModalClose()}
                input={approvalDropdown}
                inputRequired={true}
                inputValue={this.props.approvalSelection}
              />
            </>
          )}
        {this.props.selectedTaskData.type ===
          strings.amendmentTypes.duplicateWarning &&
          !this.props.selectedTaskData.cleared && (
            <>
              <Button.Group>
                <Button
                  className="activationButton"
                  primary
                  inverted
                  onClick={
                    this.props.toggleEnableDisableTaskApprovalModalVisibility
                  }
                  type="button"
                >
                  {strings.button.actionLabel}
                </Button>
              </Button.Group>
              <InputModal
                confirmMessage={strings.modal.actionTaskMessage}
                handleModalConfirm={this.props.handleModalConfirm}
                modalClassName="approveDuplicateModal"
                modalOpen={this.props.enableDisableTaskApprovalModalOpen}
                showOkButton={true}
                toggleModal={
                  this.props.toggleEnableDisableTaskApprovalModalVisibility
                }
                okLabel={strings.button.confirm}
                resetDataOnModalClose={() => this.props.resetDataOnModalClose()}
                input={approvalDropdown}
                inputRequired={true}
                inputValue={this.props.approvalSelection}
              />
            </>
          )}
        {this.props.selectedTaskData.type ===
          strings.amendmentTypes.mentorAssigned &&
          !this.props.selectedTaskData.cleared && (
            <>
              <Button.Group>
                <Button
                  className="activationButton"
                  primary
                  inverted
                  onClick={
                    this.props.toggleEnableDisableTaskApprovalModalVisibility
                  }
                  type="button"
                >
                  {strings.button.actionLabel}
                </Button>
              </Button.Group>
              <InputModal
                confirmMessage={strings.modal.actionTaskMessage}
                handleModalConfirm={this.props.handleModalConfirm}
                modalClassName="assignmentModal"
                modalOpen={this.props.enableDisableTaskApprovalModalOpen}
                showOkButton={true}
                toggleModal={
                  this.props.toggleEnableDisableTaskApprovalModalVisibility
                }
                disabled={
                  this.props.approvalSelection ===
                    strings.fieldValues.rejected &&
                  this.props.rejectionReason.length < 1
                }
                okLabel={strings.button.confirm}
                resetDataOnModalClose={() => this.props.resetDataOnModalClose()}
                input={approvalDropdown}
                inputRequired={true}
                inputValue={this.props.approvalSelection}
              >
                <div className="spaceAbove">
                  {this.props.approvalSelection ===
                    strings.fieldValues.rejected && rejectionReasonInput}
                </div>
              </InputModal>
            </>
          )}
      </div>
    );
  };
  renderCancelSaveButtons = () => {
    return (
      <div className="containerButtons">
        <Button.Group>
          <Button
            className="saveButton"
            primary
            inverted
            onClick={this.props.validateForm}
            disabled={
              JSON.stringify(this.props.selectedTaskData) ===
              JSON.stringify(this.props.initialTaskData)
            }
          >
            {strings.button.saveLabel}
          </Button>
          <BasicModal
            confirmMessage={strings.modal.saveChangesMessage}
            handleModalConfirm={this.props.handleModalConfirm}
            modalClassName="confirmSaveModal"
            modalOpen={this.props.confirmSaveModalOpen}
            showNoButton={true}
            showOkButton={false}
            showYesButton={true}
            toggleModal={this.props.toggleConfirmSaveModalVisibility}
            noLabel={strings.button.noLabel}
            yesLabel={strings.button.yesLabel}
            okLabel={strings.button.okLabel}
          />
        </Button.Group>
        <Button.Group>
          <Button
            className="cancelButton"
            primary
            onClick={this.props.toggleCancelChangesModalVisibility}
            type="button"
          >
            {strings.button.cancelLabel}
          </Button>
          <BasicModal
            confirmMessage={strings.modal.cancelChangesMessage}
            handleModalConfirm={
              this.props.mode === "create"
                ? this.props.handleModalConfirm
                : this.props.handleModeSwitch
            }
            modalClassName="cancelChangesModal"
            modalOpen={this.props.cancelChangesModalOpen}
            showOkButton={false}
            showYesButton={true}
            showNoButton={true}
            toggleModal={this.props.toggleCancelChangesModalVisibility}
            noLabel={strings.button.noLabel}
            yesLabel={strings.button.yesLabel}
            okLabel={strings.button.okLabel}
          />
        </Button.Group>
      </div>
    );
  };
  renderForm = () => {
    return (
      <>
        <div className="infoHeader">{strings.form.header.taskInfo}</div>
        <div className="infoWrapper">
          <Form.Group>
            <ValidatedFormInput
              formInvalid={this.props.formInvalid}
              inputData={this.props.selectedTaskData.crmId}
              inputLabel={strings.form.label.crmId}
              inputName="crmId"
              inputPlaceholder={strings.form.placeholder.crmId}
              formFieldWidth={8}
              handleChange={this.props.handleChange}
              validationResult={this.props.validationResults.crmId}
              mode={this.props.mode}
              required={false}
            />
            <ValidatedFormInput
              formInvalid={this.props.formInvalid}
              inputData={this.props.selectedTaskData.prisonNumber}
              inputLabel={strings.form.label.pNumber}
              inputName="pNumber"
              inputPlaceholder={strings.form.placeholder.pNumber}
              formFieldWidth={8}
              handleChange={this.props.handleChange}
              validationResult={this.props.validationResults.pNumber}
              mode={this.props.mode}
              required={false}
            />
          </Form.Group>
          <Form.Group>
            <ValidatedFormInput
              formInvalid={this.props.formInvalid}
              inputData={
                this.props.selectedTaskData.clearReason
                  ? this.props.selectedTaskData.clearReason
                  : ""
              }
              inputLabel={strings.form.label.clearReason}
              inputName="clearReason"
              inputPlaceholder={strings.form.placeholder.clearReason}
              formFieldWidth={8}
              handleChange={this.props.handleChange}
              validationResult={this.props.validationResults.clearReason}
              mode={this.props.mode}
              required={false}
            />
            <ValidatedDateInput
              inputId="dueDate"
              formInvalid={this.props.formInvalid}
              inputLabel={strings.form.label.dueDate}
              inputName="dueDate"
              inputPlaceholder={strings.form.placeholder.dueDate}
              inputDateFormat="DD/MM/YYYY"
              inputData={
                this.props.selectedTaskData.dueDate
                  ? convertFromIso(this.props.selectedTaskData.dueDate)
                  : ""
              }
              iconPosition="left"
              handleChange={this.props.handleChange}
              validationResult={this.props.validationResults.dueDate}
              mode={this.props.mode}
              formFieldWidth={8}
              required={false}
            />
          </Form.Group>
          <Form.Group>
            <ValidatedFormInput
              formInvalid={this.props.formInvalid}
              inputData={this.props.selectedTaskData.customerForename}
              inputLabel={strings.form.label.firstName}
              inputName="firstName"
              inputPlaceholder={strings.form.placeholder.firstName}
              formFieldWidth={8}
              handleChange={this.props.handleChange}
              validationResult={this.props.validationResults.firstName}
              mode={this.props.mode}
              required={false}
            />
            <ValidatedFormInput
              formInvalid={this.props.formInvalid}
              inputData={this.props.selectedTaskData.customerSurname}
              inputLabel={strings.form.label.surname}
              inputName="surname"
              inputPlaceholder={strings.form.placeholder.surname}
              formFieldWidth={8}
              handleChange={this.props.handleChange}
              validationResult={this.props.validationResults.surname}
              required={false}
              mode={this.props.mode}
            />
          </Form.Group>
          <Form.Group>
            <ValidatedFormTextArea
              formInvalid={this.props.formInvalid}
              inputData={this.props.selectedTaskData.description}
              inputLabel={strings.form.label.description}
              inputName="description"
              inputPlaceholder={strings.form.placeholder.description}
              formFieldWidth={8}
              handleChange={this.props.handleChange}
              required={false}
              mode={this.props.mode}
            />
            <ValidatedFormInput
              formInvalid={this.props.formInvalid}
              inputData={
                this.props.selectedTaskData.cleared
                  ? strings.fieldValues.yes
                  : strings.fieldValues.no
              }
              inputLabel={strings.form.label.cleared}
              inputName="cleared"
              inputPlaceholder={strings.form.placeholder.cleared}
              formFieldWidth={8}
              handleChange={this.props.handleChange}
              validationResult={this.props.validationResults.cleared}
              mode={this.props.mode}
              required={false}
            />
          </Form.Group>
          {(this.props.selectedTaskData.type ===
            strings.amendmentTypes.deleteAmendment ||
            this.props.selectedTaskData.type ===
              strings.amendmentTypes.changeAmendment) && (
            <Form.Group>
              <ValidatedFormInput
                formInvalid={this.props.formInvalid}
                inputData={
                  this.props.deleteReason.value
                    ? this.props.deleteReason.value
                    : this.props.deleteReason
                }
                inputLabel={strings.form.label.deleteReason}
                inputName="deleteReason"
                inputPlaceholder={strings.form.placeholder.deleteReason}
                formFieldWidth={8}
                handleChange={this.props.handleChange}
                validationResult={this.props.validationResults.deleteReason}
                disabled={this.props.mode === "edit"}
                required={false}
                mode={this.props.mode}
              />
            </Form.Group>
          )}
          {this.props.selectedTaskData.type ===
            strings.amendmentTypes.mentorAssigned &&
            this.props.selectedTaskData.rejectionReason && (
              <Form.Group>
                <ValidatedFormInput
                  formInvalid={this.props.formInvalid}
                  inputData={this.props.selectedTaskData.rejectionReason}
                  inputLabel={strings.form.label.rejectionReason}
                  inputName="rejectionReason"
                  inputPlaceholder={strings.form.placeholder.rejectionReason}
                  formFieldWidth={8}
                  handleChange={this.props.handleChange}
                  validationResult={
                    this.props.validationResults.rejectionReason
                  }
                  disabled={this.props.mode === "edit"}
                  required={false}
                  mode={this.props.mode}
                />
              </Form.Group>
            )}
        </div>
      </>
    );
  };

  render = () => {
    return (
      <div className="container">
        <ComponentHeader
          returnToList={this.returnToList}
          pageTitle={this.props.pageTitle}
          mode={this.props.mode}
          requestStatus={this.props.requestStatus}
          modifyButtons={this.renderModifyButtons()}
          cancelSaveButtons={this.renderCancelSaveButtons()}
          headerList={strings.header.taskList}
          permissionsUpdate={permissions.tasks.update}
          permissionsCreate={permissions.tasks.create}
          sticky
        />

        <Form id="taskForm" className="infoBlock stickyForm">
          <fieldset disabled={this.props.mode === "view"}>
            {this.renderForm()}
          </fieldset>
        </Form>
      </div>
    );
  };
}

ModifyTask.propTypes = {
  mode: PropTypes.string.isRequired,
  selectedTaskData: PropTypes.object.isRequired,
  pageTitle: PropTypes.string.isRequired,
  confirmSaveModalOpen: PropTypes.bool.isRequired,
  cancelChangesModalOpen: PropTypes.bool.isRequired,
  toggleConfirmSaveModalVisibility: PropTypes.func.isRequired,
  toggleCancelChangesModalVisibility: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleModalConfirm: PropTypes.func.isRequired,
  formInvalid: PropTypes.bool.isRequired,
  validateForm: PropTypes.func.isRequired,
  validationResults: PropTypes.object,
  requestStatus: PropTypes.bool,
  initialTaskData: PropTypes.object.isRequired,
  deleteModalOpen: PropTypes.bool.isRequired,
  clearTaskReason: PropTypes.string,
  handleChangeModal: PropTypes.func,
  enableDisableTaskApprovalModalOpen: PropTypes.bool,
  toggleEnableDisableTaskApprovalModalVisibility: PropTypes.func,
  handleApprovalModal: PropTypes.func,
  handleApprovalDropdown: PropTypes.func,
  approvalSelection: PropTypes.string,
  deleteReason: PropTypes.string
};

export default withRouter(ModifyTask);
