import { amendmentActionTypes } from "../actions/amendment.actionTypes";

const initialState = {
  permissionStatus: "",
  requestId: "",
  deleteReason: ""
};

export default (state = initialState, action) => {
  switch (action.type) {
    case amendmentActionTypes.REQUEST_DELETE_PERMISSION_REQUEST:
      return {
        ...state
      };
    case amendmentActionTypes.REQUEST_DELETE_PERMISSION_SUCCESS:
      return {
        ...state,
        requestId: action.response.data.Data.requestId
      };
    case amendmentActionTypes.REQUEST_DELETE_PERMISSION_FAILURE:
      return {
        ...state,
        error: action.error.response.data.Reason
      };

    case amendmentActionTypes.GET_DELETE_PERMISSION_STATUS_REQUEST:
      return {
        ...state,
        statusRequestMade: true
      };
    case amendmentActionTypes.GET_DELETE_PERMISSION_STATUS_SUCCESS:
      return {
        ...state,
        statusRequestMade: false,
        permissionStatus: action.response.Data.status,
        deleteReason: action.response.Data.requestDetails.reason
      };
    case amendmentActionTypes.GET_DELETE_PERMISSION_STATUS_FAILURE:
      return {
        ...state,
        error: action.error.response.data.Reason,
        statusRequestMade: false
      };
    case amendmentActionTypes.SET_DELETE_PERMISSION_STATUS_ACTIVE_REQUEST:
      return {
        ...state
      };
    case amendmentActionTypes.SET_DELETE_PERMISSION_STATUS_ACTIVE_SUCCESS:
      return {
        ...state,
        requestId: "",
        permissionStatus: "Actioned"
      };
    case amendmentActionTypes.SET_DELETE_PERMISSION_STATUS_ACTIVE_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case amendmentActionTypes.UPDATE_DELETE_PERMISSION_STATUS_REQUEST:
      return {
        ...state
      };
    case amendmentActionTypes.UPDATE_DELETE_PERMISSION_STATUS_SUCCESS:
      return {
        ...state
      };
    case amendmentActionTypes.UPDATE_DELETE_PERMISSION_STATUS_FAILURE:
      return {
        ...state,
        error: action.error
      };

    default:
      return state;
  }
};
