const colors = {
  green: "rgba(0,179,0,1)",
  orange: "rgba(255,149,0,1)",
  red: "rgba(204,51,51,1)"
};

const emptyTaskListDataByType = {
  customerTransfer: [],
  duplicateWarning: [],
  baselinePrerelease: [],
  baselineFourWeek: [],
  baselineExit: [],
  govFourWeek: [],
  govMidpoint: [],
  govExit: [],
  signupMeeting: [],
  prereleaseMeeting: [],
  midpointMeeting: [],
  exitMeeting: [],
  feedbackPrerelease: [],
  feedbackFourWeeks: [],
  feedbackExit: [],
  changeAmendment: [],
  deleteAmendment: [],
  gateLiberation: [],
  mentorAssigned: [],
  weeklyEngagement: [],
  monthlyEngagement: []
};
const emptyTaskListDataByDate = {
  customerTransfer: { due: [], dueSoon: [], dueVerySoon: [] },
  duplicateWarning: { due: [], dueSoon: [], dueVerySoon: [] },
  baselinePrerelease: { due: [], dueSoon: [], dueVerySoon: [] },
  baselineFourWeek: { due: [], dueSoon: [], dueVerySoon: [] },
  baselineExit: { due: [], dueSoon: [], dueVerySoon: [] },
  govFourWeek: { due: [], dueSoon: [], dueVerySoon: [] },
  govMidpoint: { due: [], dueSoon: [], dueVerySoon: [] },
  govExit: { due: [], dueSoon: [], dueVerySoon: [] },
  signupMeeting: { due: [], dueSoon: [], dueVerySoon: [] },
  prereleaseMeeting: { due: [], dueSoon: [], dueVerySoon: [] },
  midpointMeeting: { due: [], dueSoon: [], dueVerySoon: [] },
  exitMeeting: { due: [], dueSoon: [], dueVerySoon: [] },
  feedbackPrerelease: { due: [], dueSoon: [], dueVerySoon: [] },
  feedbackFourWeeks: { due: [], dueSoon: [], dueVerySoon: [] },
  feedbackExit: { due: [], dueSoon: [], dueVerySoon: [] },
  changeAmendment: { due: [], dueSoon: [], dueVerySoon: [] },
  deleteAmendment: { due: [], dueSoon: [], dueVerySoon: [] },
  gateLiberation: { due: [], dueSoon: [], dueVerySoon: [] },
  mentorAssigned: { due: [], dueSoon: [], dueVerySoon: [] },
  weeklyEngagement: { due: [], dueSoon: [], dueVerySoon: [] },
  monthlyEngagement: { due: [], dueSoon: [], dueVerySoon: [] }
};

export { colors, emptyTaskListDataByType, emptyTaskListDataByDate };
